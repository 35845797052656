// monacotto App.js

import React, { useState, useReducer, useContext, useEffect, useRef, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useDropzone } from 'react-dropzone';
import { CSSTransition } from 'react-transition-group';
import { useCookies } from 'react-cookie';
// import QRCode from 'qrcode.react';
import jsQR from 'jsqr';
import decimal from 'decimal.js';
import * as bip39 from 'bip39';
import BIP32Factory from 'bip32';
import ECPairFactory from 'ecpair';
import ecc from '@bitcoinerlab/secp256k1';
import coininfo from 'coininfo';
import * as bitcoin from 'bitcoinjs-lib';
import * as bitcoinMessage from 'bitcoinjs-message';
import { TwitterShareButton, TwitterIcon } from 'react-share';
import './App.css';
import iconMpurse from './mpurse.png';
import iconLeftBlack from './baseline_arrow_left_black_18dp.png';
import iconRightBlack from './baseline_arrow_right_black_18dp.png';
import iconCircleLeft from './arrow_circle_left_FILL0_wght400_GRAD0_opsz48.svg';
import iconCircleRight from './arrow_circle_right_FILL0_wght400_GRAD0_opsz48.svg';
import iconLogin from './login_black_24dp.svg';
import iconDetail from './more_horiz_FILL0_wght400_GRAD0_opsz48.svg';
import iconSign1 from './border_color_FILL0_wght400_GRAD0_opsz48.svg';
import iconSearch from './search_FILL0_wght400_GRAD0_opsz48.svg';
import iconHandShake from './handshake_FILL0_wght400_GRAD0_opsz48.svg';
import iconSign from './history_edu_FILL0_wght400_GRAD0_opsz48.svg';
import iconCard from './book_FILL0_wght400_GRAD0_opsz48.svg';
import iconLock from './lock_FILL0_wght400_GRAD0_opsz48.svg';
import iconList from './format_list_bulleted_FILL0_wght400_GRAD0_opsz48.svg';
import iconHome from './home_FILL0_wght400_GRAD0_opsz48.svg';
import iconLoginList from './patient_list_FILL0_wght400_GRAD0_opsz48.svg';
import iconReload from './refresh_black_24dp.svg';
import iconZero from './counter_0_FILL0_wght400_GRAD0_opsz48.svg';
import iconSwords from './swords_24dp_FILL0_wght400_GRAD0_opsz24.svg';
import iconEdit from './edit_FILL0_wght400_GRAD0_opsz48.svg';
import iconCotto from './cotto.png';
import iconCopy from './content_copy_FILL0_wght400_GRAD0_opsz48.svg';
import iconKeyCard from './playing_cards_FILL0_wght400_GRAD0_opsz24.svg';
import iconQr from './qr_code_2_FILL0_wght400_GRAD0_opsz24.svg';
import iconFilter from './filter_alt_24dp_FILL0_wght400_GRAD0_opsz24.svg';
import iconExpand from './expand_all_24dp_000000_FILL0_wght400_GRAD0_opsz24.svg';
import iconCollapse from './collapse_all_24dp_000000_FILL0_wght400_GRAD0_opsz24.svg';
import iconAddress from './slab_serif_24dp_000000_FILL0_wght400_GRAD0_opsz24.svg';
import logoMonacotto from './logoMonacotto.png';
import imageManualMonachen from './howToUse_monachen.png';
import imageManualPurchase03 from './howToUse_purchase03.png';
import imageManualPurchase04 from './howToUse_purchase04.png';
import imageManualPurchase05 from './howToUse_purchase05.png';
import imageManualPurchaseImageButton from './howToUse_purchase_imageButton.png';
import imageManualExhibit03 from './howToUse_exhibit03.png';
import imageManualExhibit04 from './howToUse_exhibit04.png';
import imageManualExhibit05 from './howToUse_exhibit05.png';
import imageManualExhibit06 from './howToUse_exhibit06.png';
import imageManualMpurse01 from './mpurse01.png';
import imageManualMpurse02 from './mpurse02.png';
import imageManualMpurse03 from './mpurse03.png';
import imageManualMpurse04 from './mpurse04.png';
import imageManualMpurse05 from './mpurse05.png';
import imageManualMpurse06 from './mpurse06.png';
import imageManualMpurse07 from './mpurse07.png';
import imageManualMpurse08 from './mpurse08.png';
import imageManualMpurse09 from './mpurse09.png';
import imageManualMpurse10 from './mpurse10.png';
import imageManualMpurseSp01 from './Mpurse_SP_AppStore.jpg';
import imageManualMpurseSp02 from './Mpurse_SP_DTV.jpg';
import imageManualMpurseSp03 from './Mpurse_SP_password.jpg';
import imageManualMpurseSp04 from './Mpurse_SP_seedPhrase.png';
import imageManualMpurseSp05 from './Mpurse_SP_acount1.png';
import imageManualPurchaseSp01 from './Mpurse_SP_menu_browser.png';
import imageManualPurchaseSp02 from './Mpurse_SP_browser.jpg';
import imageManualPurchaseSp03 from './Mpurse_SP_exte_card.png';
import imageManualExhibitSP01 from './Mpurse_SP_exte.png';
import imageManualExhibitSP02 from './Mpurse_SP_exte_issue.png';
import imageManualExhibitSP03 from './Mpurse_SP_menu_wallet.png';
import imageNotAMonacard from './notAMonacard.png';
// import cottoRolling from './cottoRolling.png';
import termsAndConditionsExhibitorHtml from './tac_exhibitor.static.link';
import termsAndConditionsPurchaserHtml from './tac_purchaser.static.link';
import termsAndConditionsOwnerHtml from './tac_owner.static.link';
import privacyPolicyHtml from './privacypolicy.static.link';

const words = require('./words.json');
const bip32 = BIP32Factory(ecc);
const ECPair = ECPairFactory(ecc);
const networkMona = coininfo('MONA').toBitcoinJS();

const notificationMaxLength = 100;
// const signatureVersion = '1';
// const versionOfPrivacyPolicy = '1';
const royaltyPercentageDigit = -6;
const priceDigit = -6;
const marginOfSessionTime = 60000;

let GlobalState;
let wallet;


// AppConnect対応
// porting nodejs EventEmitter
const EventEmitter=function(){
  function EventEmitter(){
    this._events=this._events||{};
    this._maxListeners=this._maxListeners||undefined
  }

  EventEmitter.EventEmitter=EventEmitter;
  EventEmitter.prototype._events=undefined;
  EventEmitter.prototype._maxListeners=undefined;
  EventEmitter.defaultMaxListeners=10;

  EventEmitter.prototype.setMaxListeners=function(n){
    if(!isNumber(n)||n<0||isNaN(n))
      throw TypeError("n must be a positive number");

    this._maxListeners=n;
    return this
  };

  EventEmitter.prototype.emit=function(type){
    var er,handler,len,args,i,listeners;
    if(!this._events)
      this._events={};
    if(type==="error"){
      if(!this._events.error||isObject(this._events.error)&&!this._events.error.length){
        er=arguments[1];

        if(er instanceof Error){
          throw er
        }
        else{
          var err=new Error('Uncaught, unspecified "error" event. ('+er+")");
          err.context=er;
          throw err
        }
      }
    }

    handler=this._events[type];

    if(isUndefined(handler))
      return false;

    if(isFunction(handler)){
      switch(arguments.length){
        case 1:
          handler.call(this);
          break;
        case 2:
          handler.call(this,arguments[1]);
          break;
        case 3:
          handler.call(this,arguments[1],arguments[2]);
          break;
        default:args=Array.prototype.slice.call(arguments,1);
          handler.apply(this,args)
      }
    }
    else if(isObject(handler)){
      args=Array.prototype.slice.call(arguments,1);
      listeners=handler.slice();
      len=listeners.length;
      for(i=0;i<len;i++)
        listeners[i].apply(this,args)
    }

    return true
  };

  EventEmitter.prototype.addListener=function(type,listener){
    var m;

    if(!isFunction(listener))
      throw TypeError("listener must be a function");

    if(!this._events)
      this._events={};

    if(this._events.newListener)
      this.emit("newListener",type,isFunction(listener.listener)?listener.listener:listener);

    if(!this._events[type])
      this._events[type]=listener;
    else if(isObject(this._events[type]))
      this._events[type].push(listener);
    else
      this._events[type]=[this._events[type],listener];

    if(isObject(this._events[type])&&!this._events[type].warned){
      if(!isUndefined(this._maxListeners)){
        m=this._maxListeners
      }
      else{
        m=EventEmitter.defaultMaxListeners
      }
      if(m&&m>0&&this._events[type].length>m){
        this._events[type].warned=true;
        console.error("(node) warning: possible EventEmitter memory "+"leak detected. %d listeners added. "+"Use emitter.setMaxListeners() to increase limit.",this._events[type].length);

        if(typeof console.trace==="function"){
          console.trace()
        }
      }
    }

    return this
  };

  EventEmitter.prototype.on=EventEmitter.prototype.addListener;
  EventEmitter.prototype.once=function(type,listener){
    if(!isFunction(listener))
      throw TypeError("listener must be a function");

    var fired=false;

    function g(){
      this.removeListener(type,g);

      if(!fired){
        fired=true;
        listener.apply(this,arguments)
      }
    }

    g.listener=listener;
    this.on(type,g);
    return this
  };

  EventEmitter.prototype.removeListener=function(type,listener){
    var list,position,length,i;

    if(!isFunction(listener))
      throw TypeError("listener must be a function");

    if(!this._events||!this._events[type])
      return this;

    list=this._events[type];
    length=list.length;
    position=-1;

    if(list===listener||isFunction(list.listener)&&list.listener===listener){
      delete this._events[type];
      if(this._events.removeListener)this.emit("removeListener",type,listener)
    }
    else if(isObject(list)){
      for(i=length;i-- >0;){
        if(list[i]===listener||list[i].listener&&list[i].listener===listener){
          position=i;
          break
        }
      }

      if(position<0)
        return this;

      if(list.length===1){
        list.length=0;
        delete this._events[type]
      }
      else{
        list.splice(position,1)
      }

      if(this._events.removeListener)
        this.emit("removeListener",type,listener)
    }

    return this
  };

  EventEmitter.prototype.removeAllListeners=function(type){
    var key,listeners;
    if(!this._events)
      return this;

    if(!this._events.removeListener){
      if(arguments.length===0)
        this._events={};
      else if(this._events[type])
        delete this._events[type];

      return this
    }

    if(arguments.length===0){
      for(key in this._events){
        if(key==="removeListener")
          continue;

        this.removeAllListeners(key)
      }

      this.removeAllListeners("removeListener");
      this._events={};
      return this
    }

    listeners=this._events[type];

    if(isFunction(listeners)){
      this.removeListener(type,listeners)
    }
    else if(listeners){
      while(listeners.length)
        this.removeListener(type,listeners[listeners.length-1])
    }

    delete this._events[type];
    return this
  };

  EventEmitter.prototype.listeners=function(type){
    var ret;

    if(!this._events||!this._events[type])
      ret=[];
    else if(isFunction(this._events[type]))
      ret=[this._events[type]];
    else
      ret=this._events[type].slice();

    return ret
  };

  EventEmitter.prototype.listenerCount=function(type){
    if(this._events){
      var evlistener=this._events[type];

      if(isFunction(evlistener))
        return 1;
      else if(evlistener)
        return evlistener.length
    }
    return 0
  };

  EventEmitter.listenerCount=function(emitter,type){
    return emitter.listenerCount(type)
  };

  function isFunction(arg){
    return typeof arg==="function"
  }

  function isNumber(arg){
    return typeof arg==="number"
  }

  function isObject(arg){
    return typeof arg==="object"&&arg!==null
  }

  function isUndefined(arg){
    return arg===void 0
  }

  return EventEmitter
}();

const ORIGIN = 'https://monapalette.komikikaku.com';
const SUPPORTED_METHODS = ['getAddress', 'sendAsset', 'signRawTransaction', 'signMessage', 'sendRawTransaction', 'counterBlock', 'counterParty'];
const monapaletteConnect = {};
const id2messageListener = {};
const sendMethod = async(method, params) => {
    if (!window.parent) throw new Error('AppConnect not found');
    const id = Math.random().toFixed(10).slice(-10);
    return await new Promise((resolve, reject) => {
        id2messageListener[id] = (event) => {
            try {
                if (event.origin !== ORIGIN) return;
                const data = event.data;
                if (data.id !== id) return;
                delete id2messageListener[id];
                if (data.error) reject(data.error);
                else resolve(data.value);
            }
            catch (error) { reject(error) }
        };
        window.parent.postMessage({ id, method, params }, ORIGIN);
    });
};
for (const method of SUPPORTED_METHODS) monapaletteConnect[method] = (...params) => sendMethod(method, params);
monapaletteConnect.updateEmitter = new EventEmitter();
window.monapaletteConnect = monapaletteConnect;
window.addEventListener('message', (event) => {
    for (const id in id2messageListener) id2messageListener[id](event);
});

// console.log('ancestor', window.location.ancestorOrigins[0]);

if (window.location.ancestorOrigins !== undefined && window.location.ancestorOrigins[0] === ORIGIN) {
  wallet = 'MonaPallet';
}
else {
  wallet = 'Mpurse';
  // wallet = 'MonaPallet';
}


function App() {
  const urlParams = (new URL(document.location)).searchParams;

  const initialState = {
    config: {
      clientParameters: {
        versionOfTheTermsAndConditions: 'dummy',
      },
    },
    balance: {},
    balanceKOM: {},
    language: urlParams.get('language') === null ? 'japanese' : urlParams.get('language'),
    device: {
      hasCamera: undefined,
    },
    permit: {
      action: 'grant',
      mode: 'individual', // inclusive
      addressOwner: '',
      // addressOwnerCoinType: 'mona',
      addressPermitTo: '',
      addressPayRoyaltyTo: '',
      // ownerName: '',
      royaltyPercentage: { face: 0, value: 0 },
    },
    requestPermission: {
      addressMain: '',
      addressOwner: '',
      royaltyPercentage: { face: 0, value: 0 },
    },
    login: {
      addressMain: '',
      // addressMainCoinType: 'mona',
      displayAddressSection: false,
    },
    configure: {
      addressMain: '',
      // addressMainCoinType: 'mona',
      addressCardFromDefault: '',
      addressPayProceedsToDefault: '',
      addressPayFromDefault: '',
      addressSendCardToDefault: '',
      userName: '',
      images: {
        main: null,
      },
      imagesNew: {
        main: null,
        mainUrl: null,
      },
      readTheTermsAndConditions: false,
      readPrivacyPolicy: false,
      acceptedVersionOfTheTermsAndConditions: null,
      acceptedVersionOfPrivacyPolicy: null,
      homed: false,
      profileText: '',
      displayAddressSection: false,
      status: null,
    },
    keyPairs: {},
    delegate: {
      action: 'request',
      addressDelegateFrom: '',
      addressDelegateTo: '',
    },
    exhibit: {
      exhibitNo: null,
      addressMain: '',
      // addressMainCoinType: 'mona',
      addressCardFrom: '',
      addressPayProceedsTo: '',
      monacottoAddressMonaparty: '',
      tokenName: '',
      amountToSell: '',
      priceMona: { face: '', value: '' },
      priceMonaWatanabe: '',
      signatureByAddressMain: '',
      signatureByAddressCardFrom: '',
      divisible: null,
      disabled: false,
      status: 'waitingForSignature',
    },
    cancelExhibit: {
      exhibitNo: null,
      addressMain: '',
      // addressMainCoinType: 'mona',
      // amountFree: null,
      tokenName: null,
      amountToSell: null,
      signatureByAddressMainCancel: null,
      status: 'waitingForSignature',
    },
    changeExhibit: {
      exhibitNo: null,
      addressMain: '',
      // addressMainCoinType: 'mona',
      addressPayProceedsTo: '',
      // amountToSell: null,
      priceMona: { face: '', value: '' },
      // priceMonaWatanabe: '',
      tokenName: null,
      amountToSell: null,
      signatureByAddressMainChange: null,
      disabled: false,
      status: 'waitingForSignature',
    },
    getHistory: {
      addressMain: '',
      addressType: 'addressOwner',
      action: 'exhibit',
      lastEvaluatedKey: {
        exhibit: [],
        purchase: [],
        sales: [],
        royaltyAddressOwner: [],
        royaltyAddressPayRoyaltyTo: [],
      },
      pagingIndex: {
        exhibit: 0,
        purchase: 0,
        sales: 0,
        royaltyAddressOwner: 0,
        royaltyAddressPayRoyaltyTo: 0,
      },
      cancelAction: 'cancel',
    },
    getPermissionHistory: {
      addressMain: '',
      action: 'permit' ,
      lastEvaluatedKey: {
        permit: [],
        permitted: [],
        permittedInclusively: [],
        requestPermission: [],
        requestedPermission: [],
        delegate: [],
        delegated: [],
      },
      pagingIndex: {
        permit: 0,
        permitted: 0,
        permittedInclusively: 0,
        requestPermission: 0,
        requestedPermission: 0,
        delegate: 0,
        delegated: 0,
      }
    },
    getUser: {
      addressMain: '',
    },
    getItem: {
      addressMain: '',
    },
    purchase: {
      purchaseNo: null,
      addressMain: '',
      addressPayFrom: '',
      addressSendCardTo: '',
      addressMainExhibitor: '',
      exhibitNo: '',
      amountToBuy: 1,
      signatureByAddressMain: '',
      signatureByAddressPayFrom: '',
      addressPayRoyaltyTo: null,
      disabled: false,
      status: 'waitingForSignature',
    },
    addressCheck: 'off',
    gallery: {
      addressMain: urlParams.get('mainaddress') === null ? null : urlParams.get('mainaddress'),
    },
    itemDetail: {
      exhibitor: {},
      item: {},
    },
    itemPlacement: [
    ],
    user: {},
    usersGeneral: [],
    usersGeneralIndex: {},
    exhibitHistory: {},
    items: {},
    itemsNew: {},
    itemsAll: {
      item: [],
    },
    purchaseHistory: {},
    salesHistory: {},
    royaltyAddressOwnerHistory: {},
    royaltyAddressPayRoyaltyToHistory: {},
    assetInfo: {},
    monacard: {},
    permitHistory: {},
    permittedHistory: {},
    permittedInclusivelyHistory: {},
    requestPermissionHistory: {},
    requestedPermissionHistory: {},
    delegateHistory: {},
    delegatedHistory: {},
    session: {},
    // acceptTheTermsAndConditions: '1',
    notification: {
      body: [ '' ],
      index: 0,
      fixed: false,
      inAnimation: false,
    },
    popup: [
      { type: null, body: null },
      { type: null, body: null },
      { type: null, body: null },
    ],
    accessing: false,
    screen: null,
    balanceFilter: false,
    registeredCard: {},
    creatorInformation: {
      creater: '',
      createrText: '',
      monacottoAddressMain: '',
      createrLink: [
        {
          title: '',
          url: '',
        },
        {
          title: '',
          url: '',
        },
        {
          title: '',
          url: '',
        },
      ],
    },
    walletType: urlParams.get('wallettype') === null ? 'mpurse' : urlParams.get('wallettype'),
    qrCodeScanner: {
      qrCode: undefined,
    },
    tokenFilter: {
      conditions: [
        {
          assetCommon: [],
          addressOwners: [],
          lockStatus: [],
          monacardName: [],
          monacardIssuerNames: [],
          monacardDescription: [],
          monacardTags: [],
          monadom: [],
        },
      ],
      addressOwner: null,
      lockStatus: null,
      monacardName: null,
      monacardIssuerName: null,
      monacardDescription: null,
      monacardTag: null,
      monadom: null,
      conditionIndex: 0,
    },
    cartMonaparty: {
    },
    cartMona: {
    },
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  GlobalState = React.createContext([state, dispatch, navigate, cookies, setCookie, removeCookie]);


  useEffect( () => {
    devLog('useEffect; getConfig');

    const func = async () => {
      // AppConnect対応
      window.monapaletteConnect.getAddress().then(() => window.mpurse = window.monapaletteConnect).catch(() => {});

      // カメラある？

      hasCamera().then((hasCamera) => {
        dispatch({ type: 'setStateMultiLayers', keys: ['device', 'hasCamera'], value: hasCamera });
        devLog('has camera; ', hasCamera);
      });

      // 各種設定値取得
      devLog('mainaddress', urlParams.get('mainaddress'));

      // -- cookie情報取得
      if (cookies.addressCheck !== undefined) {
        dispatch({ type: 'setState', key: 'addressCheck', value: cookies.addressCheck });
      }

      dispatch({ type: 'setState', key: 'accessing', value: true });

      const configKeys = ['clientParameters', 'monacottoAddress', 'initialNotification', 'blockbook'];
      const config = await getConfig(dispatch, configKeys);

      // 初期通知表示
      // dispatch({ type: 'setStateMultiLayers', keys: ['notification', 'body', 0], value: config.body.initialNotification.value });

      // -- ユーザ情報取得

      const usersGeneral = await getUser(state, dispatch, 'all');
      devLog('usersGeneral', JSON.stringify(usersGeneral));

      dispatch({ type: 'setState', key: 'accessing', value: false });

      let usersGeneralIndex = {};

      for (const user of usersGeneral.body) {
        usersGeneralIndex[user.addressMain] = user;
      }

      dispatch({ type: 'setState', key: 'usersGeneralIndex', value: usersGeneralIndex });

      // -- モナダム情報取得

      getRegisteredCard(state, dispatch);

      devLog('domain', document.domain);
      // console.log('domain', document.domain);
    };

    func();
  }, []);

  return (
    <div className="App ">
      <div className="">
        <Routes>
          {/* <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/' : '/'} element={<LP />} /> */}
          {/* <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/index.html' : '/index.html'} element={<LP />} /> */}
          <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/' : '/'} element={<NewCard />} />
          <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/index.html' : '/index.html'} element={<NewCard />} />
          <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/plaza' : '/plaza'} element={<Village />} />
          <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/config' : '/config'} element={<ManagementScreen />}/>
          <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/exhibit' : '/exhibit'} element={<Exhibit />}/>
          <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/home' : '/home'} element={<Gallery />}/>
          <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/item' : '/item'} element={<ItemDetail />}/>
          <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/newcard' : '/newcard'} element={<NewCard />}/>
          <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/allcard' : '/allcard'} element={<AllCard />}/>
          <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/history' : '/history'} element={<History />}/>
          <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/permission' : '/permission'} element={<Permission />}/>
          <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/redirect' : '/redirect'} element={<Redirect />}/>
          <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/howtouse' : '/howtouse'} element={<HowToUse />}/>
          <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/howtouseadvanced' : '/howtouseadvanced'} element={<HowToUseAdvanced />}/>
          <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/howtousempurse' : '/howtousempurse'} element={<HowToUseMpurse />}/>
        </Routes>
      </div>
    </div>
  );
}

// REDIRECT
function Redirect() {
  const [state, dispatch, navigate] = useContext(GlobalState);
  const urlParams = (new URL(document.location)).searchParams;
  const addressMain = urlParams.get('mainaddress') === null ? null : urlParams.get('mainaddress');
  const exhibitNo = urlParams.get('exhibitno') === null ? null : parseInt(urlParams.get('exhibitno'), 10);

  useEffect( () => {
    const func = async () => {
      if (addressMain !== undefined && addressMain !== null && addressMain !== '') {
        if (exhibitNo !== undefined && exhibitNo !== null && exhibitNo !== '') {
          // -- アイテム情報取得
          const items = await getItem(state, dispatch, [addressMain])
          .then( items => {
            devLog('items', JSON.stringify(items));

            // -- アセット情報取得

            getAssetInfoByItems(state, dispatch, items);

            // -- モナカード情報取得
            const assetCommons = Object.keys(items.body).reduce( (acc, cur) => {
              devLog('cur', cur);
              devLog('item', JSON.stringify(items.body[cur].item));
              const assetCommons = items.body[cur].item.map( item => item.asset_longname === null ? item.asset : item.asset_longname );
              return acc.concat(assetCommons);
            }, []);

            getMonacard(state, dispatch, assetCommons);

            // itemDetailセット

            const itemsArray = items.body[addressMain].item.filter( item => item.exhibitNo === exhibitNo );

            if (itemsArray[0] !== undefined && (itemsArray[0].status === 'onSale' || itemsArray[0].status === 'soldOut')) {
              devLog('redirect to ItemDetail');

              dispatch({ type: 'setStateMultiLayers', keys: ['itemDetail', 'item'], value: itemsArray[0] });
              dispatch({ type: 'setStateMultiLayers', keys: ['itemDetail', 'exhibitor'], value: itemsArray[0].addressMain });
              navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/item' : '/item');
            }
            else if (state.gallery.addressMain !== undefined && state.gallery.addressMain !== null && state.gallery.addressMain !== '' &&
                state.usersGeneralIndex[state.gallery.addressMain] !== undefined) {
              devLog('redirect to Gallery');
              navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/home' : '/home');
            }
          })
        }
        else if (state.gallery.addressMain !== undefined && state.gallery.addressMain !== null && state.gallery.addressMain !== '' &&
            state.usersGeneralIndex[state.gallery.addressMain] !== undefined) {
          devLog('redirect to Gallery');
          navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/home' : '/home');
        }
      }
      else {
        devLog('redirect to Village');
        navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/newcard' : '/newcard');
      }
    }

    func();
  });

  return (
    <div>
    </div>
  );
}

// HEADER
function Header(props){
  const [state, dispatch, navigate, cookies, setCookie] = useContext(GlobalState);
  const screen = props.screen;
  const popupLayer = props.popupLayer;
  const popupLayerNext = popupLayer !== undefined ? popupLayer + 1 : 0;

  // リロードボタン
  let reloadButton;

  if (screen === 'newCard' || screen === 'village' || screen === 'allCard') {
    let searchItemType;
    let setItemKey;

    if (screen === 'newCard') {
      searchItemType = 'new';
      setItemKey = 'itemsNew';
    }
    else if (screen === 'village') {
      searchItemType = 'new';
      setItemKey = 'itemsNew';
    }
    else { // allCard
      searchItemType = 'all';
      setItemKey = 'itemsAll';
    }

    reloadButton =
    <button className='button1' onClick={ () => reload(state, dispatch, 'all', searchItemType, setItemKey) } >
      <img className='size2x2' src={iconReload} alt='' />
    </button>;
  }
  else if (screen === 'gallery') {
    reloadButton =
    <button className='button1' onClick={ () => reloadGallery(state, dispatch) } >
      <img className='size2x2' src={iconReload} alt='' />
    </button>;
  }
  else {
    reloadButton = null;
  }

  // フィルタボタン
  let balanceFilter;

  if (screen === 'newCard' || screen === 'gallery' || screen === 'allCard') {
    balanceFilter =
    <button className={'button1' + (state.balanceFilter === true ? ' backgroundColorMonacottoPale' : '')} onClick={ () => dispatch({ type: 'setState', key: 'balanceFilter', value: !state.balanceFilter }) }
    >
      <img className='size2x2' src={iconZero} alt='' />
    </button>;
  }
  else {
    balanceFilter = null;
  }

  // 固定通知
  let fixedWordNotification =
  <div>
    5/2に利用規約の変更を行いました。設定画面でログインし、規約を確認・同意のうえ、署名・登録を行ってください。
  </div>;
  {/*
      <div>
        ただいまお試しサービス期間につき、出品・購入は申込みが必要になります。利用を希望される方は、以下よりお申し込みください。
      </div>
      <button className={'borderNone backgroundColorTransparent marginTop0p5 focusEffect01 cursor'} tabindex='0'
        onClick={ () => window.open('https://spotlight.soy/detail?article_id=v2ao93jto') }
      >
        {'https://spotlight.soy/detail?article_id=v2ao93jto'}
      </button>
  */}


  return (
    <div className='maxWidth100vw' >
      {/* PC */}
      <div className='invisibleSp'>
        <div className='flexColumn'>
          <div className='flexRow justifyContentSpaceBetween' >
            {/* logo */}
            <div className='flexRow'>
              <button className='borderNone backgroundColorTransparent cursor '
                onClick={ () => {
                  devLog(screen);
                  if (screen !== 'village') {
                    navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/newcard' : '/newcard');
                  }
                }}
              >
                <img className='width24 ' src={logoMonacotto} alt='' />
              </button>
              <div>
                { process.env.REACT_APP_ENVIRONMENT === 'dev' ? 'dev' : null }
              </div>
              {/* cotto-chan */}
              <button className='borderNone backgroundColorTransparent cursor marginTop1'
                onClick={ () => dispatch({ type: 'setStateMultiLayers', keys: ['notification', 'fixed'], value: !state.notification.fixed }) }
              >
                <div className={ state.accessing ? 'cottoRolling' : 'cottoStill' } />
              </button>
              { state.notification.fixed ? <Notification /> : <NotificationFadingOut /> }
            </div>
            {/* notification */}
            {/*
              <div className='borderMonacotto padding0p5 flexColumn justifyContentCenter'>
                { fixedWordNotification }
              </div>
            */}
            <div className='flexColumn'>
              {/* language */}
              <div className='flexRow justifyContentFlexEnd marginSide0p5'>
                <button className={'borderNone backgroundColorWhite focusEffect01 borderRadius2 riseOut2 marginSide0p2 ' + (state.language === 'english' ? 'colorRed' : 'cursor')} tabindex='0'
                  onClick={ () => {
                            dispatch({type: 'setState', key: 'language', value: 'english'});
                  }}>
                  english
                </button>
                <button className={'borderNone backgroundColorWhite focusEffect01 borderRadius2 riseOut2 marginSide0p2 ' + (state.language === 'japanese' ? 'colorRed' : 'cursor')} tabindex='0'
                  onClick={ () => {
                            dispatch({type: 'setState', key: 'language', value: 'japanese'});
                  }}>
                  japanese
                </button>
              </div>
              <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop0p5'>
                {/* user registration */}
                <button className='buttonMainColor widthMin12 height3 ' tabindex='0'
                  onClick={ () => {
                    handleClickPopupUserRegistration(state, dispatch);
                  }}
                >
                  <div>
                    { words.userRegistration[state.language] }
                  </div>
                </button>
                {/* login */}
                <button className='buttonMainColor widthMin12 height3 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
                  onClick={ () => {
                    handleClickPopupLogin(state, dispatch);
                  }}
                >
                  <div>
                    { words.login[state.language] }
                  </div>
                  <img className='size2x2' src={iconLogin} alt='' />
                </button>
              </div>
            </div>
          </div>
          {/* menu */}
          <div className='flexRow justifyContentFlexEnd' >
            <div className='flexRow justifyContentFlexEnd marginTop1 marginSide1'>
              { reloadButton }
              { balanceFilter }
            </div>
            <button className='height3 borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginTop1 marginSide0p2' tabindex='0'
              onClick={ () => {
                window.open(`https://${process.env.REACT_APP_MONACHEN_MAIN_URL}`);
              }}
            >
              {words.monachen[state.language]}
            </button>
            <button className='height3 borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginTop1 marginSide0p2'
              onClick={ () => {
                devLog(screen);
                if (screen !== 'newCard') {
                  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/newcard' : '/newcard');
                }
              }}
            >
              {words.newCard[state.language]}
            </button>
            <TokenFilterSection popupLayerNext={popupLayerNext} screen={screen} margin=' marginTop1 marginSide0p2' />
            <button className='height3 borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginTop1 marginSide0p2'
              onClick={ () => {
                devLog(screen);
                if (screen !== 'village') {
                  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/plaza' : '/plaza');
                }
              }}
            >
              {words.goOut[state.language]}
            </button>
            <button className='height3 borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginTop1 marginSide0p2'
              onClick={ () => {
                if (screen !== 'exhibit') {
                  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/exhibit' : '/exhibit');
                }
              }}
            >
              {words.exhibit[state.language]}
            </button>
            <button className='height3 borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginTop1 marginSide0p2'
              onClick={ () => {
                if (screen !== 'history') {
                  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/history' : '/history');
                }
              }}
            >
              {words.history[state.language]}
            </button>
            <button className='height3 borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginTop1 marginSide0p2'
              onClick={ () => {
                if (screen !== 'permission') {
                  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/permission' : '/permission');
                }
              }}
            >
              {words.permissionAndDelegation[state.language]}
            </button>
            <button className='height3 borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginTop1 marginSide0p2'
              onClick={ () => {
                if (screen !== 'managementScreen') {
                  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/config' : '/config');
                }
              }}
            >
              {words.setUp[state.language]}
            </button>
            <button className='height3 borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginTop1 marginSide0p2' tabindex='0'
              onClick={ () => {
                if (screen !== 'howToUse') {
                  /* window.open('https://spotlight.soy/detail?article_id=p7m455wkd'); */
                  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/howtouse' : '/howtouse'); 
                }
              }}
            >
              {words.howToUse[state.language]}
            </button>
          </div>
        </div>
        {/* popup */}
        {/*
          <Popup layer={0}/>
          <Popup layer={1}/>
        */}
      </div>
      {/* SP */}
      <div className='invisiblePc'>
        <div className='flexColumn alignItemsCenter '>
          <div>
            { process.env.REACT_APP_ENVIRONMENT === 'dev' ? 'dev' : null }
          </div>
          <div className='flexRow justifyContentCenter'>
            {/* logo */}
            <button className='borderNone backgroundColorTransparent cursor width75PC'
              onClick={ () => {
                devLog(screen);
                if (screen !== 'village') {
                  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/newcard' : '/newcard');
                }
              }}
            >
              <img className='widthMax' src={logoMonacotto} alt='' />
            </button>
            {/* cotto-chan */}
            <button className='borderNone backgroundColorTransparent cursor '
              onClick={ () => dispatch({ type: 'setStateMultiLayers', keys: ['notification', 'fixed'], value: !state.notification.fixed }) }
            >
              <div className={ state.accessing ? 'cottoRolling' : 'cottoStill' } />
            </button>
          </div>
          {/* cotto-chan */}
          <RollingCottochan />
          { state.notification.fixed ? <Notification /> : <NotificationFadingOut /> }
          {/* notification */}
          {/*
            <div className='borderMonacotto padding0p5'>
              { fixedWordNotification }
            </div>
          */}
          {/* language */}
          <div className='flexRow justifyContentSpaceAround marginSide0p5'>
            <button className={'borderNone backgroundColorWhite focusEffect01 borderRadius2 riseOut2 marginSide0p2 ' + (state.language === 'english' ? 'colorRed' : 'cursor')} tabindex='0'
              onClick={ () => {
                        dispatch({type: 'setState', key: 'language', value: 'english'});
              }}>
              english
            </button>
            <button className={'borderNone backgroundColorWhite focusEffect01 borderRadius2 riseOut2 marginSide0p2 ' + (state.language === 'japanese' ? 'colorRed' : 'cursor')} tabindex='0'
              onClick={ () => {
                        dispatch({type: 'setState', key: 'language', value: 'japanese'});
              }}>
              japanese
            </button>
          </div>
          <div className='flexRow justifyContentCenter alignItemsCenter marginTop0p5 '>
            {/* user registration */}
            <button className='buttonMainColor widthMin12 height3 ' tabindex='0'
              onClick={ () => {
                handleClickPopupUserRegistration(state, dispatch);
              }}
            >
              <div>
                { words.userRegistration[state.language] }
              </div>
            </button>
            {/* login */}
            <button className='buttonMainColor widthMin12 height3 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
              onClick={ () => {
                handleClickPopupLogin(state, dispatch);
              }}
            >
              <div>
                { words.login[state.language] }
              </div>
              <img className='size2x2' src={iconLogin} alt='' />
            </button>
          </div>
          {/* menu */}
          <div className='flexRow justifyContentSpaceAround marginTopBottom1' >
            <div className='marginTopBottom0p3 marginSide1'>
              { reloadButton }
              { balanceFilter }
            </div>
            <button className='height3 borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 marginTopBottom0p3' tabindex='0'
              onClick={ () => {
                window.open(`https://${process.env.REACT_APP_MONACHEN_MAIN_URL}`);
              }}
            >
              {words.monachen[state.language]}
            </button>
            <button className='height3 borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 marginTopBottom0p3'
              onClick={ () => {
                devLog(screen);
                if (screen !== 'newCard') {
                  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/newcard' : '/newcard');
                }
              }}
            >
              {words.newCard[state.language]}
            </button>
            <TokenFilterSection popupLayerNext={popupLayerNext} screen={screen} margin=' marginSide0p2 marginTopBottom0p3' />
            <button className='height3 borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 marginTopBottom0p3'
              onClick={ () => {
                devLog(screen);
                if (screen !== 'village') {
                  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/plaza' : '/plaza');
                }
              }}
            >
              {words.goOut[state.language]}
            </button>
            <button className='height3 borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 marginTopBottom0p3'
              onClick={ () => {
                if (screen !== 'exhibit') {
                  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/exhibit' : '/exhibit');
                }
              }}
            >
              {words.exhibit[state.language]}
            </button>
            <button className='height3 borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 marginTopBottom0p3'
              onClick={ () => {
                if (screen !== 'history') {
                  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/history' : '/history');
                }
              }}
            >
              {words.history[state.language]}
            </button>
            <button className='height3 borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 marginTopBottom0p3'
              onClick={ () => {
                if (screen !== 'permission') {
                  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/permission' : '/permission');
                }
              }}
            >
              {words.permissionAndDelegation[state.language]}
            </button>
            <button className='height3 borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 marginTopBottom0p3'
              onClick={ () => {
                if (screen !== 'managementScreen') {
                  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/config' : '/config');
                }
              }}
            >
              {words.setUp[state.language]}
            </button>
            <button className='height3 borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 marginTopBottom0p3' tabindex='0'
              onClick={ () => {
                if (screen !== 'howToUse') {
                  /* window.open('https://spotlight.soy/detail?article_id=p7m455wkd'); */
                  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/howtouse' : '/howtouse'); 
                }
              }}
            >
              {words.howToUse[state.language]}
            </button>
          </div>
        </div>
        {/* popup */}
        {/*
          <Popup layer={0}/>
          <Popup layer={1}/>
        */}
      </div>
    </div>
  );
}

// FOOTER
function Footer() {
  const [state, dispatch, navigate] = useContext(GlobalState);

  return (
    <div className='footer'>
      <button className={'borderNone backgroundColorTransparent marginSide1 focusEffect01 cursor'} tabindex='0'
        onClick={ () => window.open('https://' + process.env.REACT_APP_ABOUT_US) }
      >
        {words.aboutUs[state.language]}
      </button>
      <button className={'borderNone backgroundColorTransparent marginSide1 focusEffect01 cursor'} tabindex='0'
        onClick={ () => {
          window.open('https://' + process.env.REACT_APP_MONACOTTO_NOTATION_BASED_ON_THE_ACT_ON_SPECIFIED_COMMERCIAL_TRANSACTIONS);
        } }>
        {words.notationBasedOnTheActOnSpecifiedCommercialTransactions[state.language]}
      </button>
      <button className={'borderNone backgroundColorTransparent marginSide1 focusEffect01 cursor'} tabindex='0'
        onClick={ () => {
          window.open('https://spotlight.soy/detail?article_id=ckkgeevvg');
        } }>
        {words.guideline[state.language]}
      </button>
      {/*
        <button className={'borderNone backgroundColorTransparent marginSide1 focusEffect01 cursor'} tabindex='0'
          onClick={ () => {
            window.open('https://' + process.env.REACT_APP_MONACOTTO_PRIVACY_POLICY);
          } }>
          {words.privacyPolicy[state.language]}
        </button>
      */}
    </div>
  );
}

// NOTIFICATION
function Notification() {
  const [state, dispatch] = useContext(GlobalState);

  if (state.notification.fixed === true) {
    return (
      <div>
        {/* PC */}
        <div className='visibleLargeOrMore '>
          <div className='flexRow justifyContentFlexEnd marginSide0p5 marginBottomM0p5'>
            <div className='cursor'
              onClick={ () => {
                if (state.notification.index > 0) {
                  dispatch({ type: 'setStateMultiLayers', keys: ['notification', 'index'], value: state.notification.index - 1 });
                }
              }}
            >
              <img className='size1p7x1p7' src={iconLeftBlack} />
            </div>
            <div className='cursor'
              onClick={ () => {
                if (state.notification.index < state.notification.body.length - 1) {
                  dispatch({ type: 'setStateMultiLayers', keys: ['notification', 'index'], value: state.notification.index + 1 });
                }
              }}
            >
              <img className='size1p7x1p7' src={iconRightBlack} />
            </div>
          </div>
          <div className='box2 '>
            { state.notification.body[state.notification.index] }
          </div>
        </div>
        {/* SP */}
        <div className='visibleMiddleOrLess flexColumn alignItemsCenter width98vw '>
          <div className='flexRow justifyContentFlexEnd width95vw marginBottomM0p5 '>
            <div className='cursor'
              onClick={ () => {
                if (state.notification.index > 0) {
                  dispatch({ type: 'setStateMultiLayers', keys: ['notification', 'index'], value: state.notification.index - 1 });
                }
              }}
            >
              <img className='size1p7x1p7' src={iconLeftBlack} />
            </div>
            <div className='cursor'
              onClick={ () => {
                if (state.notification.index < state.notification.body.length - 1) {
                  dispatch({ type: 'setStateMultiLayers', keys: ['notification', 'index'], value: state.notification.index + 1 });
                }
              }}
            >
              <img className='size1p7x1p7' src={iconRightBlack} />
            </div>
          </div>
          <div className='box2Sp '>
            { state.notification.body[state.notification.index] }
          </div>
        </div>
      </div>
    );
  }
  else {
  }
}

// NOTIFICATION FADING OUT
function NotificationFadingOut () {
  const [state, dispatch] = useContext(GlobalState);

  useEffect( () => {
    return () => {
      dispatch({ type: 'stopNotificationAnimation', key: 'notification' });
    };
  }, []);

  return (
    <CSSTransition in={state.notification.inAnimation} timeout={5000} classNames="messageFade"
      onEntered={ () => {
                    dispatch({ type: 'stopNotificationAnimation', key: 'notification' });
                  }
               }
    >
      <div className={state.notification.inAnimation ? 'maxWidth98vw' : 'invisible'} >
          { state.notification.body[state.notification.index] }
      </div>
    </CSSTransition>
  );
}

// ROLLING COTTOCHAN
function RollingCottochan () {
  const [state, dispatch] = useContext(GlobalState);

  let cottochan;

  if (state.accessing) {
    cottochan = <div className='cottoRollingCenter' />;
  }
  else {
    cottochan = null;
  }

  return (
    <div>
      { cottochan }
    </div>
  );
}

// USER REGISTRATION POPUP
function UserRegistrationPopup(props) {
  const popupLayer = props.popupLayer;

  return <UserRegistration popupLayer={popupLayer} />;
}

// LOGIN POPUP
function LoginPopup(props) {
  const [state, dispatch, navigate, cookies, setCookie] = useContext(GlobalState);
  const popupLayer = props.popupLayer;

  // ログインセクション

  let loginSection;
  let addressSection;
  let switchIcon;

  if (state.login.displayAddressSection) {
    addressSection =
    <div className='flexRow justifyContentCenter alignItemsCenter marginTop0p5'>
      <TextLine3 fieldName='loginAddressMain' keys={['login', 'addressMain']} face={words.login[state.language]} tooltip='right' />
      <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['login', 'addressMain'], popupLayer) }>
        <img className='size2x2' src={iconMpurse} alt='' />
      </button>
      <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['login', 'addressMain'], popupLayer) }>
        <img className='size2x2' src={iconList} alt='' />
      </button>
    </div>;

    switchIcon = iconCollapse;
  }
  else {
    switchIcon = iconExpand;
  }

  loginSection =
  <div className='flexColumn alignItemsCenter marginTop0p5'>
    { addressSection }
    <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
      onClick={ async () => {
        const result = await handleClickLogin(state, dispatch, cookies, setCookie, null, popupLayer);

        if (result?.message === 'nfcRequired') {
          const callback = (keyPairs) => {
            handleClickLogin(state, dispatch, cookies, setCookie, keyPairs, popupLayer);
            keepKeyPairsInMemory(state, dispatch, keyPairs);
          };

          handleClickNfc(state, dispatch, callback);
        }
        else if (result?.message === 'bcRequired') {
          const callback = (keyPairs) => {
            handleClickLogin(state, dispatch, cookies, setCookie, keyPairs, popupLayer);
            keepKeyPairsInMemory(state, dispatch, keyPairs);
          };

          handleClickScanQr(state, dispatch, callback);
        }
      }}
    >
      <div>
        { words.login[state.language] }
      </div>
      <img className='size2x2' src={iconLogin} alt='' />
    </button>
    <div className='flexRow justifyContentCenter alignItemsCenter marginTop0p5'>
      <button className={'button1' + (Object.keys(state.session).length > 0 ? ' backgroundColorMonacottoPale' : ' backgroundColorMonacottoAlmostWhite')}
        onClick={ () => handleClickViewSession(state, dispatch) }
      >
        <img className='size2x2' src={iconLoginList} alt='' />
      </button>
      <button className={'button1'} tabindex='0'
        onClick={ () => {
          dispatch({ type: 'setStateMultiLayers', keys: ['login', 'displayAddressSection'], value: state.login.displayAddressSection ? false : true });
        }}
      >
        <img className='size2x2' src={switchIcon} alt='' />
      </button>
    </div>
  </div>


  return (
    <div>
      {/* PC */}
      <div className='visibleMiddleOrMore flexColumn alignItemsCenter'>
        <div className="marginBottom1 ">
          <SelectWalletBox />
        </div>
        <div className="marginBottom1 ">
          { loginSection }
        </div>
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess flexColumn widthMax'>
        <div className='widthMax padding0p5 marginTopBottom0p5 '>
          <SelectWalletBox />
        </div>
        <div className="widthMax ">
          { loginSection }
        </div>
      </div>
    </div>
  );
}

// ウォレット選択ボックス
// SELECT WALLET BOX
function SelectWalletBox(props) {
  const [state, dispatch] = useContext(GlobalState);

  let walletSelectBox;

  // ウォレット選択ボタン

  const mpurseButton =
  <button className={'box3 focusEffect01 riseOut2 ' + (state.walletType === 'mpurse' ? 'colorRed borderSelected' : 'borderNone')}
    tabindex='0'
    onClick={ () => {
      dispatch({type: 'setState', key: 'walletType', value: 'mpurse'});
    }}
  >
    <div>
      {words.mpurse[state.language]}
    </div>
  </button>;

  const nfcButton =
  <button className={'box3 focusEffect01 riseOut2 ' + (state.walletType === 'nfc' ? 'colorRed borderSelected' : 'borderNone')}
    tabindex='0'
    onClick={ () => {
      dispatch({type: 'setState', key: 'walletType', value: 'nfc'});
    }}
  >
    <div>
      {words.keyCard[state.language]}
    </div>
    <div>
      {words.nfc[state.language]}
    </div>
  </button>;

  const bcButton =
  <button className={'box3 focusEffect01 riseOut2 ' + (state.walletType === 'qr' ? 'colorRed borderSelected' : 'borderNone')}
    tabindex='0'
    onClick={ () => {
      dispatch({type: 'setState', key: 'walletType', value: 'qr'});
    }}
  >
    <div>
      {words.keyCard[state.language]}
    </div>
    <div>
      {words.bc[state.language]}
    </div>
  </button>

  if ('NDEFReader' in window && state.device.hasCamera) {
    walletSelectBox =
    <div className='flexRow justifyContentSpaceAround widthMax '>
      { mpurseButton }
      { nfcButton }
      { bcButton }
    </div>;
  }
  else if ('NDEFReader' in window) {
    walletSelectBox =
    <div className='flexRow justifyContentSpaceAround widthMax '>
      { mpurseButton }
      { nfcButton }
    </div>;
  }
  else if (state.device.hasCamera) {
    walletSelectBox =
    <div className='flexRow justifyContentSpaceAround widthMax '>
      { mpurseButton }
      { bcButton }
    </div>;
  }
  else {
    walletSelectBox = null;
  }

  
  return walletSelectBox;
}

// SIGN ICON
function SignIcon() {
  const [state] = useContext(GlobalState);
  let signIcon;

  if (state.walletType === 'mpurse') {
    signIcon = iconMpurse;
  }
  else if (state.walletType === 'nfc') {
    signIcon = iconKeyCard;
  }
  else { // qr
    signIcon = iconQr;
  }


  return <img className='size2x2 margin0p5' src={signIcon} alt='' />;
}

// // SELECT SESSION POPUP
// function SelectSessionPopup(props) {
//   const [state, dispatch] = useContext(GlobalState);
//   const popupLayer = props.popupLayer;
//   const keysArray = state.popup[popupLayer].body.keysArray;
//   const qr = state.popup[popupLayer].body.qr;
// 
//   let sessionAddresses = Object.keys(state.session);
// 
//   sessionAddresses = sessionAddresses.filter( address =>
//     state.session[address].expirationOfSession >= Date.now() && state.balance.monaparty[address] !== undefined
//   );
// 
//   let qrButtonDummy;
// 
//   if (qr === true) {
//     qrButtonDummy =
//     <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite ' disabled={true} >
//     </button>
//   }
// 
//   const title =
//   <div className='widthMax'>
//     {/* PC */}
//     <div className='visibleMiddleOrMore boxMonacotto1 margin0p5' >
//       <div className='marginSide1 widthMin25' >
//         { words.loggedInAddress[state.language] }
//       </div>
//       <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite ' disabled={true} >
//       </button>
//       { qrButtonDummy }
//     </div>
//     {/* SP */}
//     <div className='visibleSmallOrLess boxMonacotto1SmallScreen alignItemsCenter' >
//       <div className='textCenter' >
//         { words.loggedInAddress[state.language] }
//       </div>
//       <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite ' disabled={true} >
//       </button>
//       { qrButtonDummy }
//     </div>
//   </div>
// 
//   const records = sessionAddresses.map( address => {
//     let qrButton;
// 
//     const selectButton =
//     <button className='button1' tabindex='0'
//       onClick={ () => {
//         for (const keys of keysArray) {
//           dispatch({ type: 'setStateMultiLayers', keys: keys, value: address });
//         }
// 
//         dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } });
//       }}
//     >
//       <img className='size2x2' src={iconCheck} alt='' />
//     </button>
// 
//     if (qr === true) {
//       qrButton =
//       <button className='button1' tabindex='0'
//         onClick={ () => {
//           const popup = {
//             type: 'displayQrPopup',
//             body: {
//               data: address,
//             },
//           };
// 
//           dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
//         }}
//       >
//         <img className='size2x2' src={iconQr} alt='' />
//       </button>
//     }
// 
//     // アクティブカラー
// 
//     let activeColor = '';
// 
//     if (state.active.addressMain === address) {
//       activeColor = ' backgroundColorPinkPale';
//     }
// 
//     return (
//       <div className=''>
//         {/* PC */}
//         <div className={'visibleMiddleOrMore boxMonacotto1 margin0p5' + activeColor} >
//           <div className='marginSide1 widthMin25' >
//             { address }
//           </div>
//           { selectButton }
//           { qrButton }
//         </div>
//         {/* SP */}
//         <div className={'visibleSmallOrLess boxMonacotto1SmallScreen alignItemsCenter' + activeColor} >
//           <div className='textCenter' >
//             { address }
//           </div>
//           { selectButton }
//           { qrButton }
//         </div>
//       </div>
//     );
//   });
// 
// 
//   return (
//     <div className=''>
//       {/* PC */}
//       <div className='visibleMiddleOrMore flexColumn alignItemsCenter'>
//         <div className='flexColumn alignItemsCenter'>
//           { title }
//         </div>
//         <div className='flexColumn alignItemsCenter'>
//           { records }
//         </div>
//       </div>
//       {/* SP */}
//       <div className='visibleSmallOrLess flexColumn widthMax'>
//         <div className='flexColumn alignItemsCenter widthMax'>
//           { title }
//         </div>
//         <div className='flexColumn alignItemsCenter widthMax'>
//           { records }
//         </div>
//       </div>
//     </div>
//   );
// }

// VIEW SESSION POPUP
function ViewSessionPopup(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;

  let sessionAddresses = Object.keys(state.session);

  sessionAddresses = sessionAddresses.filter( address =>
    state.session[address].expirationOfSession >= Date.now() && state.balance.monaparty[address] !== undefined
  );

  const title =
  <div className='widthMax'>
    {/* PC */}
    <div className='visibleMiddleOrMore boxMonacotto1 margin0p5' >
      <div className='marginSide1 widthMin25' >
        { words.loggedInAddress[state.language] }
      </div>
      <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite ' disabled={true} >
      </button>
    </div>
    {/* SP */}
    <div className='visibleSmallOrLess boxMonacotto1SmallScreen alignItemsCenter' >
      <div className='textCenter' >
        { words.loggedInAddress[state.language] }
      </div>
      <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite ' disabled={true} >
      </button>
    </div>
  </div>

  const records = sessionAddresses.map( address =>
    <div className=''>
      {/* PC */}
      <div className='visibleMiddleOrMore boxMonacotto1 margin0p5' >
        <div className='marginSide1 widthMin25' >
          { address }
        </div>
        <button className='button1' tabindex='0'
          onClick={ () => {
            const popup = {
              type: 'displayQrPopup',
              body: {
                data: address,
              },
            };

            dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
          }}
        >
          <img className='size2x2' src={iconQr} alt='' />
        </button>
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess boxMonacotto1SmallScreen alignItemsCenter' >
        <div className='textCenter' >
          { address }
        </div>
        <button className='button1' tabindex='0'
          onClick={ () => {
            const popup = {
              type: 'displayQrPopup',
              body: {
                data: address,
              },
            };

            dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
          }}
        >
          <img className='size2x2' src={iconQr} alt='' />
        </button>
      </div>
    </div>
  );


  return (
    <div className=''>
      {/* PC */}
      <div className='visibleMiddleOrMore flexColumn alignItemsCenter'>
        <div className='flexColumn alignItemsCenter'>
          { title }
        </div>
        <div className='flexColumn alignItemsCenter'>
          { records }
        </div>
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess flexColumn widthMax'>
        <div className='flexColumn alignItemsCenter widthMax'>
          { title }
        </div>
        <div className='flexColumn alignItemsCenter widthMax'>
          { records }
        </div>
      </div>
    </div>
  );
}

// // DISPLAY QR POPUP
// function DisplayQrPopup(props) {
//   const [state, dispatch] = useContext(GlobalState);
//   const popupLayer = props.popupLayer;
//   const data = state.popup[popupLayer].body.data;
// 
//   return (
//     <div className='flexColumn alignItemsCenter '>
//       <QRCode value={data} />
//     </div>
//   );
// }


// TOKEN FILTER SECTION
function TokenFilterSection(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);
  const popupLayerNext = props.popupLayerNext;
  const screen = props.screen;
  const margin = props.margin;


  return (
    <button className={'height3 borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 ' + margin}
      onClick={ () => {
        const popup = { type: 'tokenFilterPopup' };
        dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });

        if (screen !== 'allCard') {
          navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/allcard' : '/allcard');
        }
      }}
    >
      { words.search[state.language] }
    </button>
  );
}

// TOKEN FILTER POPUP
function TokenFilterPopup(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;


  return (
    <div className='flexColumn alignItemsCenter'>
      <div className='flexRow justifyContentCenter ' >
        {
          state.tokenFilter.conditions.map( (condition, index) => {
            let styleFocused;

            if (index === state.tokenFilter.conditionIndex) {
              styleFocused = ' backgroundColorPinkPale';
            }
            else {
              styleFocused = ' backgroundColorTransparent';
            }

            return (
              <button className={'flexColumn alignItemsFlexEnd widthMin5 heightMin5 margin0p5 borderMonacotto ' + styleFocused}
                onClick={ () => {
                  dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditionIndex'], value: index });
                }}
              >
                <button className='backgroundColorTransparent borderNone '
                  onClick={ (event) => {
                    if (state.tokenFilter.conditions.length >= 2) {
                      const conditions = [ ...state.tokenFilter.conditions];
                      conditions.splice(index, 1);

                      dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions'], value: conditions});

                      if (state.tokenFilter.conditionIndex === state.tokenFilter.conditions.length - 1) {
                        dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditionIndex'], value: state.tokenFilter.conditions.length - 2 });
                      }
                    }
                    else {
                      const conditionsInitial = [
                        {
                          assetCommon: [],
                          addressOwners: [],
                          lockStatus: [],
                          monacardName: [],
                          monacardIssuerNames: [],
                          monacardDescription: [],
                          monacardTags: [],
                          monadom: [],
                        },
                      ];

                      dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions'], value: conditionsInitial });
                      dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditionIndex'], value: 0 });
                    }

                    event.stopPropagation();
                  }}
                >
                  <div className='font2 ' >
                    ×
                  </div>
                </button>
                <div className='flexColumn alignItemsCenter ' >
                  <ConditionSection condition={condition} popupLayer={popupLayer} conditionKey='assetCommon' />
                  <ConditionSection condition={condition} popupLayer={popupLayer} conditionKey='addressOwners' />
                  <ConditionSection condition={condition} popupLayer={popupLayer} conditionKey='lockStatus' />
                  <ConditionSection condition={condition} popupLayer={popupLayer} conditionKey='monacardName' />
                  <ConditionSection condition={condition} popupLayer={popupLayer} conditionKey='monacardIssuerNames' />
                  <ConditionSection condition={condition} popupLayer={popupLayer} conditionKey='monacardDescription' />
                  <ConditionSection condition={condition} popupLayer={popupLayer} conditionKey='monacardTags' />
                  <ConditionSection condition={condition} popupLayer={popupLayer} conditionKey='monadom' />
                </div>
              </button>
            );
          })
        }
      </div>
      <div className='flexRow justifyContentFlexStart' >
        <TextLine3 fieldName='tokenFilterAddressOwner' keys={['tokenFilter', 'assetCommon']} face={`${words.tokenName[state.language]} (${words.partialMatch[state.language]})`} tooltip='true' />
        <button className='button1' tabindex='0'
          onClick={ () => {
            let assetCommons;

            if (state.tokenFilter.assetCommon !== undefined && state.tokenFilter.assetCommon !== null && state.tokenFilter.assetCommon !== '') {
              assetCommons = [ ...state.tokenFilter.conditions[state.tokenFilter.conditionIndex].assetCommon, state.tokenFilter.assetCommon ];
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions', state.tokenFilter.conditionIndex, 'assetCommon'], value: assetCommons });
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'assetCommon'], value: '' });
            }
          }}
        >
          <img className='size2x2' src={iconFilter} alt='' />
        </button>
      </div>
      <div className='flexRow justifyContentFlexStart' >
        <TextLine3 fieldName='tokenFilterAddressOwner' keys={['tokenFilter', 'addressOwner']} face={words.addressOwner[state.language]} tooltip='true' />
        <button className='button1' tabindex='0'
          onClick={ () => {
            let addressOwners;

            if (state.tokenFilter.addressOwner !== undefined && state.tokenFilter.addressOwner !== null && state.tokenFilter.addressOwner !== '') {
              addressOwners = [ ...state.tokenFilter.conditions[state.tokenFilter.conditionIndex].addressOwners, state.tokenFilter.addressOwner ];
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions', state.tokenFilter.conditionIndex, 'addressOwners'], value: addressOwners });
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'addressOwner'], value: '' });
            }
          }}
        >
          <img className='size2x2' src={iconFilter} alt='' />
        </button>
      </div>
      <div className='flexRow justifyContentFlexStart' >
        <div className='flexRow justifyContentCenter box1Width ' >
          <button className={'box3 focusEffect01 riseOut2 ' + (state.tokenFilter.lockStatus === 'locked' ? 'colorRed borderSelected' : 'borderNone')}
            tabindex='0'
            onClick={ () => {
              dispatch({type: 'setStateMultiLayers', keys: ['tokenFilter', 'lockStatus'], value: 'locked'});
            }}
          >
            <div>
              {words.locked[state.language]}
            </div>
          </button>
          <button className={'box3 focusEffect01 riseOut2 ' + (state.tokenFilter.lockStatus === 'unlocked' ? 'colorRed borderSelected' : 'borderNone')}
            tabindex='0'
            onClick={ () => {
              dispatch({type: 'setStateMultiLayers', keys: ['tokenFilter', 'lockStatus'], value: 'unlocked'});
            }}
          >
            <div>
              {words.unlocked[state.language]}
            </div>
          </button>
        </div>
        <button className='button1' tabindex='0'
          onClick={ () => {
            let lockStatuses;

            if (state.tokenFilter.lockStatus !== undefined && state.tokenFilter.lockStatus !== null && state.tokenFilter.lockStatus !== '') {
              lockStatuses = [state.tokenFilter.lockStatus];
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions', state.tokenFilter.conditionIndex, 'lockStatus'], value: lockStatuses });
            }
          }}
        >
          <img className='size2x2' src={iconFilter} alt='' />
        </button>
      </div>
      <div className='flexRow justifyContentFlexStart' >
        <TextLine3 fieldName='tokenFilterMonacardName' keys={['tokenFilter', 'monacardName']} face={`${words.monacardName[state.language]} (${words.partialMatch[state.language]})`} tooltip='true' />
        <button className='button1' tabindex='0'
          onClick={ () => {
            let monacardNames;

            if (state.tokenFilter.monacardName !== undefined && state.tokenFilter.monacardName !== null && state.tokenFilter.monacardName !== '') {
              monacardNames = [ ...state.tokenFilter.conditions[state.tokenFilter.conditionIndex].monacardName, state.tokenFilter.monacardName ];
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions', state.tokenFilter.conditionIndex, 'monacardName'], value: monacardNames });
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'monacardName'], value: '' });
            }
          }}
        >
          <img className='size2x2' src={iconFilter} alt='' />
        </button>
      </div>
      <div className='flexRow justifyContentFlexStart' >
        <TextLine3 fieldName='tokenFilterMonacardIssuerName' keys={['tokenFilter', 'monacardIssuerName']} face={words.issuerName[state.language]} tooltip='true' />
        <button className='button1' tabindex='0'
          onClick={ () => {
            let monacardIssuerNames;

            if (state.tokenFilter.monacardIssuerName !== undefined && state.tokenFilter.monacardIssuerName !== null && state.tokenFilter.monacardIssuerName !== '') {
              monacardIssuerNames = [ ...state.tokenFilter.conditions[state.tokenFilter.conditionIndex].monacardIssuerNames, state.tokenFilter.monacardIssuerName ];
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions', state.tokenFilter.conditionIndex, 'monacardIssuerNames'], value: monacardIssuerNames });
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'monacardIssuerName'], value: '' });
            }
          }}
        >
          <img className='size2x2' src={iconFilter} alt='' />
        </button>
      </div>
      <div className='flexRow justifyContentFlexStart' >
        <TextLine3 fieldName='tokenFilterMonacardDescription' keys={['tokenFilter', 'monacardDescription']} face={`${words.monacardDescription[state.language]} (${words.partialMatch[state.language]})`} tooltip='true' />
        <button className='button1' tabindex='0'
          onClick={ () => {
            let monacardDescriptions;

            if (state.tokenFilter.monacardDescription !== undefined && state.tokenFilter.monacardDescription !== null && state.tokenFilter.monacardDescription !== '') {
              monacardDescriptions = [ ...state.tokenFilter.conditions[state.tokenFilter.conditionIndex].monacardDescription, state.tokenFilter.monacardDescription ];
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions', state.tokenFilter.conditionIndex, 'monacardDescription'], value: monacardDescriptions });
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'monacardDescription'], value: '' });
            }
          }}
        >
          <img className='size2x2' src={iconFilter} alt='' />
        </button>
      </div>
      <div className='flexRow justifyContentFlexStart' >
        <TextLine3 fieldName='tokenFilterMonacardTag' keys={['tokenFilter', 'monacardTag']} face={words.monacardTag[state.language]} tooltip='true' />
        <button className='button1' tabindex='0'
          onClick={ () => {
            let monacardTags;

            if (state.tokenFilter.monacardTag !== undefined && state.tokenFilter.monacardTag !== null && state.tokenFilter.monacardTag !== '') {
              monacardTags = [ ...state.tokenFilter.conditions[state.tokenFilter.conditionIndex].monacardTags, state.tokenFilter.monacardTag ];
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions', state.tokenFilter.conditionIndex, 'monacardTags'], value: monacardTags });
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'monacardTag'], value: '' });
            }
          }}
        >
          <img className='size2x2' src={iconFilter} alt='' />
        </button>
      </div>
      <div className='widthMax textLeft marginTop0p5' >
        { words.monadomRegistration[state.language] }
      </div>
      <div className='flexRow justifyContentFlexStart' >
        <div className='flexRow justifyContentCenter box1Width ' >
          <button className={'box3 focusEffect01 riseOut2 ' + (state.tokenFilter.monadom === 'registered' ? 'colorRed borderSelected' : 'borderNone')}
            tabindex='0'
            onClick={ () => {
              dispatch({type: 'setStateMultiLayers', keys: ['tokenFilter', 'monadom'], value: 'registered'});
            }}
          >
            <div>
              {words.registered[state.language]}
            </div>
          </button>
          <button className={'box3 focusEffect01 riseOut2 ' + (state.tokenFilter.monadom === 'unregistered' ? 'colorRed borderSelected' : 'borderNone')}
            tabindex='0'
            onClick={ () => {
              dispatch({type: 'setStateMultiLayers', keys: ['tokenFilter', 'monadom'], value: 'unregistered'});
            }}
          >
            <div>
              {words.unregistered[state.language]}
            </div>
          </button>
        </div>
        <button className='button1' tabindex='0'
          onClick={ () => {
            let monadoms;

            if (state.tokenFilter.monadom !== undefined && state.tokenFilter.monadom !== null && state.tokenFilter.monadom !== '') {
              monadoms = [state.tokenFilter.monadom];
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions', state.tokenFilter.conditionIndex, 'monadom'], value: monadoms });
            }
          }}
        >
          <img className='size2x2' src={iconFilter} alt='' />
        </button>
      </div>
      {/*
        <button className='button3 flexRow justifyContentCenter alignItemsCenter'
          onClick={ () => {
            let addressOwners;

            if (state.tokenFilter.addressOwner !== undefined && state.tokenFilter.addressOwner !== null && state.tokenFilter.addressOwner !== '') {
              addressOwners = [ ...state.tokenFilter.conditions.addressOwners, state.tokenFilter.addressOwner ];
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions', 'addressOwners'], value: addressOwners });
              dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'addressOwner'], value: '' });
            }
          }}
        >
          <div>
            {words.filter[state.language]}
          </div>
          <img className='size2x2' src={iconFilter} alt='' />
        </button>
      */}
      <div className='flexRow justifyContentFlexStart' >
        <button className='button3 flexRow justifyContentCenter alignItemsCenter'
          onClick={ () => {
            const conditionsInitial = {
              assetCommon: [],
              addressOwners: [],
              lockStatus: [],
              monacardName: [],
              monacardIssuerNames: [],
              monacardDescription: [],
              monacardTags: [],
              monadom: [],
            };

            dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions', state.tokenFilter.conditions.length], value: conditionsInitial });
            dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditionIndex'], value: state.tokenFilter.conditions.length });
          }}
        >
          {words.addAndCondition[state.language]}
        </button>
        <button className='button3 flexRow justifyContentCenter alignItemsCenter'
          onClick={ () => {
            const conditionsInitial = [
              {
                assetCommon: [],
                addressOwners: [],
                lockStatus: [],
                monacardName: [],
                monacardIssuerNames: [],
                monacardDescription: [],
                monacardTags: [],
                monadom: [],
              },
            ];

            dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditions'], value: conditionsInitial });
            dispatch({ type: 'setStateMultiLayers', keys: ['tokenFilter', 'conditionIndex'], value: 0 });
          }}
        >
          {words.clear[state.language]}
        </button>
      </div>
    </div>
  );
}

// CONDITION SECTION
function ConditionSection(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const condition = props.condition;
  const conditionKey = props.conditionKey;

  if (condition[conditionKey].length <= 0) {
    return null;
  }

  return (
    <div className='flexColumn alignItemsCenter borderMonacottoPale margin0p5'>
      <div className='flexColumn margin0p5 ' >
        { words[conditionKey][state.language] }
      </div>
      {
        condition[conditionKey].map( value =>
          <ValueForDisplayInList popupLayer={popupLayer} conditionKey={conditionKey} value={value} />
        )
      }
    </div>
  );
}

// VALUE FOR DISPLAY IN LIST
function ValueForDisplayInList(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const conditionKey = props.conditionKey;
  const value = props.value;

  if (conditionKey === 'addressOwners') {
    return (
      <button className='backgroundColorMonacottoPale borderNone margin0p5 '
        onClick={ () => {
          const body = value;
          const popup = { type: 'generalItems', body: body };
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
        }}
      >
        { value.substring(value.length - 6) }
      </button>
    );
  }
  else {
    return (
      <button className='backgroundColorMonacottoPale borderNone margin0p5 ' >
        { value }
      </button>
    );
  }
}

// MANAGEMENT SCREEN
function ManagementScreen() {
  const [state, dispatch, navigate, cookies, setCookie, removeCookie] = useContext(GlobalState);
  const inputImageFile = useRef(null);

  const onDropAccepted = useCallback((acceptedFiles) => {
    devLog('acceptedFiles', acceptedFiles);
    dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'imagesNew', 'main'], value: acceptedFiles[0] });
    const dataUrl = URL.createObjectURL(acceptedFiles[0]);
    devLog('dataUrl', dataUrl);
    dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'imagesNew', 'mainUrl'], value: dataUrl });
  }, []);

  const maxFiles = 1;
  const accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/gif': ['.gif'],
  };

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone({ onDropAccepted, maxFiles, accept });

  const dropZoneStyle = {
    width: '24em',
    height: '18em',
    border: "1px dotted #888",
  };


  return (
    <div>
      {/* PC */}
      <div className="mainMonacotto invisibleSp">
        <Header screen='managementScreen' />
        {/* configure */}
        <div className='flexRow justifyContentCenter' >
          <div className='flexColumn alignItemsCenter margin1' >
            <div className='flexColumn alignItemsFlexStart' >
              <SelectWalletBox />
              {/* address main */}
              <div className='flexRow alignItemsCenter' >
                <AddressIfNotHiddenSections keys={['configure', 'addressMain']} />
                <AddressHistoryIfNotHiddenSections keys={['configure', 'addressMain']} />
              </div>
              {/* user name */}
              <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
                <UserName keys={['configure', 'userName']} />
                <SwitchIcon />
              </div>
              {/* default address */}
              <div className='borderMonacotto backgroundColorTransparent padding1 marginTop1'>
                <div>
                  {words.addressDefaultOptional[state.language]}
                </div>
                <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
                  <TextLine3 fieldName='addressCardFromDefault' keys={['configure', 'addressCardFromDefault']}
                    face={words.addressCardFrom[state.language]} tooltip={true}
                  />
                  <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['configure', 'addressCardFromDefault']) }>
                    <img className='size2x2' src={iconMpurse} alt= '' />
                  </button>
                  <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['configure', 'addressCardFromDefault']) }>
                    <img className='size2x2' src={iconList} alt= '' />
                  </button>
                </div>
                <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
                  <TextLine3 fieldName='addressPayProceedsToDefault' keys={['configure', 'addressPayProceedsToDefault']}
                    face={words.addressPayProceedsTo[state.language]} tooltip={true}
                  />
                  <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['configure', 'addressPayProceedsToDefault']) }>
                    <img className='size2x2' src={iconMpurse} alt= '' />
                  </button>
                  <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['configure', 'addressPayProceedsToDefault']) }>
                    <img className='size2x2' src={iconList} alt= '' />
                  </button>
                </div>
                <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
                  <TextLine3 fieldName='addressPayFromDefault' keys={['configure', 'addressPayFromDefault']}
                    face={words.addressPayFrom[state.language]} tooltip={true}
                  />
                  <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['configure', 'addressPayFromDefault']) }>
                    <img className='size2x2' src={iconMpurse} alt= '' />
                  </button>
                  <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['configure', 'addressPayFromDefault']) }>
                    <img className='size2x2' src={iconList} alt= '' />
                  </button>
                </div>
                <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
                  <TextLine3 fieldName='addressSendCardToDefault' keys={['configure', 'addressSendCardToDefault']}
                    face={words.addressSendCardTo[state.language]} tooltip={true}
                  />
                  <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['configure', 'addressSendCardToDefault']) }>
                    <img className='size2x2' src={iconMpurse} alt= '' />
                  </button>
                  <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['configure', 'addressSendCardToDefault']) }>
                    <img className='size2x2' src={iconList} alt= '' />
                  </button>
                </div>
              </div>
              {/* my home */}
              {/*
                <div className='flexRow justifyContentCenter marginSide1'>
                  <button className={'box3 focusEffect01 riseOut2 ' + (state.configure.homed ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                    onClick={ () => {
                              dispatch({type: 'setStateMultiLayers', keys: ['configure', 'homed'], value: true});
                    }}>
                    {words.haveMyHome[state.language]}
                  </button>
                  <button className={'box3 focusEffect01 riseOut2 ' + (!state.configure.homed ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                    onClick={ () => {
                              dispatch({type: 'setStateMultiLayers', keys: ['configure', 'homed'], value: false});
                    }}>
                    {words.notHaveMyHome[state.language]}
                  </button>
                </div>
              */}
              <SelectMakeMyHomeOrNot />
              {/* terms and conditions */}
              <div className='flexColumn alignItemsFlexStart '>
                <TermsAndConditions />
              </div>
            </div>
          </div>
          <div className='flexColumn alignItemsCenter margin1'>
            {/* image */}
            <div className='relative margin1 '>
                <div {...getRootProps()} style={dropZoneStyle} >
                  <input {...getInputProps} style={{ display: 'none' }} />
                  <div className='padding0p5'>
                    {words.mainImageWithDescription[state.language]}<br/>
                    {words.dragAndDrop[state.language]}<br/>
                  </div>
                </div>
                {
                  state.configure.imagesNew.mainUrl !== undefined && state.configure.imagesNew.mainUrl !== null ?
                  <img className='mainImageAbsolute' src={state.configure.imagesNew.mainUrl} />
                  : null
                }
            </div>
            {/* profile text */}
            <TextArea2 fieldName='attributedTextText' keys={['configure', 'profileText']} face={words.profileText[state.language]} boxClass='box4 margin1' textAreaClass='textArea1' />
            <UserRegistrationButton />
          </div>
          {/* others */}
          <div className='flexColumn alignItemsCenter margin1'>
            {/* clear address history */}
            <button className='borderNone backgroundColorWhite textLeft focusEffect01 cursor borderRadius2 riseOut2 margin1 ' tabindex='0'
              onClick={ () => {
                removeCookie('addresses');
                dispatch({ type: 'setNotification', key: 'notification', value: words.clearedAddressHistory[state.language] });
              } }>
              {words.clearAddressHistory[state.language]}
            </button>
            {/* address check */}
            <div className='flexColumn alignItemsFlexStart margin1'>
              <div className=''>
                {words.addressCheck[state.language]}
              </div>
              <div className='flexRow justifyContentCenter'>
                <button className={'box3 focusEffect01 riseOut2 ' + (state.addressCheck === 'off' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                  onClick={ () => {
                    dispatch({type: 'setState', key: 'addressCheck', value: 'off'});
                    setCookie('addressCheck', 'off', { maxAge: state.config.clientParameters.cookie.addressCheck.maxAge, secure: true, sameSite: 'strict' });
                  }}>
                  {words.off[state.language]}
                </button>
                <button className={'box3 focusEffect01 riseOut2 ' + (state.addressCheck === 'strict' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                  onClick={ () => {
                    dispatch({type: 'setState', key: 'addressCheck', value: 'strict'});
                    setCookie('addressCheck', 'strict', { maxAge: state.config.clientParameters.cookie.addressCheck.maxAge, secure: true, sameSite: 'strict' });
                  }}>
                  {words.on[state.language]}
                </button>
                <button className={'box3 focusEffect01 riseOut2 ' + (state.addressCheck === 'addressSecond' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                  onClick={ () => {
                    dispatch({type: 'setState', key: 'addressCheck', value: 'addressSecond'});
                    setCookie('addressCheck', 'addressSecond', { maxAge: state.config.clientParameters.cookie.addressCheck.maxAge, secure: true, sameSite: 'strict' });
                  }}>
                  {words.allowTheSecondAddress[state.language]}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* development */}
        <div className=''>
          { process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null }
          { process.env.REACT_APP_ENVIRONMENT === 'dev' ? 'acceptedFiles' + JSON.stringify(acceptedFiles) : null }
          { process.env.REACT_APP_ENVIRONMENT === 'dev' ? 'fileRejections' + JSON.stringify(fileRejections) : null }
        </div>
        <Footer />
      </div>
      {/* SP */}
      <div className="flexColumn mainMonacotto widthMax invisiblePc">
        <Header screen='managementScreen' />
        {/* configure */}
        <div className='flexColumn alignItemsCenter widthMax ' >
          <div className='borderTopBottom marginTopBottom1'>
            {words.userRegistration[state.language]}
          </div>
          <SelectWalletBox />
          {/* address main */}
          <div className='flexRow alignItemsCenter' >
            <AddressIfNotHiddenSections keys={['configure', 'addressMain']} />
            <AddressHistoryIfNotHiddenSections keys={['configure', 'addressMain']} />
          </div>
          {/* user name */}
          <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
            <UserName keys={['configure', 'userName']} />
            <SwitchIcon />
          </div>
          {/* default address */}
          <div className='borderMonacotto backgroundColorTransparent padding1 marginTop1'>
            <div>
              {words.addressDefaultOptional[state.language]}
            </div>
            {/* addressCardFrom */}
            <TextLine3 fieldName='addressCardFromDefault' keys={['configure', 'addressCardFromDefault']}
              face={words.addressCardFrom[state.language]} tooltip={true}
            />
            <div className='flexRow justifyContentFlexStart alignItemsCenter '>
              <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['configure', 'addressCardFromDefault']) }>
                <img className='size2x2' src={iconMpurse} alt='' />
              </button>
              <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['configure', 'addressCardFromDefault']) }>
                <img className='size2x2' src={iconList} alt='' />
              </button>
            </div>
            {/* addressPayProceedsTo */}
            <TextLine3 fieldName='addressPayProceedsToDefault' keys={['configure', 'addressPayProceedsToDefault']}
              face={words.addressPayProceedsTo[state.language]} tooltip={true}
            />
            <div className='flexRow justifyContentFlexStart alignItemsCenter '>
              <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['configure', 'addressPayProceedsToDefault']) }>
                <img className='size2x2' src={iconMpurse} alt='' />
              </button>
              <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['configure', 'addressPayProceedsToDefault']) }>
                <img className='size2x2' src={iconList} alt='' />
              </button>
            </div>
            {/* addressPayFrom */}
            <TextLine3 fieldName='addressPayFromDefault' keys={['configure', 'addressPayFromDefault']}
              face={words.addressPayFrom[state.language]} tooltip={true}
            />
            <div className='flexRow justifyContentFlexStart alignItemsCenter '>
              <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['configure', 'addressPayFromDefault']) }>
                <img className='size2x2' src={iconMpurse} alt='' />
              </button>
              <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['configure', 'addressPayFromDefault']) }>
                <img className='size2x2' src={iconList} alt='' />
              </button>
            </div>
            {/* addressSendCardTo */}
            <TextLine3 fieldName='addressSendCardToDefault' keys={['configure', 'addressSendCardToDefault']}
              face={words.addressSendCardTo[state.language]} tooltip={true}
            />
            <div className='flexRow justifyContentFlexStart alignItemsCenter '>
              <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['configure', 'addressSendCardToDefault']) }>
                <img className='size2x2' src={iconMpurse} alt='' />
              </button>
              <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['configure', 'addressSendCardToDefault']) }>
                <img className='size2x2' src={iconList} alt='' />
              </button>
            </div>
          </div>
          {/* my home */}
          <SelectMakeMyHomeOrNot />
          {/* image */}
          {/*
          <div className='mainImageContainer margin1 '>
            <div className='padding0p5'>
              {words.mainImageWithDescription[state.language]}<br/>
            </div>
            {
              state.configure.imagesNew.mainUrl !== undefined && state.configure.imagesNew.mainUrl !== null ?
              <img className='mainImageAbsolute' src={state.configure.imagesNew.mainUrl} />
              : null
            }
          </div>
          */}
          <input ref={inputImageFile} className='invisible' type='file' accept='image/png, .png, image/jpeg, .jpg, .jpeg, image/gif, .gif' 
            onChange={ (e) => {
              devLog('acceptedFiles', JSON.stringify(e.currentTarget.files[0].name));
              dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'imagesNew', 'main'], value: e.currentTarget.files[0] });
              const dataUrl = URL.createObjectURL(e.currentTarget.files[0]);
              devLog('dataUrl', dataUrl);
              dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'imagesNew', 'mainUrl'], value: dataUrl });
            }}
          />
          <button className='mainImageContainer flexColumn justifyContentFlexStart margin1 ' onClick={ () => { inputImageFile.current.click(); } }>
            <div className='padding0p5 textLeft'>
              {words.mainImageWithDescription[state.language]}<br/>
              {words.click[state.language]}<br/>
            </div>
            {
              state.configure.imagesNew.mainUrl !== undefined && state.configure.imagesNew.mainUrl !== null ?
              <img className='mainImageAbsolute' src={state.configure.imagesNew.mainUrl} alt='' />
              : null
            }
          </button>
          {/* profile text */}
          <TextArea2 fieldName='attributedTextText' keys={['configure', 'profileText']} face={words.profileText[state.language]}
            outerClass='widthMax' boxClass='box4 ' textAreaClass='textArea1'
          />
          {/* terms and conditions */}
          <div className='flexColumn alignItemsFlexStart '>
            <TermsAndConditions />
          </div>
          <UserRegistrationButton />
          {/* others */}
          <div className='borderTopBottom marginTopBottom1'>
            {words.otherSettings[state.language]}
          </div>
          <button className='borderNone backgroundColorWhite textLeft focusEffect01 cursor borderRadius2 riseOut2 margin1 ' tabindex='0'
            onClick={ () => {
              removeCookie('addresses');
              dispatch({ type: 'setNotification', key: 'notification', value: words.clearedAddressHistory[state.language] });
            } }>
            {words.clearAddressHistory[state.language]}
          </button>
          {/* address check */}
          <div className='flexColumn alignItemsFlexStart margin1'>
            <div className=''>
              {words.addressCheck[state.language]}
            </div>
            <div className='flexRow justifyContentCenter'>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.addressCheck === 'off' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                  dispatch({type: 'setState', key: 'addressCheck', value: 'off'});
                  setCookie('addressCheck', 'off', { maxAge: state.config.clientParameters.cookie.addressCheck.maxAge, secure: true, sameSite: 'strict' });
                }}>
                {words.off[state.language]}
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.addressCheck === 'strict' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                  dispatch({type: 'setState', key: 'addressCheck', value: 'strict'});
                  setCookie('addressCheck', 'strict', { maxAge: state.config.clientParameters.cookie.addressCheck.maxAge, secure: true, sameSite: 'strict' });
                }}>
                {words.on[state.language]}
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.addressCheck === 'addressSecond' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                  dispatch({type: 'setState', key: 'addressCheck', value: 'addressSecond'});
                  setCookie('addressCheck', 'addressSecond', { maxAge: state.config.clientParameters.cookie.addressCheck.maxAge, secure: true, sameSite: 'strict' });
                }}>
                {words.allowTheSecondAddress[state.language]}
              </button>
            </div>
          </div>
        </div>
        {/* development */}
        <Footer />
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
      <Popup layer={2}/>
    </div>
  );
}

// USER REGISTRATION
function UserRegistration(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;


  return (
    <div>
      <div className="visibleMiddleOrMore flexColumn alignItemsCenter borderKOM marginTop1 padding1">
        <div className=''>
          { words.userRegistration[state.language] }
        </div>
        <SelectWalletBox />
        <div className='marginTop0p5 '>
          <AddressIfNotHiddenSections keys={['configure', 'addressMain']} />
        </div>
        <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
          <UserName keys={['configure', 'userName']} />
          <SwitchIcon />
        </div>
        <div className='marginTop1 '>
          <SelectMakeMyHomeOrNot />
        </div>
        <div className='marginTopBottom0p5 '>
          <TermsAndConditions />
        </div>
        <UserRegistrationButton popupLayer={popupLayer} />
      </div>
      <div className="visibleSmallOrLess flexColumn alignItemsCenter borderKOM marginTop1 padding1">
        <div className=''>
          { words.userRegistration[state.language] }
        </div>
        <SelectWalletBox />
        <div className='marginTop0p5 '>
          <AddressIfNotHiddenSections keys={['configure', 'addressMain']} />
        </div>
        <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
          <UserName keys={['configure', 'userName']} />
          <SwitchIcon />
        </div>
        <div className='marginTop1 '>
          <SelectMakeMyHomeOrNot />
        </div>
        <div className='marginTopBottom0p5 '>
          <TermsAndConditions />
        </div>
        <UserRegistrationButton popupLayer={popupLayer} />
      </div>
    </div>
  );
}

// ADDRESS IF NOT HIDDEN SECTIONS
function AddressIfNotHiddenSections(props) {
  const [state] = useContext(GlobalState);
  const keys = props.keys;

  let addressSection;

  if (state.configure.displayAddressSection) {
    addressSection = <AddressSection keys={keys} />;
  }


  return addressSection;
}

// ADDRESS SECTION
function AddressSection(props) {
  const [state, dispatch] = useContext(GlobalState);
  const keys = props.keys;


  return (
    <div className='flexRow justifyContentCenter alignItemsCenter '>
      <TextLine3 fieldName='addressSectionAddressMain' keys={keys} face={words.addressMain[state.language]} tooltip='right' />
      <button className='button1'
        onClick={ () => handleClickMpurse(state, dispatch, keys) }
      >
        <img className='size2x2' src={iconAddress} alt='' />
      </button>
      {/*
        <button className='button1'
          onClick={ () =>
            handleClickAddressHistory(state, dispatch, cookies, ['login', 'addressMain'], popupLayer)
          }
        >
          <img className='size2x2' src={iconLoginList} alt='' />
        </button>
      */}
    </div>
  );
}

// ADDRESS HISTORY IF NOT HIDDEN SECTIONS
function AddressHistoryIfNotHiddenSections(props) {
  const [state, dispatch, navigate, cookies] = useContext(GlobalState);
  const keys = props.keys;

  let addressHistorySection;

  if (state.configure.displayAddressSection) {
    addressHistorySection =
    <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, keys) }>
      <img className='size2x2' src={iconList}  alt='' />
    </button>;
  }


  return addressHistorySection;
}

// USER NAME
function UserName(props) {
  const [state] = useContext(GlobalState);
  const keys = props.keys;


  return (
    <TextLine3 fieldName='userName' keys={keys} face={words.userName[state.language]} tooltip='right' />
  );
}

// SWITCH ICON
function SwitchIcon() {
  const [state, dispatch] = useContext(GlobalState);

  let switchIcon;

  if (state.configure.displayAddressSection) {
    switchIcon = iconCollapse;
  }
  else {
    switchIcon = iconExpand;
  }


  return (
    <button className={'button1'} tabindex='0'
      onClick={ () => {
        dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'displayAddressSection'], value: state.configure.displayAddressSection ? false : true });
      }}
    >
      <img className='size2x2' src={switchIcon} alt='' />
    </button>
  );
}

function TermsAndConditions() {
  const [state, dispatch] = useContext(GlobalState);

  // if (state.configure.acceptedVersionOfTheTermsAndConditions !== state.config.clientParameters.versionOfTheTermsAndConditions ||
  //     state.configure.acceptedVersionOfPrivacyPolicy !== state.config.clientParameters.versionOfPrivacyPolicy) {
  //   return (
  //     <button className='borderNone backgroundColorWhite textLeft focusEffect01 colorRed cursor borderRadius2 riseOut2 marginTopBottom0p5' tabindex='0'
  //       onClick={ () => {
  //         if (state.configure.readTheTermsAndConditions === true) {
  //           dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'acceptedVersionOfTheTermsAndConditions'], value: state.config.clientParameters.versionOfTheTermsAndConditions });
  //         }

  //         if (state.configure.readPrivacyPolicy === true) {
  //           dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'acceptedVersionOfPrivacyPolicy'], value: state.config.clientParameters.versionOfPrivacyPolicy });
  //         }
  //       }
  //     }>
  //       {words.iAcceptTheTermsAndConditionsAndPrivacypolicy[state.language]}
  //     </button>
  //   );
  // }
  // else {
  //   return (
  //     <button className='borderNone backgroundColorWhite textLeft focusEffect01 borderRadius2 riseOut2 marginTopBottom0p5'>
  //         {words.theTermsAndConditionsAndPrivacypolicyHaveBeenAccepted[state.language]}
  //     </button>
  //   );
  // }

  // TAC文言

  let tacWord;

  if (state.configure.acceptedVersionOfTheTermsAndConditions === state.config.clientParameters.versionOfTheTermsAndConditions &&
      state.configure.acceptedVersionOfPrivacyPolicy === state.config.clientParameters.versionOfPrivacyPolicy) {
    tacWord = words.theTermsAndConditionsAndPrivacypolicyHaveBeenAccepted[state.language];
  }
  else {
    tacWord = words.termsAndConditionsAndPrivacypolicy[state.language];
  }

  // 利用規約ボタン

  const tacButton =
  <button
    className={
      'borderNone backgroundColorWhite heightMin3 textLeft focusEffect01 cursor borderRadius2 riseOut2 marginTopBottom0p5' +
      (state.configure.readTheTermsAndConditions ? '' : 'colorRed')
    }
    tabindex='0'
    onClick={ () => {
      window.open('https://' + process.env.REACT_APP_MONACOTTO_TERMS_AND_CONDITIONS);
      dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readTheTermsAndConditions'], value: true });
      dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readPrivacyPolicy'], value: true });
    }}
  >
    { tacWord }
  </button>;

  // 利用規約同意チェックボックス

  const tacCheckbox =
  <button
    className={
      'width1 height1 borderMonacottoNoRadius marginSide1 ' +
      (
        (
          state.configure.acceptedVersionOfTheTermsAndConditions === state.config.clientParameters.versionOfTheTermsAndConditions &&
          state.configure.acceptedVersionOfPrivacyPolicy === state.config.clientParameters.versionOfPrivacyPolicy
        ) ? 'backgroundColorPinkPale' : 'backgroundColorTransparent'
      )
    }
    onClick={ () => {
      if (
        state.configure.acceptedVersionOfTheTermsAndConditions !== state.config.clientParameters.versionOfTheTermsAndConditions ||
        state.configure.acceptedVersionOfPrivacyPolicy !== state.config.clientParameters.versionOfPrivacyPolicy
      ) {
        dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'acceptedVersionOfTheTermsAndConditions'], value: state.config.clientParameters.versionOfTheTermsAndConditions });
        dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'acceptedVersionOfPrivacyPolicy'], value: state.config.clientParameters.versionOfPrivacyPolicy });
      }
      else {
        dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'acceptedVersionOfTheTermsAndConditions'], value: null });
        dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'acceptedVersionOfPrivacyPolicy'], value: null });
      }
    }}  
  >
  </button>;


  return (
    <div className='flexRow justifyContentFlexStart alignItemsCenter '>
      { tacCheckbox }
      { tacButton }
    </div>
  );
}

function TermsAndConditionsPopup(props) {
  const [state, dispatch] = useContext(GlobalState);

  const termsAndConditions = {
    exhibitor: {
      face: words.forExhibitor[state.language],
      html: termsAndConditionsExhibitorHtml,
    },
    purchaser: {
      face: words.forPurchaser[state.language],
      html: termsAndConditionsPurchaserHtml,
    },
    owner: {
      face: words.forOwner[state.language],
      html: termsAndConditionsOwnerHtml,
    },
    privacyPolicy: {
      face: words.privacyPolicy[state.language],
      html: privacyPolicyHtml,
    },
  };

  const buttons = ['exhibitor', 'purchaser', 'owner', 'privacyPolicy'].filter( target1 => target1 !== props.target ).map( target2 =>
    <button className='backgroundColorMonacottoPale borderMonacotto margin0p5'
      onClick={ () => {
        const popup = { type: 'generalItems', body: <TermsAndConditionsPopup target={target2} /> };
        dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
      }}
    >
      { termsAndConditions[target2].face }
    </button>
  );

  return (
    <div>
      <div>
        { buttons }
      </div>
      <div dangerouslySetInnerHTML={ { __html: termsAndConditions[props.target].html } } />
    </div>
  );
}

function PrivacyPolicy() {
  const [state, dispatch] = useContext(GlobalState);

  if (state.configure.acceptedVersionOfPrivacyPolicy !== state.config.clientParameters.versionOfPrivacyPolicy) {
    return (
      <button className='borderNone backgroundColorWhite textLeft focusEffect01 colorRed cursor borderRadius2 riseOut2 marginTopBottom0p5' tabindex='0'
        onClick={ () => {
          if (state.configure.readPrivacyPolicy === true) {
            dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'acceptedVersionOfPrivacyPolicy'], value: state.config.clientParameters.versionOfPrivacyPolicy });
          }
        }
      }>
        {words.iAcceptPrivacyPolicy[state.language]}
      </button>
    );
  }
  else {
    return (
      <button className='borderNone backgroundColorWhite textLeft focusEffect01 borderRadius2 riseOut2 marginTopBottom0p5'>
        {words.privacyPolicyHasBeenAccepted[state.language]}
      </button>
    );
  }
}

// ユーザー登録ボタン
function UserRegistrationButton(props) {
  const [state, dispatch, navigate, cookies, setCookie, removeCookie] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const popupLayerNext = popupLayer !== undefined ? popupLayer + 1 : 0;

  return (
    <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
      onClick={ async () => {
          const result = await handleClickSetup(state, dispatch, cookies, setCookie);

          if (result?.message === 'nfcRequired') {
            const callback = (keyPairs) => {
              handleClickSetup(state, dispatch, cookies, setCookie, keyPairs);
              keepKeyPairsInMemory(state, dispatch, keyPairs);
            };

            handleClickNfc(state, dispatch, callback);
          }
          else if (result?.message === 'bcRequired') {
            const callback = (keyPairs) => {
              handleClickSetup(state, dispatch, cookies, setCookie, keyPairs);
              keepKeyPairsInMemory(state, dispatch, keyPairs);
            };

            handleClickScanQr(state, dispatch, callback, { popupLayer: popupLayerNext });
          }
      }}
    >
      <div>
        {words.signByMainAddressToRegister[state.language]}
      </div>
      { SignIcon() }
    </button>
  );
}

// マイホームを作るかどうか選択
function SelectMakeMyHomeOrNot() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div className='flexRow justifyContentCenter marginSide1'>
      <button className={'box3 focusEffect01 riseOut2 ' + (state.configure.homed ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
        onClick={ () => {
                  dispatch({type: 'setStateMultiLayers', keys: ['configure', 'homed'], value: true});
        }}>
        {words.haveMyHome[state.language]}
      </button>
      <button className={'box3 focusEffect01 riseOut2 ' + (!state.configure.homed ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
        onClick={ () => {
                  dispatch({type: 'setStateMultiLayers', keys: ['configure', 'homed'], value: false});
        }}>
        {words.notHaveMyHome[state.language]}
      </button>
    </div>
  );
}

// EXHIBIT
function Exhibit() {
  const [state, dispatch, navigate, cookies, setCookie] = useContext(GlobalState);

  let exhibitNo;

  if (state.exhibit.exhibitNo !== undefined && state.exhibit.exhibitNo !== null && state.exhibit.exhibitNo !== '') {
    exhibitNo = state.exhibit.exhibitNo;
  }
  else {
    exhibitNo = '-';
  }

  useEffect( () => {
    dispatch({ type: 'setState', key: 'exhibit',
      value: {
        exhibitNo: '',
        addressMain: state.exhibit.addressMain,
        // addressMainCoinType: 'mona',
        addressCardFrom: state.exhibit.addressCardFrom,
        addressPayProceedsTo: state.exhibit.addressPayProceedsTo,
        tokenName: state.exhibit.tokenName,
        amountToSell: state.exhibit.amountToSell,
        priceMona: state.exhibit.priceMona,
        signatureByAddressMain: '',
        signatureByAddressCardFrom: '',
        disabled: false,
        status: 'waitingForSignature',
      }
    });
  }, []);


  return (
    <div>
      {/* PC */}
      <div className='visibleMiddleOrMore' >
        <Header screen='exhibit' />
        <div className='flexRow justifyContentCenter' >
          <div className='flexColumn alignItemsFlexStart' >
            <div className='flexRow justifyContentSpaceBetween alignItemsCenter marginTop1 widthMax'>
              <div>
                { 'No. ' + exhibitNo }
              </div>
              <div>
                <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2' tabindex='0' onClick={ () => { handleClickDefaultExhibit(state, dispatch) } }>
                  {words.default[state.language]}
                </button>
                <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2' tabindex='0' onClick={ () => { handleClickClearExhibit(state, dispatch) } }>
                  {words.clear[state.language]}
                </button>
              </div>
            </div>
            <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
              <TextLine3 fieldName='exhibitAddressMain' keys={['exhibit', 'addressMain']} face={words.addressMain[state.language]} tooltip={true} disabled={state.exhibit.disabled} />
              <button className='button1' disabled={state.exhibit.disabled} onClick={ () => handleClickMpurse(state, dispatch, ['exhibit', 'addressMain']) }>
                <img className='size2x2' src={iconMpurse} />
              </button>
              <button className='button1' disabled={state.exhibit.disabled} onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['exhibit', 'addressMain']) }>
                <img className='size2x2' src={iconList} />
              </button>
            </div>
            <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
              <TextLine3 fieldName='exhibitaddressCardFrom' keys={['exhibit', 'addressCardFrom']} face={words.addressCardFrom[state.language]} tooltip={true} disabled={state.exhibit.disabled} />
              <button className='button1' disabled={state.exhibit.disabled} onClick={ () => handleClickMpurse(state, dispatch, ['exhibit', 'addressCardFrom']) }>
                <img className='size2x2' src={iconMpurse} />
              </button>
              <button className='button1' disabled={state.exhibit.disabled} onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['exhibit', 'addressCardFrom']) }>
                <img className='size2x2' src={iconList} />
              </button>
            </div>
            <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
              <TextLine3 fieldName='exhibitaddressPayProceedsTo' keys={['exhibit', 'addressPayProceedsTo']} face={words.addressPayProceedsTo[state.language]} tooltip={true} disabled={state.exhibit.disabled} />
              <button className='button1' disabled={state.exhibit.disabled} onClick={ () => handleClickMpurse(state, dispatch, ['exhibit', 'addressPayProceedsTo']) }>
                <img className='size2x2' src={iconMpurse} />
              </button>
              <button className='button1' disabled={state.exhibit.disabled} onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['exhibit', 'addressPayProceedsTo']) }>
                <img className='size2x2' src={iconList} />
              </button>
              {/* <button className='dummyPadButton1' disabled={true} /> */}
            </div>
            <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
              <TextLine3 fieldName='exhibittokenName' keys={['exhibit', 'tokenName']} face={words.tokenName[state.language]} tooltip={true} disabled={state.exhibit.disabled} />
              {/*
                <button className='dummyPadButton1' disabled={true} />
                <button className='dummyPadButton1' disabled={true} />
              */}
            </div>
            <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
              <TextLine3 fieldName='exhibitamountToSell' keys={['exhibit', 'amountToSell']} face={words.amount[state.language]} type='setStateMultiLayersNum' disabled={state.exhibit.disabled} />
              {/*
                <button className='dummyPadButton1' disabled={true} />
                <button className='dummyPadButton1' disabled={true} />
              */}
            </div>
            <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
              <TextLine3 fieldName='exhibitpriceMona' keys={['exhibit', 'priceMona']} face={words.unitPriceMonaWithUnit[state.language]}
                type='setStateMultiLayersFloat' adjustType='round' adjustExp={priceDigit} disabled={state.exhibit.disabled}
              />
              {/*
                <button className='dummyPadButton1' disabled={true} />
                <button className='dummyPadButton1' disabled={true} />
              */}
            </div>
            {
              (state.exhibit.signatureByAddressMain === undefined ||
               state.exhibit.signatureByAddressMain === null ||
               state.exhibit.signatureByAddressMain === '') ?
                <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
                  onClick={ async () => {
                    const result = await handleClickSignExhibit(state, dispatch, cookies, setCookie, 'addressMain');

                    if (result?.message === 'nfcRequired') {
                      const callback = (keyPairs) => {
                        handleClickSignExhibit(state, dispatch, cookies, setCookie, 'addressMain', keyPairs);
                        keepKeyPairsInMemory(state, dispatch, keyPairs);
                      };

                      handleClickNfc(state, dispatch, callback);
                    }
                    else if (result?.message === 'bcRequired') {
                      const callback = (keyPairs) => {
                        handleClickSignExhibit(state, dispatch, cookies, setCookie, 'addressMain', keyPairs);
                        keepKeyPairsInMemory(state, dispatch, keyPairs);
                      };

                      handleClickScanQr(state, dispatch, callback);
                    }
                  }}
                >
                  <div>
                    {words.signByMainAddress[state.language]}
                  </div>
                  <img className='size2x2' src={iconSign} alt='' />
                </button>
              : null
            }
            {
              (state.exhibit.signatureByAddressMain !== undefined &&
               state.exhibit.signatureByAddressMain !== null &&
               state.exhibit.signatureByAddressMain !== '' &&
               (state.exhibit.signatureByAddressCardFrom === undefined ||
                state.exhibit.signatureByAddressCardFrom === null ||
                state.exhibit.signatureByAddressCardFrom === '')) ?
                <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
                  onClick={ async () => {
                    const result = handleClickSignExhibit(state, dispatch, cookies, setCookie, 'addressCardFrom');

                    if (result?.message === 'nfcRequired') {
                      const callback = (keyPairs) => {
                        handleClickSignExhibit(state, dispatch, cookies, setCookie, 'addressCardFrom', keyPairs);
                        keepKeyPairsInMemory(state, dispatch, keyPairs);
                      };

                      handleClickNfc(state, dispatch, callback);
                    }
                    else if (result?.message === 'bcRequired') {
                      const callback = (keyPairs) => {
                        handleClickSignExhibit(state, dispatch, cookies, setCookie, 'addressCardFrom', keyPairs);
                        keepKeyPairsInMemory(state, dispatch, keyPairs);
                      };

                      handleClickScanQr(state, dispatch, callback);
                    }
                  }}
                >
                  <div>
                    {words.signByAddressYouSendCardsFrom[state.language]}
                  </div>
                  <img className='size2x2' src={iconSign} alt='' />
                </button>
              : null
            }
            {
              state.exhibit.status === 'waitingForToken' ?
                <button className='button2 borderRadius2 riseOut2' tabindex='0'
                  onClick={ async () => {
                    const result = await handleClickSendToken(state, dispatch);

                    if (result?.message === 'nfcRequired') {
                      const callback = (keyPairs) => {
                        handleClickSendToken(state, dispatch, keyPairs);
                        keepKeyPairsInMemory(state, dispatch, keyPairs);
                      };

                      handleClickNfc(state, dispatch, callback);
                    }
                    else if (result?.message === 'bcRequired') {
                      const callback = (keyPairs) => {
                        handleClickSendToken(state, dispatch, keyPairs);
                        keepKeyPairsInMemory(state, dispatch, keyPairs);
                      };

                      handleClickScanQr(state, dispatch, callback);
                    }
                  }}
                >
                  {words.sendCards[state.language]}
                </button>
              : null
            }
          </div>
        </div>
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess flexColumn alignItemsCenter ' >
        <Header screen='exhibit' />
        <div className='flexColumn alignItemsCenter marginTopBottom1'>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter marginTop1 widthMax'>
            <div>
              { 'No. ' + exhibitNo }
            </div>
            <div>
              <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2' tabindex='0' onClick={ () => { handleClickDefaultExhibit(state, dispatch) } }>
                {words.default[state.language]}
              </button>
              <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2' tabindex='0' onClick={ () => { handleClickClearExhibit(state, dispatch) } }>
                {words.clear[state.language]}
              </button>
            </div>
          </div>
          <div className='flexColumn alignItemsFlexStart' >
            <div className='flexColumn justifyContentFlexStart marginTop1 '>
              <TextLine3 fieldName='exhibitAddressMain' keys={['exhibit', 'addressMain']} face={words.addressMain[state.language]} tooltip={true} disabled={state.exhibit.disabled} />
              <div className='flexRow justifyContentFlexStart alignItemsCenter' >
                <button className='button1' disabled={state.exhibit.disabled} onClick={ () => handleClickMpurse(state, dispatch, ['exhibit', 'addressMain']) }>
                  <img className='size2x2' src={iconMpurse} />
                </button>
                <button className='button1' disabled={state.exhibit.disabled} onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['exhibit', 'addressMain']) }>
                  <img className='size2x2' src={iconList} />
                </button>
              </div>
            </div>
            <div className='flexColumn justifyContentFlexStart marginTop1 '>
              <TextLine3 fieldName='exhibitaddressCardFrom' keys={['exhibit', 'addressCardFrom']} face={words.addressCardFrom[state.language]} tooltip={true} disabled={state.exhibit.disabled} />
              <div className='flexRow justifyContentFlexStart alignItemsCenter' >
                <button className='button1' disabled={state.exhibit.disabled} onClick={ () => handleClickMpurse(state, dispatch, ['exhibit', 'addressCardFrom']) }>
                  <img className='size2x2' src={iconMpurse} />
                </button>
                <button className='button1' disabled={state.exhibit.disabled} onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['exhibit', 'addressCardFrom']) }>
                  <img className='size2x2' src={iconList} />
                </button>
              </div>
            </div>
            <div className='flexColumn justifyContentFlexStart marginTopBottom1 '>
              <TextLine3 fieldName='exhibitaddressPayProceedsTo' keys={['exhibit', 'addressPayProceedsTo']} face={words.addressPayProceedsTo[state.language]} tooltip={true} disabled={state.exhibit.disabled} />
              <div className='flexRow justifyContentFlexStart alignItemsCenter' >
                <button className='button1' disabled={state.exhibit.disabled} onClick={ () => handleClickMpurse(state, dispatch, ['exhibit', 'addressPayProceedsTo']) }>
                  <img className='size2x2' src={iconMpurse} />
                </button>
                <button className='button1' disabled={state.exhibit.disabled} onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['exhibit', 'addressPayProceedsTo']) }>
                  <img className='size2x2' src={iconList} />
                </button>
              </div>
            </div>
            <TextLine3 fieldName='exhibittokenName' keys={['exhibit', 'tokenName']} face={words.tokenName[state.language]} tooltip={true} disabled={state.exhibit.disabled} />
            <TextLine3 fieldName='exhibitamountToSell' keys={['exhibit', 'amountToSell']} face={words.amount[state.language]} type='setStateMultiLayersNum' disabled={state.exhibit.disabled} />
            <TextLine3 fieldName='exhibitpriceMona' keys={['exhibit', 'priceMona']} face={words.unitPriceMonaWithUnit[state.language]}
              type='setStateMultiLayersFloat' adjustType='round' adjustExp={priceDigit} disabled={state.exhibit.disabled}
            />
          </div>
          {
            (state.exhibit.signatureByAddressMain === undefined ||
             state.exhibit.signatureByAddressMain === null ||
             state.exhibit.signatureByAddressMain === '') ?
              <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
                onClick={ async () => {
                  const result = await handleClickSignExhibit(state, dispatch, cookies, setCookie, 'addressMain');

                  if (result?.message === 'nfcRequired') {
                    const callback = (keyPairs) => {
                      handleClickSignExhibit(state, dispatch, cookies, setCookie, 'addressMain', keyPairs);
                      keepKeyPairsInMemory(state, dispatch, keyPairs);
                    };

                    handleClickNfc(state, dispatch, callback);
                  }
                  else if (result?.message === 'bcRequired') {
                    const callback = (keyPairs) => {
                      handleClickSignExhibit(state, dispatch, cookies, setCookie, 'addressMain', keyPairs);
                      keepKeyPairsInMemory(state, dispatch, keyPairs);
                    };

                    handleClickScanQr(state, dispatch, callback);
                  }
                }}
              >
                <div>
                  {words.signByMainAddress[state.language]}
                </div>
                <img className='size2x2' src={iconSign} alt='' />
              </button>
            : null
          }
          {
            (state.exhibit.signatureByAddressMain !== undefined &&
             state.exhibit.signatureByAddressMain !== null &&
             state.exhibit.signatureByAddressMain !== '' &&
             (state.exhibit.signatureByAddressCardFrom === undefined ||
              state.exhibit.signatureByAddressCardFrom === null ||
              state.exhibit.signatureByAddressCardFrom === '')) ?
              <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
                onClick={ async () => {
                  const result = await handleClickSignExhibit(state, dispatch, cookies, setCookie, 'addressCardFrom');

                  if (result?.message === 'nfcRequired') {
                    const callback = (keyPairs) => {
                      handleClickSignExhibit(state, dispatch, cookies, setCookie, 'addressCardFrom', keyPairs);
                      keepKeyPairsInMemory(state, dispatch, keyPairs);
                    };

                    handleClickNfc(state, dispatch, callback);
                  }
                  else if (result?.message === 'bcRequired') {
                    const callback = (keyPairs) => {
                      handleClickSignExhibit(state, dispatch, cookies, setCookie, 'addressCardFrom', keyPairs);
                      keepKeyPairsInMemory(state, dispatch, keyPairs);
                    };

                    handleClickScanQr(state, dispatch, callback);
                  }
                }}
              >
                <div>
                  {words.signByAddressYouSendCardsFrom[state.language]}
                </div>
                <img className='size2x2' src={iconSign} alt='' />
              </button>
            : null
          }
          {
            state.exhibit.status === 'waitingForToken' ?
              <button className='button2 borderRadius2 riseOut2' tabindex='0'
                onClick={ async () => {
                  const result = await handleClickSendToken(state, dispatch);

                  if (result?.message === 'nfcRequired') {
                    const callback = (keyPairs) => {
                      handleClickSendToken(state, dispatch, keyPairs);
                      keepKeyPairsInMemory(state, dispatch, keyPairs);
                    };

                    handleClickNfc(state, dispatch, callback);
                  }
                  else if (result?.message === 'bcRequired') {
                    const callback = (keyPairs) => {
                      handleClickSendToken(state, dispatch, keyPairs);
                      keepKeyPairsInMemory(state, dispatch, keyPairs);
                    };

                    handleClickScanQr(state, dispatch, callback);
                  }
                }}
              >
                {words.sendCards[state.language]}
              </button>
            : null
          }
        </div>
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
    </div>
  );
}

// RESUME EXHIBIT
function ResumeExhibit(props) {
  const [state, dispatch,, cookies, setCookie] = useContext(GlobalState);
  const record = props.record;
  const popupLayer = props.popupLayer;

  return (
    <div className='flexRow justifyContentCenter'>
      {
        state.exhibit.status === 'waitingForToken' && record.sendTokenTx_hashFromClient === undefined ?
          <button className='button2 borderRadius2 riseOut2' tabindex='0'
            onClick={ async () => {
              const result = await handleClickSendToken(state, dispatch);
              devLog('resume exhibit');

              if (result.status === 'fulfilled') {
                devLog('get exhibit history');
                handleClickGetExhibitHistory(state, dispatch, cookies, setCookie, 0, null, null);
              }
              else if (result?.message === 'nfcRequired') {
                const callback = async (keyPairs) => {
                  const result = await handleClickSendToken(state, dispatch ,keyPairs);
                  keepKeyPairsInMemory(state, dispatch, keyPairs);

                  if (result.status === 'fulfilled') {
                    devLog('get exhibit history');
                    handleClickGetExhibitHistory(state, dispatch, cookies, setCookie, 0, null, null);
                  }
                };

                handleClickNfc(state, dispatch, callback);
              }
              else if (result?.message === 'bcRequired') {
                const callback = async (keyPairs) => {
                  const result = await handleClickSendToken(state, dispatch ,keyPairs);
                  keepKeyPairsInMemory(state, dispatch, keyPairs);

                  if (result.status === 'fulfilled') {
                    devLog('get exhibit history');
                    handleClickGetExhibitHistory(state, dispatch, cookies, setCookie, 0, null, null);
                  }
                };

                handleClickScanQr(state, dispatch, callback, { popupLayer: popupLayer + 1 });
              }
            }}
          >
            {words.sendCards[state.language]}
          </button>
        : null
      }
    </div>
  );
}

// CHANGE EXHIBIT
function ChangeExhibit() {
  const [state, dispatch,, cookies] = useContext(GlobalState);

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='flexColumn alignItemsFlexStart'>
          <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
            <TextLine3 fieldName='changeExhibitAddressPayProceedsTo' keys={['changeExhibit', 'addressPayProceedsTo']} face={words.addressPayProceedsTo[state.language]} tooltip={true}
              disabled={state.changeExhibit.disabled}
            />
            <button className='button1' disabled={state.changeExhibit.disabled} onClick={ () => handleClickMpurse(state, dispatch, ['changeExhibit', 'addressPayProceedsTo']) }>
              <img className='size2x2' src={iconMpurse} />
            </button>
            <button className='button1' disabled={state.changeExhibit.disabled} onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['changeExhibit', 'addressPayProceedsTo'], 0) }>
              <img className='size2x2' src={iconList} />
            </button>
          </div>
          <TextLine3 fieldName='changeExhibitPriceMona' keys={['changeExhibit', 'priceMona']} face={words.unitPriceMonaWithUnit[state.language]}
            type='setStateMultiLayersFloat' adjustType='round' adjustExp={priceDigit} disabled={state.changeExhibit.disabled}
          />
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='flexColumn alignItemsFlexStart'>
          <TextLine3 fieldName='changeExhibitAddressPayProceedsTo' keys={['changeExhibit', 'addressPayProceedsTo']} face={words.addressPayProceedsTo[state.language]} tooltip={true}
            disabled={state.changeExhibit.disabled}
          />
          <div className='flexRow justifyContentFlexStart alignItemsCenter marginTopBottom0p5 '>
            <button className='button1' disabled={state.changeExhibit.disabled} onClick={ () => handleClickMpurse(state, dispatch, ['changeExhibit', 'addressPayProceedsTo']) }>
              <img className='size2x2' src={iconMpurse} />
            </button>
            <button className='button1' disabled={state.changeExhibit.disabled} onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['changeExhibit', 'addressPayProceedsTo'], 0) }>
              <img className='size2x2' src={iconList} />
            </button>
          </div>
          <TextLine3 fieldName='changeExhibitPriceMona' keys={['changeExhibit', 'priceMona']} face={words.unitPriceMonaWithUnit[state.language]}
            type='setStateMultiLayersFloat' adjustType='round' adjustExp={priceDigit} disabled={state.changeExhibit.disabled}
          />
        </div>
      </div>
    </div>
  );
}

// CHANGE EXHIBIT SIGNING
function ChangeExhibitSigning(props) {
  const [state, dispatch,, cookies, setCookie] = useContext(GlobalState);
  const popupLayerNext = props.popupLayer !== undefined && props.popupLayer !== null ? (props.popupLayer + 1) : 0;

  const onClickSignChangeExhibit = async () => {
    const result = await handleClickSignChangeExhibit(state, dispatch, cookies, setCookie);

    if (result.status === 'fulfilled') {
      const activeCertification = buildActiveCertification(result, 'exhibit', ['addressMain', 'addressMainActual']);
      handleClickGetExhibitHistory(state, dispatch, cookies, setCookie, 0, activeCertification);
    }
    else if (result?.message === 'nfcRequired') {
      const callback = async (keyPairs) => {
        const result = await handleClickSignChangeExhibit(state, dispatch, cookies, setCookie, keyPairs);
        keepKeyPairsInMemory(state, dispatch, keyPairs);

        if (result.status === 'fulfilled') {
          const activeCertification = buildActiveCertification(result, 'exhibit', ['addressMain', 'addressMainActual']);
          handleClickGetExhibitHistory(state, dispatch, cookies, setCookie, 0, activeCertification);
        }
      };

      handleClickNfc(state, dispatch, callback);
    }
    else if (result?.message === 'bcRequired') {
      const callback = async (keyPairs) => {
        const result = await handleClickSignChangeExhibit(state, dispatch, cookies, setCookie, keyPairs);
        keepKeyPairsInMemory(state, dispatch, keyPairs);

        if (result.status === 'fulfilled') {
          const activeCertification = buildActiveCertification(result, 'exhibit', ['addressMain', 'addressMainActual']);
          handleClickGetExhibitHistory(state, dispatch, cookies, setCookie, 0, activeCertification);
        }
      };

      handleClickScanQr(state, dispatch, callback, { popupLayer: popupLayerNext });
    }
  };


  return (
        <div className='flexRow justifyContentCenter alignItemsCenter marginTop1 '>
          {
            (state.changeExhibit.signatureByAddressMainChange === undefined ||
             state.changeExhibit.signatureByAddressMainChange === null ||
             state.changeExhibit.signatureByAddressMainChange === '') &&
            state.changeExhibit.exhibitNo === state.popup[props.popupLayer].body.exhibitNo &&
            state.changeExhibit.status === 'waitingForSignature' ?
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0' onClick={ onClickSignChangeExhibit } >
              <div>
                {words.signByMainAddress[state.language]}
              </div>
              <img className='size2x2' src={iconSign} />
            </button>
            : null
          }
        </div>
  );

              /*
              onClick={ async () => {
                const result = await handleClickSignChangeExhibit(state, dispatch, cookies, setCookie);

                if (result.status === 'fulfilled') {
                  // const { body: { sessionId, expirationOfSession }} = result;
                  // handleClickGetExhibitHistory(state, dispatch, cookies, setCookie, 0, { address: state.changeExhibit.addressMain, sessionId, expirationOfSession });

                  const { body: { sessionId, expirationOfSession, exhibit }} = result;
                  const activeCertification = [];

                  if (sessionId.addressMain !== undefined) {
                    activeCertification.push({ address: exhibit.addressMain, sessionId: sessionId.addressMain, expirationOfSession: expirationOfSession.addressMain });
                  }

                  if (sessionId.addressMainActual !== undefined) {
                    activeCertification.push({ address: exhibit.addressMainActual, sessionId: sessionId.addressMainActual, expirationOfSession: expirationOfSession.addressMainActual });
                  }

                  handleClickGetExhibitHistory(state, dispatch, cookies, setCookie, 0, activeCertification);
                }
              }}
              */
}

// CANCEL SIGNING
function CancelSigning(props) {
  const [state, dispatch,, cookies, setCookie] = useContext(GlobalState);
  const popupLayerNext = props.popupLayer !== undefined && props.popupLayer !== null ? (props.popupLayer + 1) : 0;

  return (
    <div className='flexRow justifyContentCenter alignItemsCenter marginTop1 '>
      {
        (state.cancelExhibit.signatureByAddressMainCancel === undefined ||
         state.cancelExhibit.signatureByAddressMainCancel === null ||
         state.cancelExhibit.signatureByAddressMainCancel === '') &&
        state.cancelExhibit.status === 'waitingForSignature' ?
        <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
          onClick={ async () => {
            const result = await handleClickSignCancelExhibit(state, dispatch, cookies, setCookie);

            if (result.status === 'fulfilled') {
              // const { body: { sessionId, expirationOfSession, cancelExhibit }} = result;
              // const activeCertification = [];

              // if (sessionId.addressMain !== undefined) {
              //   activeCertification.push({ address: cancelExhibit.addressMain, sessionId: sessionId.addressMain, expirationOfSession: expirationOfSession.addressMain });
              // }

              // if (sessionId.addressMainActual !== undefined) {
              //   activeCertification.push({ address: cancelExhibit.addressMainActual, sessionId: sessionId.addressMainActual, expirationOfSession: expirationOfSession.addressMainActual });
              // }

              const activeCertification = buildActiveCertification(result, 'cancelExhibit', ['addressMain', 'addressMainActual']);
              handleClickGetExhibitHistory(state, dispatch, cookies, setCookie, 0, activeCertification);
            }
            else if (result?.message === 'nfcRequired') {
              const callback = async (keyPairs) => {
                const result = await handleClickSignCancelExhibit(state, dispatch, cookies, setCookie, keyPairs);
                keepKeyPairsInMemory(state, dispatch, keyPairs);

                if (result.status === 'fulfilled') {
                  const activeCertification = buildActiveCertification(result, 'cancelExhibit', ['addressMain', 'addressMainActual']);
                  handleClickGetExhibitHistory(state, dispatch, cookies, setCookie, 0, activeCertification);
                }
              };

              handleClickNfc(state, dispatch, callback);
            }
            else if (result?.message === 'bcRequired') {
              const callback = async (keyPairs) => {
                const result = await handleClickSignCancelExhibit(state, dispatch, cookies, setCookie, keyPairs);
                keepKeyPairsInMemory(state, dispatch, keyPairs);

                if (result.status === 'fulfilled') {
                  const activeCertification = buildActiveCertification(result, 'cancelExhibit', ['addressMain', 'addressMainActual']);
                  handleClickGetExhibitHistory(state, dispatch, cookies, setCookie, 0, activeCertification);
                }
              };

              handleClickScanQr(state, dispatch, callback, { popupLayer: popupLayerNext });
            }
          }}
        >
          <div>
            {words.signByMainAddress[state.language]}
          </div>
          <img className='size2x2' src={iconSign} />
        </button>
        : null
      }
    </div>
  );
}

// VILLAGE
function Village() {
  const [state, dispatch, navigate] = useContext(GlobalState);
  let homes;

  /*
  useEffect( () => {
    // 出品物取得
    const func = async () => {
      // -- ユーザ情報取得
      const usersGeneral = await getUser(state, dispatch, 'all');
      devLog('usersGeneral', JSON.stringify(usersGeneral));
    };

    // // usersGeneral index作成
    // for (const [index, item] of state.items[state.gallery.addressMain].itemPlacement[0].items.entries()) {
    //   itemPlacementIndex[item.itemType][item.no.toString()] = index;
    // }

    func();
  }, []);
  */

  if (state.usersGeneral.length > 0) {
    homes = state.usersGeneral.filter( user => user.homed === true ).map( user => <HomeGate user={user} /> )
  }
  else {
    homes = null;
  }

  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        <Header screen='village' />
        <div className='flexColumn alignItemsCenter ' >
          {/*
          <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
            <TextLine3 fieldName='getItemAddressMain' keys={['gallery', 'addressMain']} face='main address' tooltip={true} />
            <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['gallery', 'addressMain']) }>
              <img className='size2x2' src={iconMpurse} />
            </button>
          </div>
          */}
          <div className='flexRow justifyContentFlexStart widthMax '>
            { homes }
          </div>
          {/*
            <Popup layer={0}/>
            <Popup layer={1}/>
          */}
        </div>
        <div>
          { process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null }
        </div>
      </div>
      {/* SP */}
      <div className="flexColumn visibleSmallOrLess ">
        <Header screen='village' />
        <div className='flexColumn alignItemsCenter ' >
          {/*
          <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
            <TextLine3 fieldName='getItemAddressMain' keys={['gallery', 'addressMain']} face='main address' tooltip={true} />
            <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['gallery', 'addressMain']) }>
              <img className='size2x2' src={iconMpurse} />
            </button>
          </div>
          */}
          <div className='flexColumn '>
            { homes }
          </div>
          {/*
            <Popup layer={0}/>
            <Popup layer={1}/>
          */}
        </div>
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
    </div>
  );
}

// HOME GATE
function HomeGate(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);

  const user = props.user;
  let mainImage;

  // メインイメージ
  if (user.images.main === true) {
    if (user.images.mainNumber !== undefined) {
      mainImage = <img className='mainImage' src={`https://${process.env.REACT_APP_MONACOTTO_USER_IMAGE}${user.addressMain}_main_${user.images.mainNumber.toString().padStart(4, '0')}.img`} />
    }
    else {
      mainImage = <img className='mainImage' src={`https://${process.env.REACT_APP_MONACOTTO_USER_IMAGE}${user.addressMain}_main.img`} />
    }
  }
  else {
    mainImage = null;
  }

  return (
    <div className='maxWidthMax' >
      {/* PC */}
      <div className="visibleMiddleOrMore">
        <button className='gate ' disabled={props.disabled ? true : false}
          onClick={ () => {
            dispatch({ type: 'setStateMultiLayers', keys: ['gallery', 'addressMain'], value: user.addressMain });
            dispatch({ type: 'setStateMultiLayers', keys: ['gallery', 'userName'], value: user.userName }); // これいらないんじゃないかと思う。
            navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/home' : '/home'); 
          }}
        >
          <div className='flexColumn justifyContentFlexStart'>
            { mainImage }
            <div className='flexRow justifyContentFlexStart font1p5 '>
              {user.userName}
            </div>
            <div className='flexRow justifyContentSpaceBetween '>
              {user.addressMain}
            </div>
          </div>
        </button>
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess">
        <button className='gateSmallScreen ' disabled={props.disabled ? true : false}
          onClick={ () => {
            dispatch({ type: 'setStateMultiLayers', keys: ['gallery', 'addressMain'], value: user.addressMain });
            dispatch({ type: 'setStateMultiLayers', keys: ['gallery', 'userName'], value: user.userName }); // これいらないんじゃないかと思う。
            navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/home' : '/home'); 
          }}
        >
          <div className='flexColumn justifyContentFlexStart'>
            <MainImage user={user} imageClass='mainImageSmallScreen' />
            <div className='flexRow justifyContentFlexStart font1p5 '>
              {user.userName}
            </div>
            <div className='flexRow justifyContentSpaceBetween '>
              {user.addressMain}
            </div>
          </div>
        </button>
      </div>
    </div>
  );
}

// MAIN IMAGE
function MainImage(props) {

  const user = props.user;
  const imageClass = props.imageClass;
  let mainImage;

  if (user.images.main === true) {
    if (user.images.mainNumber !== undefined) {
      mainImage = <img className={imageClass} src={`https://${process.env.REACT_APP_MONACOTTO_USER_IMAGE}${user.addressMain}_main_${user.images.mainNumber.toString().padStart(4, '0')}.img`} />
    }
    else {
      mainImage = <img className={imageClass} src={`https://${process.env.REACT_APP_MONACOTTO_USER_IMAGE}${user.addressMain}_main.img`} />
    }
  }
  else {
    mainImage = null;
  }

  return (
    <div className='' >
      { mainImage }
    </div>
  );
}

// GALLERY
function Gallery() {
  const [state, dispatch, navigate] = useContext(GlobalState);

  const usersGeneralExhibitor = state.usersGeneralIndex[state.gallery.addressMain];
  const exhibitor = {
    addressMain: state.gallery.addressMain,
    userName: usersGeneralExhibitor !== undefined ? usersGeneralExhibitor.userName : null,
    images: usersGeneralExhibitor !== undefined ? usersGeneralExhibitor.images : {},
  };

  let itemPlacementIndex = {
    exhibitToken: {},
  };
  let itemsSorted = [
    [],
  ];
  let replaceItemButton;
  let mainImage;
  let mainImageNumber;

  useEffect( () => {
    if (state.gallery.addressMain === undefined || state.gallery.addressMain === null || state.gallery.addressMain === '') {
      devLog('Gallery useEffect');
      navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/newcard' : '/newcard');
    }
    else {
      // スクリーン設定
      dispatch({ type: 'setState', key: 'screen', value: 'gallery' });
    }
  });

  useEffect( () => {
    // 出品物取得
    const func = async () => {
      /*
      // -- ユーザ情報取得
      const usersGeneral = await getUser(state, dispatch, 'all');
      devLog('usersGeneral', JSON.stringify(usersGeneral));
      */

      if (state.gallery.addressMain !== undefined && state.gallery.addressMain !== null && state.gallery.addressMain !== '') {

        dispatch({ type: 'setState', key: 'accessing', value: true });

        // -- アイテム情報取得
        const items = await getItem(state, dispatch, [state.gallery.addressMain])
        .then( items => {
          devLog('items', JSON.stringify(items));

          // -- アセット情報取得

          getAssetInfoByItems(state, dispatch, items);

          // -- モナカード情報取得
          const assetCommons = Object.keys(items.body).reduce( (acc, cur) => {
            devLog('cur', cur);
            devLog('item', JSON.stringify(items.body[cur].item));
            const assetCommons = items.body[cur].item.map( item => item.asset_longname === null ? item.asset : item.asset_longname );
            return acc.concat(assetCommons);
          }, []);

          getMonacard(state, dispatch, assetCommons);
        })

        dispatch({ type: 'setState', key: 'accessing', value: false });

        // dispatch({ type: 'setStateMultiLayers', keys: ['gallery', 'addressMain'], value: state.getItem.addressMain });
      }
    };

    func();
  }, []);

  if (state.gallery.addressMain === undefined || state.items[state.gallery.addressMain] === undefined) {
    return (
      <div>
        <Header screen='gallery' />
      </div>
    );
  }

  // itemPlacement index作成
  for (let room = 0; room <= state.items[state.gallery.addressMain].itemPlacement.length - 1; room++) {
    for (const [index, item] of state.items[state.gallery.addressMain].itemPlacement[room].items.entries()) {
      itemPlacementIndex[item.itemType][item.no.toString()] = {
        room: room,
        index: index,
      }
    }
  }

  // item並べ替え
  for (const item of state.items[state.gallery.addressMain].item) {
    itemsSorted[itemPlacementIndex.exhibitToken[item.exhibitNo.toString()].room][itemPlacementIndex.exhibitToken[item.exhibitNo.toString()].index] = item;
  }

  if ( state.session[state.gallery.addressMain] !== undefined &&
       state.session[state.gallery.addressMain].expirationOfSession > Date.now() + marginOfSessionTime &&
       state.items[state.gallery.addressMain].itemPlacement[0] !== undefined ) {
    replaceItemButton = <div>
        <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginTop1'
          onClick={ () => handleClickReplaceItem(state, dispatch) }
        >
          {words.commitReplacement[state.language]}
        </button>
    </div>
  }
  else {
    replaceItemButton = null;
  }

  // メインイメージ
  if (exhibitor.images.main === true) {
    if (exhibitor.images.mainNumber !== undefined) {
      mainImage = <img className='mainImage ' src={`https://${process.env.REACT_APP_MONACOTTO_USER_IMAGE}${exhibitor.addressMain}_main_${exhibitor.images.mainNumber.toString().padStart(4, '0')}.img`} />
      mainImageNumber = exhibitor.images.mainNumber;
    }
    else {
      mainImage = <img className='mainImage ' src={`https://${process.env.REACT_APP_MONACOTTO_USER_IMAGE}${exhibitor.addressMain}_main.img`} />
      mainImageNumber = 0;
    }
  }
  else {
    mainImage = null;
    mainImageNumber = 'default';
  }

  // Twitterボタン
  const urlParams = [`mainaddress=${exhibitor.addressMain}`];
  if (mainImageNumber !== 'default') {
    urlParams.push(`mainimageno=${mainImageNumber}`);
  }
  if (state.language !== 'japanese') {
    urlParams.push(`language=${state.language}`);
  }
  const urlParamsStr = urlParamsStringify(urlParams);
  const url = `https://${process.env.REACT_APP_MONACOTTO_EXTENSION_URL}redirect${urlParamsStr}`;
  const title = `もなこっと ${exhibitor.userName}のマイホームです。`
  const hashtags = ['monacotto', 'もなこっと'];

  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        <Header screen='gallery' />
        <div className='flexColumn alignItemsCenter ' >
          <div className='flexRow justifyContentFlexStart widthMax' >
            <div className='margin1 '>
              { mainImage }
            </div>
            <div className='flexColumn justifyContentFlexStart width24 margin1'>
              <div className='flexRow justifyContentFlexStart font1p5 '>
                {usersGeneralExhibitor.userName}
              </div>
              <div className='flexRow justifyContentSpaceBetween '>
                {usersGeneralExhibitor.addressMain}
              </div>
              <div className='flexRow justifyContentSpaceBetween marginTopBottom1 preWrap'>
                {usersGeneralExhibitor.profileText}
              </div>
            </div>
            <div className='flexColumn justifyContentFlexStart margin1'>
              <div>
                {replaceItemButton}
              </div>
              <div className='marginTop1'>
                <TwitterShareButton
                  url={url}
                  title={title}
                  hashtags={hashtags}
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </div>
            </div>
          </div>
          {/*
          <button className='borderNone backgroundColorTransparent font2 cursor marginTop1'
                   onClick={ () => dispatch({ type: 'setStateMultiLayers', keys: ['session', state.gallery.addressMain], value: { expirationOfSession: 0 } }) }
          >
            log out
          </button>
          */}
          <div className='flexRow justifyContentFlexStart widthMax'>
            {
              /*
              state.items[state.gallery.addressMain].item.map( item => {
                if (item.status === 'onSale') {
                  return <Item item={item} />
                }
                else {
                  return null;
                }
              })
              */
              itemsSorted[0].map( item => <Item item={item} index={itemPlacementIndex.exhibitToken[item.exhibitNo.toString()].index} exhibitor={exhibitor} /> )
            }
          </div>
          {/* development */}
          <div>
            { process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null }
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="flexColumn visibleSmallOrLess ">
        <Header screen='gallery' />
        <div className='flexColumn alignItemsCenter ' >
          <div className=''>
            <MainImage user={exhibitor} imageClass='mainImageSmallScreen' />
          </div>
          <div className='flexColumn justifyContentFlexStart width95vw '>
            <div className='flexRow justifyContentFlexStart widthMax breakWord font1p5 '>
              {usersGeneralExhibitor.userName}
            </div>
            <div className='flexRow justifyContentSpaceBetween widthMax breakAll '>
              {usersGeneralExhibitor.addressMain}
            </div>
            <div className='flexRow justifyContentSpaceBetween widthMax breakWord marginTopBottom1'>
              {usersGeneralExhibitor.profileText}
            </div>
          </div>
          <div className='flexColumn justifyContentFlexStart width95vw '>
            <div>
              {replaceItemButton}
            </div>
            <div className='marginTop1'>
              <TwitterShareButton
                url={url}
                title={title}
                hashtags={hashtags}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </div>
          </div>
          {/*
          <button className='borderNone backgroundColorTransparent font2 cursor marginTop1'
                   onClick={ () => dispatch({ type: 'setStateMultiLayers', keys: ['session', state.gallery.addressMain], value: { expirationOfSession: 0 } }) }
          >
            log out
          </button>
          */}
          <div className='flexRow justifyContentSpaceAround'>
            {
              /*
              state.items[state.gallery.addressMain].item.map( item => {
                if (item.status === 'onSale') {
                  return <Item item={item} />
                }
                else {
                  return null;
                }
              })
              */
              itemsSorted[0].map( item => <Item item={item} index={itemPlacementIndex.exhibitToken[item.exhibitNo.toString()].index} exhibitor={exhibitor} /> )
            }
          </div>
        </div>
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
    </div>
  );
}

// FOR DEVELOPMENT
function ForDevelopment() {
  const [state] = useContext(GlobalState);

  return (
    <div className='invisibleSp' >
        <hr/>
        <div className='widthVW'>
          exhibitHistory: {JSON.stringify(state.exhibitHistory)}<br/>
          session: {JSON.stringify(state.session)}<br/>
          user: {JSON.stringify(state.user)}<br/>
          usersGeneral: {JSON.stringify(state.usersGeneral)}<br/>
          configure: {JSON.stringify(state.configure)}<br/>
          assetInfo: {JSON.stringify(state.assetInfo)}<br/>
          monacard: {JSON.stringify(state.monacard)}<br/>
          purchase: {JSON.stringify(state.purchase)}<br/>
          popup: {JSON.stringify(state.popup)}<br/>
          getPermissionHistory: {JSON.stringify(state.getPermissionHistory)}<br/>
          changeExhibit: {JSON.stringify(state.changeExhibit)}<br/>
        </div>
    </div>
  );
}

// ITEM
function Item(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);

  // const popup = { type: 'itemDetail', body: props.item };
  const item = props.item;

  let arrows;

  if ( state.screen === 'gallery' &&
       state.session[state.gallery.addressMain] !== undefined &&
       state.session[state.gallery.addressMain].expirationOfSession > Date.now() + marginOfSessionTime ) {
    arrows = <div>
      <button className='z200Left flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2Half '
        onClick={ (e) => {
          e.stopPropagation();
          dispatch({ type: 'itemReplace', index: props.index, direction: 'left' });
        }}
      >
        <img className='size2x2' src={iconCircleLeft} />
      </button>
      <button className='z200Right flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2Half '
        onClick={ (e) => {
          e.stopPropagation();
          dispatch({ type: 'itemReplace', index: props.index, direction: 'right' });
        }}
      >
        <img className='size2x2' src={iconCircleRight} />
      </button>
    </div>
  }
  else {
    arrows = null;
  }

  let supply;

  if (state.assetInfo[item.asset] !== undefined) {
    supply = state.assetInfo[item.asset].supply;
  }
  else {
    supply = null;
  }

  let lockedImage;

  if (state.assetInfo[item.asset] !== undefined && state.assetInfo[item.asset].locked) {
    lockedImage = <img className='size1p7x1p7' src={iconLock} />;
  }
  else {
    lockedImage = null;
  }

  let screen;
  let soldOut;

  if (item.status === 'soldOut') {
    screen = <div className='screen140'>
    </div>;

    soldOut = <div className='z150Center soldOut'>
      SOLD OUT
    </div>;
  }
  else {
    screen = null;
    soldOut = null;
  }

  // 保有数
  let balance;
  let balanceNum = 0;

  for (const address of Object.keys(state.session)) {
    if (state.session[address].expirationOfSession >= Date.now()) {
      balanceNum += state.balanceKOM[address]?.[item.asset]?.quantity || 0;
    }
  }

  if (Object.keys(state.session).length > 0) {
    balance = <div className='z170TopRight flexRow justifyContentCenter alignItemsCenter size2x2Large borderRadius0p5 backgroundColorMonacottoAlmostWhiteBf ' >
      { balanceNum }
    </div>
  }
  else {
    balance = null;
  }

  if (state.balanceFilter === true && balanceNum > 0) {
    return null;
  }

  // モナダム登録有無
  let monadomCard;

  if (state.registeredCard[item.asset] !== undefined) {
    monadomCard =
    <div className='borderMonacotto padding0p3 marginTopBottom0p5'>
      { 'MONADOM' }
    </div>;
  }


  return (
           <div>
             <button className='boxCard' disabled={props.disabled ? true : false}
               onClick={ () => {
                 dispatch({ type: 'setStateMultiLayers', keys: ['itemDetail', 'item'], value: props.item });
                 dispatch({ type: 'setStateMultiLayers', keys: ['itemDetail', 'exhibitor'], value: props.exhibitor});
                 navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/item' : '/item');
               }}
             >
               <div className='flexColumn justifyContentFlexStart'>
                 <div className='relative' >
                   {
                     state.monacard[props.item.asset] !== undefined ?
                     <img className='cardImage' src={state.config.clientParameters.monacardUrl + state.monacard[item.asset].cid + 'l'} />
                     : null
                   }
                   { screen }
                   { soldOut }
                   { arrows }
                   { balance }
                 </div>
                 <div className='flexRow justifyContentFlexStart font1p5 '>
                   {item.asset_longname === null ? item.asset : item.asset_longname}
                 </div>
                 <div className='flexRow justifyContentSpaceBetween '>
                   <div className='flexRow alignItemsFlexEnd'>
                     <span className='font1p5'>{item.priceMona}</span>
                     <span className='paddingLeft0p5'>{'MONA'}</span>
                   </div>
                   <div className='flexRow alignItemsFlexEnd'>
                     <span className='paddingRight0p5'>{words.amountToSell[state.language]}</span>
                     <span className='font1p5'>{`${item.amountFree} / ${supply}`}</span>
                     { lockedImage }
                   </div>
                 </div>
                 { monadomCard }
               </div>
             </button>
           </div>
  );
}

// ITEM DETAIL
function ItemDetail() {
  const [state, dispatch, navigate, cookies, setCookie] = useContext(GlobalState);
  const item = state.itemDetail.item;
  const exhibitor = state.itemDetail.exhibitor;
  const exhibitorName = state.usersGeneralIndex[item.addressMain] !== undefined ? state.usersGeneralIndex[item.addressMain].userName : '';
  const assetCommon = item.asset_longname === null ? item.asset : item.asset_longname;
  const monadomInfo = state.registeredCard[item.asset];

  let {
    cardName: cardName2,
    cardOwnerName: cardOwnerName2,
    cardDescription: cardDescription2,
    cid: cid2,
    cardImageUrl: cardImageUrl2,
    cardImageUrlSP: cardImageUrlSP2,
    isAMonacard: isAMonacard2,
  } = getMonacardInfoAfterAll(state, item, 'large');

  useEffect( () => {
    if (item.addressMain === undefined || item.exhibitNo === undefined) {
      devLog('itemDetail useEffect');
      navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/newcard' : '/newcard');
    }
  });

  useEffect( () => {
    dispatch({ type: 'setState', key: 'purchase',
      value: {
        purchaseNo: null,
        addressMain: state.purchase.addressMain,
        addressPayFrom: state.purchase.addressPayFrom,
        addressSendCardTo: state.purchase.addressSendCardTo,
        addressMainExhibitor: '',
        exhibitNo: '',
        amountToBuy: state.purchase.amountToBuy,
        signatureByAddressMain: '',
        signatureByAddressPayFrom: '',
        addressPayRoyaltyTo: null,
        disabled: false,
        status: 'waitingForSignature',
      }
    });
  }, []);

  let purchaseNo;

  if (state.purchase.purchaseNo !== undefined && state.purchase.purchaseNo !== null && state.purchase.purchaseNo !== '') {
    purchaseNo = state.purchase.purchaseNo;
  }
  else {
    purchaseNo = '-';
  }

  let supply;

  if (state.assetInfo[item.asset] !== undefined) {
    supply = state.assetInfo[item.asset].supply;
  }
  else {
    supply = null;
  }

  let lockedImage;

  if (state.assetInfo[item.asset] !== undefined && state.assetInfo[item.asset].locked) {
    lockedImage = <img className='size1p7x1p7' src={iconLock} alt='' />;
  }
  else {
    lockedImage = null;
  }

  let vendable;

  if (state.assetInfo[item.asset] !== undefined) {
    if (state.assetInfo[item.asset].vendable) {
      vendable =
        <div className='borderMonacotto padding0p3 marginTopBottom0p5'>
          {words.vendable[state.language]}
        </div>;
    }
    else {
      vendable =
        <div className='borderMonacotto padding0p3 marginTopBottom0p5'>
          {words.notVendable[state.language]}
        </div>;
    }
  }
  else {
    vendable = null;
  }

  let listed;

  if (state.assetInfo[item.asset] !== undefined) {
    if (state.assetInfo[item.asset].listed) {
      listed =
        <div className='borderMonacotto padding0p3 marginTopBottom0p5 marginLeft0p5'>
          {words.listed[state.language]}
        </div>;
    }
    else {
      listed =
        <div className='borderMonacotto padding0p3 marginTopBottom0p5 marginLeft0p5'>
          {words.notListed[state.language]}
        </div>;
    }
  }
  else {
    listed = null;
  }

  /*
  let reassignable;

  if (state.assetInfo[item.asset] !== undefined && state.assetInfo[item.asset].reassignable) {
    reassignable =
      <div className='borderMonacotto'>
        reassignable
      </div>;
  }
  else {
    reassignable = null;
  }
  */

  // モナダム登録有無

  let monadomCard;

  if (state.registeredCard[item.asset] !== undefined) {
    monadomCard =
    <div className='borderMonacotto padding0p3 marginTopBottom0p5 marginLeft0p5'>
      { 'MONADOM' }
    </div>;
  }

  let cardName;
  let cardDescription;

  if (state.assetInfo[item.asset] !== undefined) {
    try {
      const description = JSON.parse(state.assetInfo[item.asset].description);
      cardName = description.monacard.name;
      cardDescription = description.monacard.desc;
    }
    catch (err) {
      if (state.monacard[item.asset] !== undefined) {
        cardName = state.monacard[item.asset].card_name;
        cardDescription = state.monacard[item.asset].add_description;
      }
      else {
        cardName = null;
        cardDescription = null;
      }
    }
  }
  else {
    cardName = null;
    cardDescription = null;
  }

  let soldOut;

  if (item.status === 'soldOut') {
    soldOut = <div className='soldOut'>
      SOLD OUT
    </div>;
  }
  else {
    soldOut = null;
  }

  // 保有数
  let balance;
  let balanceNum = 0;

  for (const address of Object.keys(state.session)) {
    if (state.session[address].expirationOfSession >= Date.now()) {
      balanceNum += state.balanceKOM[address]?.[item.asset]?.quantity || 0;
    }
  }

  if (Object.keys(state.session).length > 0) {
    balance = <div className='flexRow alignItemsFlexEnd marginSide1'>
      <div className=''>
        {words.numberOfHoldings[state.language]}
      </div>
      <div className='font1p5 marginSide0p5'>
        { balanceNum }
      </div>
    </div>
  }
  else {
    balance = null;
  }

  const urlParams = [`mainaddress=${item.addressMain}`, `exhibitno=${item.exhibitNo}`, `asset=${assetCommon}`];
  if (state.language !== 'japanese') {
    urlParams.push(`language=${state.language}`);
  }
  const urlParamsStr = urlParamsStringify(urlParams);
  const url = `https://${process.env.REACT_APP_MONACOTTO_EXTENSION_URL}redirect${urlParamsStr}`;
  const title = `もなこっと ${exhibitorName}のマイホームで ${assetCommon} 出品中です。`
  const hashtags = ['monacotto', 'もなこっと'];

  const confirmTheDetailsOfSendingMona = <button className='buttonMainColor heightMin3 paddingSide1' tabindex='0'
    onClick={ () => {
      const popup = {
        type: 'sendMonaConfirmation',
        body: {
          addressPayProceedsTo: item.addressPayProceedsTo,
          addressPayRoyaltyTo: state.purchase.addressPayRoyaltyTo,
          monacottoAddressMona: state.config.monacottoAddress.monacottoAddressMona,
          proceedsNetMona: item.proceedsNetMona * state.purchase.amountToBuy,
          royaltyMona: item.royaltyMona * state.purchase.amountToBuy,
          feeMona: item.feeMona * state.purchase.amountToBuy,
          transactionFeeUpperBound: state.config.clientParameters.transactionFeeUpperBound,
        },
      };

      dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
    }}
  >
    {words.confirmTheDetailsOfSendingMona[state.language]}
  </button>

  let confirmation;

  if (wallet === 'MonaPallet') {
    confirmation = confirmTheDetailsOfSendingMona;
  }
  else { // Mpurse
    confirmation = null;
  }

  // モナダムボタン

  let monadomInfoButton;

  if (monadomInfo !== undefined) {
    monadomInfoButton =
    <button className='button1' tabindex='0'
      onClick={ () => {
        const card = {
          ...item,
          cardImageUrl: cardImageUrl2,
          cardImageUrlSP: cardImageUrlSP2,
          isAMonacard: isAMonacard2,
        };

        const popup = {
          type: 'monadomInfo',
          body: {
            card,
          },
        };

        dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
      }}
    >
      <img className='size2x2' src={iconSwords} alt='' />
    </button>;
  }


  return (
    <div className=''>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        <Header screen='itemDetail' />
        <div className='flexRow justifyContentCenter'>
          {/* image */}
          <div className='margin2'>
            {
              state.monacard[item.asset] !== undefined ?
              <img className='cardImageLarge' src={state.config.clientParameters.monacardUrl + state.monacard[item.asset].cid} alt='' />
              : null
            }
          </div>
          <div className='flexColumn' >
            <div className='margin2'>
              <div className='flexRow justifyContentFlexStart alignItemsCenter' >
                <div className='flexColumn' >
                  <div className='marginBottom0p5 font1p5'>
                    {assetCommon}
                  </div>
                  <div className='marginBottom0p5 '>
                    <span className='font1p5'>{item.priceMona}</span>
                    <span className=''>{' MONA'}</span>
                  </div>
                </div>
                <div className='marginSide1'>
                  { soldOut }
                </div>
              </div>
              <div className='flexRow alignItemsFlexEnd marginBottom0p5 '>
                <div className=''>
                  {words.amountToSell[state.language]}
                </div>
                <div className='font1p5 marginSide0p5'>
                  {`${item.amountFree} / ${supply}`}
                </div>
                { lockedImage }
                { balance }
              </div>
              <div className='flexRow alignItemsFlexEnd marginBottom0p5 '>
                { vendable }
                { listed }
                { monadomCard }
              </div>
              <div className='flexColumn marginTopBottom0p5'>
                <div>
                  {words.exhibitor[state.language]}
                </div>
                <div className='paddingLeft1'>
                  {state.usersGeneralIndex[item.addressMain] !== undefined ? <span>{state.usersGeneralIndex[item.addressMain].userName}<br/></span> : null}
                  {item.addressMain}
                </div>
              </div>
              <div className='flexColumn marginBottom0p5'>
                <div>
                  {words.owner[state.language]}
                </div>
                <div className='paddingLeft1'>
                  {state.usersGeneralIndex[item.addressOwner] !== undefined ? <span>{state.usersGeneralIndex[item.addressOwner].userName}<br/></span> : null}
                  {item.addressOwner}
                </div>
              </div>
            </div>
            <div className='flexColumn margin2'>
              <div className='widthMax25 font1p5 marginBottom0p5'>
                {/* state.monacard[item.asset] !== undefined ? state.monacard[item.asset].card_name : null */}
                { cardName }
              </div>
              <div className='widthMax25'>
                {/* state.monacard[item.asset] !== undefined ? state.monacard[item.asset].add_description : null */}
                { cardDescription }
              </div>
            </div>
            <div className='flexRow margin2'>
              {/* item detail more */}
              <button className='button1' tabindex='0'
                onClick={ () => {
                  const popup = { type: 'itemDetailMore', body: item };
                  dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
                }}
              >
                <img className='size2x2' src={iconDetail} alt='' />
              </button>
              {/* Monacard */}
              <button className='button1' tabindex='0'
                onClick={ () => window.open(`https://card.mona.jp/explorer/card_detail?asset=${item.asset}`) }
              >
                <img className='size2x2' src={iconCard} alt='' />
              </button>
              {/* monadomInfo */}
              { monadomInfoButton }
              {/* back home */}
              <button className='button1' tabindex='0'
                onClick={ () => {
                  dispatch({ type: 'setStateMultiLayers', keys: ['gallery', 'addressMain'], value: item.addressMain });
                  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/home' : '/home'); 
                }}
              >
                <img className='size2x2' src={iconHome} alt='' />
              </button>
              {/* twitter */}
              <div className='margin0p5'>
                <TwitterShareButton
                  url={url}
                  title={title}
                  hashtags={hashtags}
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </div>
            </div>
            {/* purchase */}
            <div className='margin2'>
              <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
                <div className='flexRow justifyContentSpaceBetween alignItemsCenter marginTop1 widthMax'>
                  <div>
                    { 'No. ' + purchaseNo }
                  </div>
                  <div>
                    <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 ' tabindex='0'
                      onClick={ () => { handleClickDefaultPurchase(state, dispatch) } }
                    >
                      {words.default[state.language]}
                    </button>
                    <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 ' tabindex='0'
                      onClick={ () => { handleClickClearPurchase(state, dispatch) } }
                    >
                      {words.clear[state.language]}
                    </button>
                  </div>
                </div>
              </div>
              <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
                <TextLine3 fieldName='purchaseAddressMain' keys={['purchase', 'addressMain']} face={words.addressMain[state.language]} tooltip={true} disabled={state.purchase.disabled} />
                <button className='button1' disabled={state.purchase.disabled} onClick={ () => handleClickMpurse(state, dispatch, ['purchase', 'addressMain']) }>
                  <img className='size2x2' src={iconMpurse} alt='' />
                </button>
                <button className='button1' disabled={state.purchase.disabled} onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['purchase', 'addressMain']) }>
                  <img className='size2x2' src={iconList} alt='' />
                </button>
              </div>
              <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
                <TextLine3 fieldName='addressPayFrom' keys={['purchase', 'addressPayFrom']} face={words.addressPayFrom[state.language]} tooltip={true} disabled={state.purchase.disabled} />
                <button className='button1' disabled={state.purchase.disabled} onClick={ () => handleClickMpurse(state, dispatch, ['purchase', 'addressPayFrom']) }>
                  <img className='size2x2' src={iconMpurse} alt='' />
                </button>
                <button className='button1' disabled={state.purchase.disabled} onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['purchase', 'addressPayFrom']) }>
                  <img className='size2x2' src={iconList} alt='' />
                </button>
              </div>
              <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
                <TextLine3 fieldName='addressSendCardTo' keys={['purchase', 'addressSendCardTo']} face={words.addressSendCardTo[state.language]} tooltip={true} disabled={state.purchase.disabled} />
                <button className='button1' disabled={state.purchase.disabled} onClick={ () => handleClickMpurse(state, dispatch, ['purchase', 'addressSendCardTo']) }>
                  <img className='size2x2' src={iconMpurse} alt='' />
                </button>
                <button className='button1' disabled={state.purchase.disabled} onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['purchase', 'addressSendCardTo']) }>
                  <img className='size2x2' src={iconList} alt='' />
                </button>
              </div>
              <TextLine3 fieldName='amountToBuy' keys={['purchase', 'amountToBuy']} face={words.amountToBuy[state.language]} type='setStateMultiLayersNum' disabled={state.purchase.disabled} />
              {
                (state.purchase.signatureByAddressMain === undefined ||
                 state.purchase.signatureByAddressMain === null ||
                 state.purchase.signatureByAddressMain === '') ?
                  <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
                    onClick={ async () => { 
                      const result = await handleClickSignPurchase(state, dispatch, cookies, setCookie, 'addressMain');

                      if (result?.message === 'nfcRequired') {
                        const callback = (keyPairs) => {
                          handleClickSignPurchase(state, dispatch, cookies, setCookie, 'addressMain', null, keyPairs);
                          keepKeyPairsInMemory(state, dispatch, keyPairs);
                        };

                        handleClickNfc(state, dispatch, callback);
                      }
                      else if (result?.message === 'bcRequired') {
                        const callback = (keyPairs) => {
                          handleClickSignPurchase(state, dispatch, cookies, setCookie, 'addressMain', null, keyPairs);
                          keepKeyPairsInMemory(state, dispatch, keyPairs);
                        };

                        handleClickScanQr(state, dispatch, callback);
                      }
                    }}
                  >
                    <div>
                      {words.signByMainAddress[state.language]}
                    </div>
                    <img className='size2x2' src={iconSign} alt='' />
                  </button>
                : null
              }
              {
                ( state.purchase.signatureByAddressMain !== undefined &&
                  state.purchase.signatureByAddressMain !== null &&
                  state.purchase.signatureByAddressMain !== '' &&
                 (state.purchase.signatureByAddressPayFrom === undefined ||
                  state.purchase.signatureByAddressPayFrom === null ||
                  state.purchase.signatureByAddressPayFrom === '')) ?
                  <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
                    onClick={ () => {
                      const result = handleClickSignPurchase(state, dispatch, cookies, setCookie, 'addressPayFrom');

                      if (result?.message === 'nfcRequired') {
                        const callback = (keyPairs) => {
                          handleClickSignPurchase(state, dispatch, cookies, setCookie, 'addressPayFrom', null, keyPairs);
                          keepKeyPairsInMemory(state, dispatch, keyPairs);
                        };

                        handleClickNfc(state, dispatch, callback);
                      }
                      else if (result?.message === 'bcRequired') {
                        const callback = (keyPairs) => {
                          handleClickSignPurchase(state, dispatch, cookies, setCookie, 'addressPayFrom', null, keyPairs);
                          keepKeyPairsInMemory(state, dispatch, keyPairs);
                        };

                        handleClickScanQr(state, dispatch, callback);
                      }
                    }}
                  >
                    <div>
                      {words.signByAddressYouPayFrom[state.language]}
                    </div>
                    <img className='size2x2' src={iconSign} alt='' />
                  </button>
                : null
              }
              {
                state.purchase.status === 'waitingForMona' ?
                  <div className='flexColumn alignItemsFlexStart'>
                    { confirmation }
                    <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
                      onClick={ async () => {
                        const result = await handleClickSendMona(state, dispatch);

                        if (result?.message === 'nfcRequired') {
                          const callback = (keyPairs) => {
                            handleClickSendMona(state, dispatch, null, keyPairs);
                            keepKeyPairsInMemory(state, dispatch, keyPairs);
                          };

                          handleClickNfc(state, dispatch, callback);
                        }
                        else if (result?.message === 'bcRequired') {
                          const callback = (keyPairs) => {
                            handleClickSendMona(state, dispatch, null, keyPairs);
                            keepKeyPairsInMemory(state, dispatch, keyPairs);
                          };

                          handleClickScanQr(state, dispatch, callback);
                        }
                      }}
                    >
                      {words.sendMona[state.language]}
                    </button>
                  </div>
                : null
              }
                    {/* <button className='button2 borderRadius2 riseOut2' tabindex='0' */}
            </div>
          </div>
        </div>
        {/* popup */}
        {/*
          <Popup layer={0}/>
          <Popup layer={1}/>
        */}
        {/* development */}
        <div>
          { process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null }
        </div>
      </div>
      {/* SP */}
      <div className="flexColumn alignItemsCenter visibleSmallOrLess ">
        <Header screen='itemDetail' />
        {/* image */}
        <div className='marginTopBottom1'>
          {
            state.monacard[item.asset] !== undefined ?
            <img className='cardImageFullWidth' src={state.config.clientParameters.monacardUrl + state.monacard[item.asset].cid} alt='' />
            : null
          }
        </div>
        <div className='flexColumn alignItemsCenter marginTopBottom1'>
          <div className='widthMax'>
            <div className='flexRow justifyContentFlexStart alignItemsCenter' >
              <div className='flexColumn' >
                <div className='marginBottom0p5 font1p5'>
                  {assetCommon}
                </div>
                <div className='marginBottom0p5 '>
                  <span className='font1p5'>{item.priceMona}</span>
                  <span className=''>{' MONA'}</span>
                </div>
              </div>
              <div className='marginSide1'>
                { soldOut }
              </div>
            </div>
            <div className='flexRow alignItemsFlexEnd marginBottom0p5 '>
              <div className=''>
                {words.amountToSell[state.language]}
              </div>
              <div className='font1p5 marginSide0p5'>
                {`${item.amountFree} / ${supply}`}
              </div>
              { lockedImage }
              { balance }
            </div>
            <div className='flexRow alignItemsFlexEnd marginBottom0p5 '>
              { vendable }
              { listed }
              { monadomCard }
            </div>
            {/* exhibitor */}
            <div className='flexColumn marginTopBottom0p5'>
              <div>
                {words.exhibitor[state.language]}
              </div>
              <div className='paddingLeft1'>
                {state.usersGeneralIndex[item.addressMain] !== undefined ? <span>{state.usersGeneralIndex[item.addressMain].userName}<br/></span> : null}
                <span className='breakAll' >{item.addressMain}</span>
              </div>
            </div>
            {/* owner */}
            <div className='flexColumn marginBottom0p5 '>
              <div>
                {words.owner[state.language]}
              </div>
              <div className='paddingLeft1'>
                {state.usersGeneralIndex[item.addressOwner] !== undefined ? <span>{state.usersGeneralIndex[item.addressOwner].userName}<br/></span> : null}
                <span className='breakAll' >{item.addressOwner}</span>
              </div>
            </div>
            <div className='flexColumn marginTopBottom1'>
              {/* card name */}
              <div className='widthMax25 font1p5 marginBottom0p5'>
                {/* state.monacard[item.asset] !== undefined ? state.monacard[item.asset].card_name : null */}
                { cardName }
              </div>
              {/* description */}
              <div className='widthMax25'>
                {/* state.monacard[item.asset] !== undefined ? state.monacard[item.asset].add_description : null */}
                { cardDescription }
              </div>
            </div>
            <div className='flexRow marginTopBottom1'>
              {/* item detail more */}
              <button className='button1' tabindex='0'
                onClick={ () => {
                  const popup = { type: 'itemDetailMore', body: item };
                  dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
                }}
              >
                <img className='size2x2' src={iconDetail} alt='' />
              </button>
              {/* Monacard */}
              <button className='button1' tabindex='0'
                onClick={ () => window.open(`https://card.mona.jp/explorer/card_detail?asset=${item.asset}`) }
              >
                <img className='size2x2' src={iconCard} alt='' />
              </button>
              {/* monadomInfo */}
              { monadomInfoButton }
              {/* back home */}
              <button className='button1' tabindex='0'
                onClick={ () => {
                  dispatch({ type: 'setStateMultiLayers', keys: ['gallery', 'addressMain'], value: item.addressMain });
                  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/home' : '/home'); 
                }}
              >
                <img className='size2x2' src={iconHome} alt='' />
              </button>
              {/* twitter */}
              <div className='margin0p5'>
                <TwitterShareButton
                  url={url}
                  title={title}
                  hashtags={hashtags}
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </div>
            </div>
          </div>
          {/* purchase */}
          <div className='flexColumn alignItemsCenter marginTopBottom1'>
            <div className='flexRow justifyContentSpaceBetween alignItemsCenter marginTop1 widthMax'>
              <div>
                { 'No. ' + purchaseNo }
              </div>
              <div>
                <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 ' tabindex='0'
                  onClick={ () => { handleClickDefaultPurchase(state, dispatch) } }
                >
                  {words.default[state.language]}
                </button>
                <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 ' tabindex='0'
                  onClick={ () => { handleClickClearPurchase(state, dispatch) } }
                >
                  {words.clear[state.language]}
                </button>
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart' >
              <div className='flexColumn justifyContentFlexStart marginTop1 '>
                <TextLine3 fieldName='purchaseAddressMain' keys={['purchase', 'addressMain']} face={words.addressMain[state.language]} tooltip={true} disabled={state.purchase.disabled} />
                <div className='flexRow justifyContentFlexStart alignItemsCenter' >
                  <button className='button1' disabled={state.purchase.disabled} onClick={ () => handleClickMpurse(state, dispatch, ['purchase', 'addressMain']) }>
                    <img className='size2x2' src={iconMpurse} alt='' />
                  </button>
                  <button className='button1' disabled={state.purchase.disabled} onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['purchase', 'addressMain']) }>
                    <img className='size2x2' src={iconList} alt='' />
                  </button>
                </div>
              </div>
              <div className='flexColumn alignItemsFlexStart marginTop1 '>
                <TextLine3 fieldName='addressPayFrom' keys={['purchase', 'addressPayFrom']} face={words.addressPayFrom[state.language]} tooltip={true} disabled={state.purchase.disabled} />
                <div className='flexRow justifyContentFlexStart alignItemsCenter' >
                  <button className='button1' disabled={state.purchase.disabled} onClick={ () => handleClickMpurse(state, dispatch, ['purchase', 'addressPayFrom']) }>
                    <img className='size2x2' src={iconMpurse} alt='' />
                  </button>
                  <button className='button1' disabled={state.purchase.disabled} onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['purchase', 'addressPayFrom']) }>
                    <img className='size2x2' src={iconList} alt='' />
                  </button>
                </div>
              </div>
              <div className='flexColumn alignItemsFlexStart marginTop1 '>
                <TextLine3 fieldName='addressSendCardTo' keys={['purchase', 'addressSendCardTo']} face={words.addressSendCardTo[state.language]} tooltip={true} disabled={state.purchase.disabled} />
                <div className='flexRow justifyContentFlexStart alignItemsCenter' >
                  <button className='button1' disabled={state.purchase.disabled} onClick={ () => handleClickMpurse(state, dispatch, ['purchase', 'addressSendCardTo']) }>
                    <img className='size2x2' src={iconMpurse} alt='' />
                  </button>
                  <button className='button1' disabled={state.purchase.disabled} onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['purchase', 'addressSendCardTo']) }>
                    <img className='size2x2' src={iconList} alt='' />
                  </button>
                </div>
              </div>
            </div>
            <TextLine3 fieldName='amountToBuy' keys={['purchase', 'amountToBuy']} face={words.amountToBuy[state.language]} type='setStateMultiLayersNum' disabled={state.purchase.disabled} />
            {
              (state.purchase.signatureByAddressMain === undefined ||
               state.purchase.signatureByAddressMain === null ||
               state.purchase.signatureByAddressMain === '') ?
                <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
                  onClick={ async () => {
                    const result = await handleClickSignPurchase(state, dispatch, cookies, setCookie, 'addressMain');

                    if (result?.message === 'nfcRequired') {
                      const callback = (keyPairs) => {
                        handleClickSignPurchase(state, dispatch, cookies, setCookie, 'addressMain', null, keyPairs);
                        keepKeyPairsInMemory(state, dispatch, keyPairs);
                      };

                      handleClickNfc(state, dispatch, callback);
                    }
                    else if (result?.message === 'bcRequired') {
                      const callback = (keyPairs) => {
                        handleClickSignPurchase(state, dispatch, cookies, setCookie, 'addressMain', null, keyPairs);
                        keepKeyPairsInMemory(state, dispatch, keyPairs);
                      };

                      handleClickScanQr(state, dispatch, callback);
                    }
                  }}
                >
                  <div>
                    {words.signByMainAddress[state.language]}
                  </div>
                  <img className='size2x2' src={iconSign} alt='' />
                </button>
              : null
            }
            {
              ( state.purchase.signatureByAddressMain !== undefined &&
                state.purchase.signatureByAddressMain !== null &&
                state.purchase.signatureByAddressMain !== '' &&
               (state.purchase.signatureByAddressPayFrom === undefined ||
                state.purchase.signatureByAddressPayFrom === null ||
                state.purchase.signatureByAddressPayFrom === '')) ?
                <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
                  onClick={ async () => {
                    const result = await handleClickSignPurchase(state, dispatch, cookies, setCookie, 'addressPayFrom');

                    if (result?.message === 'nfcRequired') {
                      const callback = (keyPairs) => {
                        handleClickSignPurchase(state, dispatch, cookies, setCookie, 'addressPayFrom', null, keyPairs);
                        keepKeyPairsInMemory(state, dispatch, keyPairs);
                      };

                      handleClickNfc(state, dispatch, callback);
                    }
                    else if (result?.message === 'bcRequired') {
                      const callback = (keyPairs) => {
                        handleClickSignPurchase(state, dispatch, cookies, setCookie, 'addressPayFrom', null, keyPairs);
                        keepKeyPairsInMemory(state, dispatch, keyPairs);
                      };

                      handleClickScanQr(state, dispatch, callback);
                    }
                  }}
                >
                  <div>
                    {words.signByAddressYouPayFrom[state.language]}
                  </div>
                  <img className='size2x2' src={iconSign} alt='' />
                </button>
              : null
            }
            {
              state.purchase.status === 'waitingForMona' ?
                <div className='flexColumn alignItemsCenter'>
                  <button className='buttonMainColor heightMin3 paddingSide1' tabindex='0'
                    onClick={ () => {
                      const popup = {
                        type: 'sendMonaConfirmation',
                        body: {
                          addressPayProceedsTo: item.addressPayProceedsTo,
                          addressPayRoyaltyTo: state.purchase.addressPayRoyaltyTo,
                          monacottoAddressMona: state.config.monacottoAddress.monacottoAddressMona,
                          proceedsNetMona: item.proceedsNetMona * state.purchase.amountToBuy,
                          royaltyMona: item.royaltyMona * state.purchase.amountToBuy,
                          feeMona: item.feeMona * state.purchase.amountToBuy,
                          transactionFeeUpperBound: state.config.clientParameters.transactionFeeUpperBound,
                        },
                      };

                      dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
                    }}
                  >
                    {words.confirmTheDetailsOfSendingMona[state.language]}
                  </button>
                  <button className='button2 borderRadius2 riseOut2' tabindex='0'
                    onClick={ async () => {
                      const result = await handleClickSendMona(state, dispatch);

                      if (result?.message === 'nfcRequired') {
                        const callback = (keyPairs) => {
                          handleClickSendMona(state, dispatch, null, keyPairs);
                          keepKeyPairsInMemory(state, dispatch, keyPairs);
                        };

                        handleClickNfc(state, dispatch, callback);
                      }
                      else if (result?.message === 'bcRequired') {
                        const callback = (keyPairs) => {
                          handleClickSendMona(state, dispatch, null, keyPairs);
                          keepKeyPairsInMemory(state, dispatch, keyPairs);
                        };

                        handleClickScanQr(state, dispatch, callback);
                      }
                    }}
                  >
                    {words.sendMona[state.language]}
                  </button>
                </div>
              : null
            }
          </div>
        </div>
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
    </div>
  );
}

// ITEM DETAIL MORE
function ItemDetailMore(props) {
  const [state, dispatch] = useContext(GlobalState);
  const record = state.popup[props.popupLayer].body;

  let supply;

  if (state.assetInfo[record.asset] !== undefined) {
    supply = state.assetInfo[record.asset].supply;
  }
  else {
    supply = null;
  }

  let lockState;

  if (state.assetInfo[record.asset] !== undefined) {
    if (state.assetInfo[record.asset].locked) {
      lockState = 'locked';
    }
    else {
      lockState = 'unlocked';
    }
  }
  else {
    lockState = 'blank';
  }

  let vendable;

  if (state.assetInfo[record.asset] !== undefined) {
    if (state.assetInfo[record.asset].vendable) {
      vendable = 'ok';
    }
    else {
      vendable = 'ng';
    }
  }
  else {
    vendable = 'blank';
  }

  let listed;

  if (state.assetInfo[record.asset] !== undefined) {
    if (state.assetInfo[record.asset].listed) {
      listed = 'ok';
    }
    else {
      listed = 'ng';
    }
  }
  else {
    listed = 'blank';
  }

  return (
    <div className=''>
      {/* PC */}
      <div className="flexColumn visibleMiddleOrMore">
        <div className='flexColumn boxDetail'>
          <div>
            {words.addressMainExhibitor[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.addressMain}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.exhibitorName[state.language]}
          </div>
          <div className='paddingLeft1'>
            { state.usersGeneralIndex[record.addressMain] !== undefined ? state.usersGeneralIndex[record.addressMain].userName : null }
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.exhibitNo[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.exhibitNo}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.asset[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.asset}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.asset_longname[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.asset_longname}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.addressOwner[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.addressOwner}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.ownerName[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.ownerName}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.addressPayRoyaltyTo[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.addressPayRoyaltyTo}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.royaltyRecipientName[state.language]}
          </div>
          <div className='paddingLeft1'>
            { state.usersGeneralIndex[record.addressPayRoyaltyTo] !== undefined ? state.usersGeneralIndex[record.addressPayRoyaltyTo].userName : null }
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.unitPriceMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.priceMona + ' MONA'}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.exhibitorProceedsNetMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {(record.proceedsNetMona / 100000000) + ' MONA'}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.royaltyMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {(record.royaltyMona / 100000000) + ' MONA'}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.feeExhibitMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {(record.feeExhibitMona / 100000000) + ' MONA'}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.feePurchaseMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {(record.feePurchaseMona / 100000000) + ' MONA'}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.issueLockStatus[state.language]}
          </div>
          <div className='paddingLeft1'>
            {words[lockState][state.language]}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.dispenser[state.language]}
          </div>
          <div className='paddingLeft1'>
            {words[vendable][state.language]}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.dex[state.language]}
          </div>
          <div className='paddingLeft1'>
            {words[listed][state.language]}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.totalSupply[state.language]}
          </div>
          <div className='paddingLeft1'>
            {supply}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.amountFree[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.amountFree}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.status[state.language]}
          </div>
          <div className='paddingLeft1'>
            {words[record.status][state.language]}
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="flexColumn alignItemsCenter widthMax visibleSmallOrLess">
        <div className='flexColumn boxDetailSmallScreen '>
          <div>
            {words.addressMainExhibitor[state.language]}
          </div>
          <div className='paddingLeft1 breakAll'>
            {record.addressMain}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen '>
          <div>
            {words.exhibitorName[state.language]}
          </div>
          <div className='paddingLeft1'>
            { state.usersGeneralIndex[record.addressMain] !== undefined ? state.usersGeneralIndex[record.addressMain].userName : null }
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.exhibitNo[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.exhibitNo}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.asset[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.asset}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.asset_longname[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.asset_longname}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.addressOwner[state.language]}
          </div>
          <div className='paddingLeft1 breakAll'>
            {record.addressOwner}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.ownerName[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.ownerName}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.addressPayRoyaltyTo[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.addressPayRoyaltyTo}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.royaltyRecipientName[state.language]}
          </div>
          <div className='paddingLeft1'>
            { state.usersGeneralIndex[record.addressPayRoyaltyTo] !== undefined ? state.usersGeneralIndex[record.addressPayRoyaltyTo].userName : null }
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.unitPriceMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.priceMona + ' MONA'}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.exhibitorProceedsNetMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {(record.proceedsNetMona / 100000000) + ' MONA'}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.royaltyMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {(record.royaltyMona / 100000000) + ' MONA'}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.feeExhibitMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {(record.feeExhibitMona / 100000000) + ' MONA'}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.feePurchaseMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {(record.feePurchaseMona / 100000000) + ' MONA'}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.issueLockStatus[state.language]}
          </div>
          <div className='paddingLeft1'>
            {words[lockState][state.language]}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.dispenser[state.language]}
          </div>
          <div className='paddingLeft1'>
            {words[vendable][state.language]}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.dex[state.language]}
          </div>
          <div className='paddingLeft1'>
            {words[listed][state.language]}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.totalSupply[state.language]}
          </div>
          <div className='paddingLeft1'>
            {supply}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.amountFree[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.amountFree}
          </div>
        </div>
        <div className='flexColumn boxDetailSmallScreen'>
          <div>
            {words.status[state.language]}
          </div>
          <div className='paddingLeft1'>
            {words[record.status][state.language]}
          </div>
        </div>
      </div>
    </div>
  );
}

// MONADOM INFO
function MonadomInfo(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const card = state.popup[popupLayer].body.card;

  // 画像

  let image;
  let imageSp;

  if (card.isAMonacard === false) {
    image = <img className='cardImageLarge marginSide1' src={imageNotAMonacard} alt='' />;
    imageSp = <img className='cardImageFullWidth' src={imageNotAMonacard} alt='' />;
  }
  else {
    image = <img className='cardImageLarge marginSide1' src={card.cardImageUrl} alt='' />;
    imageSp = <img className='cardImageFullWidth' src={card.cardImageUrlSP} alt='' />;
  }

  // クリエイター情報編集

  const popup = { type: 'creatorInformation', body: card };

  const editButton =
  <button className='button1'
    onClick={ () => {
      const monacottoAddressMain = state.registeredCard[card.asset].monacottoLink?.url?.match(/mainaddress=(\w+)$/)[1];

      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'creater'], value: state.registeredCard[card.asset].creater }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrText'], value: state.registeredCard[card.asset].createrText }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'monacottoAddressMain'], value: monacottoAddressMain }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 0, 'title'], value: state.registeredCard[card.asset]?.createrLink?.[0]?.title }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 0, 'url'], value: state.registeredCard[card.asset]?.createrLink?.[0]?.url }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 1, 'title'], value: state.registeredCard[card.asset]?.createrLink?.[1]?.title }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 1, 'url'], value: state.registeredCard[card.asset]?.createrLink?.[1]?.url }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 2, 'title'], value: state.registeredCard[card.asset]?.createrLink?.[2]?.title }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 2, 'url'], value: state.registeredCard[card.asset]?.createrLink?.[2]?.url }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.popupLayer + 1], value: popup }); 
    }}
  >
    <img className='size2x2' src={iconEdit} alt='' />
  </button>;


  return (
    <div className=''>
      {/* PC */}
      <div className='visibleMiddleOrMore flexRow justifyContentCenter'>
        <div className=''>
          { image }
        </div>
        <div className='flexColumn alignItemsFlexStart cardImageLarge marginSide1'>
          <div className='flexColumn alignItemsFlexStart widthMax '>
            <CardSpec card={card} />
          </div>
          <div className='flexColumn alignItemsFlexStart widthMax borderKOMFatBorder padding1 marginTop0p5'>
            <div className='flexRow alignItemsFlexEnd marginBottom0p5'>
              <div className='marginRight1'>
                { words.creater[state.language] }
              </div>
              <div className='font1p5'>
                { state.registeredCard[card.asset].creater }
              </div>
            </div>
            <textarea className='widthMax height15 backgroundColorTransparent borderKOM preWrap padding0p5 marginTopBottom1'
              value={ state.registeredCard[card.asset].createrText }
            />
            <div className='flexRow justifyContentFlexStart '>
              <MonacottoLink asset={card.asset}  />
              <CreaterLink asset={card.asset} index={0} />
              <CreaterLink asset={card.asset} index={1} />
              <CreaterLink asset={card.asset} index={2} />
            </div>
            <div className='flexRow justifyContentFlexEnd widthMax '>
              { editButton }
            </div>
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess flexColumn alignItemsCenter ">
        <div className=''>
          { imageSp }
        </div>
        <div className='flexColumn alignItemsFlexStart width96vw '>
          <div className='flexColumn alignItemsFlexStart widthMax '>
            <CardSpec card={card} />
          </div>
          <div className='flexColumn alignItemsFlexStart widthMax borderKOMFatBorder padding1 marginTop0p5'>
            <div className='flexRow alignItemsFlexEnd marginBottom0p5'>
              <div className='marginRight1'>
                { words.creater[state.language] }
              </div>
              <div className='font1p5'>
                { state.registeredCard[card.asset].creater }
              </div>
            </div>
            <textarea className='widthMax height15 backgroundColorTransparent borderKOM preWrap padding0p5 marginTopBottom1'
              value={ state.registeredCard[card.asset].createrText }
            />
            <div className='flexRow justifyContentFlexStart '>
              <MonacottoLink asset={card.asset}  />
              <CreaterLink asset={card.asset} index={0} />
              <CreaterLink asset={card.asset} index={1} />
              <CreaterLink asset={card.asset} index={2} />
            </div>
            <div className='flexRow justifyContentFlexEnd widthMax '>
              { editButton }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// CARD SPEC
function CardSpec(props) {
  const [state] = useContext(GlobalState);
  const card = props.card;
  const information = state.registeredCard[card.asset];

  // エディション(レプリカ)
  let edition;

  if (information.edition === 'replica') {
    edition =
    <div className='marginSide0p5'>
      【OFFICIAL REPLICA】
    </div>;
  }

  // カードスペック

  let spec;

  if (information.category === 'magic') {

    // タイプ
    let typeText = '';

    if (information.preEffects !== undefined) {
      typeText = information.preEffects.reduce( (acc, cur) => {
        if (cur.type !== undefined && cur.type !== null && cur.type !== '') {
          return acc + words[cur.type][state.language] + ' ';
        }
        else {
          return acc;
        }
      },
      '');
    }

    if (typeText === '') {
      typeText = words.nothing[state.language];
    }

    // コスト
    let costText = '';

    if (information.costs !== undefined) {
      for (const cost of information.costs) {
        if (cost.type === 'discardCards') {
          if (cost.comparison === 'equal') {
            costText += words.discardCards01[state.language] + cost.amount + words.discardCards02[state.language];
          }
        }
      }
    }

    if (costText === '') {
      costText = words.nothing[state.language];
    }

    // 排他
    let exclusionText = '';

    if (information.exclusions !== undefined) {
      for (const exclusion of information.exclusions) {
        if (exclusion.type === 'excludeOpponent') {
          exclusionText += exclusion.typesToExclude.reduce( (acc, cur) => acc + words[cur][state.language] + ' ', '');
        }
      }
    }

    if (exclusionText === '') {
      exclusionText = words.nothing[state.language];
    }

    spec =
    <div className='flexColumn alignItemsFlexStart widthMax padding0p5' >
      <div className='flexRow justifyContentSpaceBetween alignItemsFlexStart widthMax marginTopBottom1'>
        <div className=''>
          { words.magic[state.language] }
        </div>
        <div className='flexRow alignItemsCenter'>
          { edition }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
        <div className=''>
          { words.name[state.language] }
        </div>
        <div className=''>
          { information.name }
        </div>
        <div className='preWrap marginTop1'>
          { information.flavorText }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
        <div className=''>
          { words.type[state.language] }
        </div>
        <div className='preWrap'>
          { typeText }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
        <div className=''>
          { words.effect[state.language] }
        </div>
        <div className='preWrap'>
          { information.effectText }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
        <div className=''>
          { words.cost[state.language] }
        </div>
        <div className='preWrap'>
          { costText }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
        <div className=''>
          { words.exclusion[state.language] }
        </div>
        <div className='preWrap'>
          { exclusionText }
        </div>
      </div>
      <div className='flexRow alignItemsCenter borderMonacotto marginTop1' >
        <div className='textCenter width2p4 height1p2' >
          { '0' }
        </div>
        {
          information.maginaThresholds.map( threshold =>
            <div className='textCenter width2p4 height1p2' >
              { threshold.face !== undefined ? threshold.face : threshold.upperBound }
            </div>
          )
        }
      </div>
    </div>
  }
  else { // monster
    spec =
    <div className='flexColumn alignItemsFlexStart widthMax padding0p5' >
      <div className='flexRow justifyContentSpaceBetween alignItemsFlexStart widthMax marginTopBottom1'>
        <div className=''>
          { words.knight[state.language] }
        </div>
        <div className='flexRow alignItemsCenter'>
          { edition }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
        <div className=''>
          { words.name[state.language] }
        </div>
        <div className=''>
          { information.name }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
        <div className=''>
          { words.profile[state.language] }
        </div>
        <div className=''>
          { information.feature }
        </div>
      </div>
    </div>
  }

  return spec;
}

// MONACOTTO LINK
function MonacottoLink(props) {
  const [state] = useContext(GlobalState);
  const asset = props.asset;

  if (state.registeredCard[asset].monacottoLink?.url === undefined) {
    return null;
  }

  return (
    <div className='flexRow alignItemsCenter marginBottom0p5 marginSide0p5'>
      <button className='buttonMainColor widthMin10 height2p2 paddingSide1 flexRow justifyContentCenter alignItemsCenter ' tabindex='0' 
        onClick={ () => {
          window.open( state.registeredCard[asset].monacottoLink.url );
        }}
      >
        <img className='heightMax' src={logoMonacotto} alt='' />
        <img className='heightMax' src={iconCotto} alt='' />
      </button>
      <button className='button1' tabindex='0' 
        onClick={ () => {
          navigator.clipboard.writeText( state.registeredCard[asset].monacottoLink.url )
          .then()
          .catch();
        }}
      >
        <img className='size2x2' src={iconCopy} alt='' />
      </button>
    </div>
  );
}

// CREATER LINK
function CreaterLink(props) {
  const [state] = useContext(GlobalState);
  const asset = props.asset;
  const index = props.index;

  if (state.registeredCard[asset].createrLink?.[index] === undefined) {
    return null;
  }

  return (
    <div className='flexRow alignItemsCenter marginBottom0p5 marginSide0p5'>
      <button className='buttonMainColor widthMin10 height2p2 paddingSide1 textCenter flexRow justifyContentCenter alignItemsCenter ' tabindex='0' 
        onClick={ () => {
          window.open( state.registeredCard[asset].createrLink[index].url );
        }}
      >
        { state.registeredCard[asset].createrLink[index].title }
      </button>
      <button className='button1' tabindex='0' 
        onClick={ () => {
          navigator.clipboard.writeText( state.registeredCard[asset].createrLink[index].url )
          .then()
          .catch();
        }}
      >
        <img className='size2x2' src={iconCopy} alt='' />
      </button>
    </div>
  );
}

// CREATOR INFORMATION
function CreatorInformation(props) {
  const [state, dispatch] = useContext(GlobalState);
  const item = state.popup[props.popupLayer].body;

  // 登録ボタン

  let registerButton;

  if (state.walletType === 'mpurse') {
    registerButton =
    <div className='flexRow justifyContentCenter widthMax marginTop1'>
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
        onClick={ () => {
          handleClickEditCreatorInformation(state, dispatch, item.asset);
        }}
      >
        <div>
          {words.signByOwnerAddressToRegister[state.language]}
        </div>
        <img className='size2x2' src={iconSign} alt=''/>
      </button>
    </div>
  }
  else if (state.walletType === 'nfc') {
    registerButton =
    <div className='flexRow justifyContentCenter widthMax marginTop1'>
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
        onClick={ () => {
          const callback = (keyPairs) => handleClickEditCreatorInformation(state, dispatch, item.asset, { keyPairs });
          handleClickNfc(state, dispatch, callback);
        }}
      >
        <div>
          {words.signByOwnerAddressToRegister[state.language]}
        </div>
        <div>
          <img className='size2x2' src={iconKeyCard} alt=''/>
        </div>
      </button>
    </div>
  }
  else { // qr
    registerButton =
    <div className='flexRow justifyContentCenter widthMax marginTop1'>
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
        onClick={ () => {
          const callback = (keyPairs) => handleClickEditCreatorInformation(state, dispatch, item.asset, { keyPairs });
          handleClickScanQr(state, dispatch, callback, { popupLayer: props.popupLayer + 1 });
        }}
      >
        <div>
          {words.signByOwnerAddressToRegister[state.language]}
        </div>
        <div>
          <img className='size2x2' src={iconQr} alt=''/>
        </div>
      </button>
    </div>
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore flexColumn alignItemsCenter">
        <div className='flexColumn alignItemsFlexStart borderKOM marginSide1 marginTop1 padding0p5'>
          {/* asset */}
          <div className='margin1'>
            { item.asset }
          </div>
          {/* creater */}
          <TextArea2 fieldName='creatorInformationCreater' keys={['creatorInformation', 'creater']} face={words.creater[state.language]}
            boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          {/* createrText */}
          <TextArea2 fieldName='creatorInformationCreaterText' keys={['creatorInformation', 'createrText']} face={words.createrText[state.language]}
            boxClass='box4 margin1' textAreaClass='textArea1'
          />
          {/* monacottoAddressMain */}
          <TextArea2 fieldName='creatorInformationMonacottoAddressMain' keys={['creatorInformation', 'monacottoAddressMain']}
            face={words.monacottoAddressMain[state.language]} boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          {/* createrLink */}
          <TextArea2 fieldName='creatorInformationCreaterLink0Title' keys={['creatorInformation', 'createrLink', 0, 'title']} face={words.createrLinkTitle[state.language] + 1}
            boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          <TextArea2 fieldName='creatorInformationCreaterLink0Url' keys={['creatorInformation', 'createrLink', 0, 'url']} face={words.createrLinkUrl[state.language] + 1}
            boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          <TextArea2 fieldName='creatorInformationCreaterLink1Title' keys={['creatorInformation', 'createrLink', 1, 'title']} face={words.createrLinkTitle[state.language] + 2}
            boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          <TextArea2 fieldName='creatorInformationCreaterLink1Url' keys={['creatorInformation', 'createrLink', 1, 'url']} face={words.createrLinkUrl[state.language] + 2}
            boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          <TextArea2 fieldName='creatorInformationCreaterLink2Title' keys={['creatorInformation', 'createrLink', 2, 'title']} face={words.createrLinkTitle[state.language] + 3}
            boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          <TextArea2 fieldName='creatorInformationCreaterLink2Url' keys={['creatorInformation', 'createrLink', 2, 'url']} face={words.createrLinkUrl[state.language] + 3}
            boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          {/* register button */}
          { registerButton }
        </div>
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess flexColumn alignItemsFlexStart widthMax padding1">
        {/* asset */}
        <div className=''>
          { item.asset }
        </div>
        {/* creater */}
        <TextArea2 fieldName='creatorInformationCreater' keys={['creatorInformation', 'creater']} face={words.creater[state.language]}
          outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        {/* createrText */}
        <TextArea2 fieldName='creatorInformationCreaterText' keys={['creatorInformation', 'createrText']} face={words.createrText[state.language]}
          outerClass='widthMax' boxClass='box4 width96PC marginTop1 ' textAreaClass='textArea1 '
        />
        {/* monacottoAddressMain */}
        <TextArea2 fieldName='creatorInformationMonacottoAddressMain' keys={['creatorInformation', 'monacottoAddressMain']}
          face={words.monacottoAddressMain[state.language]} outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        {/* createrLink */}
        <TextArea2 fieldName='creatorInformationCreaterLink0Title' keys={['creatorInformation', 'createrLink', 0, 'title']} face={words.createrLinkTitle[state.language] + 1}
          outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        <TextArea2 fieldName='creatorInformationCreaterLink0Url' keys={['creatorInformation', 'createrLink', 0, 'url']} face={words.createrLinkUrl[state.language] + 1}
          outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        <TextArea2 fieldName='creatorInformationCreaterLink1Title' keys={['creatorInformation', 'createrLink', 1, 'title']} face={words.createrLinkTitle[state.language] + 2}
          outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        <TextArea2 fieldName='creatorInformationCreaterLink1Url' keys={['creatorInformation', 'createrLink', 1, 'url']} face={words.createrLinkUrl[state.language] + 2}
          outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        <TextArea2 fieldName='creatorInformationCreaterLink2Title' keys={['creatorInformation', 'createrLink', 2, 'title']} face={words.createrLinkTitle[state.language] + 3}
          outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        <TextArea2 fieldName='creatorInformationCreaterLink2Url' keys={['creatorInformation', 'createrLink', 2, 'url']} face={words.createrLinkUrl[state.language] + 3}
          outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        {/* register button */}
        { registerButton }
      </div>
    </div>
  );
}


// NEW CARD
function NewCard(props) {
  const [state, dispatch] = useContext(GlobalState);

  useEffect( () => {
    // 出品物取得
    const func = async () => {
      if (state.itemsNew.item === undefined) {
        dispatch({ type: 'setState', key: 'accessing', value: true });

        // -- アイテム情報取得
        const items = await getItemNew(state, dispatch, 'new', 'itemsNew')
        .then( items => {
          devLog('items', JSON.stringify(items));

          // -- アセット情報取得

          getAssetInfo(state, dispatch, items.body.item.map( item => item.asset ));

          // -- モナカード情報取得
          const assetCommons = items.body.item.map( item => item.asset_longname === null ? item.asset : item.asset_longname );
          getMonacard(state, dispatch, assetCommons);
        })

        dispatch({ type: 'setState', key: 'accessing', value: false });
      }
    };

    func();

    // スクリーン設定
    dispatch({ type: 'setState', key: 'screen', value: 'newCard' });
  }, []);


  return (
    <div className=''>
      {/* PC */}
      <div className="flexColumn visibleMiddleOrMore">
        <Header screen='newCard' />
        <div className='flexColumn alignItemsCenter ' >
          <div className='flexRow justifyContentFlexStart widthMax'>
            {
              state.itemsNew.item !== undefined ?
              state.itemsNew.item.map( item => <Item item={item} exhibitor={item.addressMain} /> )
              : null
            }
          </div>
          {/* development */}
          <div>
            { process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null }
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="flexColumn visibleSmallOrLess ">
        <Header screen='newCard' />
        <div className='flexColumn alignItemsCenter ' >
          <div className='flexRow justifyContentSpaceAround'>
            {
              state.itemsNew.item !== undefined ?
              state.itemsNew.item.map( item => <Item item={item} exhibitor={item.addressMain} /> )
              : null
            }
          </div>
        </div>
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
    </div>
  );
}

// ALL CARD
function AllCard(props) {
  const [state, dispatch] = useContext(GlobalState);

  useEffect( () => {
    // 出品物取得
    const func = async () => {
      if (state.itemsAll.item.length === 0) {
        dispatch({ type: 'setState', key: 'accessing', value: true });

        // -- アイテム情報取得
        await getItemNew(state, dispatch, 'all', 'itemsAll')
        .then( items => {
          devLog('items', JSON.stringify(items));

          // -- アセット情報取得

          getAssetInfo(state, dispatch, items.body.item.map( item => item.asset ));

          // -- モナカード情報取得
          const assetCommons = items.body.item.map( item => item.asset_longname === null ? item.asset : item.asset_longname );
          getMonacard(state, dispatch, assetCommons);
        })

        dispatch({ type: 'setState', key: 'accessing', value: false });
      }
    };

    func();

    // スクリーン設定
    dispatch({ type: 'setState', key: 'screen', value: 'allCard' });
  }, []);


  let items = state.itemsAll.item

  // 本命フィルタ
  items = state.tokenFilter.conditions.reduce( (acc, cur) =>
    acc.filter( item => {
      let attributesUnified = {};

      if (state.assetInfo[item.asset]?.asset_longname !== undefined && state.assetInfo[item.asset].asset_longname !== null) {
        attributesUnified.assetCommon = state.assetInfo[item.asset].asset_longname;
      }
      else {
        attributesUnified.assetCommon = item.asset;
      }

      if (state.assetInfo[item.asset] !== undefined) {
        attributesUnified.addressOwners = state.assetInfo[item.asset].owner;
        attributesUnified.lockStatus = state.assetInfo[item.asset].locked === true ? 'locked' : 'unlocked';
      }

      if (state.assetInfo[item.asset]?.description !== undefined && state.assetInfo[item.asset]?.description !== null && state.assetInfo[item.asset]?.description !== '' ) {
        try {
          const description = JSON.parse(state.assetInfo[item.asset].description);
          attributesUnified.monacardName = description.monacard.name;
          attributesUnified.monacardIssuerNames = description.monacard.owner;
          attributesUnified.monacardDescription = description.monacard.desc;
          attributesUnified.monacardTags = description.monacard.tag.split(',');
        }
        catch (err) {
          // モナカードのdescriptionじゃない。
        }
      }

      if (state.registeredCard[item.asset] !== undefined) {
        attributesUnified.monadom = 'registered';
      }
      else {
        attributesUnified.monadom = 'unregistered';
      }

      if (Object.keys(cur).some( key => cur[key].length >= 1 )) {
        return Object.keys(cur).some( key => {
          if (key === 'monacardName' || key === 'monacardDescription' || key === 'assetCommon') {
            return cur[key].some( condition => {
              return new RegExp(condition).test(attributesUnified[key]);
            });
          }
          // else if (key === 'assetCommon') {
          //   return cur[key].some( condition => {
          //     return new RegExp(condition).test(attributesUnified[key]);
          //   });
          // }
          else if (Array.isArray(attributesUnified[key])) {
            return attributesUnified[key].some( attribute => cur[key].includes(attribute) );
          }
          else { // scalar
            return cur[key].includes(attributesUnified[key]);
          }
        });
      }
      else {
        return true;
      }
    })
    , items
  );

  // トークン名でソート

  items = items.sort( (a, b) => {
    let aComp;
    let bComp;

    if (state.assetInfo[a.asset]?.asset_longname !== undefined && state.assetInfo[a.asset]?.asset_longname !== null) {
      aComp = state.assetInfo[a.asset].asset_longname; 
    }
    else {
      aComp = a.asset; 
    }

    if (state.assetInfo[b.asset]?.asset_longname !== undefined && state.assetInfo[b.asset]?.asset_longname !== null) {
      bComp = state.assetInfo[b.asset].asset_longname; 
    }
    else {
      bComp = b.asset; 
    }

    if (aComp < bComp) {
      return -1;
    }
    else if (aComp > bComp) {
      return 1;
    }
    else {
      return 0;
    }
  });


  return (
    <div className=''>
      {/* PC */}
      <div className="flexColumn visibleMiddleOrMore">
        <Header screen='allCard' />
        <div className='flexColumn alignItemsCenter ' >
          <div className='flexRow justifyContentFlexStart widthMax'>
            { items.map( item => <Item item={item} exhibitor={item.addressMain} /> ) }
          </div>
          {/* development */}
          <div>
            { process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null }
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="flexColumn visibleSmallOrLess ">
        <Header screen='allCard' />
        <div className='flexColumn alignItemsCenter ' >
          <div className='flexRow justifyContentSpaceAround'>
            { items.map( item => <Item item={item} exhibitor={item.addressMain} /> ) }
          </div>
        </div>
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
    </div>
  );
}

// QR CODE SCANNER
function QrCodeScanner(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);
  // const [resultData, setResultData] = useState(undefined);
  const popupLayer = props.popupLayer;
  const callback = props.callback;
  const callbackType = props.callbackType;
  const options = props.options;
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  // const stopScanRef = useRef(false);
  devLog('QrCodeScanner rendered.');

  useEffect( () => {
    devLog('useEffect triggered.');
    // stopScanRef.current = false; // scanQrCode()のブレーキを外す。
    const abortController = new AbortController();
    const signal = abortController.signal;
    devLog('signal', signal);

    const constraints = {
      video: {
        facingMode: 'environment',
        width: { ideal: 300 },
        height: { ideal: 300 },
      },
    };

    // デバイスのカメラにアクセスする
    navigator.mediaDevices
    .getUserMedia(constraints)
    .then((stream) => {
      // デバイスのカメラにアクセスすることに成功したら、video要素にストリームをセットする
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
        devLog('signal2', signal);
        scanQrCode(signal);
      }
    })
    .catch((err) => console.error('Error accessing media devices:', err));

    const currentVideoRef = videoRef.current

    // コンポーネントがアンマウントされたら、カメラのストリームを停止する
    return () => {
      // stopScanRef.current = true; // scanQrCode()を止める。
      devLog('cleanup', signal);
      abortController.abort();

      if (currentVideoRef && currentVideoRef.srcObject) {
        const stream = currentVideoRef.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, []);

  const scanQrCode = (signal) => {
    devLog('scanQrCode called');
    if (signal.aborted) {
      devLog('Scanning stopped');
      return;
    }

    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (canvas && video) {
      const ctx = canvas.getContext('2d');
      // canvas.width = video.videoWidth;
      // canvas.height = video.videoHeight;

      if (ctx) {
        // カメラの映像をcanvasに描画する
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        // QRコードをスキャンする
        const qrCodeData = jsQR(imageData.data, imageData.width, imageData.height);

        if (qrCodeData) {
          // スキャンされた内容を確認する
          // if (false) {
          //   setTimeout(scanQrCode, 100); // スキャンの頻度を制限
          //   return;
          // }

          // setResultData(qrCodeData.data)
          dispatch({ type: 'setStateMultiLayers', keys: ['qrCodeScanner', 'qrCode'], value: qrCodeData.data });
          dispatch({ type: 'setNotification', key: 'notification', value: 'scaned' });
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } });

          if (callbackType === 'decryptMnemonic') {
            doSomethingWithEncryptedMnemonics(state, dispatch, [qrCodeData.data], callback, options);
          }
          else { // simpelCallback
            callback(qrCodeData.data);
          }

          return;
        }

        setTimeout(() => { scanQrCode(signal); }, 333);
      }
    }
  };

  // let scanner;

  // if (resultData === undefined) {
  //   scanner =
  //   <div className=''>
  //     <div className=''>
  //       <video ref={videoRef} autoPlay playsInline className='' />
  //       <canvas ref={canvasRef} width='300' height='300' className='' />
  //     </div>
  //   </div>;
  // }

  // let button;
  // 
  // if (resultData !== undefined) {
  //   button =
  //   <div className=''>
  //     <button>push</button>
  //   </div>
  // }


  return (
    <div className='flexColumn justifyContentCenter alignItemsCenter relative border'>
      <video ref={videoRef} autoPlay playsInline className='absoluteZero zM10' />
      <canvas ref={canvasRef} width='300' height='300' className='' />
    </div>
  );
}


// POPUP
function Popup(props) {
  const [state, dispatch] = useContext(GlobalState);

  const popup = state.popup[props.layer];

  const popupComponent = {
    // itemDetail: <ItemDetail popupLayer={props.layer} />,
    itemDetailMore: <ItemDetailMore popupLayer={props.layer} />,
    exhibitRecordDetailResume: <ExhibitRecordDetailResume popupLayer={props.layer} />,
    exhibitRecordDetailChange: <ExhibitRecordDetailChange popupLayer={props.layer} />,
    exhibitRecordDetailFinished: <ExhibitRecordDetailFinished popupLayer={props.layer} />,
    purchaseRecordDetail: <PurchaseRecordDetail popupLayer={props.layer} />,
    generalList: <GeneralList popupLayer={props.layer} />,
    generalItems: <GeneralItems popupLayer={props.layer} />,
    sendMonaConfirmation: <SendMonaConfirmation popupLayer={props.layer} />,
    monadomInfo: <MonadomInfo popupLayer={props.layer} />,
    creatorInformation: <CreatorInformation popupLayer={props.layer} />,
    tokenFilterPopup: <TokenFilterPopup popupLayer={props.layer} />,
    loginPopup: <LoginPopup popupLayer={props.layer} />,
    userRegistrationPopup: <UserRegistrationPopup popupLayer={props.layer} />,
  };

  if (state.popup[props.layer].type === null) {
    return (
      <div className='invisible' />
    );
  }
  else if (// state.popup[props.layer].type === 'itemDetail' ||
           // state.popup[props.layer].type === 'itemDetailMore' ||
           // state.popup[props.layer].type === 'exhibitRecordDetailResume' ||
           // state.popup[props.layer].type === 'exhibitRecordDetailChange' ||
           // state.popup[props.layer].type === 'exhibitRecordDetailFinished' ||
           // state.popup[props.layer].type === 'purchaseRecordDetail' ||
           // state.popup[props.layer].type === 'generalList' ||
           // state.popup[props.layer].type === 'sendMonaConfirmation' ||
           state.popup[props.layer].type !== undefined && state.popup[props.layer].type !== null) {
    return (
      <div>
        {/* PC */}
        <div className="visibleMiddleOrMore">
          <div className={'popupBackgroundMultiLayer' + (popup.extendedClassesBackGround !== undefined ? ' ' + popup.extendedClassesBackGround : '')} style={{ '--popupLayer': props.layer }}>
            <button className='closePopupMultiLayer' style={{ '--popupLayer': props.layer }}
              onClick={ () => dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.layer], value: { type: null, body: null } }) }
            />
            <div className='popupMultiLayer' style={{ '--popupLayer': props.layer }}>
              <button className='topRightClose focusEffect01' tabindex='0'
                onClick={ () => dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.layer], value: { type: null, body: null } }) } 
              >
                x
              </button>
              {popupComponent[state.popup[props.layer].type]}
            </div>
          </div>
        </div>
        {/* SP */}
        <div className="visibleSmallOrLess">
          <div className='popupBackgroundMultiLayer' style={{ '--popupLayer': props.layer }}>
            <button className='closePopupMultiLayer' style={{ '--popupLayer': props.layer }}
              onClick={ () => dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.layer], value: { type: null, body: null } }) }
            />
            <div className='popupMultiLayerSmallScreen ' style={{ '--popupLayer': props.layer }}>
              <button className='topRightClose focusEffect01' tabindex='0'
                onClick={ () => dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.layer], value: { type: null, body: null } }) } 
              >
                x
              </button>
              {popupComponent[state.popup[props.layer].type]}
            </div>
          </div>
        </div>
      </div>
    );
  }
  else {
    return (
      <div className='' >
      </div>
    );
  }
}

// HISTORY
function History() {
  const [state, dispatch, navigate, cookies, setCookie] = useContext(GlobalState);
  let combinedAction; 
  let historyItems;
  let historyFunction;
  let arrowRight;
  let arrowLeft;
  let addressType;
  let addressFace;

  // 複合アクション
  if (state.getHistory.action === 'exhibit') {
    combinedAction = 'exhibit';
  }
  else if (state.getHistory.action === 'purchase') {
    combinedAction = 'purchase';
  }
  else if (state.getHistory.action === 'sales') {
    combinedAction = 'sales';
  }
  else { // royalty
    if (state.getHistory.addressType === 'addressOwner') {
      combinedAction = 'royaltyAddressOwner';
    }
    else { // addressPayRoyaltyTo
      combinedAction = 'royaltyAddressPayRoyaltyTo';
    }
  }

  if (combinedAction === 'exhibit') {
    if (state.exhibitHistory[state.getHistory.addressMain] !== undefined && state.exhibitHistory[state.getHistory.addressMain].exhibit !== undefined) {
      historyItems = state.exhibitHistory[state.getHistory.addressMain].exhibit.map( record => {
        if (true) {
          return <ExhibitRecord record={record} />
        }
        else {
          return null;
        }
      });

      historyItems.unshift(<ExhibitRecordTitle />);
    }
    else {
      historyItems = [<ExhibitRecordTitle />];
    }

    historyFunction = async (pagingIndex) => {
      const result = await handleClickGetExhibitHistory(state, dispatch, cookies, setCookie, pagingIndex);

      if (result?.message === 'nfcRequired') {
        const callback = (keyPairs) => {
          handleClickGetExhibitHistory(state, dispatch, cookies, setCookie, pagingIndex, null, null, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickNfc(state, dispatch, callback);
      }
      else if (result?.message === 'bcRequired') {
        const callback = (keyPairs) => {
          handleClickGetExhibitHistory(state, dispatch, cookies, setCookie, pagingIndex, null, null, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickScanQr(state, dispatch, callback);
      }
    };
  }
  else if (combinedAction === 'purchase') {
    if (state.purchaseHistory[state.getHistory.addressMain] !== undefined && state.purchaseHistory[state.getHistory.addressMain].purchase !== undefined) {
      historyItems = state.purchaseHistory[state.getHistory.addressMain].purchase.map( record => {
        if (true) {
          return <PurchaseRecord record={record} />
        }
        else {
          return null;
        }
      });

      historyItems.unshift(<PurchaseRecordTitle />);
    }
    else {
      historyItems = [<PurchaseRecordTitle />];
    }

    historyFunction = async (pagingIndex) => {
      const result = await handleClickGetPurchaseHistory(state, dispatch, cookies, setCookie, pagingIndex);

      if (result?.message === 'nfcRequired') {
        const callback = (keyPairs) => {
          handleClickGetPurchaseHistory(state, dispatch, cookies, setCookie, pagingIndex, null, null, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickNfc(state, dispatch, callback);
      }
      else if (result?.message === 'bcRequired') {
        const callback = (keyPairs) => {
          handleClickGetPurchaseHistory(state, dispatch, cookies, setCookie, pagingIndex, null, null, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickScanQr(state, dispatch, callback);
      }
    };
  }
  else if (combinedAction === 'sales') {
    if (state.salesHistory[state.getHistory.addressMain] !== undefined && state.salesHistory[state.getHistory.addressMain].sales !== undefined) {
      historyItems = state.salesHistory[state.getHistory.addressMain].sales.map( record => {
        if (true) {
          return <SalesRecord record={record} />
        }
        else {
          return null;
        }
      });

      historyItems.unshift(<SalesRecordTitle />);
    }
    else {
      historyItems = [<SalesRecordTitle />];
    }

    historyFunction = async (pagingIndex) => {
      const result = await handleClickGetSalesHistory(state, dispatch, cookies, setCookie, pagingIndex);

      if (result?.message === 'nfcRequired') {
        const callback = (keyPairs) => {
          handleClickGetSalesHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickNfc(state, dispatch, callback);
      }
      else if (result?.message === 'bcRequired') {
        const callback = (keyPairs) => {
          handleClickGetSalesHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickScanQr(state, dispatch, callback);
      }
    };
  }
  else if (combinedAction === 'royaltyAddressOwner') {
    if (state.royaltyAddressOwnerHistory[state.getHistory.addressMain] !== undefined && state.royaltyAddressOwnerHistory[state.getHistory.addressMain].royalty !== undefined) {
      historyItems = state.royaltyAddressOwnerHistory[state.getHistory.addressMain].royalty.map( record => {
        if (true) {
          return <RoyaltyRecord record={record} />
        }
        else {
          return null;
        }
      });

      historyItems.unshift(<RoyaltyRecordTitle />);
    }
    else {
      historyItems = [<RoyaltyRecordTitle />];
    }

    historyFunction = async (pagingIndex) => {
      const result = await handleClickGetRoyaltyHistory(state, dispatch, cookies, setCookie, pagingIndex);

      if (result?.message === 'nfcRequired') {
        const callback = (keyPairs) => {
          handleClickGetRoyaltyHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickNfc(state, dispatch, callback);
      }
      else if (result?.message === 'bcRequired') {
        const callback = (keyPairs) => {
          handleClickGetRoyaltyHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickScanQr(state, dispatch, callback);
      }
    };
  }
  else { // royaltyAddressPayRoyaltyTo
    if (state.royaltyAddressPayRoyaltyToHistory[state.getHistory.addressMain] !== undefined && state.royaltyAddressPayRoyaltyToHistory[state.getHistory.addressMain].royalty !== undefined) {
      historyItems = state.royaltyAddressPayRoyaltyToHistory[state.getHistory.addressMain].royalty.map( record => {
        if (true) {
          return <RoyaltyRecord record={record} />
        }
        else {
          return null;
        }
      });

      historyItems.unshift(<RoyaltyRecordTitle />);
    }
    else {
      historyItems = [<RoyaltyRecordTitle />];
    }

    historyFunction = async (pagingIndex) => {
      const result = await handleClickGetRoyaltyHistory(state, dispatch, cookies, setCookie, pagingIndex);

      if (result?.message === 'nfcRequired') {
        const callback = (keyPairs) => {
          handleClickGetRoyaltyHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickNfc(state, dispatch, callback);
      }
      else if (result?.message === 'bcRequired') {
        const callback = (keyPairs) => {
          handleClickGetRoyaltyHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickScanQr(state, dispatch, callback);
      }
    };
  }


  // 矢印

  if ( state.getHistory.pagingIndex[combinedAction] >= 1 ) {
    arrowLeft = <div>
      <button className='flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2 '
        onClick={ () => { historyFunction(state.getHistory.pagingIndex[combinedAction] - 1) } }
      >
        <img className='size2x2' src={iconCircleLeft} />
      </button>
    </div>
  }
  else {
    arrowLeft = <div>
      <button className='size2x2Large backgroundColorTransparent borderNone' disabled={true} >
        <div className='size2x2' />
      </button>
    </div>
  }

  if ( state.getHistory.lastEvaluatedKey[combinedAction][state.getHistory.pagingIndex[combinedAction]] !== undefined &&
       state.getHistory.lastEvaluatedKey[combinedAction][state.getHistory.pagingIndex[combinedAction]] !== null ) {
    arrowRight = <div>
      <button className='flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2 '
        onClick={ () => { historyFunction(state.getHistory.pagingIndex[combinedAction] + 1) } }
      >
        <img className='size2x2' src={iconCircleRight} />
      </button>
    </div>
  }
  else {
    arrowRight = <div>
      <button className='size2x2Large backgroundColorTransparent borderNone' disabled={true} >
        <div className='size2x2' />
      </button>
    </div>
  }

  // addressType

  if (state.getHistory.action === 'royalty') {
    addressType =
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='flexRow justifyContentSpaceAround marginSide0p5 marginTop0p5'>
          <button className={'box3 focusEffect01 riseOut2 ' + (state.getHistory.addressType === 'addressOwner' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
            onClick={ () => {
              dispatch({type: 'setStateMultiLayers', keys: ['getHistory', 'addressType'], value: 'addressOwner'});
            }}>
            {words.addressOwner[state.language]}
          </button>
          <button className={'box3 focusEffect01 riseOut2 ' + (state.getHistory.addressType === 'addressPayRoyaltyTo' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
            onClick={ () => {
                dispatch({type: 'setStateMultiLayers', keys: ['getHistory', 'addressType'], value: 'addressPayRoyaltyTo'});
            }}>
            {words.addressPayRoyaltyTo[state.language]}
          </button>
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='flexColumn alignItemsCenter '>
          <button className={'box3Sp marginTopBottom0p5 focusEffect01 riseOut2 ' + (state.getHistory.addressType === 'addressOwner' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
            onClick={ () => {
              dispatch({type: 'setStateMultiLayers', keys: ['getHistory', 'addressType'], value: 'addressOwner'});
            }}>
            {words.addressOwner[state.language]}
          </button>
          <button className={'box3Sp marginTopBottom0p5 focusEffect01 riseOut2 ' + (state.getHistory.addressType === 'addressPayRoyaltyTo' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
            onClick={ () => {
                dispatch({type: 'setStateMultiLayers', keys: ['getHistory', 'addressType'], value: 'addressPayRoyaltyTo'});
            }}>
            {words.addressPayRoyaltyTo[state.language]}
          </button>
        </div>
      </div>
    </div>;
  }
  else {
    addressType = null;
  }

  // addressFace

  if (state.getHistory.action === 'royalty') {
    if (state.getHistory.addressType === 'addressOwner') {
      addressFace = words.addressOwner[state.language];
    }
    else { // addressPayRoyaltyTo
      addressFace = words.addressPayRoyaltyTo[state.language];
    }
  }
  else {
    addressFace = words.addressMain[state.language];
  }

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <Header screen='history' />
        <div>
          <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
            {/* action */}
            <div className='flexRow justifyContentSpaceAround marginSide0p5 marginTop0p5'>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.getHistory.action === 'exhibit' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['getHistory', 'action'], value: 'exhibit'});
                }}>
                {words.exhibitHistory[state.language]}
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.getHistory.action === 'purchase' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['getHistory', 'action'], value: 'purchase'});
                }}>
                {words.purchaseHistory[state.language]}
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.getHistory.action === 'sales' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['getHistory', 'action'], value: 'sales'});
                }}>
                {words.salesHistory[state.language]}
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.getHistory.action === 'royalty' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                    dispatch({type: 'setStateMultiLayers', keys: ['getHistory', 'action'], value: 'royalty'});
                }}>
                {words.royaltyHistory[state.language]}
              </button>
            </div>
            { addressType }
            {/* address */}
            <TextLine3 fieldName='historyAddressMain' keys={['getHistory', 'addressMain']} face={addressFace} tooltip={true}
              extraFunctionOnChange={ [
                {
                  function: () => {
                    dispatch({
                      type: 'setStateMultiLayers',
                      keys: ['getHistory', 'pagingIndex'],
                      value: { exhibit: 0, purchase: 0, sales: 0, royaltyAddressOwner: 0, royaltyAddressPayRoyaltyTo: 0 }
                    });
                    dispatch({
                      type: 'setStateMultiLayers',
                      keys: ['getHistory', 'lastEvaluatedKey'],
                      value: { exhibit: [], purchase: [], sales: [], royaltyAddressOwner: [], royaltyAddressPayRoyaltyTo: [] }
                    });
                  },
                  arguments: [],
                }
              ]}
            />
            <button className='button1'
              onClick={ () => {
                handleClickMpurse(state, dispatch, ['getHistory', 'addressMain']);
                dispatch({
                  type: 'setStateMultiLayers',
                  keys: ['getHistory', 'pagingIndex'],
                  value: { exhibit: 0, purchase: 0, sales: 0, royaltyAddressOwner: 0, royaltyAddressPayRoyaltyTo: 0 }
                });
                dispatch({
                  type: 'setStateMultiLayers',
                  keys: ['getHistory', 'lastEvaluatedKey'],
                  value: { exhibit: [], purchase: [], sales: [], royaltyAddressOwner: [], royaltyAddressPayRoyaltyTo: [] }
                });
              }}
            >
              <img className='size2x2' src={iconMpurse} />
            </button>
            <button className='button1'
              onClick={ () => {
                handleClickAddressHistory(state, dispatch, cookies, ['getHistory', 'addressMain']);
                dispatch({
                  type: 'setStateMultiLayers',
                  keys: ['getHistory', 'pagingIndex'],
                  value: { exhibit: 0, purchase: 0, sales: 0, royaltyAddressOwner: 0, royaltyAddressPayRoyaltyTo: 0 }
                });
                dispatch({
                  type: 'setStateMultiLayers',
                  keys: ['getHistory', 'lastEvaluatedKey'],
                  value: { exhibit: [], purchase: [], sales: [], royaltyAddressOwner: [], royaltyAddressPayRoyaltyTo: [] }
                });
              }}
            >
              <img className='size2x2' src={iconList} />
            </button>
            <button className='button1' tabindex='0' onClick={ () => { historyFunction(0) } }>
              <img className='size2x2' src={iconSearch} />
            </button>
            { arrowLeft }
            { arrowRight }
          </div>
        </div>
        { historyItems }
        {/*
          <Popup layer={0}/>
          <Popup layer={1}/>
        */}
        {/* development */}
        <div>
          { process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null }
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <Header screen='history' />
        <div className='flexColumn alignItemsCenter marginTop1 '>
          {/* action */}
          <div className='flexColumn alignItemsCenter marginTopBottom0p5'>
            <button className={'box3Sp marginTopBottom0p5 focusEffect01 riseOut2 ' + (state.getHistory.action === 'exhibit' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
              onClick={ () => {
                        dispatch({type: 'setStateMultiLayers', keys: ['getHistory', 'action'], value: 'exhibit'});
              }}>
              {words.exhibitHistory[state.language]}
            </button>
            <button className={'box3Sp marginTopBottom0p5 focusEffect01 riseOut2 ' + (state.getHistory.action === 'purchase' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
              onClick={ () => {
                        dispatch({type: 'setStateMultiLayers', keys: ['getHistory', 'action'], value: 'purchase'});
              }}>
              {words.purchaseHistory[state.language]}
            </button>
            <button className={'box3Sp marginTopBottom0p5 focusEffect01 riseOut2 ' + (state.getHistory.action === 'sales' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
              onClick={ () => {
                        dispatch({type: 'setStateMultiLayers', keys: ['getHistory', 'action'], value: 'sales'});
              }}>
              {words.salesHistory[state.language]}
            </button>
            <button className={'box3Sp marginTopBottom0p5 focusEffect01 riseOut2 ' + (state.getHistory.action === 'royalty' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
              onClick={ () => {
                  dispatch({type: 'setStateMultiLayers', keys: ['getHistory', 'action'], value: 'royalty'});
              }}>
              {words.royaltyHistory[state.language]}
            </button>
          </div>
          { addressType }
          {/* address */}
          <div className='flexColumn '>
            <TextLine3 fieldName='historyAddressMain' keys={['getHistory', 'addressMain']} face={addressFace} tooltip={true}
              extraFunctionOnChange={ [
                {
                  function: () => {
                    dispatch({
                      type: 'setStateMultiLayers',
                      keys: ['getHistory', 'pagingIndex'],
                      value: { exhibit: 0, purchase: 0, sales: 0, royaltyAddressOwner: 0, royaltyAddressPayRoyaltyTo: 0 }
                    });
                    dispatch({
                      type: 'setStateMultiLayers',
                      keys: ['getHistory', 'lastEvaluatedKey'],
                      value: { exhibit: [], purchase: [], sales: [], royaltyAddressOwner: [], royaltyAddressPayRoyaltyTo: [] }
                    });
                  },
                  arguments: [],
                }
              ]}
            />
            <div className='flexRow justifyContentFlexStart marginTopBottom0p5'>
              <button className='button1'
                onClick={ () => {
                  handleClickMpurse(state, dispatch, ['getHistory', 'addressMain']);
                  dispatch({
                    type: 'setStateMultiLayers',
                    keys: ['getHistory', 'pagingIndex'],
                    value: { exhibit: 0, purchase: 0, sales: 0, royaltyAddressOwner: 0, royaltyAddressPayRoyaltyTo: 0 }
                  });
                  dispatch({
                    type: 'setStateMultiLayers',
                    keys: ['getHistory', 'lastEvaluatedKey'],
                    value: { exhibit: [], purchase: [], sales: [], royaltyAddressOwner: [], royaltyAddressPayRoyaltyTo: [] }
                  });
                }}
              >
                <img className='size2x2' src={iconMpurse} />
              </button>
              <button className='button1'
                onClick={ () => {
                  handleClickAddressHistory(state, dispatch, cookies, ['getHistory', 'addressMain']);
                  dispatch({
                    type: 'setStateMultiLayers',
                    keys: ['getHistory', 'pagingIndex'],
                    value: { exhibit: 0, purchase: 0, sales: 0, royaltyAddressOwner: 0, royaltyAddressPayRoyaltyTo: 0 }
                  });
                  dispatch({
                    type: 'setStateMultiLayers',
                    keys: ['getHistory', 'lastEvaluatedKey'],
                    value: { exhibit: [], purchase: [], sales: [], royaltyAddressOwner: [], royaltyAddressPayRoyaltyTo: [] }
                  });
                }}
              >
                <img className='size2x2' src={iconList} />
              </button>
              <button className='button1' tabindex='0' onClick={ () => { historyFunction(0) } }>
                <img className='size2x2' src={iconSearch} />
              </button>
            </div>
          </div>
          <div className='flexRow justifyContentFlexStart marginTopBottom0p5'>
            <div className='marginSide1'>
              { arrowLeft }
            </div>
            <div className='marginSide1'>
              { arrowRight }
            </div>
          </div>
        </div>
        { historyItems }
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
    </div>
  );
}

// EXHIBIT RECORD TITLE
function ExhibitRecordTitle() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { words.exhibitNo[state.language] }
          </div>
          <div className='marginSide1 widthMin25' >
            { words.tokenName[state.language] }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.unitPriceMona[state.language] }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.amountFree[state.language] + ' / ' + words.amountToSellWhole[state.language] }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.status[state.language] }
          </div>
          <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite' disabled={true} />
        </div>
      </div>
      {/* SP */}
    </div>
  );
}

// EXHIBIT RECORD
function ExhibitRecord(props) {
  const [state, dispatch] = useContext(GlobalState);

  const record = props.record;
  const assetCommon = props.record.asset_longname === null ? props.record.asset : props.record.asset_longname;

  let buttons;
  let popup;

  if (record.status === 'waitingForSignature') {
    popup = { type: 'exhibitRecordDetailResume', body: props.record };
    buttons =
      <button className='button1' onClick={ () => { dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup }) } } >
        <img className='size2x2' src={iconDetail} />
      </button>;
  }
  else if (record.status === 'waitingForToken') {
    popup = { type: 'exhibitRecordDetailResume', body: props.record };
    buttons =
      <button className='button1' onClick={ () => { dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup }) } } >
        <img className='size2x2' src={iconDetail} />
      </button>;
  }
  else if (record.status === 'onSale' && record.amountFree >= 1) {
    popup = { type: 'exhibitRecordDetailChange', body: props.record };
    buttons =
      <button className='button1' onClick={ () => { dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup }) } } >
        <img className='size2x2' src={iconDetail} />
      </button>;
  }
  else {
    popup = { type: 'exhibitRecordDetailFinished', body: props.record };
    buttons =
      <button className='button1' onClick={ () => { dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup }) } } >
        <img className='size2x2' src={iconDetail} />
      </button>;
  }

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { 'No. ' + props.record.exhibitNo }
          </div>
          <div className='marginSide1 widthMin25' >
            { assetCommon }
          </div>
          <div className='marginSide1 widthMin15' >
            { props.record.priceMona + ' MONA' }
          </div>
          <div className='marginSide1 widthMin15' >
            { props.record.amountFree + ' / ' + props.record.amountToSell }
          </div>
          <div className='marginSide1 widthMin15' >
            { words[props.record.status][state.language] }
          </div>
          { buttons }
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='boxMonacotto1SmallScreen flexColumn alignItemsFlexStart '  >
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.exhibitNo[state.language] }
            </div>
            <div className='flexRow alignItemsCenter' >
              <div>
                { 'No. ' + props.record.exhibitNo }
              </div>
              { buttons }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween width98PC'>
            <div className='' >
              { words.tokenName[state.language] }
            </div>
            <div className='breakAll' >
              { assetCommon }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween width98PC'>
            <div className='' >
              { words.unitPriceMona[state.language] }
            </div>
            <div className='' >
              { props.record.priceMona + ' MONA' }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween width98PC'>
            <div className='' >
              { words.amountFree[state.language] + ' / ' + words.amountToSellWhole[state.language] }
            </div>
            <div className='' >
              { props.record.amountFree + ' / ' + props.record.amountToSell }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween width98PC'>
            <div className='' >
              { words.status[state.language] }
            </div>
            <div className='' >
              { words[props.record.status][state.language] }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// EXHIBIT RECORD DETAIL RESUME
function ExhibitRecordDetailResume(props) {
  const [state, dispatch,, cookies, setCookie] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const record = state.popup[props.popupLayer].body;

  useEffect( () => {
    // state.exhibitに値を格納する。
    const exhibit = {
      exhibitNo: record.exhibitNo,
      addressMain: record.addressMain,
      addressCardFrom: record.addressCardFrom,
      addressPayProceedsTo: record.addressPayProceedsTo,
      monacottoAddressMonaparty: record.monacottoAddressMonaparty,
      tokenName: record.tokenName,
      amountToSell: record.amountToSell,
      priceMona: { face: record.priceMona, value: record.priceMona },
      priceMonaWatanabe: record.priceMonaWatanabe,
      signatureByAddressMain: record.signatureByAddressMain,
      signatureByAddressCardFrom: record.signatureByAddressCardFrom,
      status: record.status,
    };

    dispatch({ type: 'setState', key: 'exhibit', value: exhibit });
  }, []);

  const onClickSignExhibit = async () => {
    const result = await handleClickSignExhibit(state, dispatch, cookies, setCookie, 'addressCardFrom');

    if (result.status === 'fulfilled') {
      const activeCertification = buildActiveCertification(result, 'exhibit', ['addressMain', 'addressMainActual', 'addressCardFrom', 'addressCardFromActual']);
      handleClickGetExhibitHistory(state, dispatch, cookies, setCookie, 0, activeCertification);
    }
    else if (result?.message === 'nfcRequired') {
      const callback = async (keyPairs) => {
        const result = await handleClickSignExhibit(state, dispatch, cookies, setCookie, 'addressCardFrom', keyPairs);
        keepKeyPairsInMemory(state, dispatch, keyPairs);

        if (result.status === 'fulfilled') {
          const activeCertification = buildActiveCertification(result, 'exhibit', ['addressMain', 'addressMainActual', 'addressCardFrom', 'addressCardFromActual']);
          handleClickGetExhibitHistory(state, dispatch, cookies, setCookie, 0, activeCertification);
        }
      };

      handleClickNfc(state, dispatch, callback);
    }
    else if (result?.message === 'bcRequired') {
      const callback = async (keyPairs) => {
        const result = await handleClickSignExhibit(state, dispatch, cookies, setCookie, 'addressCardFrom', keyPairs);
        keepKeyPairsInMemory(state, dispatch, keyPairs);

        if (result.status === 'fulfilled') {
          const activeCertification = buildActiveCertification(result, 'exhibit', ['addressMain', 'addressMainActual', 'addressCardFrom', 'addressCardFromActual']);
          handleClickGetExhibitHistory(state, dispatch, cookies, setCookie, 0, activeCertification);
        }
      };

      handleClickScanQr(state, dispatch, callback, { popupLayer: popupLayer + 1 });
    }
  };


  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        {/* exhibit */}
        <div className='flexRow justifyContentCenter'>
          {
            ((state.exhibit.signatureByAddressCardFrom === undefined ||
              state.exhibit.signatureByAddressCardFrom === null ||
              state.exhibit.signatureByAddressCardFrom === '') &&
             (state.exhibit.exhibitNo !== undefined &&
              state.exhibit.exhibitNo !== null &&
              state.exhibit.exhibitNo !== '')) ?
              <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0' onClick={ onClickSignExhibit } >
                <div>
                  {words.signByAddressYouSendCardsFrom[state.language]}
                </div>
                <img className='size2x2' src={iconSign} />
              </button>
            : null
          }
        </div>
        <ResumeExhibit record={record} popupLayer={popupLayer} />
        <div className='flexColumn'>
          <div className='flexColumn boxDetail'>
            <div>
              {words.addressMain[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.addressMain}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.exhibitNo[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.exhibitNo}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.asset[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.asset}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.asset_longname[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.asset_longname}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.addressOwner[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.addressOwner}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.ownerName[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.ownerName}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.addressPayRoyaltyTo[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.addressPayRoyaltyTo}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.royaltyRecipientName[state.language]}
            </div>
            <div className='paddingLeft1'>
              { state.usersGeneralIndex[record.addressPayRoyaltyTo] !== undefined ? state.usersGeneralIndex[record.addressPayRoyaltyTo].userName : null }
            </div>
          </div>
          <div className='flexRow boxDetail justifyContentSpaceBetween'>
            <div className='flexColumn '>
              <div>
                {words.addressCardFrom[state.language]}
              </div>
              <div className='paddingLeft1'>
                {record.addressCardFrom}
              </div>
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.addressPayProceedsTo[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.addressPayProceedsTo}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.unitPriceMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.priceMona + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.exhibitorProceedsNetMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.proceedsNetMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.royaltyMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.royaltyMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.feeExhibitMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.feeExhibitMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.feePurchaseMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.feePurchaseMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.amountToSellWhole[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.amountToSell}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.amountFree[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.amountFree}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.status[state.language]}
            </div>
            <div className='paddingLeft1'>
              {words[record.status][state.language]}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.applicationTimeOfExhibit[state.language]}
            </div>
            <div className='paddingLeft1'>
              {localTime(record.serverTimeOfAddressMain)}
            </div>
          </div>
          {
            record.status === 'waitingForSignature' ?
            <div className='flexColumn boxDetail'>
              <div>
                {words.deadlineOfTheSecondSignature[state.language]}
              </div>
              <div className='paddingLeft1'>
                {localTime(record.serverTimeOfAddressMain + state.config.clientParameters.exhibitSecondSignatureExpirationTime)}
              </div>
            </div>
            : null
          }
          {
            record.status === 'waitingForToken' ?
            <div className='flexColumn boxDetail'>
              <div>
                {words.deadlineOfSendingCards[state.language]}
              </div>
              <div className='paddingLeft1'>
                {localTime(record.serverTimeOfAddressCardFrom + state.config.clientParameters.exhibitExpirationTime)}
              </div>
            </div>
            : null
          }
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        {/* exhibit */}
        <div className='flexRow justifyContentCenter'>
          {
            ((state.exhibit.signatureByAddressCardFrom === undefined ||
              state.exhibit.signatureByAddressCardFrom === null ||
              state.exhibit.signatureByAddressCardFrom === '') &&
             (state.exhibit.exhibitNo !== undefined &&
              state.exhibit.exhibitNo !== null &&
              state.exhibit.exhibitNo !== '')) ?
              <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'onClick={ onClickSignExhibit } >
                <div>
                  {words.signByAddressYouSendCardsFrom[state.language]}
                </div>
                <img className='size2x2' src={iconSign} />
              </button>
            : null
          }
        </div>
        <ResumeExhibit record={record} popupLayer={popupLayer} />
        {/* detail */}
        <div className='flexColumn alignItemsCenter'>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressMain[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressMain}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.exhibitNo[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.exhibitNo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.asset[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.asset}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.asset_longname[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.asset_longname}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressOwner[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressOwner}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.ownerName[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.ownerName}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressPayRoyaltyTo[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressPayRoyaltyTo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.royaltyRecipientName[state.language]}
            </div>
            <div className='paddingLeft1'>
              { state.usersGeneralIndex[record.addressPayRoyaltyTo] !== undefined ? state.usersGeneralIndex[record.addressPayRoyaltyTo].userName : null }
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressCardFrom[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressCardFrom}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressPayProceedsTo[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressPayProceedsTo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.unitPriceMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.priceMona + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.exhibitorProceedsNetMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.proceedsNetMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.royaltyMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.royaltyMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.feeExhibitMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.feeExhibitMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.feePurchaseMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.feePurchaseMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.amountToSellWhole[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.amountToSell}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.amountFree[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.amountFree}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.status[state.language]}
            </div>
            <div className='paddingLeft1'>
              {words[record.status][state.language]}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.applicationTimeOfExhibit[state.language]}
            </div>
            <div className='paddingLeft1'>
              {localTime(record.serverTimeOfAddressMain)}
            </div>
          </div>
          {
            record.status === 'waitingForSignature' ?
            <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
              <div className='width96PC '>
                {words.deadlineOfTheSecondSignature[state.language]}
              </div>
              <div className='paddingLeft1'>
                {localTime(record.serverTimeOfAddressMain + state.config.clientParameters.exhibitSecondSignatureExpirationTime)}
              </div>
            </div>
            : null
          }
          {
            record.status === 'waitingForToken' ?
            <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
              <div className='width96PC '>
                {words.deadlineOfSendingCards[state.language]}
              </div>
              <div className='paddingLeft1'>
                {localTime(record.serverTimeOfAddressCardFrom + state.config.clientParameters.exhibitExpirationTime)}
              </div>
            </div>
            : null
          }
        </div>
      </div>
    </div>
  );
                // onClick={ async () => {
                //   const result = await handleClickSignExhibit(state, dispatch, cookies, setCookie, 'addressCardFrom');

                //   if (result.status === 'fulfilled') {
                //     // handleClickGetExhibitHistory(state, dispatch, cookies, setCookie, 0);
                //     const { body: { sessionId, expirationOfSession, exhibit }} = result;
                //     const activeCertification = [];

                //     if (sessionId.addressMain !== undefined) {
                //       activeCertification.push({ address: exhibit.addressMain, sessionId: sessionId.addressMain, expirationOfSession: expirationOfSession.addressMain });
                //     }

                //     if (sessionId.addressMainActual !== undefined) {
                //       activeCertification.push({ address: exhibit.addressMainActual, sessionId: sessionId.addressMainActual, expirationOfSession: expirationOfSession.addressMainActual });
                //     }

                //     if (sessionId.addressCardFrom !== undefined) {
                //       activeCertification.push({ address: exhibit.addressCardFrom, sessionId: sessionId.addressCardFrom, expirationOfSession: expirationOfSession.addressCardFrom });
                //     }

                //     if (sessionId.addressCardFromActual !== undefined) {
                //       activeCertification.push({ address: exhibit.addressCardFromActual, sessionId: sessionId.addressCardFromActual, expirationOfSession: expirationOfSession.addressCardFromActual });
                //     }

                //     handleClickGetExhibitHistory(state, dispatch, cookies, setCookie, 0, activeCertification);
                //   }
                // }}

                // onClick={ async () => {
                //   const result = await handleClickSignExhibit(state, dispatch, cookies, setCookie, 'addressCardFrom');

                //   if (result.status === 'fulfilled') {
                //     handleClickGetExhibitHistory(state, dispatch, cookies, setCookie, 0);
                //   }
                // }}
}

// EXHIBIT RECORD DETAIL CHANGE
function ExhibitRecordDetailChange(props) {
  const [state, dispatch] = useContext(GlobalState);
  const record = state.popup[props.popupLayer].body;

  useEffect( () => {
    // state.changeExhibitに値を格納する。
    const changeExhibit = {
      exhibitNo: record.exhibitNo,
      addressMain: record.addressMain,
      addressPayProceedsTo: record.addressPayProceedsTo,
      priceMona: { face: record.priceMona, value: record.priceMona },
      tokenName: record.tokenName, // ← addressHistoryで使う。
      amountToSell: record.amountToSell, // ← addressHistoryで使う。
      signatureByAddressMainChange: null,
      status: 'waitingForSignature', // ← onSaleにしたいという気持ちがある。
    };

    dispatch({ type: 'setState', key: 'changeExhibit', value: changeExhibit });

    // state.cancelExhibitに値を格納する。
    const cancelExhibit = {
      exhibitNo: record.exhibitNo,
      addressMain: record.addressMain,
      tokenName: record.tokenName, // ← addressHistoryで使う。
      amountToSell: record.amountToSell, // ← addressHistoryで使う。
      signatureByAddressMainCancel: null,
      status: 'waitingForSignature', // ← onSaleにしたいという気持ちがある。
    };

    dispatch({ type: 'setState', key: 'cancelExhibit', value: cancelExhibit });
  }, []);

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        {/* cancel action */}
        <div className='flexRow justifyContentSpaceAround marginSide0p5 marginTop0p5'>
          <button className={'box3 focusEffect01 riseOut2 ' + (state.getHistory.cancelAction === 'cancel' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
            onClick={ () => {
                      dispatch({type: 'setStateMultiLayers', keys: ['getHistory', 'cancelAction'], value: 'cancel'});
            }}>
            {words.cancel[state.language]}
          </button>
          <button className={'box3 focusEffect01 riseOut2 ' + (state.getHistory.cancelAction === 'change' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
            onClick={ () => {
                      dispatch({type: 'setStateMultiLayers', keys: ['getHistory', 'cancelAction'], value: 'change'});
            }}>
            {words.cancelAndReexhibit[state.language]}
          </button>
        </div>
        {/* exhibit */}
        <div className='flexRow justifyContentCenter'>
          { state.getHistory.cancelAction === 'change' ? <ChangeExhibit /> : null }
        </div>
        <div>
          { state.getHistory.cancelAction === 'cancel' ? <CancelSigning popupLayer={props.popupLayer} /> : <ChangeExhibitSigning popupLayer={props.popupLayer} /> }
        </div>
        <div className='flexColumn'>
          <div className='flexRow boxDetail justifyContentSpaceBetween'>
            <div className='flexColumn '>
              <div>
                {words.addressMain[state.language]}
              </div>
              <div className='paddingLeft1'>
                {record.addressMain}
              </div>
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.exhibitNo[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.exhibitNo}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.asset[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.asset}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.asset_longname[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.asset_longname}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.addressOwner[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.addressOwner}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.ownerName[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.ownerName}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.addressPayRoyaltyTo[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.addressPayRoyaltyTo}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.royaltyRecipientName[state.language]}
            </div>
            <div className='paddingLeft1'>
              { state.usersGeneralIndex[record.addressPayRoyaltyTo] !== undefined ? state.usersGeneralIndex[record.addressPayRoyaltyTo].userName : null }
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.addressCardFrom[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.addressCardFrom}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.addressPayProceedsTo[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.addressPayProceedsTo}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.unitPriceMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.priceMona + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.exhibitorProceedsNetMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.proceedsNetMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.royaltyMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.royaltyMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.feeExhibitMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.feeExhibitMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.feePurchaseMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.feePurchaseMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.amountToSellWhole[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.amountToSell}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.amountFree[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.amountFree}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.status[state.language]}
            </div>
            <div className='paddingLeft1'>
              {words[record.status][state.language]}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.txidOfSendingCards[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.receiveTokenTx_hash}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.msg_indexOfSendingCards[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.receiveTokenMsg_index}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.exhibitNoToBeTakenOver[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.exhibitNoToBeTakenOver}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.exhibitNoToTakeOver[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.exhibitNoToTakeOver}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.applicationTimeOfExhibit[state.language]}
            </div>
            <div className='paddingLeft1'>
              {localTime(record.serverTimeOfAddressMain)}
            </div>
          </div>
          <div className='margin1'>
            当出品に紐づく購入履歴も表示する予定です。
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        {/* cancel action */}
        <div className='flexRow justifyContentSpaceAround marginSide0p5 marginTop0p5'>
          <button className={'box3 focusEffect01 riseOut2 ' + (state.getHistory.cancelAction === 'cancel' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
            onClick={ () => {
                      dispatch({type: 'setStateMultiLayers', keys: ['getHistory', 'cancelAction'], value: 'cancel'});
            }}>
            {words.cancel[state.language]}
          </button>
          <button className={'box3 focusEffect01 riseOut2 ' + (state.getHistory.cancelAction === 'change' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
            onClick={ () => {
                      dispatch({type: 'setStateMultiLayers', keys: ['getHistory', 'cancelAction'], value: 'change'});
            }}>
            {words.cancelAndReexhibit[state.language]}
          </button>
        </div>
        {/* exhibit */}
        <div className='flexColumn alignItemsCenter'>
          <div className=''>
            { state.getHistory.cancelAction === 'change' ? <ChangeExhibit /> : null }
          </div>
          <div>
            { state.getHistory.cancelAction === 'cancel' ? <CancelSigning popupLayer={props.popupLayer} /> : <ChangeExhibitSigning popupLayer={props.popupLayer} /> }
          </div>
        </div>
        {/* detail */}
        <div className='flexColumn alignItemsCenter'>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressMain[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressMain}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.exhibitNo[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.exhibitNo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.asset[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.asset}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.asset_longname[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.asset_longname}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressOwner[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressOwner}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.ownerName[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.ownerName}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressPayRoyaltyTo[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressPayRoyaltyTo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.royaltyRecipientName[state.language]}
            </div>
            <div className='paddingLeft1'>
              { state.usersGeneralIndex[record.addressPayRoyaltyTo] !== undefined ? state.usersGeneralIndex[record.addressPayRoyaltyTo].userName : null }
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressCardFrom[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressCardFrom}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressPayProceedsTo[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressPayProceedsTo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.unitPriceMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.priceMona + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.exhibitorProceedsNetMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.proceedsNetMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.royaltyMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.royaltyMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.feeExhibitMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.feeExhibitMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.feePurchaseMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.feePurchaseMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.amountToSellWhole[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.amountToSell}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.amountFree[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.amountFree}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.status[state.language]}
            </div>
            <div className='paddingLeft1'>
              {words[record.status][state.language]}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.txidOfSendingCards[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.receiveTokenTx_hash}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.msg_indexOfSendingCards[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.receiveTokenMsg_index}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.exhibitNoToBeTakenOver[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.exhibitNoToBeTakenOver}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.exhibitNoToTakeOver[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.exhibitNoToTakeOver}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.applicationTimeOfExhibit[state.language]}
            </div>
            <div className='paddingLeft1'>
              {localTime(record.serverTimeOfAddressMain)}
            </div>
          </div>
          <div className='margin1'>
            当出品に紐づく購入履歴も表示する予定です。
          </div>
        </div>
      </div>
    </div>
  );
}

// EXHIBIT RECORD DETAIL FINISHED
function ExhibitRecordDetailFinished(props) {
  const [state, dispatch] = useContext(GlobalState);
  const record = state.popup[props.popupLayer].body;

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='flexColumn'>
          <div className='flexColumn boxDetail'>
            <div>
              {words.addressMain[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.addressMain}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.exhibitNo[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.exhibitNo}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.asset[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.asset}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.asset_longname[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.asset_longname}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.addressOwner[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.addressOwner}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.ownerName[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.ownerName}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.addressPayRoyaltyTo[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.addressPayRoyaltyTo}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.royaltyRecipientName[state.language]}
            </div>
            <div className='paddingLeft1'>
              { state.usersGeneralIndex[record.addressPayRoyaltyTo] !== undefined ? state.usersGeneralIndex[record.addressPayRoyaltyTo].userName : null }
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.addressCardFrom[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.addressCardFrom}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.addressPayProceedsTo[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.addressPayProceedsTo}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.unitPriceMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.priceMona + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.exhibitorProceedsNetMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.proceedsNetMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.royaltyMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.royaltyMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.feeExhibitMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.feeExhibitMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.feePurchaseMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.feePurchaseMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.amountToSellWhole[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.amountToSell}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.amountFree[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.amountFree}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.status[state.language]}
            </div>
            <div className='paddingLeft1'>
              {words[record.status][state.language]}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.txidOfSendingCards[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.receiveTokenTx_hash}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.msg_indexOfSendingCards[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.receiveTokenMsg_index}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.txidOfSendingBackCards[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.sendBackTokenTxid}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.exhibitNoToBeTakenOver[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.exhibitNoToBeTakenOver}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.exhibitNoToTakeOver[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.exhibitNoToTakeOver}
            </div>
          </div>
          <div className='flexColumn boxDetail'>
            <div>
              {words.applicationTimeOfExhibit[state.language]}
            </div>
            <div className='paddingLeft1'>
              {localTime(record.serverTimeOfAddressMain)}
            </div>
          </div>
          {
            record.status === 'expiredWithoutSecondSignature' ?
            <div className='flexColumn boxDetail'>
              <div>
                {words.deadlineOfTheSecondSignature[state.language]}
              </div>
              <div className='paddingLeft1'>
                {localTime(record.serverTimeOfAddressMain + state.config.clientParameters.exhibitSecondSignatureExpirationTime)}
              </div>
            </div>
            : null
          }
          {
            record.status === 'expired' ?
            <div className='flexColumn boxDetail'>
              <div>
                {words.deadlineOfSendingCards[state.language]}
              </div>
              <div className='paddingLeft1'>
                {localTime(record.serverTimeOfAddressCardFrom + state.config.clientParameters.exhibitExpirationTime)}
              </div>
            </div>
            : null
          }
          <div className='margin1'>
            当出品に紐づく購入履歴も表示する予定です。
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='flexColumn alignItemsCenter'>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressMain[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressMain}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.exhibitNo[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.exhibitNo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.asset[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.asset}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.asset_longname[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.asset_longname}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressOwner[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressOwner}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.ownerName[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.ownerName}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressPayRoyaltyTo[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressPayRoyaltyTo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.royaltyRecipientName[state.language]}
            </div>
            <div className='paddingLeft1'>
              { state.usersGeneralIndex[record.addressPayRoyaltyTo] !== undefined ? state.usersGeneralIndex[record.addressPayRoyaltyTo].userName : null }
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressCardFrom[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressCardFrom}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressPayProceedsTo[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressPayProceedsTo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.unitPriceMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.priceMona + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.exhibitorProceedsNetMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.proceedsNetMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.royaltyMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.royaltyMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.feeExhibitMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.feeExhibitMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.feePurchaseMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.feePurchaseMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.amountToSellWhole[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.amountToSell}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.amountFree[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.amountFree}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.status[state.language]}
            </div>
            <div className='paddingLeft1'>
              {words[record.status][state.language]}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.txidOfSendingCards[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.receiveTokenTx_hash}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.msg_indexOfSendingCards[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.receiveTokenMsg_index}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.txidOfSendingBackCards[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.sendBackTokenTxid}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.exhibitNoToBeTakenOver[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.exhibitNoToBeTakenOver}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.exhibitNoToTakeOver[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.exhibitNoToTakeOver}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.applicationTimeOfExhibit[state.language]}
            </div>
            <div className='paddingLeft1'>
              {localTime(record.serverTimeOfAddressMain)}
            </div>
          </div>
          {
            record.status === 'expiredWithoutSecondSignature' ?
            <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
              <div className='width96PC '>
                {words.deadlineOfTheSecondSignature[state.language]}
              </div>
              <div className='paddingLeft1'>
                {localTime(record.serverTimeOfAddressMain + state.config.clientParameters.exhibitSecondSignatureExpirationTime)}
              </div>
            </div>
            : null
          }
          {
            record.status === 'expired' ?
            <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
              <div className='width96PC '>
                {words.deadlineOfSendingCards[state.language]}
              </div>
              <div className='paddingLeft1'>
                {localTime(record.serverTimeOfAddressCardFrom + state.config.clientParameters.exhibitExpirationTime)}
              </div>
            </div>
            : null
          }
          <div className='margin1'>
            当出品に紐づく購入履歴も表示する予定です。
          </div>
        </div>
      </div>
    </div>
  );
}

// PURCHASE RECORD TITLE
function PurchaseRecordTitle(props) {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { words.purchaseNo[state.language] }
          </div>
          <div className='marginSide1 widthMin25' >
            { words.tokenName[state.language] }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.unitPriceMona[state.language] }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.amountToBuy[state.language] }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.status[state.language] }
          </div>
          <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite' disabled={true} />
        </div>
      </div>
      {/* SP */}
    </div>
  );
}

// PURCHASE RECORD
function PurchaseRecord(props) {
  const [state, dispatch] = useContext(GlobalState);
  const record = props.record;
  const assetCommon = record.asset_longname === null ? record.asset : record.asset_longname;

  let buttons;
  let popup;

  if (record.status === 'waitingForSignature') {
    popup = { type: 'purchaseRecordDetail', body: props.record };
    buttons =
      <button className='button1' onClick={ () => { dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup }) } } >
        <img className='size2x2' src={iconDetail} />
      </button>;
  }
  else if (record.status === 'waitingForMona') {
    popup = { type: 'purchaseRecordDetail', body: props.record };
    buttons =
      <button className='button1' onClick={ () => { dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup }) } } >
        <img className='size2x2' src={iconDetail} />
      </button>;
  }
  else {
    // buttons = <div className='width2p4' />
    popup = { type: 'purchaseRecordDetail', body: props.record };
    buttons =
      <button className='button1' onClick={ () => { dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup }) } } >
        <img className='size2x2' src={iconDetail} />
      </button>;
  }

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { 'No. ' + props.record.purchaseNo }
          </div>
          <div className='marginSide1 widthMin25' >
            { assetCommon }
          </div>
          <div className='marginSide1 widthMin15' >
            { props.record.priceMona + ' MONA' }
          </div>
          <div className='marginSide1 widthMin15' >
            { props.record.amountToBuy }
          </div>
          <div className='marginSide1 widthMin15' >
            { words[props.record.status][state.language] }
          </div>
          { buttons }
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='boxMonacotto1SmallScreen flexColumn alignItemsFlexStart '  >
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.purchaseNo[state.language] }
            </div>
            <div className='flexRow alignItemsCenter' >
              <div>
                { 'No. ' + props.record.purchaseNo }
              </div>
              { buttons }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.tokenName[state.language] }
            </div>
            <div className='' >
              { assetCommon }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.unitPriceMona[state.language] }
            </div>
            <div className='' >
              { props.record.priceMona + ' MONA' }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.amountToBuy[state.language] }
            </div>
            <div className='' >
              { props.record.amountToBuy }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.status[state.language] }
            </div>
            <div className='' >
              { words[props.record.status][state.language] }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// PURCHASE RECORD DETAIL
function PurchaseRecordDetail(props) {
  const [state, dispatch] = useContext(GlobalState);
  const record = state.popup[props.popupLayer].body;

  useEffect( () => {
    // state.purchaseに値を格納する。 ←　これやんなくてもいいかも。むしろやらないほうがいいかも。
    const purchase = {
      purchaseNo: record.purchaseNo,
      addressMain: record.addressMain,
      addressPayFrom: record.addressPayFrom,
      addressSendCardTo: record.addressSendCardTo,
      addressMainExhibitor: record.addressMainExhibitor,
      exhibitNo: record.exhibitNo,
      amountToBuy: record.amountToBuy,
      signatureByAddressMain: record.signatureByAddressMain,
      signatureByAddressPayFrom: record.signatureByAddressPayFrom,
      addressPayRoyaltyTo: record.addressPayRoyaltyTo,
      status: record.status,
    };

    dispatch({ type: 'setState', key: 'purchase', value: purchase });
  }, []);

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div>
          <ResumePurchaseSigning popupLayer={props.popupLayer} />
        </div>
        <ResumePurchase record={record} popupLayer={props.popupLayer} />
        <div className='flexColumn boxDetail'>
          <div>
            {words.addressMain[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.addressMain}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.purchaseNo[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.purchaseNo}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.addressMainExhibitor[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.addressMainExhibitor}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.exhibitorName[state.language]}
          </div>
          <div className='paddingLeft1'>
            {state.usersGeneralIndex[record.addressMainExhibitor] !== undefined ? state.usersGeneralIndex[record.addressMainExhibitor].userName : null}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.exhibitNo[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.exhibitNo}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.asset[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.asset}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.asset_longname[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.asset_longname}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.addressOwner[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.addressOwner}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.ownerName[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.ownerName}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.addressPayProceedsTo[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.addressPayProceedsTo}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.addressPayRoyaltyTo[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.addressPayRoyaltyTo}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.royaltyRecipientName[state.language]}
          </div>
          <div className='paddingLeft1'>
            {state.usersGeneralIndex[record.addressPayRoyaltyTo] !== undefined ? state.usersGeneralIndex[record.addressPayRoyaltyTo].userName : null}
          </div>
        </div>
        <div className='flexRow boxDetail justifyContentSpaceBetween'>
          <div className='flexColumn '>
            <div>
              {words.addressPayFrom[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.addressPayFrom}
            </div>
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.addressSendCardTo[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.addressSendCardTo}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.unitPriceMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.priceMona + ' MONA'}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.exhibitorProceedsNetMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {(record.proceedsNetMona / 100000000) + 'MONA'}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.royaltyMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {(record.royaltyMona / 100000000) + ' MONA'}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.feeExhibitMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {(record.feeExhibitMona / 100000000) + ' MONA'}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.feePurchaseMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {(record.feePurchaseMona / 100000000) + ' MONA'}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.amountToBuy[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.amountToBuy}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.status[state.language]}
          </div>
          <div className='paddingLeft1'>
            {words[record.status][state.language]}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.txidOfSendingMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.receiveMonaTxid}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.txidOfSendingCards[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.sendTokenTxid}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.applicationTimeOfPurchase[state.language]}
          </div>
          <div className='paddingLeft1'>
            {localTime(record.serverTimeOfAddressMain)}
          </div>
        </div>
        {
          record.status === 'waitingForSignature' || record.status === 'expiredWithoutSecondSignature' ?
          <div className='flexColumn boxDetail'>
            <div>
              {words.deadlineOfTheSecondSignature[state.language]}
            </div>
            <div className='paddingLeft1'>
              {localTime(record.serverTimeOfAddressMain + state.config.clientParameters.purchaseSecondSignatureExpirationTime)}
            </div>
          </div>
          : null
        }
        {
          record.status === 'waitingForMona' || record.status === 'expired' ?
          <div className='flexColumn boxDetail'>
            <div>
              {words.deadlineOfSendingMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {localTime(record.serverTimeOfAddressPayFrom + state.config.clientParameters.purchaseExpirationTime)}
            </div>
          </div>
          : null
        }
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div>
          <ResumePurchaseSigning popupLayer={props.popupLayer} />
        </div>
        <ResumePurchase record={record} popupLayer={props.popupLayer} />
        {/* detail */}
        <div className='flexColumn alignItemsCenter'>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressMain[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressMain}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.purchaseNo[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.purchaseNo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressMainExhibitor[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressMainExhibitor}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.exhibitorName[state.language]}
            </div>
            <div className='paddingLeft1'>
              {state.usersGeneralIndex[record.addressMainExhibitor] !== undefined ? state.usersGeneralIndex[record.addressMainExhibitor].userName : null}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.exhibitNo[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.exhibitNo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.asset[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.asset}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.asset_longname[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.asset_longname}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressOwner[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressOwner}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.ownerName[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.ownerName}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressPayProceedsTo[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressPayProceedsTo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressPayRoyaltyTo[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressPayRoyaltyTo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.royaltyRecipientName[state.language]}
            </div>
            <div className='paddingLeft1'>
              {state.usersGeneralIndex[record.addressPayRoyaltyTo] !== undefined ? state.usersGeneralIndex[record.addressPayRoyaltyTo].userName : null}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressPayFrom[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressPayFrom}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressSendCardTo[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressSendCardTo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.unitPriceMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.priceMona + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.exhibitorProceedsNetMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.proceedsNetMona / 100000000) + 'MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.royaltyMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.royaltyMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.feeExhibitMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.feeExhibitMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.feePurchaseMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.feePurchaseMona / 100000000) + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.amountToBuy[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.amountToBuy}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.status[state.language]}
            </div>
            <div className='paddingLeft1'>
              {words[record.status][state.language]}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.txidOfSendingMona[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.receiveMonaTxid}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.txidOfSendingCards[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.sendTokenTxid}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.applicationTimeOfPurchase[state.language]}
            </div>
            <div className='paddingLeft1'>
              {localTime(record.serverTimeOfAddressMain)}
            </div>
          </div>
          {
            record.status === 'waitingForSignature' || record.status === 'expiredWithoutSecondSignature' ?
            <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
              <div className='width96PC '>
                {words.deadlineOfTheSecondSignature[state.language]}
              </div>
              <div className='paddingLeft1'>
                {localTime(record.serverTimeOfAddressMain + state.config.clientParameters.purchaseSecondSignatureExpirationTime)}
              </div>
            </div>
            : null
          }
          {
            record.status === 'waitingForMona' || record.status === 'expired' ?
            <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
              <div className='width96PC '>
                {words.deadlineOfSendingMona[state.language]}
              </div>
              <div className='paddingLeft1'>
                {localTime(record.serverTimeOfAddressPayFrom + state.config.clientParameters.purchaseExpirationTime)}
              </div>
            </div>
            : null
          }
        </div>
      </div>
    </div>
  );
}

// RESUME PURCHASE
function ResumePurchase(props) {
  const [state, dispatch,, cookies, setCookie] = useContext(GlobalState);
  const record = props.record;
  const popupLayer = props.popupLayer;

  const confirmTheDetailsOfSendingMona = <button className='buttonMainColor heightMin3 paddingSide1' tabindex='0'
    onClick={ () => {
      const popup = {
        type: 'sendMonaConfirmation',
        body: {
          addressPayProceedsTo: record.addressPayProceedsTo,
          addressPayRoyaltyTo: record.addressPayRoyaltyTo,
          monacottoAddressMona: state.config.monacottoAddress.monacottoAddressMona,
          proceedsNetMona: record.proceedsNetMona * record.amountToBuy,
          royaltyMona: record.royaltyMona * record.amountToBuy,
          feeMona: record.feeMona * record.amountToBuy,
          transactionFeeUpperBound: state.config.clientParameters.transactionFeeUpperBound,
        },
      };

      dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
    }}
  >
    {words.confirmTheDetailsOfSendingMona[state.language]}
  </button>

  let confirmation;

  if (wallet === 'MonaPallet') {
    confirmation = confirmTheDetailsOfSendingMona;
  }
  else { // Mpurse
    confirmation = null;
  }

  return (
      <div className='flexRow justifyContentCenter'>
        {
          state.purchase.status === 'waitingForMona' && record.sendMonaTxidFromClient === undefined ?
            <div className='flexColumn alignItemsCenter'>
              {/* PC */}
              <div className="visibleMiddleOrMore">
                { confirmation }
              </div>
              {/* SP */}
              <div className="flexColumn alignItemsCenter visibleSmallOrLess ">
                <button className='buttonMainColor heightMin3 paddingSide1' tabindex='0'
                  onClick={ () => {
                    const popup = {
                      type: 'sendMonaConfirmation',
                      body: {
                        addressPayProceedsTo: record.addressPayProceedsTo,
                        addressPayRoyaltyTo: record.addressPayRoyaltyTo,
                        monacottoAddressMona: state.config.monacottoAddress.monacottoAddressMona,
                        proceedsNetMona: record.proceedsNetMona * record.amountToBuy,
                        royaltyMona: record.royaltyMona * record.amountToBuy,
                        feeMona: record.feeMona * record.amountToBuy,
                        transactionFeeUpperBound: state.config.clientParameters.transactionFeeUpperBound,
                      },
                    };

                    dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
                  }}
                >
                  {words.confirmTheDetailsOfSendingMona[state.language]}
                </button>
              </div>
              <button className='button2 borderRadius2 riseOut2' tabindex='0'
                onClick={ async () => {
                  const result = await handleClickSendMona(state, dispatch, props.popupLayer);

                  if (result.status === 'fulfilled') {
                    handleClickGetPurchaseHistory(state, dispatch, cookies, setCookie, 0, null, null);
                  }
                  else if (result?.message === 'nfcRequired') {
                    const callback = async (keyPairs) => {
                      const result = await handleClickSendMona(state, dispatch, props.popupLayer, keyPairs);
                      keepKeyPairsInMemory(state, dispatch, keyPairs);

                      if (result.status === 'fulfilled') {
                        handleClickGetPurchaseHistory(state, dispatch, cookies, setCookie, 0, null, null);
                      }
                    };

                    handleClickNfc(state, dispatch, callback);
                  }
                  else if (result?.message === 'bcRequired') {
                    const callback = async (keyPairs) => {
                      const result = await handleClickSendMona(state, dispatch, props.popupLayer, keyPairs);
                      keepKeyPairsInMemory(state, dispatch, keyPairs);

                      if (result.status === 'fulfilled') {
                        handleClickGetPurchaseHistory(state, dispatch, cookies, setCookie, 0, null, null);
                      }
                    };

                    handleClickScanQr(state, dispatch, callback, { popupLayer: popupLayer + 1 });
                  }
                }}
              >
                {words.sendMona[state.language]}
              </button>
            </div>
          : null
        }
      </div>
  );
}

// RESUME PURCHASE SIGNING
function ResumePurchaseSigning(props) {
  const [state, dispatch, , cookies, setCookie] = useContext(GlobalState);

  return (
      <div className='flexRow justifyContentCenter'>
        {
          ((state.purchase.signatureByAddressPayFrom === undefined ||
            state.purchase.signatureByAddressPayFrom === null ||
            state.purchase.signatureByAddressPayFrom === '') &&
           (state.purchase.exhibitNo !== undefined &&
            state.purchase.exhibitNo !== null &&
            state.purchase.exhibitNo !== '') &&
           state.purchase.status === 'waitingForSignature') ?
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
              onClick={ async () => {
                const result = await handleClickSignPurchase(state, dispatch, cookies, setCookie, 'addressPayFrom', props.popupLayer);

                if (result.status === 'fulfilled') {
                  handleClickGetPurchaseHistory(state, dispatch, cookies, setCookie, 0);
                }
                else if (result?.message === 'nfcRequired') {
                  const callback = async (keyPairs) => {
                    const result = await handleClickSignPurchase(state, dispatch, cookies, setCookie, 'addressPayFrom', props.popupLayer, keyPairs);
                    keepKeyPairsInMemory(state, dispatch, keyPairs);

                    if (result.status === 'fulfilled') {
                      handleClickGetPurchaseHistory(state, dispatch, cookies, setCookie, 0);
                    }
                  };

                  handleClickNfc(state, dispatch, callback);
                }
                else if (result?.message === 'bcRequired') {
                  const callback = async (keyPairs) => {
                    const result = await handleClickSignPurchase(state, dispatch, cookies, setCookie, 'addressPayFrom', props.popupLayer, keyPairs);
                    keepKeyPairsInMemory(state, dispatch, keyPairs);

                    if (result.status === 'fulfilled') {
                      handleClickGetPurchaseHistory(state, dispatch, cookies, setCookie, 0);
                    }
                  };

                  handleClickScanQr(state, dispatch, callback, { popupLayer: props.popupLayer + 1 });
                }
              }}
            >
              <div>
                {words.signByAddressYouPayFrom[state.language]}
              </div>
              <img className='size2x2' src={iconSign} />
            </button>
          : null
        }
      </div>
  );
}

// SALES RECORD TITLE
function SalesRecordTitle(props) {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { words.exhibitNo[state.language] }
          </div>
          <div className='marginSide1 widthMin25' >
            { words.tokenName[state.language] }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.exhibitorProceedsNetMona[state.language] }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.amountToBuy[state.language] }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.exhibitorsTotalNetProceeds[state.language] }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.purchaserName[state.language] }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.dateAndTime[state.language] }
          </div>
          <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite' disabled={true} />
        </div>
      </div>
      {/* SP */}
    </div>
  );
}

// SALES RECORD
function SalesRecord(props) {
  const [state, dispatch] = useContext(GlobalState);
  const record = props.record;
  const assetCommon = record.asset_longname === null ? record.asset : record.asset_longname;

  /* detail */
  const items =
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='flexColumn boxDetail'>
          <div>
            {words.addressMainExhibitor[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.addressMainExhibitor}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.exhibitNo[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.exhibitNo}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.asset[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.asset}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.asset_longname[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.asset_longname}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.addressMainPurchaser[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.addressMain}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.purchaserName[state.language]}
          </div>
          <div className='paddingLeft1'>
            {state.usersGeneralIndex[record.addressMain] !== undefined ? state.usersGeneralIndex[record.addressMain].userName : null}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.purchaseNo[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.purchaseNo}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.addressOwner[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.addressOwner}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.ownerName[state.language]}
          </div>
          <div className='paddingLeft1'>
            {state.usersGeneralIndex[record.addressOwner] !== undefined ? state.usersGeneralIndex[record.addressOwner].userName : null}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.addressPayRoyaltyTo[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.addressPayRoyaltyTo}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.royaltyRecipientName[state.language]}
          </div>
          <div className='paddingLeft1'>
            {state.usersGeneralIndex[record.addressPayRoyaltyTo] !== undefined ? state.usersGeneralIndex[record.addressPayRoyaltyTo].userName : null}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.addressPayProceedsTo[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.addressPayProceedsTo}
          </div>
        </div>
        <div className='flexRow boxDetail justifyContentSpaceBetween'>
          <div className='flexColumn '>
            <div>
              {words.addressPurchaserPaysFrom[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.addressPayFrom}
            </div>
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.unitPriceMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.priceMona + ' MONA'}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.exhibitorProceedsNetMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            { (new decimal(record.proceedsNetMona)).dividedBy(100000000).toNumber() + ' MONA' }
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.royaltyMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            { (new decimal(record.royaltyMona)).dividedBy(100000000).toNumber() + ' MONA' }
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.feeExhibitMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            { (new decimal(record.feeExhibitMona)).dividedBy(100000000).toNumber() + ' MONA' }
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.feePurchaseMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            { (new decimal(record.feePurchaseMona)).dividedBy(100000000).toNumber() + ' MONA' }
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.amountToBuy[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.amountToBuy}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.exhibitorsTotalNetProceeds[state.language]}
          </div>
          <div className='paddingLeft1'>
            { (new decimal(record.proceedsNetMona * record.amountToBuy)).dividedBy(100000000).toNumber() + ' MONA' }
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.txidOfSendingMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.receiveMonaTxid}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.applicationTimeOfPurchase[state.language]}
          </div>
          <div className='paddingLeft1'>
            {localTime(record.serverTimeOfAddressMain)}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.confirmationTimeOfReceiptOfPayment[state.language]}
          </div>
          <div className='paddingLeft1'>
            {localTime(record.receiveMonaConfirmationTime)}
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='flexColumn alignItemsCenter'>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressMainExhibitor[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressMainExhibitor}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.exhibitNo[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.exhibitNo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.asset[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.asset}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.asset_longname[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.asset_longname}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressMainPurchaser[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressMain}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.purchaserName[state.language]}
            </div>
            <div className='paddingLeft1'>
              {state.usersGeneralIndex[record.addressMain] !== undefined ? state.usersGeneralIndex[record.addressMain].userName : null}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.purchaseNo[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.purchaseNo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressOwner[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressOwner}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.ownerName[state.language]}
            </div>
            <div className='paddingLeft1'>
              {state.usersGeneralIndex[record.addressOwner] !== undefined ? state.usersGeneralIndex[record.addressOwner].userName : null}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressPayRoyaltyTo[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressPayRoyaltyTo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.royaltyRecipientName[state.language]}
            </div>
            <div className='paddingLeft1'>
              {state.usersGeneralIndex[record.addressPayRoyaltyTo] !== undefined ? state.usersGeneralIndex[record.addressPayRoyaltyTo].userName : null}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressPayProceedsTo[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressPayProceedsTo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              <div>
                {words.addressPurchaserPaysFrom[state.language]}
              </div>
              <div className='paddingLeft1 breakAll'>
                {record.addressPayFrom}
              </div>
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.unitPriceMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.priceMona + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.exhibitorProceedsNetMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              { (new decimal(record.proceedsNetMona)).dividedBy(100000000).toNumber() + ' MONA' }
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.royaltyMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              { (new decimal(record.royaltyMona)).dividedBy(100000000).toNumber() + ' MONA' }
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.feeExhibitMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              { (new decimal(record.feeExhibitMona)).dividedBy(100000000).toNumber() + ' MONA' }
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.feePurchaseMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              { (new decimal(record.feePurchaseMona)).dividedBy(100000000).toNumber() + ' MONA' }
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.amountToBuy[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.amountToBuy}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.exhibitorsTotalNetProceeds[state.language]}
            </div>
            <div className='paddingLeft1'>
              { (new decimal(record.proceedsNetMona * record.amountToBuy)).dividedBy(100000000).toNumber() + ' MONA' }
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.txidOfSendingMona[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.receiveMonaTxid}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.applicationTimeOfPurchase[state.language]}
            </div>
            <div className='paddingLeft1'>
              {localTime(record.serverTimeOfAddressMain)}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.confirmationTimeOfReceiptOfPayment[state.language]}
            </div>
            <div className='paddingLeft1'>
              {localTime(record.receiveMonaConfirmationTime)}
            </div>
          </div>
        </div>
      </div>
    </div>

  const popup = { type: 'generalItems', body: items };
  const buttons =
    <button className='button1' onClick={ () => { dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup }) } } >
      <img className='size2x2' src={iconDetail} />
    </button>;

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { 'No. ' + props.record.exhibitNo }
          </div>
          <div className='marginSide1 widthMin25' >
            { assetCommon }
          </div>
          <div className='marginSide1 widthMin15' >
            { (new decimal(record.proceedsNetMona)).dividedBy(100000000).toNumber() + ' MONA' }
          </div>
          <div className='marginSide1 widthMin15' >
            { record.amountToBuy }
          </div>
          <div className='marginSide1 widthMin15' >
            { (new decimal(record.proceedsNetMona * record.amountToBuy)).dividedBy(100000000).toNumber() + ' MONA' }
          </div>
          <div className='marginSide1 widthMin15' >
            { state.usersGeneralIndex[record.addressMain] !== undefined ? state.usersGeneralIndex[record.addressMain].userName : null }
          </div>
          <div className='marginSide1 widthMin15' >
            { localTime(record.receiveMonaConfirmationTime, 'yearToMinute') }
          </div>
          { buttons }
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='boxMonacotto1SmallScreen flexColumn alignItemsFlexStart '  >
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.exhibitNo[state.language] }
            </div>
            <div className='flexRow alignItemsCenter' >
              <div className='' >
                { 'No. ' + props.record.exhibitNo }
              </div>
              { buttons }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.tokenName[state.language] }
            </div>
            <div className='' >
              { assetCommon }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.exhibitorProceedsNetMona[state.language] }
            </div>
            <div className='' >
              { (new decimal(record.proceedsNetMona)).dividedBy(100000000).toNumber() + ' MONA' }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.amountToBuy[state.language] }
            </div>
            <div className='' >
              { record.amountToBuy }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.exhibitorsTotalNetProceeds[state.language] }
            </div>
            <div className='' >
              { (new decimal(record.proceedsNetMona * record.amountToBuy)).dividedBy(100000000).toNumber() + ' MONA' }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.purchaserName[state.language] }
            </div>
            <div className='' >
              { state.usersGeneralIndex[record.addressMain] !== undefined ? state.usersGeneralIndex[record.addressMain].userName : null }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.dateAndTime[state.language] }
            </div>
            <div className='' >
              { localTime(record.receiveMonaConfirmationTime, 'yearToMinute') }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// ROYALTY RECORD TITLE
function RoyaltyRecordTitle(props) {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin15' >
            { words.exhibitorName[state.language] }
          </div>
          <div className='marginSide1 widthMin25' >
            { words.tokenName[state.language] }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.royalty[state.language] }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.amountToBuy[state.language] }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.totalRoyalty[state.language] }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.purchaserName[state.language] }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.dateAndTime[state.language] }
          </div>
          <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite' disabled={true} />
        </div>
      </div>
      {/* SP */}
    </div>
  );
}

// ROYALTY RECORD
function RoyaltyRecord(props) {
  const [state, dispatch] = useContext(GlobalState);
  const record = props.record;
  const assetCommon = record.asset_longname === null ? record.asset : record.asset_longname;

  /* detail */
  const items =
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='flexColumn boxDetail'>
          <div>
            {words.addressOwner[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.addressOwner}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.ownerName[state.language]}
          </div>
          <div className='paddingLeft1'>
            {state.usersGeneralIndex[record.addressOwner] !== undefined ? state.usersGeneralIndex[record.addressOwner].userName : null}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.addressMainExhibitor[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.addressMainExhibitor}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.exhibitorName[state.language]}
          </div>
          <div className='paddingLeft1'>
            {state.usersGeneralIndex[record.addressMainExhibitor] !== undefined ? state.usersGeneralIndex[record.addressMainExhibitor].userName : null}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.exhibitNo[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.exhibitNo}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.asset[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.asset}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.asset_longname[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.asset_longname}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.addressMainPurchaser[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.addressMain}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.purchaserName[state.language]}
          </div>
          <div className='paddingLeft1'>
            {state.usersGeneralIndex[record.addressMain] !== undefined ? state.usersGeneralIndex[record.addressMain].userName : null}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.purchaseNo[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.purchaseNo}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.addressPayRoyaltyTo[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.addressPayRoyaltyTo}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.royaltyRecipientName[state.language]}
          </div>
          <div className='paddingLeft1'>
            {state.usersGeneralIndex[record.addressPayRoyaltyTo] !== undefined ? state.usersGeneralIndex[record.addressPayRoyaltyTo].userName : null}
          </div>
        </div>
        <div className='flexRow boxDetail justifyContentSpaceBetween'>
          <div className='flexColumn '>
            <div>
              {words.addressPurchaserPaysFrom[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.addressPayFrom}
            </div>
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.unitPriceMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.priceMona + ' MONA'}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.royaltyMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            { (new decimal(record.royaltyMona)).dividedBy(100000000).toNumber() + ' MONA' }
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.amountToBuy[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.amountToBuy}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.totalRoyalty[state.language]}
          </div>
          <div className='paddingLeft1'>
            { (new decimal(record.royaltyMona * record.amountToBuy)).dividedBy(100000000).toNumber() + ' MONA' }
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.txidOfSendingMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.receiveMonaTxid}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.applicationTimeOfPurchase[state.language]}
          </div>
          <div className='paddingLeft1'>
            {localTime(record.serverTimeOfAddressMain)}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.confirmationTimeOfReceiptOfPayment[state.language]}
          </div>
          <div className='paddingLeft1'>
            {localTime(record.receiveMonaConfirmationTime)}
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='flexColumn alignItemsCenter'>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressOwner[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressOwner}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.ownerName[state.language]}
            </div>
            <div className='paddingLeft1'>
              {state.usersGeneralIndex[record.addressOwner] !== undefined ? state.usersGeneralIndex[record.addressOwner].userName : null}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressMainExhibitor[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressMainExhibitor}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.exhibitorName[state.language]}
            </div>
            <div className='paddingLeft1'>
              {state.usersGeneralIndex[record.addressMainExhibitor] !== undefined ? state.usersGeneralIndex[record.addressMainExhibitor].userName : null}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.exhibitNo[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.exhibitNo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.asset[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.asset}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.asset_longname[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.asset_longname}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressMainPurchaser[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressMain}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.purchaserName[state.language]}
            </div>
            <div className='paddingLeft1'>
              {state.usersGeneralIndex[record.addressMain] !== undefined ? state.usersGeneralIndex[record.addressMain].userName : null}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.purchaseNo[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.purchaseNo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressPayRoyaltyTo[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressPayRoyaltyTo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.royaltyRecipientName[state.language]}
            </div>
            <div className='paddingLeft1'>
              {state.usersGeneralIndex[record.addressPayRoyaltyTo] !== undefined ? state.usersGeneralIndex[record.addressPayRoyaltyTo].userName : null}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              <div>
                {words.addressPurchaserPaysFrom[state.language]}
              </div>
              <div className='paddingLeft1 breakAll'>
                {record.addressPayFrom}
              </div>
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.unitPriceMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.priceMona + ' MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.royaltyMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              { (new decimal(record.royaltyMona)).dividedBy(100000000).toNumber() + ' MONA' }
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.amountToBuy[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.amountToBuy}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.totalRoyalty[state.language]}
            </div>
            <div className='paddingLeft1'>
              { (new decimal(record.royaltyMona * record.amountToBuy)).dividedBy(100000000).toNumber() + ' MONA' }
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.txidOfSendingMona[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.receiveMonaTxid}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.applicationTimeOfPurchase[state.language]}
            </div>
            <div className='paddingLeft1'>
              {localTime(record.serverTimeOfAddressMain)}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.confirmationTimeOfReceiptOfPayment[state.language]}
            </div>
            <div className='paddingLeft1'>
              {localTime(record.receiveMonaConfirmationTime)}
            </div>
          </div>
        </div>
      </div>
    </div>

  const popup = { type: 'generalItems', body: items };
  const buttons =
    <button className='button1' onClick={ () => { dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup }) } } >
      <img className='size2x2' src={iconDetail} />
    </button>;

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin15' >
            { state.usersGeneralIndex[record.addressMainExhibitor] !== undefined ? state.usersGeneralIndex[record.addressMainExhibitor].userName : null }
          </div>
          <div className='marginSide1 widthMin25' >
            { assetCommon }
          </div>
          <div className='marginSide1 widthMin15' >
            { (new decimal(record.royaltyMona)).dividedBy(100000000).toNumber() + ' MONA' }
          </div>
          <div className='marginSide1 widthMin15' >
            { record.amountToBuy }
          </div>
          <div className='marginSide1 widthMin15' >
            { (new decimal(record.royaltyMona * record.amountToBuy)).dividedBy(100000000).toNumber() + ' MONA' }
          </div>
          <div className='marginSide1 widthMin15' >
            { state.usersGeneralIndex[record.addressMain] !== undefined ? state.usersGeneralIndex[record.addressMain].userName : null }
          </div>
          <div className='marginSide1 widthMin15' >
            { localTime(record.receiveMonaConfirmationTime, 'yearToMinute') }
          </div>
          { buttons }
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='boxMonacotto1SmallScreen flexColumn alignItemsFlexStart '  >
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.exhibitorName[state.language] }
            </div>
            <div className='flexRow alignItemsCenter' >
              { state.usersGeneralIndex[record.addressMainExhibitor] !== undefined ? state.usersGeneralIndex[record.addressMainExhibitor].userName : null }
              { buttons }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.tokenName[state.language] }
            </div>
            <div className='' >
              { assetCommon }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.royalty[state.language] }
            </div>
            <div className='' >
              { (new decimal(record.royaltyMona)).dividedBy(100000000).toNumber() + ' MONA' }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.amountToBuy[state.language] }
            </div>
            <div className='' >
              { record.amountToBuy }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.totalRoyalty[state.language] }
            </div>
            <div className='' >
              { (new decimal(record.royaltyMona * record.amountToBuy)).dividedBy(100000000).toNumber() + ' MONA' }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.purchaserName[state.language] }
            </div>
            <div className='' >
              { state.usersGeneralIndex[record.addressMain] !== undefined ? state.usersGeneralIndex[record.addressMain].userName : null }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.dateAndTime[state.language] }
            </div>
            <div className='' >
              { localTime(record.receiveMonaConfirmationTime, 'yearToMinute') }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// SEND MONA CONFIRMATION
function SendMonaConfirmation(props) {
  const [state, dispatch] = useContext(GlobalState);
  const sendMonaDetail = state.popup[props.popupLayer].body;
  let transactionFee;
  let transactionFeeUpperBound;
  let transactionFeeSmallScreen;
  let transactionFeeUpperBoundSmallScreen;

  // transactionFee or transactionFeeUpperBound
  if (sendMonaDetail.transactionFee !== undefined) {
    transactionFee =
      <div className='boxMonacotto1 margin0p5 heightMin2'>
        <div className='widthMin15 marginSide1'>
          {words.transactionFee[state.language]}
        </div>
        <div className='widthMin25 marginSide1'>
        </div>
        <div className='widthMin15 marginSide1'>
          {(sendMonaDetail.transactionFee / 100000000) + ' MONA'}
        </div>
      </div>;

    transactionFeeUpperBound = null;

    transactionFeeSmallScreen =
      <div className='boxMonacotto1SmallScreen '>
        <div className=''>
          {words.transactionFee[state.language]}
        </div>
        <div className='breakAll paddingLeft1'>
        </div>
        <div className='paddingLeft1'>
          {(sendMonaDetail.transactionFee / 100000000) + ' MONA'}
        </div>
      </div>;

    transactionFeeUpperBoundSmallScreen = null;
  }
  else {
    transactionFee = null;

    transactionFeeUpperBound =
      <div className='boxMonacotto1 margin0p5 heightMin2'>
        <div className='widthMin15 marginSide1'>
          {words.transactionFeeUpperBound[state.language]}
        </div>
        <div className='widthMin25 marginSide1'>
        </div>
        <div className='widthMin15 marginSide1'>
          {(sendMonaDetail.transactionFeeUpperBound / 100000000) + ' MONA'}
        </div>
      </div>;

    transactionFeeSmallScreen = null;

    transactionFeeUpperBoundSmallScreen =
      <div className='boxMonacotto1SmallScreen '>
        <div className=''>
          {words.transactionFeeUpperBound[state.language]}
        </div>
        <div className='breakAll paddingLeft1'>
        </div>
        <div className='paddingLeft1'>
          {(sendMonaDetail.transactionFeeUpperBound / 100000000) + ' MONA'}
        </div>
      </div>;
  }

  // confirmation section

  let confirmationSection;

  if (sendMonaDetail.callbackOk !== undefined && sendMonaDetail.callbackCancel !== undefined) {
    confirmationSection =
    <div className='flexRow justifyContentCenter margin1 '>
      <button className='button2 margin1 ' onClick={ sendMonaDetail.callbackOk } >
        { sendMonaDetail.buttonFaceOk }
      </button>
      <button className='button2 margin1 ' onClick={ sendMonaDetail.callbackCancel } >
        { sendMonaDetail.buttonFaceCancel }
      </button>
    </div>
  }


  return (
    <div>
      {/* PC */}
      <div className='visibleLargeOrMore flexColumn'>
        <div className='marginTop0p5'>
          {words.confirmTheDetailsOfTheRemittance[state.language]}
        </div>
        <div className='boxMonacotto1 margin0p5 heightMin2'>
          <div className='widthMin15 marginSide1'>
            {words.exhibitorReceipts[state.language]}
          </div>
          <div className='widthMin25 marginSide1'>
            {sendMonaDetail.addressPayProceedsTo}
          </div>
          <div className='widthMin15 marginSide1'>
            {(sendMonaDetail.proceedsNetMona / 100000000) + ' MONA'}
          </div>
        </div>
        <div className='boxMonacotto1 margin0p5 heightMin2'>
          <div className='widthMin15 marginSide1'>
            {words.royalty[state.language]}
          </div>
          <div className='widthMin25 marginSide1'>
            {sendMonaDetail.addressPayRoyaltyTo}
          </div>
          <div className='widthMin15 marginSide1'>
            {(sendMonaDetail.royaltyMona / 100000000) + ' MONA'}
          </div>
        </div>
        <div className='boxMonacotto1 margin0p5 heightMin2'>
          <div className='widthMin15 marginSide1'>
            {words.serviceFee[state.language]}
          </div>
          <div className='widthMin25 marginSide1'>
            {sendMonaDetail.monacottoAddressMona}
          </div>
          <div className='widthMin15 marginSide1'>
            {(sendMonaDetail.feeMona / 100000000) + ' MONA'}
          </div>
        </div>
        { transactionFee }
        { transactionFeeUpperBound }
        { confirmationSection }
      </div>
      {/* SP */}
      <div className='visibleMiddleOrLess flexColumn'>
        <div className='marginTop0p5'>
          {words.confirmTheDetailsOfTheRemittance[state.language]}
        </div>
        <div className='boxMonacotto1SmallScreen '>
          <div className=''>
            {words.exhibitorReceipts[state.language]}
          </div>
          <div className='breakAll paddingLeft1'>
            {sendMonaDetail.addressPayProceedsTo}
          </div>
          <div className='paddingLeft1'>
            {(sendMonaDetail.proceedsNetMona / 100000000) + ' MONA'}
          </div>
        </div>
        <div className='boxMonacotto1SmallScreen '>
          <div className=''>
            {words.royalty[state.language]}
          </div>
          <div className='breakAll paddingLeft1'>
            {sendMonaDetail.addressPayRoyaltyTo}
          </div>
          <div className='paddingLeft1'>
            {(sendMonaDetail.royaltyMona / 100000000) + ' MONA'}
          </div>
        </div>
        <div className='boxMonacotto1SmallScreen '>
          <div className=''>
            {words.serviceFee[state.language]}
          </div>
          <div className='breakAll paddingLeft1'>
            {sendMonaDetail.monacottoAddressMona}
          </div>
          <div className='paddingLeft1'>
            {(sendMonaDetail.feeMona / 100000000) + ' MONA'}
          </div>
        </div>
        { transactionFeeSmallScreen }
        { transactionFeeUpperBoundSmallScreen }
        { confirmationSection }
      </div>
    </div>
  );
}

// GENERAL LIST
function GeneralList(props) {
  const [state, dispatch] = useContext(GlobalState);
  const body = state.popup[props.popupLayer].body;

  return (
    <div className=''>
      {/* PC */}
      <div className='visibleMiddleOrMore flexColumn alignItemsCenter'>
        <div className='flexColumn alignItemsCenter'>
          { body.title }
        </div>
        <div className='flexColumn alignItemsCenter'>
          { body.options }
        </div>
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess flexColumn widthMax'>
        <div className='flexColumn alignItemsCenter widthMax'>
          { body.title }
        </div>
        <div className='flexColumn alignItemsCenter widthMax'>
          { body.options }
        </div>
      </div>
    </div>
  );
}

// GENERAL ITEMS
function GeneralItems(props) {
  const [state, dispatch] = useContext(GlobalState);
  const body = state.popup[props.popupLayer].body;

  return (
    <div className='flexColumn alignItemsCenter'>
      { body }
    </div>
  );
}

// ADDRESS HISTORY TITLE
function AddressHistoryTitle(props) {
  const [state] = useContext(GlobalState);

  return (
    <div className='widthMax'>
      {/* PC */}
      <div className='visibleMiddleOrMore boxMonacotto1 margin0p5' >
        <div className='marginSide1 widthMin25' >
          { words.address[state.language] }
        </div>
        <div className='marginSide1 widthMin20' >
          { words.action[state.language] }
        </div>
        <div className='marginSide1 widthMin15' >
          { words.addressType[state.language] }
        </div>
        <div className='marginSide1 widthMin25' >
          { words.description[state.language] }
        </div>
        {/*
        <div className='marginSide1 widthMin10' >
          { words.exhibitor[state.language] }
        </div>
        <div className='marginSide1 widthMin10' >
          { words.tokenName[state.language] }
        </div>
        <div className='marginSide1 widthMin7' >
          { words.amount[state.language] }
        </div>
        */}
        <div className='marginSide1 widthMin10' >
          { words.dateAndTime[state.language] }
        </div>
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess boxMonacotto1SmallScreen ' >
        <div className='' >
          { words.address[state.language] }
        </div>
        <div className='' >
          { words.action[state.language] }
        </div>
        <div className='' >
          { words.addressType[state.language] }
        </div>
        <div className='' >
          { words.description[state.language] }
        </div>
        {/*
        <div className='marginSide1 widthMin10' >
          { words.exhibitor[state.language] }
        </div>
        <div className='marginSide1 widthMin10' >
          { words.tokenName[state.language] }
        </div>
        <div className='marginSide1 widthMin7' >
          { words.amount[state.language] }
        </div>
        */}
        <div className='' >
          { words.dateAndTime[state.language] }
        </div>
      </div>
    </div>
  );
}

// ADDRESS HISTORY RECORD
function AddressHistoryRecord(props) {
  const [state, dispatch] = useContext(GlobalState);
  const record = props.record;
  const stateKeys = props.stateKeys;
  const layer = props.layer;

  return (
    <div className=''>
      {/* PC */}
      <button className='visibleMiddleOrMore boxMonacotto1 margin0p5 cursor riseOut2'
        onClick={ () => {
          dispatch({ type: 'setStateMultiLayers', keys: stateKeys, value: record.address });
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', layer], value: { type: null, body: null } });
        }} 
      >
        <div className='marginSide1 widthMin25' >
          { record.address }
        </div>
        <div className='marginSide1 widthMin20' >
          { words[record.action][state.language] }
        </div>
        <div className='marginSide1 widthMin15' >
          { words[record.addressType][state.language] }
        </div>
        <div className='marginSide1 widthMin25' >
          { record.description }
        </div>
        {/*
        <div className='marginSide1 widthMin10' >
          { state.usersGeneralIndex[record.addressMainExhibitor] !== undefined ? state.usersGeneralIndex[record.addressMainExhibitor].userName : record.addressMainExhibitor }
        </div>
        <div className='marginSide1 widthMin10' >
          { record.tokenName }
        </div>
        <div className='marginSide1 widthMin7' >
          { record.amount }
        </div>
        */}
        <div className='marginSide1 widthMin10' >
          { localTime(record.time, 'yearToMinute') }
        </div>
      </button>
      {/* SP */}
      <button className='visibleSmallOrLess boxMonacotto1SmallScreen cursor riseOut2'
        onClick={ () => {
          dispatch({ type: 'setStateMultiLayers', keys: stateKeys, value: record.address });
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', layer], value: { type: null, body: null } });
        }} 
      >
        <div className='breakAll' >
          { record.address }
        </div>
        <div className='' >
          { words[record.action][state.language] }
        </div>
        <div className='' >
          { words[record.addressType][state.language] }
        </div>
        <div className='' >
          { record.description }
        </div>
        {/*
        <div className='marginSide1 widthMin10' >
          { state.usersGeneralIndex[record.addressMainExhibitor] !== undefined ? state.usersGeneralIndex[record.addressMainExhibitor].userName : record.addressMainExhibitor }
        </div>
        <div className='marginSide1 widthMin10' >
          { record.tokenName }
        </div>
        <div className='marginSide1 widthMin7' >
          { record.amount }
        </div>
        */}
        <div className='' >
          { localTime(record.time, 'yearToMinute') }
        </div>
      </button>
    </div>
  );
}

// SESSION TITLE
function SessionTitle() {
  const [state] = useContext(GlobalState);

  return (
    <div className='widthMax'>
      {/* PC */}
      <div className='visibleMiddleOrMore boxMonacotto1 margin0p5' >
        <div className='marginSide1 widthMin25' >
          { words.loggedInAddress[state.language] }
        </div>
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess boxMonacotto1SmallScreen alignItemsCenter' >
        <div className='textCenter' >
          { words.loggedInAddress[state.language] }
        </div>
      </div>
    </div>
  );
}

// SESSION RECORD
function SessionRecord(props) {
  const [state, dispatch] = useContext(GlobalState);
  const record = props.record;

  return (
    <div className=''>
      {/* PC */}
      <div className='visibleMiddleOrMore boxMonacotto1 margin0p5' >
        <div className='marginSide1 widthMin25' >
          { record.address }
        </div>
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess boxMonacotto1SmallScreen alignItemsCenter' >
        <div className='textCenter' >
          { record.address }
        </div>
      </div>
    </div>
  );
}

// PERMISSION
function Permission() {
  const [state, dispatch, navigate, cookies, setCookie] = useContext(GlobalState);
  let historyItems;
  let historyFunction;
  let arrowRight;
  let arrowLeft;
  let addressPermitToField;

  if (state.getPermissionHistory.action === 'permit') {
    if (state.permitHistory[state.getPermissionHistory.addressMain] !== undefined &&
        state.permitHistory[state.getPermissionHistory.addressMain].permit !== undefined) {
      historyItems = state.permitHistory[state.getPermissionHistory.addressMain].permit.map( record => {
        if (true) {
          return <PermitRecord record={record} />
        }
        else {
          return null;
        }
      });

      historyItems.unshift(<PermitRecordTitle />);
    }
    else {
      historyItems = [<PermitRecordTitle />];
    }

    historyFunction = async (pagingIndex) => {
      const result = await handleClickGetPermitHistory(state, dispatch, cookies, setCookie, pagingIndex);

      if (result?.message === 'nfcRequired') {
        const callback = (keyPairs) => {
          handleClickGetPermitHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickNfc(state, dispatch, callback);
      }
      else if (result?.message === 'bcRequired') {
        const callback = (keyPairs) => {
          handleClickGetPermitHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickScanQr(state, dispatch, callback);
      }
    };
  }
  else if (state.getPermissionHistory.action === 'permitted') {
    if (state.permittedHistory[state.getPermissionHistory.addressMain] !== undefined &&
        state.permittedHistory[state.getPermissionHistory.addressMain].permitted !== undefined) {
      historyItems = state.permittedHistory[state.getPermissionHistory.addressMain].permitted.map( record => {
        if (true) {
          return <PermittedRecord record={record} />
        }
        else {
          return null;
        }
      });

      historyItems.unshift(<PermittedRecordTitle />);
    }
    else {
      historyItems = [<PermittedRecordTitle />];
    }

    historyFunction = async (pagingIndex) => {
      const result = await handleClickGetPermittedHistory(state, dispatch, cookies, setCookie, pagingIndex);

      if (result?.message === 'nfcRequired') {
        const callback = (keyPairs) => {
          handleClickGetPermittedHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickNfc(state, dispatch, callback);
      }
      else if (result?.message === 'bcRequired') {
        const callback = (keyPairs) => {
          handleClickGetPermittedHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickScanQr(state, dispatch, callback);
      }
    };
  }
  else if (state.getPermissionHistory.action === 'permittedInclusively') {
    if (state.permittedInclusivelyHistory[state.getPermissionHistory.addressMain]?.permitted !== undefined) {
      historyItems = state.permittedInclusivelyHistory[state.getPermissionHistory.addressMain].permitted.map( record => {
        if (true) {
          return <PermittedRecord record={record} />
        }
        else {
          return null;
        }
      });

      historyItems.unshift(<PermittedRecordTitle />);
    }
    else {
      historyItems = [<PermittedRecordTitle />];
    }

    historyFunction = async (pagingIndex) => {
      const result = await handleClickGetPermittedInclusivelyHistory(state, dispatch, cookies, setCookie, pagingIndex);

      if (result?.message === 'nfcRequired') {
        const callback = (keyPairs) => {
          handleClickGetPermittedInclusivelyHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickNfc(state, dispatch, callback);
      }
      else if (result?.message === 'bcRequired') {
        const callback = (keyPairs) => {
          handleClickGetPermittedInclusivelyHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickScanQr(state, dispatch, callback);
      }
    };
  }
  else if (state.getPermissionHistory.action === 'requestPermission') {
    if (state.requestPermissionHistory[state.getPermissionHistory.addressMain] !== undefined &&
        state.requestPermissionHistory[state.getPermissionHistory.addressMain].requestPermission !== undefined) {
      historyItems = state.requestPermissionHistory[state.getPermissionHistory.addressMain].requestPermission.map( record => {
        if (true) {
          return <RequestPermissionRecord record={record} />
        }
        else {
          return null;
        }
      });

      historyItems.unshift(<RequestPermissionRecordTitle />);
    }
    else {
      historyItems = [<RequestPermissionRecordTitle />];
    }

    historyFunction = async (pagingIndex) => {
      const result = await handleClickGetRequestPermissionHistory(state, dispatch, cookies, setCookie, pagingIndex);

      if (result?.message === 'nfcRequired') {
        const callback = (keyPairs) => {
          handleClickGetRequestPermissionHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickNfc(state, dispatch, callback);
      }
      else if (result?.message === 'bcRequired') {
        const callback = (keyPairs) => {
          handleClickGetRequestPermissionHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickScanQr(state, dispatch, callback);
      }
    };
  }
  else if (state.getPermissionHistory.action === 'requestedPermission') {
    if (state.requestedPermissionHistory[state.getPermissionHistory.addressMain] !== undefined &&
        state.requestedPermissionHistory[state.getPermissionHistory.addressMain].requestedPermission !== undefined) {
      historyItems = state.requestedPermissionHistory[state.getPermissionHistory.addressMain].requestedPermission.map( record => {
        if (true) {
          return <RequestedPermissionRecord record={record} />
        }
        else {
          return null;
        }
      });

      historyItems.unshift(<RequestedPermissionRecordTitle />);
    }
    else {
      historyItems = [<RequestedPermissionRecordTitle />];
    }

    historyFunction = async (pagingIndex) => {
      const result = await handleClickGetRequestedPermissionHistory(state, dispatch, cookies, setCookie, pagingIndex);

      if (result?.message === 'nfcRequired') {
        const callback = (keyPairs) => {
          handleClickGetRequestedPermissionHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickNfc(state, dispatch, callback);
      }
      else if (result?.message === 'bcRequired') {
        const callback = (keyPairs) => {
          handleClickGetRequestedPermissionHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickScanQr(state, dispatch, callback);
      }
    };
  }
  else if (state.getPermissionHistory.action === 'delegate') {
    if (state.delegateHistory[state.getPermissionHistory.addressMain] !== undefined &&
        state.delegateHistory[state.getPermissionHistory.addressMain].delegate !== undefined) {
      historyItems = state.delegateHistory[state.getPermissionHistory.addressMain].delegate.map( record => {
        if (true) {
          return <DelegateRecord record={record} />
        }
        else {
          return null;
        }
      });

      historyItems.unshift(<DelegateRecordTitle />);
    }
    else {
      historyItems = [<DelegateRecordTitle />];
    }

    historyFunction = async (pagingIndex) => {
      const result = await handleClickGetDelegateHistory(state, dispatch, cookies, setCookie, pagingIndex);

      if (result?.message === 'nfcRequired') {
        const callback = (keyPairs) => {
          handleClickGetDelegateHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickNfc(state, dispatch, callback);
      }
      else if (result?.message === 'bcRequired') {
        const callback = (keyPairs) => {
          handleClickGetDelegateHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickScanQr(state, dispatch, callback);
      }
    };
  }
  else { // delegated
    if (state.delegatedHistory[state.getPermissionHistory.addressMain] !== undefined &&
        state.delegatedHistory[state.getPermissionHistory.addressMain].delegated !== undefined) {
      historyItems = state.delegatedHistory[state.getPermissionHistory.addressMain].delegated.map( record => {
        if (true) {
          return <DelegatedRecord record={record} />
        }
        else {
          return null;
        }
      });

      historyItems.unshift(<DelegatedRecordTitle />);
    }
    else {
      historyItems = [<DelegatedRecordTitle />];
    }

    historyFunction = async (pagingIndex) => {
      const result = await handleClickGetDelegatedHistory(state, dispatch, cookies, setCookie, pagingIndex);

      if (result?.message === 'nfcRequired') {
        const callback = (keyPairs) => {
          handleClickGetDelegatedHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickNfc(state, dispatch, callback);
      }
      else if (result?.message === 'bcRequired') {
        const callback = (keyPairs) => {
          handleClickGetDelegatedHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickScanQr(state, dispatch, callback);
      }
    };
  }

  // 矢印

  if ( state.getPermissionHistory.pagingIndex[state.getPermissionHistory.action] >= 1 ) {
    arrowLeft = <div>
      <button className='flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2 '
        onClick={ () => historyFunction(state.getPermissionHistory.pagingIndex[state.getPermissionHistory.action] - 1) }
      >
        <img className='size2x2' src={iconCircleLeft} />
      </button>
    </div>
  }
  else {
    arrowLeft = <div>
      <button className='size2x2Large backgroundColorTransparent borderNone' disabled={true} >
        <div className='size2x2' />
      </button>
    </div>
  }

  if ( state.getPermissionHistory.lastEvaluatedKey[state.getPermissionHistory.action][state.getPermissionHistory.pagingIndex[state.getPermissionHistory.action]] !== undefined &&
       state.getPermissionHistory.lastEvaluatedKey[state.getPermissionHistory.action][state.getPermissionHistory.pagingIndex[state.getPermissionHistory.action]] !== null ) {
    arrowRight = <div>
      <button className='flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2 '
        onClick={ () => { historyFunction(state.getPermissionHistory.pagingIndex[state.getPermissionHistory.action] + 1) } }
      >
        <img className='size2x2' src={iconCircleRight} />
      </button>
    </div>
  }
  else {
    arrowRight = <div>
      <button className='size2x2Large backgroundColorTransparent borderNone' disabled={true} >
        <div className='size2x2' />
      </button>
    </div>
  }

  // 出品許可先アドレス

  if (state.permit.mode === 'individual') {
    addressPermitToField =
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
          <TextLine3 fieldName='addressPermitTo' keys={['permit', 'addressPermitTo']} face={words.addressPermitTo[state.language]} tooltip={true} />
          <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['permit', 'addressPermitTo']) }>
            <img className='size2x2' src={iconMpurse} />
          </button>
          <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['permit', 'addressPermitTo']) }>
            <img className='size2x2' src={iconList} />
          </button>
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='flexColumn alignItemsFlexStart marginTopBottom0p5 '>
          <TextLine3 fieldName='addressPermitTo' keys={['permit', 'addressPermitTo']} face={words.addressPermitTo[state.language]} tooltip={true} />
          <div className='flexRow justifyContentFlexStart alignItemsCenter '>
            <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['permit', 'addressPermitTo']) }>
              <img className='size2x2' src={iconMpurse} />
            </button>
            <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['permit', 'addressPermitTo']) }>
              <img className='size2x2' src={iconList} />
            </button>
          </div>
        </div>
      </div>
    </div>
  }
  else {
    addressPermitToField = null;
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <Header screen='permission' />
        <div className='flexRow justifyContentCenter '>
          {/* permit */}
          <div className='borderMonacotto backgroundColorTransparent padding1 margin1'>
            {/* title */}
            <div>
              {words.grantPermissionOfExhibit[state.language]}
            </div>
            {/* grant or revoke */}
            <div className='flexRow justifyContentCenter marginSide1'>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.permit.action === 'grant' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['permit', 'action'], value: 'grant'});
                }}>
                {words.grantPermission[state.language]}
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.permit.action === 'revoke' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['permit', 'action'], value: 'revoke'});
                }}>
                {words.revokePermission[state.language]}
              </button>
            </div>
            {/* individual or inclusive */}
            <div className='flexRow justifyContentCenter marginSide1'>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.permit.mode === 'individual' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['permit', 'mode'], value: 'individual'});
                }}>
                {words.individualPermission[state.language]}
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.permit.mode === 'inclusive' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['permit', 'mode'], value: 'inclusive'});
                }}>
                {words.inclusivePermission[state.language]}
              </button>
            </div>
            {/* owner address */}
            <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
              <TextLine3 fieldName='addressOwner' keys={['permit', 'addressOwner']} face={words.addressOwner[state.language]} tooltip={true} />
              <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['permit', 'addressOwner']) }>
                <img className='size2x2' src={iconMpurse} />
              </button>
              <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['permit', 'addressOwner']) }>
                <img className='size2x2' src={iconList} />
              </button>
            </div>
            {/* addressPermitTo */}
            { addressPermitToField }
            {
              state.permit.action === 'grant' ?
              <div>
                {/* addressPayRoyaltyTo */}
                <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
                  <TextLine3 fieldName='addressPayRoyaltyTo' keys={['permit', 'addressPayRoyaltyTo']}
                    face={words.addressPayRoyaltyTo[state.language]} tooltip={true}
                  />
                  <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['permit', 'addressPayRoyaltyTo']) }>
                    <img className='size2x2' src={iconMpurse} />
                  </button>
                  <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['permit', 'addressPayRoyaltyTo']) }>
                    <img className='size2x2' src={iconList} />
                  </button>
                </div>
                {/* royaltyPercentage */}
                <TextLine3 fieldName='royaltyPercentage' keys={['permit', 'royaltyPercentage']}
                  face={words.royaltyPercentage[state.language]} type='setStateMultiLayersFloat' adjustType='round' adjustExp={royaltyPercentageDigit}
                />
              </div> :
              null
            }
            {/* commit button */}
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
              onClick={ async () => {
                const result = await handleClickPermit(state, dispatch, cookies, setCookie);

                if (result?.message === 'nfcRequired') {
                  const callback = (keyPairs) => {
                    handleClickPermit(state, dispatch, cookies, setCookie, keyPairs);
                    keepKeyPairsInMemory(state, dispatch, keyPairs);
                  };

                  handleClickNfc(state, dispatch, callback);
                }
                else if (result?.message === 'bcRequired') {
                  const callback = (keyPairs) => {
                    handleClickPermit(state, dispatch, cookies, setCookie, keyPairs);
                    keepKeyPairsInMemory(state, dispatch, keyPairs);
                  };

                  handleClickScanQr(state, dispatch, callback);
                }
              }}
            >
              <div>
                {words.signByOwnerAddressToRegister[state.language]}
              </div>
              <img className='size2x2' src={iconSign} />
            </button>
          </div>
          {/* request permission */}
          <div className='borderMonacotto backgroundColorTransparent padding1 margin1'>
            <div>
              {words.requestPermissionOfExhibit[state.language]}
            </div>
            <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
              <TextLine3 fieldName='requestPermissionAddressMain' keys={['requestPermission', 'addressMain']}
                face={words.addressMain[state.language]} tooltip={true} />
              <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['requestPermission', 'addressMain']) }>
                <img className='size2x2' src={iconMpurse} />
              </button>
              <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['requestPermission', 'addressMain']) }>
                <img className='size2x2' src={iconList} />
              </button>
            </div>
            <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
              <TextLine3 fieldName='requestPermissionAddressOwner' keys={['requestPermission', 'addressOwner']}
                face={words.addressOwner[state.language]} tooltip={true} />
              {/*
              <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['requestPermission', 'addressOwner']) }>
                <img className='size2x2' src={iconMpurse} />
              </button>
              */}
            </div>
            {/* <TextLine3 fieldName='ownerName' keys={['permit', 'ownerName']} face='owner name' tooltip={true} /> */}
            <TextLine3 fieldName='requestPermissionRoyaltyPercentage' keys={['requestPermission', 'royaltyPercentage']}
              face={words.royaltyPercentage[state.language]} type='setStateMultiLayersFloat' adjustType='round' adjustExp={royaltyPercentageDigit}
            />
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
              onClick={ async () => {
                const result = await handleClickRequestPermission(state, dispatch, cookies, setCookie);

                if (result?.message === 'nfcRequired') {
                  const callback = (keyPairs) => {
                    handleClickRequestPermission(state, dispatch, cookies, setCookie, keyPairs);
                    keepKeyPairsInMemory(state, dispatch, keyPairs);
                  };

                  handleClickNfc(state, dispatch, callback);
                }
                else if (result?.message === 'bcRequired') {
                  const callback = (keyPairs) => {
                    handleClickRequestPermission(state, dispatch, cookies, setCookie, keyPairs);
                    keepKeyPairsInMemory(state, dispatch, keyPairs);
                  };

                  handleClickScanQr(state, dispatch, callback);
                }
              }}
            >
              <div>
                {words.signByMainAddressToRegister[state.language]}
              </div>
              <img className='size2x2' src={iconSign} />
            </button>
          </div>
          {/* delegate */}
          <div className='borderMonacotto backgroundColorTransparent padding1 margin1'>
            {/* <div className='flexColumn alignItemsCenter margin1' > */}
            {/* title */}
            {/* <div className='borderTopBottom marginTopBottom1'> */}
            <div>
              {words.signatureDelegation[state.language]}
            </div>
            {/* action */}
            <div className='flexRow justifyContentCenter marginSide1'>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.delegate.action === 'request' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['delegate', 'action'], value: 'request'});
                }}>
                {words.delegate[state.language]}
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.delegate.action === 'revokeRequest' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['delegate', 'action'], value: 'revokeRequest'});
                }}>
                {words.revokeDelegation[state.language]}
              </button>
            </div>
            <div className='flexRow justifyContentCenter marginSide1'>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.delegate.action === 'accept' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['delegate', 'action'], value: 'accept'});
                }}>
                {words.acceptDelegation[state.language]}
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.delegate.action === 'revokeAcceptance' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['delegate', 'action'], value: 'revokeAcceptance'});
                }}>
                {words.revokeAcceptance[state.language]}
              </button>
            </div>
            <div className='flexColumn alignItemsFlexStart' >
            {/* <div className='borderMonacotto backgroundColorTransparent padding1 marginTop1'> */}
              {/* addressDelegateFrom */}
              <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
                <TextLine3 fieldName='delegateAddressMain' keys={['delegate', 'addressDelegateFrom']}
                  face={words.addressDelegateFrom[state.language]} tooltip={true}
                />
                <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['delegate', 'addressDelegateFrom']) }>
                  <img className='size2x2' src={iconMpurse} />
                </button>
                <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['delegate', 'addressDelegateFrom']) }>
                  <img className='size2x2' src={iconList} />
                </button>
              </div>
              {/* addressDelegateTo */}
              <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
                <TextLine3 fieldName='delegateAddressDelegateTo' keys={['delegate', 'addressDelegateTo']}
                  face={words.addressDelegateTo[state.language]} tooltip={true}
                />
                <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['delegate', 'addressDelegateTo']) }>
                  <img className='size2x2' src={iconMpurse} />
                </button>
                <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['delegate', 'addressDelegateTo']) }>
                  <img className='size2x2' src={iconList} />
                </button>
              </div>
            {/* </div> */}
            </div>
            {/* register button */}
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
              onClick={ async () => {
                const result = await handleClickDelegate(state, dispatch, cookies, setCookie);

                if (result?.message === 'nfcRequired') {
                  const callback = (keyPairs) => {
                    handleClickDelegate(state, dispatch, cookies, setCookie, keyPairs);
                    keepKeyPairsInMemory(state, dispatch, keyPairs);
                  };

                  handleClickNfc(state, dispatch, callback);
                }
                else if (result?.message === 'bcRequired') {
                  const callback = (keyPairs) => {
                    handleClickDelegate(state, dispatch, cookies, setCookie, keyPairs);
                    keepKeyPairsInMemory(state, dispatch, keyPairs);
                  };

                  handleClickScanQr(state, dispatch, callback);
                }
              }}
            >
              <div>
                { 
                  state.delegate.action === 'request' || state.delegate.action === 'revokeRequest' ?
                    words.signByDelegationSourceAddressToRegister[state.language]
                  : words.signByDelegatedAddressToRegister[state.language]
                }
              </div>
              <img className='size2x2' src={iconSign} />
            </button>
          </div>
        </div>
        {/* history */}
        <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
          {/* action */}
          <div className='flexRow justifyContentSpaceAround marginSide0p5 marginTop0p5'>
            <button className={'box3 focusEffect01 riseOut2 ' + (state.getPermissionHistory.action === 'permit' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
              onClick={ () => {
                        dispatch({type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'action'], value: 'permit'});
              }}>
              {words.permit[state.language]}
            </button>
            <button className={'box3 focusEffect01 riseOut2 ' + (state.getPermissionHistory.action === 'permitted' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
              onClick={ () => {
                        dispatch({type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'action'], value: 'permitted'});
              }}>
              {words.permitted[state.language]}
            </button>
            <button className={'box3 focusEffect01 riseOut2 ' + (state.getPermissionHistory.action === 'permittedInclusively' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
              onClick={ () => {
                        dispatch({type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'action'], value: 'permittedInclusively'});
              }}>
              {words.permittedInclusively[state.language]}
            </button>
            <button className={'box3 focusEffect01 riseOut2 ' + (state.getPermissionHistory.action === 'requestPermission' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
              onClick={ () => {
                        dispatch({type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'action'], value: 'requestPermission'});
              }}>
              {words.requestPermission[state.language]}
            </button>
            <button className={'box3 focusEffect01 riseOut2 ' + (state.getPermissionHistory.action === 'requestedPermission' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
              onClick={ () => {
                        dispatch({type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'action'], value: 'requestedPermission'});
              }}>
              {words.requestedPermission[state.language]}
            </button>
            <button className={'box3 focusEffect01 riseOut2 ' + (state.getPermissionHistory.action === 'delegate' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
              onClick={ () => {
                        dispatch({type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'action'], value: 'delegate'});
              }}>
              {words.delegate2[state.language]}
            </button>
            <button className={'box3 focusEffect01 riseOut2 ' + (state.getPermissionHistory.action === 'delegated' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
              onClick={ () => {
                        dispatch({type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'action'], value: 'delegated'});
              }}>
              {words.delegated[state.language]}
            </button>
          </div>
          {/* addressMain */}
          <TextLine3 fieldName='historyAddressMain' keys={['getPermissionHistory', 'addressMain']} face={words.addressMain[state.language]} tooltip={true}
            extraFunctionOnChange={ [
              {
                function: () => {
                  dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'pagingIndex'],
                    value: { permit: 0, permitted: 0, permittedInclusively: 0, requestPermission: 0, requestedPermission: 0, delegate: 0, delegated: 0 }
                  });
                  dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'lastEvaluatedKey'],
                    value: { permit: [], permitted: [], permittedInclusively: [], requestPermission: [], requestedPermission: [], delegate: [], delegated: [] }
                  });
                },
                arguments: [],
              }
            ]}
          />
          <button className='button1'
            onClick={ () => {
              handleClickMpurse(state, dispatch, ['getPermissionHistory', 'addressMain']);
              dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'pagingIndex'],
                value: { permit: 0, permitted: 0, permittedInclusively: 0, requestPermission: 0, requestedPermission: 0, delegate: 0, delegated: 0 }
              });
              dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'lastEvaluatedKey'],
                value: { permit: [], permitted: [], permittedInclusively: [], requestPermission: [], requestedPermission: [], delegate: [], delegated: [] }
              });
            }}
          >
            <img className='size2x2' src={iconMpurse} />
          </button>
          <button className='button1'
            onClick={ () => {
              handleClickAddressHistory(state, dispatch, cookies, ['getPermissionHistory', 'addressMain']);
              dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'pagingIndex'],
                value: { permit: 0, permitted: 0, permittedInclusively: 0, requestPermission: 0, requestedPermission: 0, delegate: 0, delegated: 0 }
              });
              dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'lastEvaluatedKey'],
                value: { permit: [], permitted: [], permittedInclusively: [], requestPermission: [], requestedPermission: [], delegate: [], delegated: [] }
              });
            }}
          >
            <img className='size2x2' src={iconList} />
          </button>
          <button className='button1' tabindex='0' onClick={ () => historyFunction(0) } >
            <img className='size2x2' src={iconSearch} />
          </button>
          { arrowLeft }
          { arrowRight }
        </div>
        { historyItems }
        {/* development */}
        <div>
          { process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null }
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <Header screen='permission' />
        <div className='flexColumn alignItemsCenter width98vw'>
          {/* permit */}
          <div className='flexColumn borderMonacotto backgroundColorTransparent padding1 marginTopBottom1'>
            {/* title */}
            <div>
              {words.grantPermissionOfExhibit[state.language]}
            </div>
            {/* grant or revoke */}
            <div className='flexRow justifyContentCenter marginSide1'>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.permit.action === 'grant' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['permit', 'action'], value: 'grant'});
                }}>
                {words.grantPermission[state.language]}
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.permit.action === 'revoke' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['permit', 'action'], value: 'revoke'});
                }}>
                {words.revokePermission[state.language]}
              </button>
            </div>
            {/* individual or inclusive */}
            <div className='flexRow justifyContentCenter marginSide1'>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.permit.mode === 'individual' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['permit', 'mode'], value: 'individual'});
                }}>
                {words.individualPermission[state.language]}
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.permit.mode === 'inclusive' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['permit', 'mode'], value: 'inclusive'});
                }}>
                {words.inclusivePermission[state.language]}
              </button>
            </div>
            {/* owner address */}
            <div className='flexColumn alignItemsFlexStart marginTopBottom0p5 '>
              <TextLine3 fieldName='addressOwner' keys={['permit', 'addressOwner']} face={words.addressOwner[state.language]} tooltip={true} />
              <div className='flexRow justifyContentFlexStart alignItemsCenter '>
                <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['permit', 'addressOwner']) }>
                  <img className='size2x2' src={iconMpurse} />
                </button>
                <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['permit', 'addressOwner']) }>
                  <img className='size2x2' src={iconList} />
                </button>
              </div>
            </div>
            {/* addressPermitTo */}
            { addressPermitToField }
            {
              state.permit.action === 'grant' ?
              <div>
                {/* addressPayRoyaltyTo */}
                <div className='flexColumn alignItemsFlexStart marginTopBottom0p5 '>
                  <TextLine3 fieldName='addressPayRoyaltyTo' keys={['permit', 'addressPayRoyaltyTo']}
                    face={words.addressPayRoyaltyTo[state.language]} tooltip={true}
                  />
                  <div className='flexRow justifyContentFlexStart alignItemsCenter '>
                    <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['permit', 'addressPayRoyaltyTo']) }>
                      <img className='size2x2' src={iconMpurse} />
                    </button>
                    <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['permit', 'addressPayRoyaltyTo']) }>
                      <img className='size2x2' src={iconList} />
                    </button>
                  </div>
                </div>
                {/* royaltyPercentage */}
                <TextLine3 fieldName='royaltyPercentage' keys={['permit', 'royaltyPercentage']}
                  face={words.royaltyPercentage[state.language]} type='setStateMultiLayersFloat' adjustType='round' adjustExp={royaltyPercentageDigit}
                />
              </div> :
              null
            }
            {/* commit button */}
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1' tabindex='0'
              onClick={ async () => {
                const result = await handleClickPermit(state, dispatch, cookies, setCookie);

                if (result?.message === 'nfcRequired') {
                  const callback = (keyPairs) => {
                    handleClickPermit(state, dispatch, cookies, setCookie, keyPairs);
                    keepKeyPairsInMemory(state, dispatch, keyPairs);
                  };

                  handleClickNfc(state, dispatch, callback);
                }
                else if (result?.message === 'bcRequired') {
                  const callback = (keyPairs) => {
                    handleClickPermit(state, dispatch, cookies, setCookie, keyPairs);
                    keepKeyPairsInMemory(state, dispatch, keyPairs);
                  };

                  handleClickScanQr(state, dispatch, callback);
                }
              }}
            >
              <div>
                {words.signByOwnerAddressToRegister[state.language]}
              </div>
              <img className='size2x2' src={iconSign} />
            </button>
          </div>
          {/* request permission */}
          <div className='flexColumn borderMonacotto backgroundColorTransparent padding1 marginTopBottom1'>
            <div>
              {words.requestPermissionOfExhibit[state.language]}
            </div>
            <div className='flexColumn alignItemsFlexStart marginTopBottom0p5 '>
              <TextLine3 fieldName='requestPermissionAddressMain' keys={['requestPermission', 'addressMain']}
                face={words.addressMain[state.language]} tooltip={true} />
              <div className='flexRow justifyContentFlexStart alignItemsCenter '>
                <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['requestPermission', 'addressMain']) }>
                  <img className='size2x2' src={iconMpurse} />
                </button>
                <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['requestPermission', 'addressMain']) }>
                  <img className='size2x2' src={iconList} />
                </button>
              </div>
            </div>
            <TextLine3 fieldName='requestPermissionAddressOwner' keys={['requestPermission', 'addressOwner']}
              face={words.addressOwner[state.language]} tooltip={true} />
            <TextLine3 fieldName='requestPermissionRoyaltyPercentage' keys={['requestPermission', 'royaltyPercentage']}
              face={words.royaltyPercentage[state.language]} type='setStateMultiLayersFloat' adjustType='round' adjustExp={royaltyPercentageDigit}
            />
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1' tabindex='0'
              onClick={ async () => {
                const result = await handleClickRequestPermission(state, dispatch, cookies, setCookie);

                if (result?.message === 'nfcRequired') {
                  const callback = (keyPairs) => {
                    handleClickRequestPermission(state, dispatch, cookies, setCookie, keyPairs);
                    keepKeyPairsInMemory(state, dispatch, keyPairs);
                  };

                  handleClickNfc(state, dispatch, callback);
                }
                else if (result?.message === 'bcRequired') {
                  const callback = (keyPairs) => {
                    handleClickRequestPermission(state, dispatch, cookies, setCookie, keyPairs);
                    keepKeyPairsInMemory(state, dispatch, keyPairs);
                  };

                  handleClickScanQr(state, dispatch, callback);
                }
              }}
            >
              <div>
                {words.signByMainAddressToRegister[state.language]}
              </div>
              <img className='size2x2' src={iconSign} />
            </button>
          </div>
          {/* delegate */}
          <div className='flexColumn borderMonacotto backgroundColorTransparent padding1 marginTopBottom1'>
            <div>
              {words.signatureDelegation[state.language]}
            </div>
            {/* action */}
            <div className='flexRow justifyContentCenter '>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.delegate.action === 'request' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['delegate', 'action'], value: 'request'});
                }}>
                {words.delegate[state.language]}
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.delegate.action === 'revokeRequest' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['delegate', 'action'], value: 'revokeRequest'});
                }}>
                {words.revokeDelegation[state.language]}
              </button>
            </div>
            <div className='flexRow justifyContentCenter '>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.delegate.action === 'accept' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['delegate', 'action'], value: 'accept'});
                }}>
                {words.acceptDelegation[state.language]}
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.delegate.action === 'revokeAcceptance' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['delegate', 'action'], value: 'revokeAcceptance'});
                }}>
                {words.revokeAcceptance[state.language]}
              </button>
            </div>
            <div className='flexColumn alignItemsFlexStart' >
              {/* addressDelegateFrom */}
              <div className='flexColumn alignItemsFlexStart marginTopBottom0p5 '>
                <TextLine3 fieldName='delegateAddressMain' keys={['delegate', 'addressDelegateFrom']}
                  face={words.addressDelegateFrom[state.language]} tooltip={true}
                />
                <div className='flexRow justifyContentFlexStart alignItemsCenter '>
                  <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['delegate', 'addressDelegateFrom']) }>
                    <img className='size2x2' src={iconMpurse} />
                  </button>
                  <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['delegate', 'addressDelegateFrom']) }>
                    <img className='size2x2' src={iconList} />
                  </button>
                </div>
              </div>
              {/* addressDelegateTo */}
              <div className='flexColumn alignItemsFlexStart marginTopBottom0p5 '>
                <TextLine3 fieldName='delegateAddressDelegateTo' keys={['delegate', 'addressDelegateTo']}
                  face={words.addressDelegateTo[state.language]} tooltip={true}
                />
                <div className='flexRow justifyContentFlexStart alignItemsCenter '>
                  <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['delegate', 'addressDelegateTo']) }>
                    <img className='size2x2' src={iconMpurse} />
                  </button>
                  <button className='button1' onClick={ () => handleClickAddressHistory(state, dispatch, cookies, ['delegate', 'addressDelegateTo']) }>
                    <img className='size2x2' src={iconList} />
                  </button>
                </div>
              </div>
            </div>
            {/* register button */}
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1' tabindex='0'
              onClick={ async () => {
                const result = await handleClickDelegate(state, dispatch, cookies, setCookie);

                if (result?.message === 'nfcRequired') {
                  const callback = (keyPairs) => {
                    handleClickDelegate(state, dispatch, cookies, setCookie, keyPairs);
                    keepKeyPairsInMemory(state, dispatch, keyPairs);
                  };

                  handleClickNfc(state, dispatch, callback);
                }
                else if (result?.message === 'bcRequired') {
                  const callback = (keyPairs) => {
                    handleClickDelegate(state, dispatch, cookies, setCookie, keyPairs);
                    keepKeyPairsInMemory(state, dispatch, keyPairs);
                  };

                  handleClickScanQr(state, dispatch, callback);
                }
              }}
            >
              <div>
                {words.signByMainAddressToRegister[state.language]}
              </div>
              <img className='size2x2' src={iconSign} />
            </button>
          </div>
        </div>
        {/* history */}
        <div className='flexColumn alignItemsCenter marginTopBottom1 '>
          <div className='borderTopBottom marginTopBottom1'>
            {words.searchForPermissionToExhibitAndSignatureDelegation[state.language]}
          </div>
          {/* action */}
          <div className='flexColumn alignItemsCenter marginTopBottom0p5'>
            <div className='flexRow justifyContentCenter '>
              <button className={'box3 marginTopBottom0p5 focusEffect01 riseOut2 ' + (state.getPermissionHistory.action === 'permit' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'action'], value: 'permit'});
                }}>
                {words.permit[state.language]}
              </button>
              <button className={'box3 marginTopBottom0p5 focusEffect01 riseOut2 ' + (state.getPermissionHistory.action === 'permitted' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'action'], value: 'permitted'});
                }}>
                {words.permitted[state.language]}
              </button>
              <button className={'box3 marginTopBottom0p5 focusEffect01 riseOut2 ' + (state.getPermissionHistory.action === 'permittedInclusively' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'action'], value: 'permittedInclusively'});
                }}>
                {words.permittedInclusively[state.language]}
              </button>
            </div>
            <div className='flexRow justifyContentCenter '>
              <button className={'box3 marginTopBottom0p5 focusEffect01 riseOut2 ' + (state.getPermissionHistory.action === 'requestPermission' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'action'], value: 'requestPermission'});
                }}>
                {words.requestPermission[state.language]}
              </button>
              <button className={'box3 marginTopBottom0p5 focusEffect01 riseOut2 ' + (state.getPermissionHistory.action === 'requestedPermission' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'action'], value: 'requestedPermission'});
                }}>
                {words.requestedPermission[state.language]}
              </button>
            </div>
            <div className='flexRow justifyContentCenter '>
              <button className={'box3 marginTopBottom0p5 focusEffect01 riseOut2 ' + (state.getPermissionHistory.action === 'delegate' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'action'], value: 'delegate'});
                }}>
                {words.delegate2[state.language]}
              </button>
              <button className={'box3 marginTopBottom0p5 focusEffect01 riseOut2 ' + (state.getPermissionHistory.action === 'delegated' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'action'], value: 'delegated'});
                }}>
                {words.delegated[state.language]}
              </button>
            </div>
          </div>
          {/* addressMain */}
          <div className='flexColumn alignItemsFlexStart marginTopBottom0p5'>
            <TextLine3 fieldName='historyAddressMain' keys={['getPermissionHistory', 'addressMain']} face={words.addressMain[state.language]} tooltip={true}
              extraFunctionOnChange={ [
                {
                  function: () => {
                    dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'pagingIndex'],
                      value: { permit: 0, permitted: 0, permittedInclusively: 0, requestPermission: 0, requestedPermission: 0, delegate: 0, delegated: 0 }
                    });
                    dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'lastEvaluatedKey'],
                      value: { permit: [], permitted: [], permittedInclusively: [], requestPermission: [], requestedPermission: [], delegate: [], delegated: [] }
                    });
                  },
                  arguments: [],
                }
              ]}
            />
            <div className='flexRow alignItemsFlexStart '>
              <button className='button1'
                onClick={ () => {
                  handleClickMpurse(state, dispatch, ['getPermissionHistory', 'addressMain']);
                  dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'pagingIndex'],
                    value: { permit: 0, permitted: 0, permittedInclusively: 0, requestPermission: 0, requestedPermission: 0, delegate: 0, delegated: 0 }
                  });
                  dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'lastEvaluatedKey'],
                    value: { permit: [], permitted: [], permittedInclusively: [], requestPermission: [], requestedPermission: [], delegate: [], delegated: [] }
                  });
                }}
              >
                <img className='size2x2' src={iconMpurse} />
              </button>
              <button className='button1'
                onClick={ () => {
                  handleClickAddressHistory(state, dispatch, cookies, ['getPermissionHistory', 'addressMain']);
                  dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'pagingIndex'],
                    value: { permit: 0, permitted: 0, permittedInclusively: 0, requestPermission: 0, requestedPermission: 0, delegate: 0, delegated: 0 }
                  });
                  dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'lastEvaluatedKey'],
                    value: { permit: [], permitted: [], permittedInclusively: [], requestPermission: [], requestedPermission: [], delegate: [], delegated: [] }
                  });
                }}
              >
                <img className='size2x2' src={iconList} />
              </button>
              <button className='button1' tabindex='0' onClick={ () => historyFunction(0) } >
                <img className='size2x2' src={iconSearch} />
              </button>
            </div>
          </div>
          <div className='flexRow marginTopBottom0p5'>
            <div className='marginSide1'>
              { arrowLeft }
            </div>
            <div className='marginSide1'>
              { arrowRight }
            </div>
          </div>
        </div>
        { historyItems }
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
    </div>
  );
}

// PERMIT RECORD TITLE
function PermitRecordTitle() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { words.userNamePermitTo[state.language] }
          </div>
          <div className='marginSide1 widthMin25' >
            { words.addressPermitTo[state.language] }
          </div>
          <div className='marginSide1 widthMin7' >
            { words.royaltyPercentage[state.language] }
          </div>
          <div className='marginSide1 widthMin25' >
            { words.addressPayRoyaltyTo[state.language] }
          </div>
        </div>
      </div>
      {/* SP */}
    </div>
  );
}

// PERMIT RECORD
function PermitRecord(props) {
  const [state, dispatch] = useContext(GlobalState);
  const record = props.record;
  let addressPermitTo;
  let userNamePermitTo;

  // const popup = { type: 'exhibitRecordDetail', body: props.record };

  // addressPermitTo
  if (record.mode === 'inclusive') {
    addressPermitTo = null;
    userNamePermitTo = words.inclusivePermission[state.language];
  }
  else { // individual or undefined
    addressPermitTo = record.addressPermitTo;
    userNamePermitTo = state.usersGeneralIndex[record.addressPermitTo] !== undefined ? state.usersGeneralIndex[record.addressPermitTo].userName : null;
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { userNamePermitTo }
          </div>
          <div className='marginSide1 widthMin25' >
            { addressPermitTo }
          </div>
          <div className='marginSide1 widthMin7' >
            { props.record.royaltyPercentage + '%' }
          </div>
          <div className='marginSide1 widthMin25' >
            { props.record.addressPayRoyaltyTo }
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='boxMonacotto1SmallScreen flexColumn alignItemsFlexStart '  >
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.userNamePermitTo[state.language] }
            </div>
            <div className='' >
              { userNamePermitTo }
            </div>
          </div>
          <div className='flexColumn alignItemsFlexStart width98PC'>
            <div className='' >
              { words.addressPermitTo[state.language] }
            </div>
            <div className='widthMax textRight' >
              { addressPermitTo }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.royaltyPercentage[state.language] }
            </div>
            <div className='' >
              { props.record.royaltyPercentage + '%' }
            </div>
          </div>
          <div className='flexColumn alignItemsFlexStart width98PC'>
            <div className='' >
              { words.addressPayRoyaltyTo[state.language] }
            </div>
            <div className='widthMax textRight' >
              { props.record.addressPayRoyaltyTo }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// PERMITTED RECORD TITLE
function PermittedRecordTitle() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { words.ownerName[state.language] }
          </div>
          <div className='marginSide1 widthMin25' >
            { words.addressOwner[state.language] }
          </div>
          <div className='marginSide1 widthMin7' >
            { words.royaltyPercentage[state.language] }
          </div>
          {/*
            <div className='marginSide1 widthMin7' >
              { words.permissionMode[state.language] }
            </div>
          */}
          <div className='marginSide1 widthMin25' >
            { words.addressPayRoyaltyTo[state.language] }
          </div>
        </div>
      </div>
      {/* SP */}
    </div>
  );
}

// PERMITTED RECORD
function PermittedRecord(props) {
  const [state, dispatch] = useContext(GlobalState);
  const record = props.record;
  // let modeWord;

  // const popup = { type: 'exhibitRecordDetail', body: props.record };

  // // modeWord
  // if (record.mode === 'individual') {
  //   modeWord = words.individualPermission[state.language];
  // }
  // else { // inclusive
  //   modeWord = words.inclusivePermission[state.language];
  // }


  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { state.usersGeneralIndex[props.record.addressOwner] !== undefined ? state.usersGeneralIndex[props.record.addressOwner].userName : null }
          </div>
          <div className='marginSide1 widthMin25' >
            { props.record.addressOwner }
          </div>
          <div className='marginSide1 widthMin7' >
            { props.record.royaltyPercentage + '%' }
          </div>
          {/*
            <div className='marginSide1 widthMin7' >
              { modeWord }
            </div>
          */}
          <div className='marginSide1 widthMin25' >
            { props.record.addressPayRoyaltyTo }
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='boxMonacotto1SmallScreen flexColumn alignItemsFlexStart '  >
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.ownerName[state.language] }
            </div>
            <div className='' >
              { state.usersGeneralIndex[props.record.addressOwner] !== undefined ? state.usersGeneralIndex[props.record.addressOwner].userName : null }
            </div>
          </div>
          <div className='flexColumn alignItemsFlexStart width98PC'>
            <div className='' >
              { words.addressOwner[state.language] }
            </div>
            <div className='widthMax textRight' >
              { props.record.addressOwner }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.royaltyPercentage[state.language] }
            </div>
            <div className='' >
              { props.record.royaltyPercentage + '%' }
            </div>
          </div>
          {/*
            <div className='flexColumn alignItemsFlexStart width98PC'>
              <div className='' >
                { words.permissionMode[state.language] }
              </div>
              <div className='widthMax textRight' >
                { modeWord }
              </div>
            </div>
          */}
          <div className='flexColumn alignItemsFlexStart width98PC'>
            <div className='' >
              { words.addressPayRoyaltyTo[state.language] }
            </div>
            <div className='widthMax textRight' >
              { props.record.addressPayRoyaltyTo }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// REQUEST PERMISSION RECORD TITLE
function RequestPermissionRecordTitle() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { words.ownerName[state.language] }
          </div>
          <div className='marginSide1 widthMin25' >
            { words.addressOwner[state.language] }
          </div>
          <div className='marginSide1 widthMin7' >
            { words.royaltyPercentage[state.language] }
          </div>
        </div>
      </div>
      {/* SP */}
    </div>
  );
}

// REQUEST PERMISSION RECORD
function RequestPermissionRecord(props) {
  const [state, dispatch] = useContext(GlobalState);

  // const popup = { type: 'exhibitRecordDetail', body: props.record };

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { state.usersGeneralIndex[props.record.addressOwner] !== undefined ? state.usersGeneralIndex[props.record.addressOwner].userName : null }
          </div>
          <div className='marginSide1 widthMin25' >
            { props.record.addressOwner }
          </div>
          <div className='marginSide1 widthMin7' >
            { props.record.royaltyPercentage + '%' }
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='boxMonacotto1SmallScreen flexColumn alignItemsFlexStart '  >
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.ownerName[state.language] }
            </div>
            <div className='' >
              { state.usersGeneralIndex[props.record.addressOwner] !== undefined ? state.usersGeneralIndex[props.record.addressOwner].userName : null }
            </div>
          </div>
          <div className='flexColumn alignItemsFlexStart width98PC'>
            <div className='' >
              { words.addressOwner[state.language] }
            </div>
            <div className='widthMax textRight' >
              { props.record.addressOwner }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.royaltyPercentage[state.language] }
            </div>
            <div className='' >
              { props.record.royaltyPercentage + '%' }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// REQUESTED PERMISSION RECORD TITLE
function RequestedPermissionRecordTitle() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { words.userNamePermitTo[state.language] }
          </div>
          <div className='marginSide1 widthMin25' >
            { words.addressPermitTo[state.language] }
          </div>
          <div className='marginSide1 widthMin7' >
            { words.royaltyPercentage[state.language] }
          </div>
          <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite' disabled={true} />
        </div>
      </div>
      {/* SP */}
    </div>
  );
}

// REQUESTED PERMISSION RECORD
function RequestedPermissionRecord(props) {
  const [state, dispatch] = useContext(GlobalState);

  // const popup = { type: 'exhibitRecordDetail', body: props.record };

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { state.usersGeneralIndex[props.record.addressMain] !== undefined ? state.usersGeneralIndex[props.record.addressMain].userName : null }
          </div>
          <div className='marginSide1 widthMin25' >
            { props.record.addressMain }
          </div>
          <div className='marginSide1 widthMin7' >
            { props.record.royaltyPercentage + '%' }
          </div>
          <button className='button1' disabled={props.disabled ? true : false}
            onClick={ () => {
              const royaltyPercentage = {
                face: props.record.royaltyPercentage,
                value: props.record.royaltyPercentage,
              }

              dispatch({ type: 'setStateMultiLayers', keys: ['permit', 'addressOwner'], value: props.record.addressOwner });
              dispatch({ type: 'setStateMultiLayers', keys: ['permit', 'addressPermitTo'], value: props.record.addressMain });
              dispatch({ type: 'setStateMultiLayers', keys: ['permit', 'royaltyPercentage'], value: royaltyPercentage });
            }}
          >
            <img className='size2x2' src={iconHandShake} />
          </button>
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='boxMonacotto1SmallScreen flexColumn alignItemsFlexStart '  >
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.userNamePermitTo[state.language] }
            </div>
            <div className='flexRow alignItemsCenter' >
              <div className='' >
                { state.usersGeneralIndex[props.record.addressMain] !== undefined ? state.usersGeneralIndex[props.record.addressMain].userName : null }
              </div>
              <button className='button1' disabled={props.disabled ? true : false}
                onClick={ () => {
                  const royaltyPercentage = {
                    face: props.record.royaltyPercentage,
                    value: props.record.royaltyPercentage,
                  }

                  dispatch({ type: 'setStateMultiLayers', keys: ['permit', 'addressOwner'], value: props.record.addressOwner });
                  dispatch({ type: 'setStateMultiLayers', keys: ['permit', 'addressPermitTo'], value: props.record.addressMain });
                  dispatch({ type: 'setStateMultiLayers', keys: ['permit', 'royaltyPercentage'], value: royaltyPercentage });
                }}
              >
                <img className='size2x2' src={iconHandShake} />
              </button>
            </div>
          </div>
          <div className='flexColumn alignItemsFlexStart width98PC'>
            <div className='' >
              { words.addressPermitTo[state.language] }
            </div>
            <div className='widthMax textRight' >
              { props.record.addressMain }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.royaltyPercentage[state.language] }
            </div>
            <div className='' >
              { props.record.royaltyPercentage + '%' }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// DELEGATE RECORD TITLE
function DelegateRecordTitle() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { words.userNameDelegateTo[state.language] }
          </div>
          <div className='marginSide1 widthMin25' >
            { words.addressDelegateTo[state.language] }
          </div>
          <div className='marginSide1 widthMin7' >
            { words.statusRequest[state.language] }
          </div>
          <div className='marginSide1 widthMin7' >
            { words.statusAccept[state.language] }
          </div>
          <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite' disabled={true} />
        </div>
      </div>
      {/* SP */}
    </div>
  );
}

// DELEGATE RECORD
function DelegateRecord(props) {
  const [state, dispatch] = useContext(GlobalState);

  // const popup = { type: 'exhibitRecordDetail', body: props.record };

  let wordStatusRequest;
  let wordStatusAccept;

  if (props.record.statusRequest !== undefined) {
    wordStatusRequest = words[props.record.statusRequest][state.language]; 
  }
  else {
    wordStatusRequest = words.invalid[state.language];
  }

  if (props.record.statusAccept !== undefined) {
    wordStatusAccept = words[props.record.statusAccept][state.language]; 
  }
  else {
    wordStatusAccept = words.invalid[state.language];
  }

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { state.usersGeneralIndex[props.record.addressDelegateTo] !== undefined ? state.usersGeneralIndex[props.record.addressDelegateTo].userName : null }
          </div>
          <div className='marginSide1 widthMin25' >
            { props.record.addressDelegateTo }
          </div>
          <div className='marginSide1 widthMin7' >
            { wordStatusRequest }
          </div>
          <div className='marginSide1 widthMin7' >
            { wordStatusAccept }
          </div>
          <button className='button1' disabled={props.disabled ? true : false}
            onClick={ () => {
              dispatch({ type: 'setStateMultiLayers', keys: ['delegate', 'addressDelegateFrom'], value: props.record.addressDelegateFrom });
              dispatch({ type: 'setStateMultiLayers', keys: ['delegate', 'addressDelegateTo'], value: props.record.addressDelegateTo });
              dispatch({ type: 'setStateMultiLayers', keys: ['delegate', 'action'], value: props.record.statusRequest !== 'valid' ? 'request' : 'revokeRequest' });
            }}
          >
            <img className='size2x2' src={iconHandShake} />
          </button>
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='boxMonacotto1SmallScreen flexColumn alignItemsFlexStart '  >
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.userNameDelegateTo[state.language] }
            </div>
            <div className='flexRow alignItemsCenter' >
              <div className='' >
                { state.usersGeneralIndex[props.record.addressDelegateTo] !== undefined ? state.usersGeneralIndex[props.record.addressDelegateTo].userName : null }
              </div>
              <button className='button1' disabled={props.disabled ? true : false}
                onClick={ () => {
                  dispatch({ type: 'setStateMultiLayers', keys: ['delegate', 'addressDelegateFrom'], value: props.record.addressDelegateFrom });
                  dispatch({ type: 'setStateMultiLayers', keys: ['delegate', 'addressDelegateTo'], value: props.record.addressDelegateTo });
                  dispatch({ type: 'setStateMultiLayers', keys: ['delegate', 'action'], value: props.record.statusRequest !== 'valid' ? 'request' : 'revokeRequest' });
                }}
              >
                <img className='size2x2' src={iconHandShake} />
              </button>
            </div>
          </div>
          <div className='flexColumn alignItemsFlexStart width98PC'>
            <div className='' >
              { words.addressDelegateTo[state.language] }
            </div>
            <div className='widthMax textRight' >
              { props.record.addressDelegateTo }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.statusRequest[state.language] }
            </div>
            <div className='' >
              { wordStatusRequest }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.statusAccept[state.language] }
            </div>
            <div className='' >
              { wordStatusAccept }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// DELEGATED RECORD TITLE
function DelegatedRecordTitle() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { words.userNameDelegateFrom[state.language] }
          </div>
          <div className='marginSide1 widthMin25' >
            { words.addressDelegateFrom[state.language] }
          </div>
          <div className='marginSide1 widthMin7' >
            { words.statusRequest[state.language] }
          </div>
          <div className='marginSide1 widthMin7' >
            { words.statusAccept[state.language] }
          </div>
          <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite' disabled={true} />
        </div>
      </div>
      {/* SP */}
    </div>
  );
}

// DELEGATED RECORD
function DelegatedRecord(props) {
  const [state, dispatch] = useContext(GlobalState);

  // const popup = { type: 'exhibitRecordDetail', body: props.record };

  let wordStatusRequest;
  let wordStatusAccept;

  if (props.record.statusRequest !== undefined) {
    wordStatusRequest = words[props.record.statusRequest][state.language]; 
  }
  else {
    wordStatusRequest = words.invalid[state.language];
  }

  if (props.record.statusAccept !== undefined) {
    wordStatusAccept = words[props.record.statusAccept][state.language]; 
  }
  else {
    wordStatusAccept = words.invalid[state.language];
  }

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { state.usersGeneralIndex[props.record.addressDelegateFrom] !== undefined ? state.usersGeneralIndex[props.record.addressDelegateFrom].userName : null }
          </div>
          <div className='marginSide1 widthMin25' >
            { props.record.addressDelegateFrom }
          </div>
          <div className='marginSide1 widthMin7' >
            { wordStatusRequest }
          </div>
          <div className='marginSide1 widthMin7' >
            { wordStatusAccept }
          </div>
          <button className='button1' disabled={props.disabled ? true : false}
            onClick={ () => {
              dispatch({ type: 'setStateMultiLayers', keys: ['delegate', 'addressDelegateFrom'], value: props.record.addressDelegateFrom });
              dispatch({ type: 'setStateMultiLayers', keys: ['delegate', 'addressDelegateTo'], value: props.record.addressDelegateTo });
              dispatch({ type: 'setStateMultiLayers', keys: ['delegate', 'action'], value: props.record.statusAccept !== 'valid' ? 'accept' : 'revokeAcceptance' });
            }}
          >
            <img className='size2x2' src={iconHandShake} />
          </button>
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='boxMonacotto1SmallScreen flexColumn alignItemsFlexStart '  >
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.userNameDelegateFrom[state.language] }
            </div>
            <div className='flexRow alignItemsCenter' >
              <div className='' >
                { state.usersGeneralIndex[props.record.addressDelegateFrom] !== undefined ? state.usersGeneralIndex[props.record.addressDelegateFrom].userName : null }
              </div>
              <button className='button1' disabled={props.disabled ? true : false}
                onClick={ () => {
                  dispatch({ type: 'setStateMultiLayers', keys: ['delegate', 'addressDelegateFrom'], value: props.record.addressDelegateFrom });
                  dispatch({ type: 'setStateMultiLayers', keys: ['delegate', 'addressDelegateTo'], value: props.record.addressDelegateTo });
                  dispatch({ type: 'setStateMultiLayers', keys: ['delegate', 'action'], value: props.record.statusAccept !== 'valid' ? 'accept' : 'revokeAcceptance' });
                }}
              >
                <img className='size2x2' src={iconHandShake} />
              </button>
            </div>
          </div>
          <div className='flexColumn alignItemsFlexStart width98PC'>
            <div className='' >
              { words.addressDelegateFrom[state.language] }
            </div>
            <div className='widthMax textRight' >
              { props.record.addressDelegateFrom }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.statusRequest[state.language] }
            </div>
            <div className='' >
              { wordStatusRequest }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.statusAccept[state.language] }
            </div>
            <div className='' >
              { wordStatusAccept }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// HOW TO USE
function HowToUse() {
  const [state, dispatch, navigate] = useContext(GlobalState);

  if (state.language === 'japanese') {
    return (
      <div>
        {/* PC */}
        <div className='visibleLargeOrMore' >
          <Header screen='howToUse' />
          <div className='flexColumn alignItemsCenter '>
            <div className='width95vw marginTopBottom1 '>
              <div>
                1つのアドレスだけを使う<span className='margin0p5 font2'>もなこっとの一番簡単な使い方</span><br/>
              </div>
              {/*
                <div className='marginTop1'>
                  ※もなこっとは現在PCでの利用を推奨しています。スマホでは表示が乱れたり、一部機能が使えない箇所があります。
                </div>
              */}
            </div>
            <div className='flexColumn borderMonacotto width98vw marginTopBottom1 padding1'>
              <div className='font2'>
                モナカードを買う
              </div>
              <div className='flexRow justifyContentSpaceAround margin1'>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    1. Mpurseをインストールする
                  </div>
                  <div className='flexRow justifyContentCenter widthMax margin1' >
                    <img className='width14' src={imageManualMpurse10} />
                  </div>
                  <div>
                    もなこっとでは、モナコインのウォレットとしてMpurseを使います。Mpurseはブラウザ拡張型のアプリケーションで、Chrome, Firefoxに対応しています。
                    Mpurseのインストールについては、
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/howtousempurse' : '/howtousempurse'); 
                      } }>
                      こちら
                    </button>
                    をご参照ください。<br/>
                    <br/>
                    ここでは、1つのアドレスを使った購入方法を説明しますので、Mpurseのアカウント(アドレス)の切替は行わないでください。
                  </div>
                </div>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    2. モナコインを準備する
                  </div>
                  <div className='margin1'>
                    <img className='width35' src={imageManualMonachen} />
                  </div>
                  <div className='margin1'>
                    Mpurseのアドレスにモナコインを送金してください。暗号資産取引所でモナコインを入手することもできますが、もなちぇんを使えば、暗号資産取引所を使わずにモナコインを入手することができます。
                    もなちぇんについては、
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        window.open(`https://${process.env.REACT_APP_MONACHEN_MAIN_URL}`);
                      } }>
                      こちら
                    </button>
                    をご参照ください。
                  </div>
                </div>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    3. ユーザー登録をする
                  </div>
                  <div className='margin1'>
                    <img className='width35' src={imageManualPurchase03} />
                  </div>
                  <div>
                    設定画面で、「メインアドレス」の横のMpurseボタンをクリックしてアドレスを入力してください。「ユーザー名」を入力してください。利用規約、プライバシーポリシーを読んで、同意するボタンを押してください。「メインアドレスで署名して登録」ボタンをクリックするとMpurseがポップアップしますので、署名ボタンをクリックしてください。
                  </div>
                </div>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    4. モナカードを買う
                  </div>
                  <div className='margin1'>
                    <img className='width35' src={imageManualPurchase04} />
                  </div>
                  <div>
                    「出かける」ボタンか、もなこっとロゴをクリックすると、マイホーム一覧が表示されます。マイホーム・メインイメージをクリックすると、マイホーム内に入り、出品商品が一覧表示されます。<br/>
                    買いたい商品をクリックし、「メインアドレス」「代金送金元アドレス」「カード受取アドレス」の横のMpurseボタンをクリックし、全部同じアドレスを入力してください。購入したい枚数を入力してください。「メインアドレスで署名する」ボタンをクリックするとMpurseがポップアップしますので、署名ボタンをクリックしてください。署名に成功すると、「MONA送信」ボタンが表示されますので、クリックし、送金内容を確認のうえ、Mpurseの「送信」ボタンをクリックしてください。数分待つと、モナカードがMpurseのモナコインアドレスに送信されます。<span className='backgroundColorRed'>2時間以内にモナコインの送金を行わないと期限切れとなり、購入はキャンセルとなります。</span>
                  </div>
                </div>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    5. 買ったモナカードを確認する
                  </div>
                  <div className='margin1'>
                    <img className='width35' src={imageManualPurchase05} />
                  </div>
                  <div>
                    送信されたモナカードを確認するにはMpurseエクステが便利です。
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        window.open('https://mpurse-extension.komikikaku.com/');
                      } }>
                      ここ
                    </button>
                    にアクセスし、Mpurseを接続すると、保有しているモナカードが表示されます。<img className='size1p7x1p7' src={imageManualPurchaseImageButton} />ボタンを押すと画像が表示されます。
                  </div>
                </div>
              </div>
            </div>
            <div className='flexColumn borderMonacotto width98vw marginTopBottom1 padding1'>
              <div className='font2'>
                モナカードを売る
              </div>
              <div className='flexRow justifyContentSpaceAround margin1'>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    1. Mpurseをインストールする
                  </div>
                  <div className='flexRow justifyContentCenter widthMax margin1' >
                    <img className='width14' src={imageManualMpurse10} />
                  </div>
                  <div>
                    もなこっとでは、モナコインのウォレットとしてMpurseを使います。Mpurseはブラウザ拡張型のアプリケーションで、Chrome, Firefoxに対応しています。
                    Mpurseのインストールについては、
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/howtousempurse' : '/howtousempurse'); 
                      } }>
                      こちら
                    </button>
                    をご参照ください。<br/>
                    <br/>
                    ここでは、1つのアドレスを使った販売方法を説明しますので、Mpurseのアカウント(アドレス)の切替は行わないでください。
                  </div>
                </div>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    2. モナコインを準備する
                  </div>
                  <div className='margin1'>
                    <img className='width35' src={imageManualMonachen} />
                  </div>
                  <div className='margin1'>
                    モナカードを出品するのに、ごく少額のモナコインが必要になります。<br/>
                    <br/>
                    Mpurseのアドレスにモナコインを送金してください。暗号資産取引所でモナコインを入手することもできますが、もなちぇんを使えば、暗号資産取引所を使わずにモナコインを入手することができます。
                    もなちぇんについては、
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        window.open(`https://${process.env.REACT_APP_MONACHEN_MAIN_URL}`);
                      } }>
                      こちら
                    </button>
                    をご参照ください。
                  </div>
                </div>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    3. モナカードを準備する
                  </div>
                  <div className='margin1'>
                    <img className='width35' src={imageManualExhibit03} />
                  </div>
                  <div>
                    Mpurseのアドレスに出品するモナカードを送信してください。<br/>
                    <br/>
                    自身でモナカードを発行する場合は、
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        window.open('https://mpurse-extension.komikikaku.com/');
                      } }>
                      Mpurseエクステ
                    </button>
                    が便利です。Mpurseエクステは、超高機能総合エンタテイメントウォレット「モナパレット」の機能を、Mpurseで使えるようにしたものです。使い方は、
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        window.open('https://opaque-toast-7ea.notion.site/fe5a2c309f3a495fa7a3399549cfe50c');
                      } }>
                      こちら
                    </button>
                    のドキュメントなどを参考にしてください。(ネットで「モナパレット」「モナカード」などのキーワードで検索すると他にも参考になる記事が出てくると思います。)
                  </div>
                </div>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    4. ユーザー登録をする
                  </div>
                  <div className='margin1'>
                    <img className='width35' src={imageManualExhibit04} />
                  </div>
                  <div>
                    設定画面で、「メインアドレス」の横のMpurseボタンをクリックしてアドレスを入力してください。「ユーザー名」を入力してください。「マイホームあり」を選択してください。(マイホームはモナカードを出品するスペースのことです。)メインイメージ(マイホームの画像)をドラッグ＆ドロップしてください。プロフィールテキストを入力してください。利用規約、プライバシーポリシーを読んで、それぞれ同意するボタンを押してください。「メインアドレスで署名して登録」ボタンをクリックするとMpurseがポップアップしますので、署名ボタンをクリックしてください。
                  </div>
                </div>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    5. モナカードを出品する
                  </div>
                  <div className='margin1'>
                    <img className='width35' src={imageManualExhibit05} />
                  </div>
                  <div>
                    出品画面で、「メインアドレス」「カード送信元アドレス」「売上受取アドレス」の横のMpurseボタンをクリックし、全部同じアドレスを入力してください。出品したいモナカードのトークン名、枚数、単価を入力してください。「メインアドレスで署名する」ボタンをクリックするとMpurseがポップアップしますので、署名ボタンをクリックしてください。署名に成功すると、「カード送信」ボタンが表示されますので、クリックし、送信内容を確認のうえ、Mpurseの「送信」ボタンをクリックしてください。数分待つと、マイホーム画面に出品したモナカードが表示されます。<span className='backgroundColorRed'>2時間以内にモナカードの送金を行わないと期限切れとなり、出品はキャンセルとなります。</span>
                  </div>
                </div>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    6. 売上を確認する
                  </div>
                  <div className='margin1'>
                    <img className='width35' src={imageManualExhibit06} />
                  </div>
                  <div>
                    出品したモナカードが売れると、マイホーム上のモナカードの在庫が減ります。しばらくすると、Mpurseのアドレスに売上が送られます。Mpurseのアドレスの残高を確認してみてください。
                  </div>
                </div>
              </div>
            </div>
            <div className='flexColumn borderMonacotto margin1 padding1'>
              <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                onClick={ () => {
                  window.open('https://spotlight.soy/detail?article_id=ckkgeevvg');
                } }>
                ガイドラインもご一読ください。
              </button>
            </div>
            <div className='flexColumn borderMonacotto margin1 padding1'>
              <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                onClick={ () => {
                  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/howtouseadvanced' : '/howtouseadvanced'); 
                } }>
                もっと使いこなしてみたい人はこちら！（FAQ）
              </button>
            </div>
          </div>
        </div>
        {/* SP */}
        <div className='visibleMiddleOrLess flexColumn alignItemsCenter ' >
          <Header screen='howToUse' />
          <div className='flexColumn alignItemsCenter '>
            <div className='width93vw marginTopBottom1 '>
              <div>
                1つのアドレスだけを使う<br/>
                <span className='font2'>もなこっとの一番簡単な使い方</span><br/>
              </div>
              <div className='marginTop1'>
                {/*
                  ※もなこっとは現在PCでの利用を推奨しています。スマホでは表示が乱れたり、一部機能が使えない箇所があります。<br/>
                */}
                ※スクリーンショットは、一部PCのものを使用しています。
              </div>
            </div>
            <div className='flexColumn alignItemsCenter borderMonacotto width96vw marginTopBottom1 '>
              <div className='font2 width93vw'>
                モナカードを買う
              </div>
              <div className='flexColumn alignItemsCenter widthMax '>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1 '>
                  <div className='widthMax'>
                    1. Mpurseをインストールする
                  </div>
                  <div className='marginTopBottom1' >
                    <img className='width60vw' src={imageManualMpurseSp05} />
                  </div>
                  <div className='widthMax breakAll'>
                    もなこっとでは、モナコインのウォレットとしてMpurseを使います。スマホではMpurseアプリを利用するのが一般的ですが、Firefoxに対応したブラウザ拡張型のアプリケーションもあります。
                    Mpurseのインストールについては、
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/howtousempurse' : '/howtousempurse'); 
                      } }>
                      こちら
                    </button>
                    をご参照ください。<br/>
                    <br/>
                    ここでは、1つのアドレスを使った購入方法を説明しますので、Mpurseのアカウント(アドレス)の切替は行わないでください。
                  </div>
                </div>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1'>
                  <div className='widthMax'>
                    2. モナコインを準備する
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width90vw' src={imageManualMonachen} />
                  </div>
                  <div className='widthMax breakAll'>
                    Mpurseのアドレスにモナコインを送金してください。暗号資産取引所でモナコインを入手することもできますが、もなちぇんを使えば、暗号資産取引所を使わずにモナコインを入手することができます。
                    もなちぇんについては、
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        window.open(`https://${process.env.REACT_APP_MONACHEN_MAIN_URL}`);
                      } }>
                      こちら
                    </button>
                    をご参照ください。
                  </div>
                </div>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1'>
                  <div className='widthMax'>
                    3. ユーザー登録をする
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width90vw' src={imageManualPurchase03} />
                  </div>
                  <div className='widthMax breakAll'>
                    設定画面で、「メインアドレス」の下のMpurseボタンをクリックしてアドレスを入力してください。「ユーザー名」を入力してください。利用規約、プライバシーポリシーを読んで、同意するボタンを押してください。「メインアドレスで署名して登録」ボタンをクリックするとMpurseがポップアップしますので、署名ボタンをクリックしてください。
                  </div>
                </div>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1'>
                  <div className='widthMax'>
                    4. モナカードを買う
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width90vw' src={imageManualPurchase04} />
                  </div>
                  <div className='widthMax breakAll'>
                    「出かける」ボタンか、もなこっとロゴをクリックすると、マイホーム一覧が表示されます。マイホーム・メインイメージをクリックすると、マイホーム内に入り、出品商品が一覧表示されます。<br/>
                    買いたい商品をクリックし、「メインアドレス」「代金送金元アドレス」「カード受取アドレス」の下のMpurseボタンをクリックし、全部同じアドレスを入力してください。購入したい枚数を入力してください。「メインアドレスで署名する」ボタンをクリックするとMpurseがポップアップしますので、署名ボタンをクリックしてください。署名に成功すると、「MONA送信内容確認」ボタンと「MONA送信」ボタンが表示されますので、クリックし、送金内容を確認のうえ、Mpurseの「送信」ボタンをクリックしてください。数分待つと、モナカードがMpurseのモナコインアドレスに送信されます。<span className='backgroundColorRed'>2時間以内にモナコインの送金を行わないと期限切れとなり、購入はキャンセルとなります。</span>
                  </div>
                </div>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1'>
                  <div className='widthMax'>
                    5. 買ったモナカードを確認する
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width60vw' src={imageManualPurchaseSp01} />
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width60vw' src={imageManualPurchaseSp02} />
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width60vw' src={imageManualPurchaseSp03} />
                  </div>
                  {/*
                    <div className='marginTopBottom1'>
                      <img className='width90vw' src={imageManualPurchase05} />
                    </div>
                  */}
                  <div className='widthMax breakAll'>
                    送信されたモナカードを確認するにはMpurseエクステが便利です。
                    Mpurseアプリのメニューからブラウザを選び、Mpurseエクステをクリックすると、保有しているモナカードが表示されます。<img className='size1p7x1p7' src={imageManualPurchaseImageButton} />ボタンを押すと画像が表示されます。
                  </div>
                </div>
              </div>
            </div>
            <div className='flexColumn alignItemsCenter borderMonacotto width96vw marginTopBottom1 '>
              <div className='font2 width93vw'>
                モナカードを売る
              </div>
              <div className='flexColumn alignItemsCenter widthMax'>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1'>
                  <div className='widthMax'>
                    1. Mpurseをインストールする
                  </div>
                  <div className='marginTopBottom1' >
                    <img className='width60vw' src={imageManualMpurseSp05} />
                  </div>
                  <div className='widthMax breakAll ' >
                    もなこっとでは、モナコインのウォレットとしてMpurseを使います。スマホではMpurseアプリを利用するのが一般的ですが、Firefoxに対応したブラウザ拡張型のアプリケーションもあります。
                    Mpurseのインストールについては、
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/howtousempurse' : '/howtousempurse'); 
                      } }>
                      こちら
                    </button>
                    をご参照ください。<br/>
                    <br/>
                    ここでは、1つのアドレスを使った販売方法を説明しますので、Mpurseのアカウント(アドレス)の切替は行わないでください。
                  </div>
                </div>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1'>
                  <div className='widthMax'>
                    2. モナコインを準備する
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width90vw' src={imageManualMonachen} />
                  </div>
                  <div className='widthMax breakAll'>
                    モナカードを出品するのに、ごく少額のモナコインが必要になります。<br/>
                    <br/>
                    Mpurseのアドレスにモナコインを送金してください。暗号資産取引所でモナコインを入手することもできますが、もなちぇんを使えば、暗号資産取引所を使わずにモナコインを入手することができます。
                    もなちぇんについては、
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        window.open(`https://${process.env.REACT_APP_MONACHEN_MAIN_URL}`);
                      } }>
                      こちら
                    </button>
                    をご参照ください。
                  </div>
                </div>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1'>
                  <div className='widthMax'>
                    3. モナカードを準備する
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width60vw' src={imageManualPurchaseSp01} />
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width60vw' src={imageManualPurchaseSp02} />
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width60vw' src={imageManualExhibitSP01} />
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width60vw' src={imageManualExhibitSP02} />
                  </div>
                  <div className='widthMax breakAll' >
                    Mpurseのアドレスに出品するモナカードを送信してください。<br/>
                    <br/>
                    自身でモナカードを発行する場合は、Mpurseエクステが便利です。Mpurseアプリのメニューからブラウザを選び、Mpurseエクステをクリックしてください。
                    Mpurseエクステは、超高機能総合エンタテイメントウォレット「モナパレット」の機能を、Mpurseで使えるようにしたものです。詳しい使い方は、
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        window.open('https://opaque-toast-7ea.notion.site/fe5a2c309f3a495fa7a3399549cfe50c');
                      } }>
                      こちら
                    </button>
                    のドキュメントなどを参考にしてください。(ネットで「モナパレット」「モナカード」などのキーワードで検索すると他にも参考になる記事が出てくると思います。)
                  </div>
                </div>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1'>
                  <div className='widthMax'>
                    4. ユーザー登録をする
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width90vw' src={imageManualExhibit04} />
                  </div>
                  <div className='widthMax breakAll' >
                    設定画面で、「メインアドレス」の下のMpurseボタンをクリックしてアドレスを入力してください。「ユーザー名」を入力してください。「マイホームあり」を選択してください。(マイホームはモナカードを出品するスペースのことです。)メインイメージ(マイホームの画像)をドラッグ＆ドロップしてください。プロフィールテキストを入力してください。利用規約、プライバシーポリシーを読んで、同意するボタンを押してください。「メインアドレスで署名して登録」ボタンをクリックするとMpurseがポップアップしますので、署名ボタンをクリックしてください。
                  </div>
                </div>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1'>
                  <div className='widthMax'>
                    5. モナカードを出品する
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width90vw' src={imageManualExhibit05} />
                  </div>
                  <div className='widthMax breakAll' >
                    出品画面で、「メインアドレス」「カード送信元アドレス」「売上受取アドレス」の下のMpurseボタンをクリックし、全部同じアドレスを入力してください。出品したいモナカードのトークン名、枚数、単価を入力してください。「メインアドレスで署名する」ボタンをクリックするとMpurseがポップアップしますので、署名ボタンをクリックしてください。署名に成功すると、「カード送信」ボタンが表示されますので、クリックし、送信内容を確認のうえ、Mpurseの「送信」ボタンをクリックしてください。数分待つと、マイホーム画面に出品したモナカードが表示されます。<span className='backgroundColorRed'>2時間以内にモナカードの送金を行わないと期限切れとなり、出品はキャンセルとなります。</span>
                  </div>
                </div>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1'>
                  <div className='widthMax'>
                    6. 売上を確認する
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width60vw' src={imageManualExhibitSP03} />
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width90vw' src={imageManualExhibit06} />
                  </div>
                  <div className='widthMax breakAll' >
                    出品したモナカードが売れると、マイホーム上のモナカードの在庫が減ります。しばらくすると、Mpurseのアドレスに売上が送られます。Mpurseアプリのメニューからウォレットを選び、Mpurseのアドレスの残高を確認してみてください。
                  </div>
                </div>
              </div>
            </div>
            <div className='flexColumn borderMonacotto width90vw marginTopBottom1 padding1'>
              <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                onClick={ () => {
                  window.open('https://spotlight.soy/detail?article_id=ckkgeevvg');
                } }>
                ガイドラインもご一読ください。
              </button>
            </div>
            <div className='flexColumn borderMonacotto width90vw marginTopBottom1 padding1'>
              <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                onClick={ () => {
                  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/howtouseadvanced' : '/howtouseadvanced'); 
                } }>
                もっと使いこなしてみたい人はこちら！（FAQ）
              </button>
            </div>
          </div>
        </div>
        {/* popup */}
        <Popup layer={0}/>
        <Popup layer={1}/>
      </div>
    );
  }
  else // english
  {
    return (
      <div>
        {/* PC */}
        <div className='visibleLargeOrMore' >
          <Header screen='howToUse' />
          <div className='flexColumn alignItemsCenter'>
            <div className='width95vw marginTopBottom1 marginSide2'>
              <div>
                <span className='margin0p5 font2'>The easiest way to use monacotto</span>using only one address<br/>
              </div>
              <div className='marginTop1'>
                *We currently recommend using monacotto on a PC. Some parts of the site may not display properly or some functions may not work on smartphones.
              </div>
            </div>
            <div className='flexColumn borderMonacotto width98vw marginTopBottom1 padding1'>
              <div className='font2'>
                Buy Monacards
              </div>
              <div className='flexRow justifyContentSpaceAround margin1'>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    1. Install Mpurse
                  </div>
                  <div className='flexRow justifyContentCenter widthMax margin1' >
                    <img className='width14' src={imageManualMpurse10} />
                  </div>
                  <div>
                    Monacotto uses Mpurse as a wallet for Monacoin. Mpurse is a browser extension application and supports Chrome and Firefox. For information on installing Mpurse, please click 
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/howtousempurse' : '/howtousempurse'); 
                      } }>
                      here
                    </button>
                    .<br/>
                    <br/>
                    Please do not switch Mpurse accounts (addresses), as this section explains how to make purchases using a single address.
                  </div>
                </div>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    2. Prepare Monacoin
                  </div>
                  <div className='margin1'>
                    <img className='width35' src={imageManualMonachen} />
                  </div>
                  <div className='margin1'>
                    Send Monacoin to your Mpurse address. You can also obtain Monacoin on crypto asset exchanges, but with monachen, you can obtain Monacoin without using a crypto asset exchange.
                    For more information about monachen, please click 
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        window.open(`https://${process.env.REACT_APP_MONACHEN_MAIN_URL}?language=${state.language}`);
                      } }>
                      here
                    </button>
                    .
                  </div>
                </div>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    3. Register as a user
                  </div>
                  <div className='margin1'>
                    <img className='width35' src={imageManualPurchase03} />
                  </div>
                  <div>
                    On the setup screen, click the Mpurse button next to "main address" to enter the address. Enter your "user name". Read the terms of conditions and Privacy Policy and click the Accept button for each. Click the "sign by main address to register" button and Mpurse will pop up, then click the Sign button.
                  </div>
                </div>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    4. Buy Monacards
                  </div>
                  <div className='margin1'>
                    <img className='width35' src={imageManualPurchase04} />
                  </div>
                  <div>
                    Click the "go out" button or the monacotto logo to display the My Home list. Click on a My Home main image to enter a My Home and view a list of items for sale.<br/>
                    Click on the item you want to buy, click on the Mpurse button next to "main address," "address you pay from" and "address you receive cards in" to enter the same address for all of them. Enter the number of cards you want to buy. Click the "{words.signByMainAddress[state.language]}" button and Mpurse will pop up, then click the Sign button. If the signature is successful, you will see the "{words.sendMona[state.language]}" button, click it, confirm the remittance details, and then click "Send" button on Mpurse. Wait a few minutes and the Monacards will be sent to your Monacoin address in Mpurse.<span className='backgroundColorRed'>Failure to remit Monacoin within 2 hours will result in the expiration time and the purchase will be canceled.</span>
                  </div>
                </div>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    5. View the Monacards you bought
                  </div>
                  <div className='margin1'>
                    <img className='width35' src={imageManualPurchase05} />
                  </div>
                  <div>
                    Mpurse extension is useful to check the monacards sent. Once you access 
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        window.open('https://mpurse-extension.komikikaku.com/');
                      } }>
                      here 
                    </button>
                    and connect your Mpurse, you will see the monacards you are holding. Press the <img className='size1p7x1p7' src={imageManualPurchaseImageButton} /> button to view the images.
                  </div>
                </div>
              </div>
            </div>
            <div className='flexColumn borderMonacotto width98vw marginTopBottom1 padding1'>
              <div className='font2'>
                Sell Monacards
              </div>
              <div className='flexRow justifyContentSpaceAround margin1'>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    1. Install Mpurse
                  </div>
                  <div className='flexRow justifyContentCenter widthMax margin1' >
                    <img className='width14' src={imageManualMpurse10} />
                  </div>
                  <div>
                    Monacotto uses Mpurse as a wallet for Monacoin. Mpurse is a browser extension application and supports Chrome and Firefox. For information on installing Mpurse, please click 
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/howtousempurse' : '/howtousempurse'); 
                      } }>
                      here
                    </button>
                    .<br/>
                    <br/>
                    Please do not switch Mpurse accounts (addresses), as this section explains how to sell Monacards using a single address.
                  </div>
                </div>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    2. Prepare Monacoin
                  </div>
                  <div className='margin1'>
                    <img className='width35' src={imageManualMonachen} />
                  </div>
                  <div className='margin1'>
                    A very small amount of Monacoin is required to exhibit a Monacard.<br/>
                    <br/>
                    Send Monacoin to your Mpurse address. You can also obtain Monacoin on crypto asset exchanges, but with monachen, you can obtain Monacoin without using a crypto asset exchange.
                    For more information about monachen, please click 
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        window.open(`https://${process.env.REACT_APP_MONACHEN_MAIN_URL}?language=${state.language}`);
                      } }>
                      here
                    </button>
                    .
                  </div>
                </div>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    3. Prepare Monacards
                  </div>
                  <div className='margin1'>
                    <img className='width35' src={imageManualExhibit03} />
                  </div>
                  <div>
                    Send the monacard you want to exhibit to your Mpurse address.<br/>
                    <br/>
                    If you want to issue your own Monacards, 
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        window.open('https://mpurse-extension.komikikaku.com/');
                      } }>
                      Mpurse extension
                    </button>
                    is useful. With Mpurse extension, you can use features of monapalette, an ultra-functional general entertainment wallet. Please refer to 
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        window.open('https://opaque-toast-7ea.notion.site/fe5a2c309f3a495fa7a3399549cfe50c');
                      } }>
                      this
                    </button>
                     document(Japanese) and others for how to use it. (You may find other helpful articles by searching the Internet for keywords such as "monapalette" and "Monacard.")
                  </div>
                </div>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    4. Register as a user
                  </div>
                  <div className='margin1'>
                    <img className='width35' src={imageManualExhibit04} />
                  </div>
                  <div>
                    On the setup screen, click the Mpurse button next to "main address" to enter the address. Enter your "{words.userName[state.language]}." Select "{words.haveMyHome[state.language]}." (My Home is the space where you will display your Monacards.) Drag and drop the main image (My Home image). Enter your profile text. Read the terms of conditions and Privacy Policy and click the Accept button for each. Click the "sign by main address to register" button and Mpurse will pop up, then click the Sign button.
                  </div>
                </div>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    5. Exhibit Monacards
                  </div>
                  <div className='margin1'>
                    <img className='width35' src={imageManualExhibit05} />
                  </div>
                  <div>
                    On the exhibit screen, click the Mpurse button next to "{words.addressMain[state.language]}," "{words.addressCardFrom[state.language]}" and "{words.addressPayProceedsTo[state.language]}" to enter the same address for all of them. Enter the token name, the number of cards, and the unit price of the Monacards you want to sell. Click the "{words.signByMainAddress[state.language]}" button and Mpurse will pop up, then click the Sign button. If the signature is successful, the "{words.sendCards[state.language]}" button will appear. Click it, confirm the details of the sending, and then click "Send" button on Mpurse. After waiting a few minutes, the exhibited Monacards will appear on your My Home screen. 2 hours are required to remit the mona card or it will expire and the transaction will be canceled. <span className='backgroundColorRed'>Failure to send Monacards within 2 hours will result in the expiration time and the exhibit will be canceled.</span>
                  </div>
                </div>
                <div className='flexColumn width35 margin1'>
                  <div className=''>
                    6. Check proceeds
                  </div>
                  <div className='margin1'>
                    <img className='width35' src={imageManualExhibit06} />
                  </div>
                  <div>
                    When the Monacards you have exhibited are sold, the stock of the Monacards will be reduced in your My Home. After a while, the proceeds will be sent to your Mpurse address; check the balance in your Mpurse address.
                  </div>
                </div>
              </div>
            </div>
            <div className='flexColumn borderMonacotto margin1 padding1'>
              <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                onClick={ () => {
                  window.open('https://spotlight.soy/detail?article_id=ckkgeevvg');
                } }>
                Please also read the guidelines. (Japanese)
              </button>
            </div>
            <div className='flexColumn borderMonacotto margin1 padding1'>
              <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                onClick={ () => {
                  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/howtouseadvanced' : '/howtouseadvanced'); 
                } }>
                If you want to get more use out of it, click here!(FAQ)
              </button>
            </div>
          </div>
        </div>
        {/* SP */}
        <div className='visibleMiddleOrLess flexColumn alignItemsCenter' >
          <Header screen='howToUse' />
          <div className='flexColumn alignItemsCenter'>
            <div className='width93vw marginTopBottom1 '>
              <div>
                <span className='font2'>The easiest way to use monacotto</span> using only one address<br/>
              </div>
              <div className='marginTop1'>
                *We currently recommend using monacotto on a PC. Some parts of the site may not display properly or some functions may not work on smartphones.<br/>
                *Some of the screenshots are from a PC.
              </div>
            </div>
            <div className='flexColumn alignItemsCenter borderMonacotto width96vw marginTopBottom1 '>
              <div className='font2 width93vw'>
                Buy Monacards
              </div>
              <div className='flexColumn alignItemsCenter widthMax'>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1'>
                  <div className='widthMax'>
                    1. Install Mpurse
                  </div>
                  <div className='marginTopBottom1' >
                    <img className='width60vw' src={imageManualMpurseSp05} />
                  </div>
                  <div className='widthMax breakAll' >
                    Monacotto uses Mpurse as a wallet for Monacoin. For smartphones, the native application of Mpurse is the most common, but there is also a browser extension application for Firefox. For information on installing Mpurse, please click 
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/howtousempurse' : '/howtousempurse'); 
                      } }>
                      here
                    </button>
                    .<br/>
                    <br/>
                    Please do not switch Mpurse accounts (addresses), as this section explains how to make purchases using a single address.
                  </div>
                </div>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1'>
                  <div className='widthMax'>
                    2. Prepare Monacoin
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width90vw' src={imageManualMonachen} />
                  </div>
                  <div className='widthMax breakAll'>
                    Send Monacoin to your Mpurse address. You can also obtain Monacoin on crypto asset exchanges, but with monachen, you can obtain Monacoin without using a crypto asset exchange.
                    For more information about monachen, please click 
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        window.open(`https://${process.env.REACT_APP_MONACHEN_MAIN_URL}?language=${state.language}`);
                      } }>
                      here
                    </button>
                    .
                  </div>
                </div>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1'>
                  <div className='widthMax'>
                    3. Register as a user
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width90vw' src={imageManualPurchase03} />
                  </div>
                  <div className='widthMax breakAll'>
                    On the setup screen, click the Mpurse button below "main address" to enter the address. Enter your "user name". Read the terms of conditions and Privacy Policy and click the Accept button. Click the "sign by main address to register" button and Mpurse will pop up, then click the Sign button.
                  </div>
                </div>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1'>
                  <div className='widthMax'>
                    4. Buy Monacards
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width90vw' src={imageManualPurchase04} />
                  </div>
                  <div className='widthMax breakAll'>
                    Click the "go out" button or the monacotto logo to display the My Home list. Click on a My Home main image to enter a My Home and view a list of items for sale.<br/>
                    Click on the item you want to buy, click on the Mpurse buttons below "main address," "address you pay from" and "address you receive cards in" to enter the same address for all of them. Enter the number of cards you want to buy. Click the "{words.signByMainAddress[state.language]}" button and Mpurse will pop up, then click the Sign button. If the signature is successful, you will see the "{words.confirmTheDetailsOfSendingMona[state.language]}" button and "{words.sendMona[state.language]}" button, click them, confirm the remittance details, and then click "Send" button on Mpurse. Wait a few minutes and the Monacards will be sent to your Monacoin address in Mpurse.<span className='backgroundColorRed'>Failure to remit Monacoin within 2 hours will result in the expiration time and the purchase will be canceled.</span>
                  </div>
                </div>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1'>
                  <div className='widthMax'>
                    5. View the Monacards you bought
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width60vw' src={imageManualPurchaseSp01} />
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width60vw' src={imageManualPurchaseSp02} />
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width60vw' src={imageManualPurchaseSp03} />
                  </div>
                  <div className='widthMax breakAll'>
                    Mpurse extension is useful to check the monacards sent. Select "Browser" from the Mpurse application menu and click on "Mpurse Extension", then you will see the Monacards you are holding.
                    Press the <img className='size1p7x1p7' src={imageManualPurchaseImageButton} /> button to view the images.
                  </div>
                </div>
              </div>
            </div>
            <div className='flexColumn alignItemsCenter borderMonacotto width96vw marginTopBottom1 '>
              <div className='font2 width93vw'>
                Sell Monacards
              </div>
              <div className='flexColumn alignItemsCenter widthMax'>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1'>
                  <div className='widthMax'>
                    1. Install Mpurse
                  </div>
                  <div className='marginTopBottom1' >
                    <img className='width60vw' src={imageManualMpurseSp05} />
                  </div>
                  <div className='widthMax breakAll'>
                    Monacotto uses Mpurse as a wallet for Monacoin. For smartphones, the native application of Mpurse is the most common, but there is also a browser extension application for Firefox. For information on installing Mpurse, please click 
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/howtousempurse' : '/howtousempurse'); 
                      } }>
                      here
                    </button>
                    .<br/>
                    <br/>
                    Please do not switch Mpurse accounts (addresses), as this section explains how to sell Monacards using a single address.
                  </div>
                </div>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1'>
                  <div className='widthMax'>
                    2. Prepare Monacoin
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width90vw' src={imageManualMonachen} />
                  </div>
                  <div className='widthMax breakAll'>
                    A very small amount of Monacoin is required to exhibit a Monacard.<br/>
                    <br/>
                    Send Monacoin to your Mpurse address. You can also obtain Monacoin on crypto asset exchanges, but with monachen, you can obtain Monacoin without using a crypto asset exchange.
                    For more information about monachen, please click 
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        window.open(`https://${process.env.REACT_APP_MONACHEN_MAIN_URL}?language=${state.language}`);
                      } }>
                      here
                    </button>
                    .
                  </div>
                </div>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1'>
                  <div className='widthMax'>
                    3. Prepare Monacards
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width60vw' src={imageManualPurchaseSp01} />
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width60vw' src={imageManualPurchaseSp02} />
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width60vw' src={imageManualExhibitSP01} />
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width60vw' src={imageManualExhibitSP02} />
                  </div>
                  <div className='widthMax breakAll'>
                    Send the monacard you want to exhibit to your Mpurse address.<br/>
                    <br/>
                    If you want to issue your own Monacards, Mpurse extension is useful. Select "Browser" from the Mpurse application menu and click on "Mpurse Extension".
                    With Mpurse extension, you can use features of monapalette, an ultra-functional general entertainment wallet. Please refer to 
                    <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                      onClick={ () => {
                        window.open('https://opaque-toast-7ea.notion.site/fe5a2c309f3a495fa7a3399549cfe50c');
                      } }>
                      this
                    </button>
                     document(Japanese) and others for how to use it. (You may find other helpful articles by searching the Internet for keywords such as "monapalette" and "Monacard.")
                  </div>
                </div>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1'>
                  <div className='widthMax'>
                    4. Register as a user
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width90vw' src={imageManualExhibit04} />
                  </div>
                  <div className='widthMax breakAll'>
                    On the setup screen, click the Mpurse button below "main address" to enter the address. Enter your "{words.userName[state.language]}." Select "{words.haveMyHome[state.language]}." (My Home is the space where you will display your Monacards.) Drag and drop the main image (My Home image). Enter your profile text. Read the terms of conditions and Privacy Policy and click the Accept button. Click the "sign by main address to register" button and Mpurse will pop up, then click the Sign button.
                  </div>
                </div>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1'>
                  <div className='widthMax'>
                    5. Exhibit Monacards
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width90vw' src={imageManualExhibit05} />
                  </div>
                  <div className='widthMax breakAll'>
                    On the exhibit screen, click the Mpurse buttons below "{words.addressMain[state.language]}," "{words.addressCardFrom[state.language]}" and "{words.addressPayProceedsTo[state.language]}" to enter the same address for all of them. Enter the token name, the number of cards, and the unit price of the Monacards you want to sell. Click the "{words.signByMainAddress[state.language]}" button and Mpurse will pop up, then click the Sign button. If the signature is successful, the "{words.sendCards[state.language]}" button will appear. Click it, confirm the details of the sending, and then click "Send" button on Mpurse. After waiting a few minutes, the exhibited Monacards will appear on your My Home screen. 2 hours are required to remit the mona card or it will expire and the transaction will be canceled. <span className='backgroundColorRed'>Failure to send Monacards within 2 hours will result in the expiration time and the exhibit will be canceled.</span>
                  </div>
                </div>
                <div className='flexColumn alignItemsCenter width93vw marginTopBottom1'>
                  <div className='widthMax'>
                    6. Check proceeds
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width60vw' src={imageManualExhibitSP03} />
                  </div>
                  <div className='marginTopBottom1'>
                    <img className='width90vw' src={imageManualExhibit06} />
                  </div>
                  <div className='widthMax breakAll'>
                    When the Monacards you have exhibited are sold, the stock of the Monacards will be reduced in your My Home. After a while, the proceeds will be sent to your Mpurse address; select "Wallet" from the Mpurse application menu and check the balance in your Mpurse address.
                  </div>
                </div>
              </div>
            </div>
            <div className='flexColumn borderMonacotto mwidth90vw arginTopBottom1 padding1'>
              <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                onClick={ () => {
                  window.open('https://spotlight.soy/detail?article_id=ckkgeevvg');
                } }>
                Please also read the guidelines. (Japanese)
              </button>
            </div>
            <div className='flexColumn borderMonacotto width90vw marginTopBottom1 padding1'>
              <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                onClick={ () => {
                  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/howtouseadvanced' : '/howtouseadvanced'); 
                } }>
                If you want to get more use out of it, click here!(FAQ)
              </button>
            </div>
          </div>
        </div>
        {/* popup */}
        <Popup layer={0}/>
        <Popup layer={1}/>
      </div>
    );
  }
}

// HOW TO USE ADVANCED  
function HowToUseAdvanced() {
  const [state, dispatch, navigate] = useContext(GlobalState);

  useEffect( () => {
    window.scrollTo(0, 0);
  }, []);

  if (state.language === 'japanese') {
    return (
      <div>
        {/* PC */}
        <div className='visibleLargeOrMore' >
          <Header screen='howToUseAdvanced' />
          <div className='flexColumn alignItemsCenter'>
            <div className='widthMax marginTopBottom1 marginSide2'>
              <div className='font2'>
                もなこっとの一歩進んだ使い方
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                購入時に、メインアドレスとは異なるアドレスからモナコインを送る
              </div>
              <div className='margin1'>
                「代金送金元アドレス」にモナコインの送り元アドレスを入力してください。Mpurseのアドレスを切り替えて、Mpurseボタンをクリックすると入力されます。<br/>
                 全項目入力後の操作は、
                 <div className='flexColumn alignItemsFlexStart marginLeft1 marginTopBottom1'>
                   <div>
                     ・「メインアドレスで署名」ボタンをクリック(Mpurseのアドレスは、メインアドレスに設定してください。) → Mpurseで署名
                   </div>
                   <div>
                     ・「代金送金元アドレスで署名」ボタンをクリック(Mpurseのアドレスは、代金送金元アドレスに設定してください。) → Mpurseで署名<br/>
                     <div className='marginLeft1'>
                       <span className='backgroundColorRed '>メインアドレスで署名してから30分以内に行わないと期限切れとなり、購入はキャンセルとなります。</span>
                     </div>
                   </div>
                   <div>
                     ・「MONA送信」ボタンをクリック(Mpurseのアドレスは、代金送金元アドレスに設定してください。)
                   </div>
                   <div>
                     ・送金内容を確認のうえ、Mpurseの「送信」ボタンをクリック<br/>
                     <div className='marginLeft1'>
                       <span className='backgroundColorRed '>代金送金元アドレスで署名してから2時間以内に行わないと期限切れとなり、購入はキャンセルとなります。</span>
                     </div>
                   </div>
                 </div>
                 という流れになります。
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                購入時に、メインアドレスとは異なるアドレスでモナカードを受け取る
              </div>
              <div className='margin1'>
                「カード受取アドレス」にモナカードを受け取りたいアドレスを入力してください。Mpurseのアドレスを切り替えて、Mpurseボタンをクリックすると入力されます。<br/>
                 全項目入力後の操作は変わりません。
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                出品時に、メインアドレスとは異なるアドレスからモナカードを送る
              </div>
              <div className='margin1'>
                「カード送信元アドレス」にモナカードの送り元アドレスを入力してください。Mpurseのアドレスを切り替えて、Mpurseボタンをクリックすると入力されます。<br/>
                 全項目入力後の操作は、
                 <div className='flexColumn alignItemsFlexStart marginLeft1 marginTopBottom1'>
                   <div>
                     ・「メインアドレスで署名」ボタンをクリック(Mpurseのアドレスは、メインアドレスに設定してください。) → Mpurseで署名
                   </div>
                   <div>
                     ・「カード送信元アドレスで署名」ボタンをクリック(Mpurseのアドレスは、カード送信元アドレスに設定してください。) → Mpurseで署名<br/>
                     <div className='marginLeft1'>
                       <span className='backgroundColorRed marginLeft1'>メインアドレスで署名してから30分以内に行わないと期限切れとなり、出品はキャンセルとなります。</span>
                     </div>
                   </div>
                   <div>
                     ・「カード送信」ボタンをクリック(Mpurseのアドレスは、カード送信元アドレスに設定してください。)
                   </div>
                   <div>
                     ・送信内容を確認のうえ、Mpurseの「送信」ボタンをクリック<br/>
                     <div className='marginLeft1'>
                       <span className='backgroundColorRed marginLeft1'>カード送信元アドレスで署名してから2時間以内に行わないと期限切れとなり、出品はキャンセルとなります。</span>
                     </div>
                   </div>
                 </div>
                 という流れになります。
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                出品時に、メインアドレスとは異なるアドレスで売上を受け取る設定をする
              </div>
              <div className='margin1'>
                「売上受取アドレス」に売上を受け取りたいアドレスを入力してください。Mpurseのアドレスを切り替えて、Mpurseボタンをクリックすると入力されます。<br/>
                 全項目入力後の操作は変わりません。
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                メインアドレスとは異なる自分のアドレスで発行したモナカードを出品する
              </div>
              <div className='margin1'>
                もなこっとでは、初期状態では、メインアドレスで発行したモナカードしか出品することができません。他のアドレスで発行したモナカードを出品するためには、発行アドレス(オーナーアドレス)からメインアドレスに対して、出品許可を与える必要があります。<br/>
                <br/>
                <span className='backgroundColorRed '>出品許可を与えるためには、発行アドレスでのユーザー登録が必要になりますので、設定画面で登録してください。</span><br/>
                次に、出品許可画面の「出品許可を与える」の各項目を以下のとおり入力してください。
                 <div className='flexColumn alignItemsFlexStart marginLeft1 marginTopBottom1'>
                   <div>
                     ・オーナーアドレス： モナカードを発行したアドレス
                   </div>
                   <div>
                     ・出品許可先アドレス： モナカードを出品したいメインアドレス
                   </div>
                   <div>
                     ・ロイヤリティ受取アドレス： 空欄のまま
                   </div>
                   <div>
                     ・ロイヤリティ・パーセンテージ： 0のまま
                   </div>
                 </div>
                 入力後、Mpurseのアドレスをオーナーアドレスに設定して署名してください。
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                他者のアドレスで発行したモナカードを出品する
              </div>
              <div className='margin1'>
                他者の発行アドレスから、自分のメインアドレスに対して出品許可を与えてもらう必要があります。そのために、他者に対して出品許可リクエストを行います。<br/>
                他者があなたに出品許可する際に、ロイヤリティ・パーセンテージを設定することができます。この場合、モナカードが売れた際に、表示価格 × ロイヤリティ・パーセンテージ × 0.01 のロイヤリティがモナカード発行者に支払われます。<br/>
                <br/>
                出品許可画面の「出品許可をリクエストする」の各項目を以下のとおり入力してください。
                 <div className='flexColumn alignItemsFlexStart marginLeft1 marginTopBottom1'>
                   <div>
                     ・メインアドレス： モナカードを出品したいメインアドレス
                   </div>
                   <div>
                     ・オーナーアドレス： モナカードを発行したアドレス(リクエストの宛先)
                   </div>
                   <div>
                     ・ロイヤリティ・パーセンテージ： 提案するロイヤリティ・パーセンテージ(相手は提案％を変更して許可する事が可能。)
                   </div>
                 </div>
                 入力後、Mpurseのアドレスをメインアドレスに設定して署名してください。
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                自分が発行したモナカードを他人が出品することを許可する
              </div>
              <div className='margin1'>
                他者から自分のアドレス宛に、出品許可リクエストが来ることがあります。<br/>
                <span className='backgroundColorRed '>出品許可リクエストを検索したり出品許可を与えるためには、対象となるアドレスでのユーザー登録が必要になりますので、設定画面で登録してください。</span><br/>
                <br/>
                出品許可画面の検索機能で、「許可リクエストされた」を選択し、メインアドレスにリクエストが来ているか調べたいアドレスを入力し、検索ボタンをクリックします。<br/>
                リクエストが来ている場合、その内容が表示されます。右端のハンドシェイクボタンをクリックすると、「出品許可を与える」の各項目にリクエスト内容がコピーされます。<br/>
                出品を許可する場合は、「ロイヤリティ受取アドレス」にロイヤリティを受け取りたいアドレスを入力し、必要に応じてロイヤリティ・パーセンテージを変更し、Mpurseのアドレスをオーナーアドレスに設定して署名・登録してください。<br/>
                <br/>
                出品許可リクエストが来ていなくても、他者に出品許可を与えることができます。<br/>
                この場合、各項目は、以下の文に当てはめて考えてください。<br/>
                <br/>
               「オーナーアドレス」で発行したモナカードを「出品許可先アドレス」が出品することを許可する。モナカードが売れたらロイヤリティ・パーセンテージ分のロイヤリティを「ロイヤリティ受取アドレス」で受け取る。
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                自分のアドレスの署名を、他の自分のアドレスに委任する。
              </div>
              <div className='margin1'>
                もなこっとを複数アドレスで利用する場合、署名するアドレスを変更するために、Mpurseのアドレスの切り替えが必要になる場面があります。<br/>
                2つの異なるアドレスで署名を行う場合、片方の署名をもう一方に委任することで、後者の署名だけで済ませることができます。<br/>
                <span className='backgroundColorRed '>モナコインの送信とモナカードの送信は他のアドレスに委任できない</span>ので、代金送金元アドレス、カード送信元アドレスを委任先アドレスにするのが基本的な使い方になります。<br/>
                <span className='backgroundColorRed '>署名委任を行うには、委任元アドレス、委任先アドレスでのユーザー登録が必要になりますので、設定画面で登録してください。</span><br/>
                出品許可・署名委任画面の「署名委任」で、「委任する」を選択し、各項目を以下のとおり入力してください。
                 <div className='flexColumn alignItemsFlexStart marginLeft1 marginTopBottom1'>
                   <div>
                     ・委任元アドレス： 署名を任せる元のアドレス
                   </div>
                   <div>
                     ・委任先アドレス： 署名を任せる先のアドレス(他のアドレスの署名を引き受けて、実際に署名行為をする側。)
                   </div>
                 </div>
                 入力後、Mpurseのアドレスを委任元アドレスに設定して署名してください。次に、「受任する」を選択し、Mpurseのアドレスを委任先アドレスに設定して署名してください。<br/>
                 委任および受任が完了すると、署名委任は有効となります。<br/>
                 なお、委任および受任の途中であっても、「委任した」「委任された」の検索が可能です。
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                モナパレットのアドレスを使う
              </div>
              <div className='margin1'>
                モナパレットのアドレス(秘密鍵)をMpurseにインポートすると、モナパレットのアドレスをもなこっとで利用できるようになります。<br/>
                詳細は、
                <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                  onClick={ () => {
                    window.open('https://spotlight.soy/detail?article_id=avg2yiq6d');
                  } }>
                  こちら
                </button>
                をご参照ください。
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                出品したモナカードの並べ替えをする
              </div>
              <div className='margin1'>
                メインアドレスでログインしている状態でマイホームに入ると、出品中のモナカードの上に左右の矢印が表示され、これをクリックすることでモナカードの並べ替えができます。<br/>
                並べ替えた配置を確定するには、「並べ替えを確定する」ボタンをクリックしてください。
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                ユーザー情報を変更する
              </div>
              <div className='margin1'>
                設定画面で登録したユーザー情報を変更する場合、一度ログインボタンをクリックして現在の登録内容を読み込んでください。<br/>
                その後、内容を変更し、Mpurseのアドレスをメインアドレスに設定して署名・登録してください。<br/>
                <br/>
                画像の更新の反映には多少時間がかかります。また、ブラウザのキャッシュクリアもお試し下さい。
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                デフォルトアドレスの設定をする
              </div>
              <div className='margin1'>
                設定画面で、「カード送信アドレス」「売上受取アドレス」「代金送金アドレス」「カード受取アドレス」のデフォルト設定ができます。<br/>
                デフォルトアドレスの設定をすると、購入時、出品時にデフォルトボタンをクリックすることで、各アドレスを簡単に入力することができます。
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                出品履歴を確認する
              </div>
              <div className='margin1'>
                履歴画面で「出品履歴」を選択し、メインアドレスを入力し、検索ボタンをクリックすることで、いままで出品した履歴が表示されます。右端の「・・・」ボタンをクリックすると詳細が表示されます。<br/>
                <br/>
                近いうちに、出品に紐づく購入の明細を表示する機能を追加する予定です。(購入者のユーザー名、メインアドレス、送金TXID等も表示します。)
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                購入履歴を確認する
              </div>
              <div className='margin1'>
                履歴画面で「購入履歴」を選択し、メインアドレスを入力し、検索ボタンをクリックすることで、いままで購入した履歴が表示されます。右端の「・・・」ボタンをクリックすると詳細が表示されます。<br/>
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                出品が途中なので、再開したい
              </div>
              <div className='margin1'>
                出品履歴を検索してください。<br/>
                <br/>
                出品履歴でステータスが「署名待ち」のものは、「カード送信元アドレス」による署名を待っている状態です。「・・・」ボタンをクリックし、Mpurseのアドレスをカード送信元アドレスに設定して、「カード送信元アドレスで署名する」ボタンをクリックしてください。その後、「カード送信」ボタンをクリックし、送信内容を確認のうえ、モナカードを送信してください。
                <br/>
                出品履歴でステータスが「カード送付待ち」のものは、モナカードの送信を待っている状態です。「・・・」ボタンをクリックし、Mpurseのアドレスをカード送信元アドレスに設定して、「カード送信」ボタンをクリックし、送信内容を確認のうえ、モナカードを送信してください。
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                購入が途中なので、再開したい
              </div>
              <div className='margin1'>
                購入履歴を検索してください。<br/>
                <br/>
                購入履歴でステータスが「署名待ち」のものは、「代金送金元アドレス」による署名を待っている状態です。「・・・」ボタンをクリックし、Mpurseのアドレスを代金送金元アドレスに設定して、「代金送金元アドレスで署名する」ボタンをクリックしてください。その後、「MONA送信」ボタンをクリックし、送金内容を確認のうえ、モナコインを送信してください。<br/>
                <br/>
                購入履歴でステータスが「MONA待ち」のものは、モナコインの送金を待っている状態です。「・・・」ボタンをクリックし、Mpurseのアドレスを代金送金元アドレスに設定して、「MONA送信」ボタンをクリックし、送金内容を確認のうえ、モナコインを送信してください。
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                出品をキャンセルしたい
              </div>
              <div className='margin1'>
                出品履歴を検索してください。<br/>
                <br/>
                出品履歴でステータスが「販売中」のものは、キャンセルが可能です。「・・・」ボタンをクリックし、「キャンセル」を選択し、Mpurseのアドレスをメインアドレスに設定して、「メインアドレスで署名する」ボタンをクリックしてください。
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                出品しているモナカードの価格、売上受取アドレスを変更したい
              </div>
              <div className='margin1'>
                もなこっとでは、出品内容の変更はできず、代わりにキャンセル＆再出品という手続きを行います。<br/>
                <br/>
                出品履歴を検索してください。<br/>
                <br/>
                出品履歴でステータスが「販売中」のものは、キャンセル＆再出品が可能です。「・・・」ボタンをクリックし、「キャンセル＆再出品」を選択してください。売上受取アドレスと価格(単価)を変更し、Mpurseのアドレスをメインアドレスに設定して、「メインアドレスで署名する」ボタンをクリックしてください。<br/>
                当出品はキャンセルされ、新しい出品に引き継がれます。
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                完売したモナカードはいつマイホームから消えるのか？
              </div>
              <div className='margin1'>
                完売から約3日後に非表示となります。
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                メッセージを見逃したのでもう一度表示させてほしい。
              </div>
              <div className='margin1'>
                もなこっとロゴの隣の青いヒヨコ「こっとちゃん」をクリックしてください。直前のメッセージが表示されます。<br/>
                また、メッセージの上の矢印をクリックすることで、過去のメッセージも表示できます。
              </div>
            </div>
          </div>
        </div>
        {/* SP */}
        <div className='visibleMiddleOrLess' >
          <Header screen='howToUseAdvanced' />
          <div className='flexColumn alignItemsCenter'>
            <div className='flexColumn alignItemsCenter width93vw marginTopBottom1 '>
              <div className='font2 '>
                もなこっとの一歩進んだ使い方
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                購入時に、メインアドレスとは異なるアドレスからモナコインを送る
              </div>
              <div className='width93vw'>
                「代金送金元アドレス」にモナコインの送り元アドレスを入力してください。Mpurseのアドレスを切り替えて、Mpurseボタンをクリックすると入力されます。<br/>
                 全項目入力後の操作は、
                 <div className='flexColumn alignItemsFlexStart marginLeft1 marginTopBottom1'>
                   <div>
                     ・「メインアドレスで署名」ボタンをクリック(Mpurseのアドレスは、メインアドレスに設定してください。) → Mpurseで署名
                   </div>
                   <div>
                     ・「代金送金元アドレスで署名」ボタンをクリック(Mpurseのアドレスは、代金送金元アドレスに設定してください。) → Mpurseで署名<br/>
                     <div className='marginLeft1'>
                       <span className='backgroundColorRed '>メインアドレスで署名してから30分以内に行わないと期限切れとなり、購入はキャンセルとなります。</span>
                     </div>
                   </div>
                   <div>
                     ・「MONA送信」ボタンをクリック(Mpurseのアドレスは、代金送金元アドレスに設定してください。)
                   </div>
                   <div>
                     ・送金内容を確認のうえ、Mpurseの「送信」ボタンをクリック<br/>
                     <div className='marginLeft1'>
                       <span className='backgroundColorRed '>代金送金元アドレスで署名してから2時間以内に行わないと期限切れとなり、購入はキャンセルとなります。</span>
                     </div>
                   </div>
                 </div>
                 という流れになります。
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                購入時に、メインアドレスとは異なるアドレスでモナカードを受け取る
              </div>
              <div className='width93vw'>
                「カード受取アドレス」にモナカードを受け取りたいアドレスを入力してください。Mpurseのアドレスを切り替えて、Mpurseボタンをクリックすると入力されます。<br/>
                 全項目入力後の操作は変わりません。
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                出品時に、メインアドレスとは異なるアドレスからモナカードを送る
              </div>
              <div className='width93vw'>
                「カード送信元アドレス」にモナカードの送り元アドレスを入力してください。Mpurseのアドレスを切り替えて、Mpurseボタンをクリックすると入力されます。<br/>
                 全項目入力後の操作は、
                 <div className='flexColumn alignItemsFlexStart marginLeft1 marginTopBottom1'>
                   <div>
                     ・「メインアドレスで署名」ボタンをクリック(Mpurseのアドレスは、メインアドレスに設定してください。) → Mpurseで署名
                   </div>
                   <div>
                     ・「カード送信元アドレスで署名」ボタンをクリック(Mpurseのアドレスは、カード送信元アドレスに設定してください。) → Mpurseで署名<br/>
                     <div className='marginLeft1'>
                       <span className='backgroundColorRed marginLeft1'>メインアドレスで署名してから30分以内に行わないと期限切れとなり、出品はキャンセルとなります。</span>
                     </div>
                   </div>
                   <div>
                     ・「カード送信」ボタンをクリック(Mpurseのアドレスは、カード送信元アドレスに設定してください。)
                   </div>
                   <div>
                     ・送信内容を確認のうえ、Mpurseの「送信」ボタンをクリック<br/>
                     <div className='marginLeft1'>
                       <span className='backgroundColorRed marginLeft1'>カード送信元アドレスで署名してから2時間以内に行わないと期限切れとなり、出品はキャンセルとなります。</span>
                     </div>
                   </div>
                 </div>
                 という流れになります。
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                出品時に、メインアドレスとは異なるアドレスで売上を受け取る設定をする
              </div>
              <div className='width93vw'>
                「売上受取アドレス」に売上を受け取りたいアドレスを入力してください。Mpurseのアドレスを切り替えて、Mpurseボタンをクリックすると入力されます。<br/>
                 全項目入力後の操作は変わりません。
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                メインアドレスとは異なる自分のアドレスで発行したモナカードを出品する
              </div>
              <div className='width93vw'>
                もなこっとでは、初期状態では、メインアドレスで発行したモナカードしか出品することができません。他のアドレスで発行したモナカードを出品するためには、発行アドレス(オーナーアドレス)からメインアドレスに対して、出品許可を与える必要があります。<br/>
                <br/>
                <span className='backgroundColorRed '>出品許可を与えるためには、発行アドレスでのユーザー登録が必要になりますので、設定画面で登録してください。</span><br/>
                次に、出品許可画面の「出品許可を与える」の各項目を以下のとおり入力してください。
                 <div className='flexColumn alignItemsFlexStart marginLeft1 marginTopBottom1'>
                   <div>
                     ・オーナーアドレス： モナカードを発行したアドレス
                   </div>
                   <div>
                     ・出品許可先アドレス： モナカードを出品したいメインアドレス
                   </div>
                   <div>
                     ・ロイヤリティ受取アドレス： 空欄のまま
                   </div>
                   <div>
                     ・ロイヤリティ・パーセンテージ： 0のまま
                   </div>
                 </div>
                 入力後、Mpurseのアドレスをオーナーアドレスに設定して署名してください。
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                他者のアドレスで発行したモナカードを出品する
              </div>
              <div className='width93vw'>
                他者の発行アドレスから、自分のメインアドレスに対して出品許可を与えてもらう必要があります。そのために、他者に対して出品許可リクエストを行います。<br/>
                他者があなたに出品許可する際に、ロイヤリティ・パーセンテージを設定することができます。この場合、モナカードが売れた際に、表示価格 × ロイヤリティ・パーセンテージ × 0.01 のロイヤリティがモナカード発行者に支払われます。<br/>
                <br/>
                出品許可画面の「出品許可をリクエストする」の各項目を以下のとおり入力してください。
                 <div className='flexColumn alignItemsFlexStart marginLeft1 marginTopBottom1'>
                   <div>
                     ・メインアドレス： モナカードを出品したいメインアドレス
                   </div>
                   <div>
                     ・オーナーアドレス： モナカードを発行したアドレス(リクエストの宛先)
                   </div>
                   <div>
                     ・ロイヤリティ・パーセンテージ： 提案するロイヤリティ・パーセンテージ(相手は提案％を変更して許可する事が可能。)
                   </div>
                 </div>
                 入力後、Mpurseのアドレスをメインアドレスに設定して署名してください。
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                自分が発行したモナカードを他人が出品することを許可する
              </div>
              <div className='width93vw'>
                他者から自分のアドレス宛に、出品許可リクエストが来ることがあります。<br/>
                <span className='backgroundColorRed '>出品許可リクエストを検索したり出品許可を与えるためには、対象となるアドレスでのユーザー登録が必要になりますので、設定画面で登録してください。</span><br/>
                <br/>
                出品許可画面の検索機能で、「許可リクエストされた」を選択し、メインアドレスにリクエストが来ているか調べたいアドレスを入力し、検索ボタンをクリックします。<br/>
                リクエストが来ている場合、その内容が表示されます。右端のハンドシェイクボタンをクリックすると、「出品許可を与える」の各項目にリクエスト内容がコピーされます。<br/>
                出品を許可する場合は、「ロイヤリティ受取アドレス」にロイヤリティを受け取りたいアドレスを入力し、必要に応じてロイヤリティ・パーセンテージを変更し、Mpurseのアドレスをオーナーアドレスに設定して署名・登録してください。<br/>
                <br/>
                出品許可リクエストが来ていなくても、他者に出品許可を与えることができます。<br/>
                この場合、各項目は、以下の文に当てはめて考えてください。<br/>
                <br/>
               「オーナーアドレス」で発行したモナカードを「出品許可先アドレス」が出品することを許可する。モナカードが売れたらロイヤリティ・パーセンテージ分のロイヤリティを「ロイヤリティ受取アドレス」で受け取る。
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                自分のアドレスの署名を、他の自分のアドレスに委任する。
              </div>
              <div className='width93vw'>
                もなこっとを複数アドレスで利用する場合、署名するアドレスを変更するために、Mpurseのアドレスの切り替えが必要になる場面があります。<br/>
                2つの異なるアドレスで署名を行う場合、片方の署名をもう一方に委任することで、後者の署名だけで済ませることができます。<br/>
                <span className='backgroundColorRed '>モナコインの送信とモナカードの送信は他のアドレスに委任できない</span>ので、代金送金元アドレス、カード送信元アドレスを委任先アドレスにするのが基本的な使い方になります。<br/>
                <span className='backgroundColorRed '>署名委任を行うには、委任元アドレス、委任先アドレスでのユーザー登録が必要になりますので、設定画面で登録してください。</span><br/>
                出品許可・署名委任画面の「署名委任」で、「委任する」を選択し、各項目を以下のとおり入力してください。
                 <div className='flexColumn alignItemsFlexStart marginLeft1 marginTopBottom1'>
                   <div>
                     ・委任元アドレス： 署名を任せる元のアドレス
                   </div>
                   <div>
                     ・委任先アドレス： 署名を任せる先のアドレス(他のアドレスの署名を引き受けて、実際に署名行為をする側。)
                   </div>
                 </div>
                 入力後、Mpurseのアドレスを委任元アドレスに設定して署名してください。次に、「受任する」を選択し、Mpurseのアドレスを委任先アドレスに設定して署名してください。<br/>
                 委任および受任が完了すると、署名委任は有効となります。<br/>
                 なお、委任および受任の途中であっても、「委任した」「委任された」の検索が可能です。
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                モナパレットのアドレスを使う
              </div>
              <div className='width93vw'>
                モナパレットのアドレス(秘密鍵)をMpurseにインポートすると、モナパレットのアドレスをもなこっとで利用できるようになります。<br/>
                詳細は、
                <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                  onClick={ () => {
                    window.open('https://spotlight.soy/detail?article_id=avg2yiq6d');
                  } }>
                  こちら
                </button>
                をご参照ください。
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                出品したモナカードの並べ替えをする
              </div>
              <div className='width93vw'>
                メインアドレスでログインしている状態でマイホームに入ると、出品中のモナカードの上に左右の矢印が表示され、これをクリックすることでモナカードの並べ替えができます。<br/>
                並べ替えた配置を確定するには、「並べ替えを確定する」ボタンをクリックしてください。
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                ユーザー情報を変更する
              </div>
              <div className='width93vw'>
                設定画面で登録したユーザー情報を変更する場合、一度ログインボタンをクリックして現在の登録内容を読み込んでください。<br/>
                その後、内容を変更し、Mpurseのアドレスをメインアドレスに設定して署名・登録してください。<br/>
                <br/>
                画像の更新の反映には多少時間がかかります。また、ブラウザのキャッシュクリアもお試し下さい。
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                デフォルトアドレスの設定をする
              </div>
              <div className='width93vw'>
                設定画面で、「カード送信アドレス」「売上受取アドレス」「代金送金アドレス」「カード受取アドレス」のデフォルト設定ができます。<br/>
                デフォルトアドレスの設定をすると、購入時、出品時にデフォルトボタンをクリックすることで、各アドレスを簡単に入力することができます。
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                出品履歴を確認する
              </div>
              <div className='width93vw'>
                履歴画面で「出品履歴」を選択し、メインアドレスを入力し、検索ボタンをクリックすることで、いままで出品した履歴が表示されます。右端の「・・・」ボタンをクリックすると詳細が表示されます。<br/>
                <br/>
                近いうちに、出品に紐づく購入の明細を表示する機能を追加する予定です。(購入者のユーザー名、メインアドレス、送金TXID等も表示します。)
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                購入履歴を確認する
              </div>
              <div className='width93vw'>
                履歴画面で「購入履歴」を選択し、メインアドレスを入力し、検索ボタンをクリックすることで、いままで購入した履歴が表示されます。右端の「・・・」ボタンをクリックすると詳細が表示されます。<br/>
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                出品が途中なので、再開したい
              </div>
              <div className='width93vw'>
                出品履歴を検索してください。<br/>
                <br/>
                出品履歴でステータスが「署名待ち」のものは、「カード送信元アドレス」による署名を待っている状態です。「・・・」ボタンをクリックし、Mpurseのアドレスをカード送信元アドレスに設定して、「カード送信元アドレスで署名する」ボタンをクリックしてください。その後、「カード送信」ボタンをクリックし、送信内容を確認のうえ、モナカードを送信してください。
                <br/>
                出品履歴でステータスが「カード送付待ち」のものは、モナカードの送信を待っている状態です。「・・・」ボタンをクリックし、Mpurseのアドレスをカード送信元アドレスに設定して、「カード送信」ボタンをクリックし、送信内容を確認のうえ、モナカードを送信してください。
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                購入が途中なので、再開したい
              </div>
              <div className='width93vw'>
                購入履歴を検索してください。<br/>
                <br/>
                購入履歴でステータスが「署名待ち」のものは、「代金送金元アドレス」による署名を待っている状態です。「・・・」ボタンをクリックし、Mpurseのアドレスを代金送金元アドレスに設定して、「代金送金元アドレスで署名する」ボタンをクリックしてください。その後、「MONA送信」ボタンをクリックし、送金内容を確認のうえ、モナコインを送信してください。<br/>
                <br/>
                購入履歴でステータスが「MONA待ち」のものは、モナコインの送金を待っている状態です。「・・・」ボタンをクリックし、Mpurseのアドレスを代金送金元アドレスに設定して、「MONA送信」ボタンをクリックし、送金内容を確認のうえ、モナコインを送信してください。
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                出品をキャンセルしたい
              </div>
              <div className='width93vw'>
                出品履歴を検索してください。<br/>
                <br/>
                出品履歴でステータスが「販売中」のものは、キャンセルが可能です。「・・・」ボタンをクリックし、「キャンセル」を選択し、Mpurseのアドレスをメインアドレスに設定して、「メインアドレスで署名する」ボタンをクリックしてください。
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                出品しているモナカードの価格、売上受取アドレスを変更したい
              </div>
              <div className='width93vw'>
                もなこっとでは、出品内容の変更はできず、代わりにキャンセル＆再出品という手続きを行います。<br/>
                <br/>
                出品履歴を検索してください。<br/>
                <br/>
                出品履歴でステータスが「販売中」のものは、キャンセル＆再出品が可能です。「・・・」ボタンをクリックし、「キャンセル＆再出品」を選択してください。売上受取アドレスと価格(単価)を変更し、Mpurseのアドレスをメインアドレスに設定して、「メインアドレスで署名する」ボタンをクリックしてください。<br/>
                当出品はキャンセルされ、新しい出品に引き継がれます。
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                完売したモナカードはいつマイホームから消えるのか？
              </div>
              <div className='width93vw'>
                完売から約3日後に非表示となります。
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                メッセージを見逃したのでもう一度表示させてほしい。
              </div>
              <div className='width93vw'>
                もなこっとロゴの隣の青いヒヨコ「こっとちゃん」をクリックしてください。直前のメッセージが表示されます。<br/>
                また、メッセージの上の矢印をクリックすることで、過去のメッセージも表示できます。
              </div>
            </div>
          </div>
        </div>
        {/* popup */}
        <Popup layer={0}/>
        <Popup layer={1}/>
      </div>
    );
  }
  else // english
  {
    return (
      <div>
        {/* PC */}
        <div className='visibleLargeOrMore' >
          <Header screen='howToUseAdvanced' />
          <div className='flexColumn alignItemsCenter'>
            <div className='widthMax marginTopBottom1 marginSide2'>
              <div className='font2'>
                How to use Monacotto one step further
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                Send Monacoin from a different address than your main address when you purchase.
              </div>
              <div className='margin1'>
                 Enter the address from which Monacoin is sent in "{words.addressPayFrom[state.language]}" field. Switch the Mpurse address and click Mpurse button to enter it.<br/>
                 After all fields have been filled in, do the following.
                 <div className='flexColumn alignItemsFlexStart marginLeft1 marginTopBottom1'>
                   <div>
                     - Click "{words.signByMainAddress[state.language]}" button and sign with Mpurse. (Mpurse address must be set as your main address.) 
                   </div>
                   <div>
                     - Click "{words.signByAddressYouPayFrom[state.language]}" button and sign with Mpurse. (Mpurse address must be set as "{words.addressPayFrom[state.language]}".) 
                     <div className='marginLeft1'>
                       <span className='backgroundColorRed '>Failure to do so within 30 minutes of signing by the main address will result in expiration and the purchase will be canceled.</span>
                     </div>
                   </div>
                   <div>
                     - Click "{words.sendMona[state.language]}" button. (Mpurse address must be set as "{words.addressPayFrom[state.language]}".) 
                   </div>
                   <div>
                     - Confirm the remittance details, and click "Send" button on Mpurse.
                     <div className='marginLeft1'>
                       <span className='backgroundColorRed '>Failure to do so within 2 hours of signing by "{words.addressPayFrom[state.language]}" will result in expiration and the purchase will be canceled.</span>
                     </div>
                   </div>
                 </div>
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                Receive Monacards in a different address than your main address when you purchase.
              </div>
              <div className='margin1'>
                 Enter the address where you want to receive the Monacards in the "{words.addressSendCardTo[state.language]}" field. Switch the Mpurse address and click Mpurse button to enter it.<br/>
                 After all fields have been filled in, do the same.
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                Send the monacards from a different address than your main address when you exhibit.
              </div>
              <div className='margin1'>
                 Enter the address from which the Monacards is sent in "{words.addressCardFrom[state.language]}" field. Switch the Mpurse address and click Mpurse button to enter it.<br/>
                 After all fields have been filled in, do the following.
                 <div className='flexColumn alignItemsFlexStart marginLeft1 marginTopBottom1'>
                   <div>
                     - Click "{words.signByMainAddress[state.language]}" button and sign with Mpurse. (Mpurse address must be set as your main address.) 
                   </div>
                   <div>
                     - Click "{words.signByAddressYouSendCardsFrom[state.language]}" button and sign with Mpurse. (Mpurse address must be set as "{words.addressCardFrom[state.language]}".) 
                     <div className='marginLeft1'>
                       <span className='backgroundColorRed '>Failure to do so within 30 minutes of signing by the main address will result in expiration and the exhibit will be canceled.</span>
                     </div>
                   </div>
                   <div>
                     - Click "{words.sendCards[state.language]}" button. (Mpurse address must be set as "{words.addressCardFrom[state.language]}".) 
                   </div>
                   <div>
                     - Confirm the sending details, and click "Send" button on Mpurse.
                     <div className='marginLeft1'>
                       <span className='backgroundColorRed '>Failure to do so within 2 hours of signing by "{words.addressCardFrom[state.language]}" will result in expiration and the exhibit will be canceled.</span>
                     </div>
                   </div>
                 </div>
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                Set up to receive proceeds in a different address than your main address when you exhibit.
              </div>
              <div className='margin1'>
                 Enter the address where you want to receive proceeds in the "{words.addressPayProceedsTo[state.language]}" field. Switch the Mpurse address and click Mpurse button to enter it.<br/>
                 After all fields have been filled in, do the same.
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                Exhibit Monacards issued by your own address that is different from your main address.
              </div>
              <div className='margin1'>
                By default, you can exhibit Monacards only issued by your main address in monacotto. In order to exhibit Monacards issued by another address, the issuing address(owner address) must give permission to the main address to exhibit the cards.<br/>
                <br/>
                <span className='backgroundColorRed '>In order to grant permission to exhibit, you will need to be registered as a user with the issuing address, so please register on the setup page.</span><br/>
                Next, fill in each field of "{words.grantPermissionOfExhibit[state.language]}" section on the permission screen as follows.
                 <div className='flexColumn alignItemsFlexStart marginLeft1 marginTopBottom1'>
                   <div>
                     - {words.addressOwner[state.language]}: The address where the Monacards were issued.
                   </div>
                   <div>
                     - {words.addressPermitTo[state.language]}: The main address where you want to exhibit the Monacards.
                   </div>
                   <div>
                     - {words.addressPayRoyaltyTo[state.language]}: Leave blank.
                   </div>
                   <div>
                     - {words.royaltyPercentage[state.language]}: Leave 0.
                   </div>
                 </div>
                 After all fields have been filled in, set the Mpurse address as {words.addressOwner[state.language]} and sign it.
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                Exhibit Monacards issued by someone else's address.
              </div>
              <div className='margin1'>
                You need to ask others to grant you permission to exhibit from their issuing address to your main address. To do so, make a exhibit permission request to the other.<br/>
                They can set a royalty percentage when they allow you to exhibit their Monacards on your My Home. In this case, the Monacard issuer(owner) will receive a royalty of the displayed price x royalty percentage x 0.01 when the Monacard is sold.<br/>
                <br/>
                Fill in each field of "{words.requestPermissionOfExhibit[state.language]}" section on the permission screen as follows.
                 <div className='flexColumn alignItemsFlexStart marginLeft1 marginTopBottom1'>
                   <div>
                     - {words.addressMain[state.language]}: The main address where you want to exhibit the Monacards.
                   </div>
                   <div>
                     - {words.addressOwner[state.language]}: The address where the Monacards were issued.(Destination of the request)
                   </div>
                   <div>
                     - {words.royaltyPercentage[state.language]}: the royalty percentage you propose. (the other can change the proposed % when he/she grant you permission.)
                   </div>
                 </div>
                 After all fields have been filled in, set the Mpurse address as {words.addressMain[state.language]} and sign it.
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                Allow others to exhibit Monacards issued by you.
              </div>
              <div className='margin1'>
                You may receive a request for permission to exhibit your Monacards from someone else to your address.<br/>
                <span className='backgroundColorRed '>In order to search for requests and grant permission to exhibit, you will need to be registered as a user with the target address, so please register on the setup screen.</span><br/>
                <br/>
                In the search function on the permission screen, select "{words.requestedPermission[state.language]}, " enter the address you want to check to see if a request has been received in "{words.addressMain[state.language]}", and click the Search button.<br/>
                If a request has been received, its details will be displayed. Click the handshake button on the far right to copy the details of the request to each field in the "{words.grantPermissionOfExhibit[state.language]}" section.<br/>
                To allow to exhibit, enter the address where you want to receive royalties in "{words.addressPayRoyaltyTo[state.language]}" field, change the royalty percentage if necessary, set the Mpurse address as "{words.addressOwner[state.language]}," sign it and register.<br/>
                <br/>
                You can grant permission to exhibit to others even if you have not received a request.<br/>
                In this case, each field should be considered in terms of the following statement.<br/>
                <br/>
                I permits "{words.addressPermitTo[state.language]}" to exhibit the Monacards issued by "{words.addressOwner[state.language]}". I will receive royalties for "{words.royaltyPercentage[state.language]}" in "{words.addressPayRoyaltyTo[state.language]}" when the Monacards are sold.
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                Delegate the signature of your address to another of your addresses.
              </div>
              <div className='margin1'>
                When using monacotto with multiple addresses, there are situations where you will need to switch the Mpurse address in order to change the address to sign with.<br/>
                When signing with two different addresses, one signature can be delegated to the other so that only the latter signature is required.<br/>
                <span className='backgroundColorRed '>Since sending Monacoins and sending Monacards cannot be delegated to other addresses,</span> the basic usage is to use the payment source address and card sending source address as {words.addressDelegateTo[state.language]}.<br/>
                <span className='backgroundColorRed '>In order to perform signature delegation, you will need to be registered as a user with the delegation source address and the delegated address, so please register on the setup screen.</span><br/>
                In the "{words.signatureDelegation[state.language]}" section of the "{words.permissionAndDelegation[state.language]}" screen, select "{words.delegate[state.language]}" and enter each item as shown below.<br/>
                 <div className='flexColumn alignItemsFlexStart marginLeft1 marginTopBottom1'>
                   <div>
                     - {words.addressDelegateFrom[state.language]}: The address you delegate signature from.
                   </div>
                   <div>
                     - {words.addressDelegateTo[state.language]}: The address you delegate signature to.(The party that accepts the signatures of the other address and actually performs the act of signing.)
                   </div>
                 </div>
                 After all fields have been filled in, set the Mpurse address as {words.addressDelegateFrom[state.language]} and sign it. Next, select "{words.acceptDelegation[state.language]}" and sign it with Mpurse address set as {words.addressDelegateTo[state.language]}.<br/>
                 On completion of delegation and acceptance, signature delegation becomes effective.<br/>
                 Note that "{words.delegate2[state.language]}" and "{words.delegated[state.language]}" searches are possible even in the middle of delegation and acceptance.
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                Use your monapalette address.
              </div>
              <div className='margin1'>
                Importing monapalette addresses (private keys) into Mpurse will make them available in monacotto.<br/>
                For more information, please refer to 
                <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                  onClick={ () => {
                    window.open('https://spotlight.soy/detail?article_id=avg2yiq6d');
                  } }>
                  here
                </button>
                . (Japanese)
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
               Rearrange the exhibited Monacards.
              </div>
              <div className='margin1'>
                When you enter your own My Home while logged in at your main address, you will see left and right arrows on the Monacards on exhibit, which you can click to rearrange the Monacards.<br/>
                To determine the sorted arrangement, click "{words.commitReplacement[state.language]}" button.
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                Change user information.
              </div>
              <div className='margin1'>
                To change the user information registered on the settings screen, click the login button once to load the current registration information.<br/>
                Then, change the information, set the Mpurse address as the main address, and sign and register.<br/>
                <br/>
                It may take some time for image updates to be reflected. Also, please try clearing your browser's cache.
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                Set the default addresses
              </div>
              <div className='margin1'>
                Default settings for "{words.addressCardFrom[state.language]}," "{words.addressPayProceedsTo[state.language]}," "{words.addressPayFrom[state.language]}" and "{words.addressSendCardTo[state.language]}" can be set on the setup screen.<br/>
                Once the default addresses are set, each address can be easily entered by clicking the default button when making a purchase or an exhibit of Monacards.
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                Check exhibit history.
              </div>
              <div className='margin1'>
                Select "exhibit history" on the history screen, enter the main address, and click Search button to display the history of your previous exhibits. Click the "..." button on the far right to view details.<br/>
                <br/>
                In the near future, we plan to add the ability to display the details of purchases tied to an exhibit. (The buyer's user name, main address, remittance TXID, etc. are also displayed.)
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                Check purchase history.
              </div>
              <div className='margin1'>
                Select "purchase history" on the history screen, enter the main address, and click Search button to display the history of your previous purchases. Click the "..." button on the far right to view details.
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                The exhibit is on its way and I want to resume it.
              </div>
              <div className='margin1'>
                Please search your exhibit history.<br/>
                <br/>
                If the status of the exhibit is "{words.waitingForSignature[state.language]}", it means that we are waiting for signature by "{words.addressCardFrom[state.language]}". Click the "..." button, set the Mpurse address as "{words.addressCardFrom[state.language]}", and click "{words.signByAddressYouSendCardsFrom[state.language]}" button. After that, click "{words.sendCards[state.language]}" button, confirm the sending details, and send the Monacards.<br/>
                <br/>
                If the status of the exhibit is "{words.waitingForToken[state.language]}", it means that we are waiting for you to send the Monacards. Click the "..." button, set the Mpurse address as "{words.addressCardFrom[state.language]}", click "{words.sendCards[state.language]}" button, confirm the sending details, and send the Monacards.
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                The purchase is on its way and I want to resume it.
              </div>
              <div className='margin1'>
                Please search your purchase history.<br/>
                <br/>
                If the status of the purchase is "{words.waitingForSignature[state.language]}", it means that we are waiting for signature by "{words.addressPayFrom[state.language]}". Click the "..." button, set the Mpurse address as "{words.addressPayFrom[state.language]}", and click "{words.signByAddressYouPayFrom[state.language]}" button. After that, click "{words.sendMona[state.language]}" button, confirm the remittance details, and send Monacoin.<br/>
                <br/>
                If the status of the purchase is "{words.waitingForMona[state.language]}", it means that we are waiting for you to send Monacoin. Click the "..." button, set the Mpurse address as "{words.addressPayFrom[state.language]}", click "{words.sendMona[state.language]}" button, confirm the remittance details, and send Monacoin.
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                I want to cancel my exhibit.
              </div>
              <div className='margin1'>
                Please search your exhibit history.<br/>
                <br/>
                If the status of the exhibit is "{words.onSale[state.language]}", you can cancel it. Click the "..." button, select "{words.cancel[state.language]}," set the Mpurse address as your main address, and click "{words.signByMainAddress[state.language]}" button.<br/>
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                I want to change the price of the Monacards I have exhibited or the address I receive proceeds in.
              </div>
              <div className='margin1'>
                Monacotto does not allow you to change exhibit details, but instead you can Cancel & Re-exhibit.<br/>
                <br/>
                Please search your exhibit history.<br/>
                <br/>
                If the status of the item is "{words.onSale[state.language]}", you can Cancel & Re-exhibit it. Click the "..." button and select "{words.cancelAndReexhibit[state.language]}." Change "{words.addressPayProceedsTo[state.language]}" and the price (unit price), set the Mpurse address as your main address, and click "{words.signByMainAddress[state.language]}" button.<br/>
                The exhibit will be canceled and taken over to the new exhibit.
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                When will sold-out Monacards disappear from My Home?
              </div>
              <div className='margin1'>
                They will disappear about 3 days after they are sold out.
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width90P borderMonacotto margin1 padding1'>
              <div className='font2'>
                I missed the message and want it to appear again.
              </div>
              <div className='margin1'>
                Click on the blue chick "Cotto-chan" next to the monacotto logo. The previous message will be displayed.<br/>
                You can also view past messages by clicking the arrow above the message.
              </div>
            </div>
          </div>
        </div>
        {/* SP */}
        <div className='visibleMiddleOrLess' >
          <Header screen='howToUseAdvanced' />
          <div className='flexColumn alignItemsCenter'>
            <div className='flexColumn alignItemsCenter width93vw marginTopBottom1 '>
              <div className='font2 '>
                How to use Monacotto one step further
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                Send Monacoin from a different address than your main address when you purchase.
              </div>
              <div className='width93vw'>
                 Enter the address from which Monacoin is sent in "{words.addressPayFrom[state.language]}" field. Switch the Mpurse address and click Mpurse button to enter it.<br/>
                 After all fields have been filled in, do the following.
                 <div className='flexColumn alignItemsFlexStart marginLeft1 marginTopBottom1'>
                   <div>
                     - Click "{words.signByMainAddress[state.language]}" button and sign with Mpurse. (Mpurse address must be set as your main address.) 
                   </div>
                   <div>
                     - Click "{words.signByAddressYouPayFrom[state.language]}" button and sign with Mpurse. (Mpurse address must be set as "{words.addressPayFrom[state.language]}".) 
                     <div className='marginLeft1'>
                       <span className='backgroundColorRed '>Failure to do so within 30 minutes of signing by the main address will result in expiration and the purchase will be canceled.</span>
                     </div>
                   </div>
                   <div>
                     - Click "{words.sendMona[state.language]}" button. (Mpurse address must be set as "{words.addressPayFrom[state.language]}".) 
                   </div>
                   <div>
                     - Confirm the remittance details, and click "Send" button on Mpurse.
                     <div className='marginLeft1'>
                       <span className='backgroundColorRed '>Failure to do so within 2 hours of signing by "{words.addressPayFrom[state.language]}" will result in expiration and the purchase will be canceled.</span>
                     </div>
                   </div>
                 </div>
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                Receive Monacards in a different address than your main address when you purchase.
              </div>
              <div className='width93vw'>
                 Enter the address where you want to receive the Monacards in the "{words.addressSendCardTo[state.language]}" field. Switch the Mpurse address and click Mpurse button to enter it.<br/>
                 After all fields have been filled in, do the same.
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                Send the monacards from a different address than your main address when you exhibit.
              </div>
              <div className='width93vw'>
                 Enter the address from which the Monacards is sent in "{words.addressCardFrom[state.language]}" field. Switch the Mpurse address and click Mpurse button to enter it.<br/>
                 After all fields have been filled in, do the following.
                 <div className='flexColumn alignItemsFlexStart marginLeft1 marginTopBottom1'>
                   <div>
                     - Click "{words.signByMainAddress[state.language]}" button and sign with Mpurse. (Mpurse address must be set as your main address.) 
                   </div>
                   <div>
                     - Click "{words.signByAddressYouSendCardsFrom[state.language]}" button and sign with Mpurse. (Mpurse address must be set as "{words.addressCardFrom[state.language]}".) 
                     <div className='marginLeft1'>
                       <span className='backgroundColorRed '>Failure to do so within 30 minutes of signing by the main address will result in expiration and the exhibit will be canceled.</span>
                     </div>
                   </div>
                   <div>
                     - Click "{words.sendCards[state.language]}" button. (Mpurse address must be set as "{words.addressCardFrom[state.language]}".) 
                   </div>
                   <div>
                     - Confirm the sending details, and click "Send" button on Mpurse.
                     <div className='marginLeft1'>
                       <span className='backgroundColorRed '>Failure to do so within 2 hours of signing by "{words.addressCardFrom[state.language]}" will result in expiration and the exhibit will be canceled.</span>
                     </div>
                   </div>
                 </div>
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                Set up to receive proceeds in a different address than your main address when you exhibit.
              </div>
              <div className='width93vw'>
                 Enter the address where you want to receive proceeds in the "{words.addressPayProceedsTo[state.language]}" field. Switch the Mpurse address and click Mpurse button to enter it.<br/>
                 After all fields have been filled in, do the same.
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                Exhibit Monacards issued by your own address that is different from your main address.
              </div>
              <div className='width93vw'>
                By default, you can exhibit Monacards only issued by your main address in monacotto. In order to exhibit Monacards issued by another address, the issuing address(owner address) must give permission to the main address to exhibit the cards.<br/>
                <br/>
                <span className='backgroundColorRed '>In order to grant permission to exhibit, you will need to be registered as a user with the issuing address, so please register on the setup page.</span><br/>
                Next, fill in each field of "{words.grantPermissionOfExhibit[state.language]}" section on the permission screen as follows.
                 <div className='flexColumn alignItemsFlexStart marginLeft1 marginTopBottom1'>
                   <div>
                     - {words.addressOwner[state.language]}: The address where the Monacards were issued.
                   </div>
                   <div>
                     - {words.addressPermitTo[state.language]}: The main address where you want to exhibit the Monacards.
                   </div>
                   <div>
                     - {words.addressPayRoyaltyTo[state.language]}: Leave blank.
                   </div>
                   <div>
                     - {words.royaltyPercentage[state.language]}: Leave 0.
                   </div>
                 </div>
                 After all fields have been filled in, set the Mpurse address as {words.addressOwner[state.language]} and sign it.
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                Exhibit Monacards issued by someone else's address.
              </div>
              <div className='width93vw'>
                You need to ask others to grant you permission to exhibit from their issuing address to your main address. To do so, make a exhibit permission request to the other.<br/>
                They can set a royalty percentage when they allow you to exhibit their Monacards on your My Home. In this case, the Monacard issuer(owner) will receive a royalty of the displayed price x royalty percentage x 0.01 when the Monacard is sold.<br/>
                <br/>
                Fill in each field of "{words.requestPermissionOfExhibit[state.language]}" section on the permission screen as follows.
                 <div className='flexColumn alignItemsFlexStart marginLeft1 marginTopBottom1'>
                   <div>
                     - {words.addressMain[state.language]}: The main address where you want to exhibit the Monacards.
                   </div>
                   <div>
                     - {words.addressOwner[state.language]}: The address where the Monacards were issued.(Destination of the request)
                   </div>
                   <div>
                     - {words.royaltyPercentage[state.language]}: the royalty percentage you propose. (the other can change the proposed % when he/she grant you permission.)
                   </div>
                 </div>
                 After all fields have been filled in, set the Mpurse address as {words.addressMain[state.language]} and sign it.
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                Allow others to exhibit Monacards issued by you.
              </div>
              <div className='width93vw'>
                You may receive a request for permission to exhibit your Monacards from someone else to your address.<br/>
                <span className='backgroundColorRed '>In order to search for requests and grant permission to exhibit, you will need to be registered as a user with the target address, so please register on the setup screen.</span><br/>
                <br/>
                In the search function on the permission screen, select "{words.requestedPermission[state.language]}, " enter the address you want to check to see if a request has been received in "{words.addressMain[state.language]}", and click the Search button.<br/>
                If a request has been received, its details will be displayed. Click the handshake button on the far right to copy the details of the request to each field in the "{words.grantPermissionOfExhibit[state.language]}" section.<br/>
                To allow to exhibit, enter the address where you want to receive royalties in "{words.addressPayRoyaltyTo[state.language]}" field, change the royalty percentage if necessary, set the Mpurse address as "{words.addressOwner[state.language]}," sign it and register.<br/>
                <br/>
                You can grant permission to exhibit to others even if you have not received a request.<br/>
                In this case, each field should be considered in terms of the following statement.<br/>
                <br/>
                I permits "{words.addressPermitTo[state.language]}" to exhibit the Monacards issued by "{words.addressOwner[state.language]}". I will receive royalties for "{words.royaltyPercentage[state.language]}" in "{words.addressPayRoyaltyTo[state.language]}" when the Monacards are sold.
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                Delegate the signature of your address to another of your addresses.
              </div>
              <div className='width93vw'>
                When using monacotto with multiple addresses, there are situations where you will need to switch the Mpurse address in order to change the address to sign with.<br/>
                When signing with two different addresses, one signature can be delegated to the other so that only the latter signature is required.<br/>
                <span className='backgroundColorRed '>Since sending Monacoins and sending Monacards cannot be delegated to other addresses,</span> the basic usage is to use the payment source address and card sending source address as {words.addressDelegateTo[state.language]}.<br/>
                <span className='backgroundColorRed '>In order to perform signature delegation, you will need to be registered as a user with the delegation source address and the delegated address, so please register on the setup screen.</span><br/>
                In the "{words.signatureDelegation[state.language]}" section of the "{words.permissionAndDelegation[state.language]}" screen, select "{words.delegate[state.language]}" and enter each item as shown below.<br/>
                 <div className='flexColumn alignItemsFlexStart marginLeft1 marginTopBottom1'>
                   <div>
                     - {words.addressDelegateFrom[state.language]}: The address you delegate signature from.
                   </div>
                   <div>
                     - {words.addressDelegateTo[state.language]}: The address you delegate signature to.(The party that accepts the signatures of the other address and actually performs the act of signing.)
                   </div>
                 </div>
                 After all fields have been filled in, set the Mpurse address as {words.addressDelegateFrom[state.language]} and sign it. Next, select "{words.acceptDelegation[state.language]}" and sign it with Mpurse address set as {words.addressDelegateTo[state.language]}.<br/>
                 On completion of delegation and acceptance, signature delegation becomes effective.<br/>
                 Note that "{words.delegate2[state.language]}" and "{words.delegated[state.language]}" searches are possible even in the middle of delegation and acceptance.
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                Use your monapalette address.
              </div>
              <div className='width93vw'>
                Importing monapalette addresses (private keys) into Mpurse will make them available in monacotto.<br/>
                For more information, please refer to 
                <button className={'borderNone backgroundColorTransparent fontHyperLink focusEffect01 cursor'} tabindex='0'
                  onClick={ () => {
                    window.open('https://spotlight.soy/detail?article_id=avg2yiq6d');
                  } }>
                  here
                </button>
                . (Japanese)
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
               Rearrange the exhibited Monacards.
              </div>
              <div className='width93vw'>
                When you enter your own My Home while logged in at your main address, you will see left and right arrows on the Monacards on exhibit, which you can click to rearrange the Monacards.<br/>
                To determine the sorted arrangement, click "{words.commitReplacement[state.language]}" button.
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                Change user information.
              </div>
              <div className='width93vw'>
                To change the user information registered on the settings screen, click the login button once to load the current registration information.<br/>
                Then, change the information, set the Mpurse address as the main address, and sign and register.<br/>
                <br/>
                It may take some time for image updates to be reflected. Also, please try clearing your browser's cache.
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                Set the default addresses
              </div>
              <div className='width93vw'>
                Default settings for "{words.addressCardFrom[state.language]}," "{words.addressPayProceedsTo[state.language]}," "{words.addressPayFrom[state.language]}" and "{words.addressSendCardTo[state.language]}" can be set on the setup screen.<br/>
                Once the default addresses are set, each address can be easily entered by clicking the default button when making a purchase or an exhibit of Monacards.
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                Check exhibit history.
              </div>
              <div className='width93vw'>
                Select "exhibit history" on the history screen, enter the main address, and click Search button to display the history of your previous exhibits. Click the "..." button on the far right to view details.<br/>
                <br/>
                In the near future, we plan to add the ability to display the details of purchases tied to an exhibit. (The buyer's user name, main address, remittance TXID, etc. are also displayed.)
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                Check purchase history.
              </div>
              <div className='width93vw'>
                Select "purchase history" on the history screen, enter the main address, and click Search button to display the history of your previous purchases. Click the "..." button on the far right to view details.
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                The exhibit is on its way and I want to resume it.
              </div>
              <div className='width93vw'>
                Please search your exhibit history.<br/>
                <br/>
                If the status of the exhibit is "{words.waitingForSignature[state.language]}", it means that we are waiting for signature by "{words.addressCardFrom[state.language]}". Click the "..." button, set the Mpurse address as "{words.addressCardFrom[state.language]}", and click "{words.signByAddressYouSendCardsFrom[state.language]}" button. After that, click "{words.sendCards[state.language]}" button, confirm the sending details, and send the Monacards.<br/>
                <br/>
                If the status of the exhibit is "{words.waitingForToken[state.language]}", it means that we are waiting for you to send the Monacards. Click the "..." button, set the Mpurse address as "{words.addressCardFrom[state.language]}", click "{words.sendCards[state.language]}" button, confirm the sending details, and send the Monacards.
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                The purchase is on its way and I want to resume it.
              </div>
              <div className='width93vw'>
                Please search your purchase history.<br/>
                <br/>
                If the status of the purchase is "{words.waitingForSignature[state.language]}", it means that we are waiting for signature by "{words.addressPayFrom[state.language]}". Click the "..." button, set the Mpurse address as "{words.addressPayFrom[state.language]}", and click "{words.signByAddressYouPayFrom[state.language]}" button. After that, click "{words.sendMona[state.language]}" button, confirm the remittance details, and send Monacoin.<br/>
                <br/>
                If the status of the purchase is "{words.waitingForMona[state.language]}", it means that we are waiting for you to send Monacoin. Click the "..." button, set the Mpurse address as "{words.addressPayFrom[state.language]}", click "{words.sendMona[state.language]}" button, confirm the remittance details, and send Monacoin.
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                I want to cancel my exhibit.
              </div>
              <div className='width93vw'>
                Please search your exhibit history.<br/>
                <br/>
                If the status of the exhibit is "{words.onSale[state.language]}", you can cancel it. Click the "..." button, select "{words.cancel[state.language]}," set the Mpurse address as your main address, and click "{words.signByMainAddress[state.language]}" button.<br/>
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                I want to change the price of the Monacards I have exhibited or the address I receive proceeds in.
              </div>
              <div className='width93vw'>
                Monacotto does not allow you to change exhibit details, but instead you can Cancel & Re-exhibit.<br/>
                <br/>
                Please search your exhibit history.<br/>
                <br/>
                If the status of the item is "{words.onSale[state.language]}", you can Cancel & Re-exhibit it. Click the "..." button and select "{words.cancelAndReexhibit[state.language]}." Change "{words.addressPayProceedsTo[state.language]}" and the price (unit price), set the Mpurse address as your main address, and click "{words.signByMainAddress[state.language]}" button.<br/>
                The exhibit will be canceled and taken over to the new exhibit.
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                When will sold-out Monacards disappear from My Home?
              </div>
              <div className='width93vw'>
                They will disappear about 3 days after they are sold out.
              </div>
            </div>
            <div className='flexColumn alignItemsCenter width96vw borderMonacotto marginTopBottom1 '>
              <div className='font2 width90vw marginTopBottom1'>
                I missed the message and want it to appear again.
              </div>
              <div className='width93vw'>
                Click on the blue chick "Cotto-chan" next to the monacotto logo. The previous message will be displayed.<br/>
                You can also view past messages by clicking the arrow above the message.
              </div>
            </div>
          </div>
        </div>
        {/* popup */}
        <Popup layer={0}/>
        <Popup layer={1}/>
      </div>
    );
  }
}

// HOW TO USE MPURSE
function HowToUseMpurse() {
  const [state, dispatch, navigate] = useContext(GlobalState);

  useEffect( () => {
    window.scrollTo(0, 0);
  }, []);


  if (state.language === 'japanese') {
    return (
      <div>
        {/* PC */}
        <div className='visibleLargeOrMore' >
          <Header screen='howToUseMpurse' />
          <div className='flexColumn alignItemsCenter'>
            <div className='widthMax marginTopBottom1 marginSide2'>
              <div className='font2'>
                Mpurseのインストール
              </div>
              <div className='marginTop1'>
                もなこっとでは、モナコイン、モナカードをMpurseというウォレットで扱います。<br/>
                Mpurseはモナコイン版MetaMaskのようなもので、MetaMaskに慣れているひとであれば使い方はそれほど難しくないでしょう。
              </div>
            </div>
            <div className='marginTopBottom1'>
              <div className='flexRow justifyContentCenter marginTopBottom1 borderMonacotto padding1' >
                <div className='flexRow justifyContentCenter width50 marginSide1'>
                  <img className='width50' src={imageManualMpurse01} />
                </div>
                <div className='width50  '>
                  Mpurseのダウンロードサイトにアクセスします。<br/>
                  <div>
                    <button className='fontHyperLink borderNone backgroundColorTransparent cursor '
                      onClick={ () => { window.open('https://chrome.google.com/webstore/detail/mpurse/ljkohnccmlcpleonoiabgfggnhpkihaa'); } }
                    >
                      Mpurse - Google Chrome版
                    </button>
                  </div>
                  <div>
                    <button className='fontHyperLink borderNone backgroundColorTransparent cursor '
                      onClick={ () => { window.open('https://addons.mozilla.org/ja/firefox/addon/mpchain_mpurse/'); } }
                    >
                      Mpurse - Firefox版
                    </button>
                  </div>
                  <br/>
                  <br/>
                  ここでは、Chrome版のインストールを説明します。<br/>
                  「Chromeに追加」をクリックします。
                </div>
              </div>
              <div className='flexRow justifyContentCenter marginTopBottom1 borderMonacotto padding1' >
                <div className='flexRow justifyContentCenter width50 marginSide1'>
                  <img className='' src={imageManualMpurse02} />
                </div>
                <div className='width50 marginSide1 '>
                  「拡張機能を追加」をクリックします。
                </div>
              </div>
              <div className='flexRow justifyContentCenter marginTopBottom1 borderMonacotto padding1 ' >
                <div className='flexRow justifyContentCenter width50 marginSide1'>
                  <img className='' src={imageManualMpurse03} />
                </div>
                <div className='width50 marginSide1 '>
                  MpurseがChromeに追加されました。
                </div>
              </div>
              <div className='flexRow justifyContentCenter marginTopBottom1 borderMonacotto padding1 ' >
                <div className='flexRow justifyContentCenter width50 marginSide1'>
                  <img className='' src={imageManualMpurse04} />
                </div>
                <div className='width50 marginSide1 '>
                  ブラウザ右上の、パズルのピースのようなアイコンをクリックします。
                </div>
              </div>
              <div className='flexRow justifyContentCenter marginTopBottom1 borderMonacotto padding1 ' >
                <div className='flexRow justifyContentCenter width50 marginSide1'>
                  <img className='' src={imageManualMpurse05} />
                </div>
                <div className='width50 marginSide1 '>
                  Mpurseの右にあるピンのアイコンをクリックします。
                </div>
              </div>
              <div className='flexRow justifyContentCenter marginTopBottom1 borderMonacotto padding1 ' >
                <div className='flexRow justifyContentCenter width50 marginSide1'>
                  <img className='' src={imageManualMpurse06} />
                </div>
                <div className='width50 marginSide1 '>
                  ブラウザ右上に、Mpurseのアイコンが追加されました。<br/>
                  このアイコンをクリックします。
                </div>
              </div>
              <div className='flexRow justifyContentCenter marginTopBottom1 borderMonacotto padding1 ' >
                <div className='flexRow justifyContentCenter width50 marginSide1'>
                  <img className='' src={imageManualMpurse07} />
                </div>
                <div className='width50 marginSide1 '>
                  「同意」をクリックします。
                </div>
              </div>
              <div className='flexRow justifyContentCenter marginTopBottom1 borderMonacotto padding1 ' >
                <div className='flexRow justifyContentCenter width50 marginSide1'>
                  <img className='' src={imageManualMpurse08} />
                </div>
                <div className='width50 marginSide1 '>
                  Mpurseを開くときのパスワードを設定します。
                </div>
              </div>
              <div className='flexRow justifyContentCenter marginTopBottom1 borderMonacotto padding1 ' >
                <div className='flexRow justifyContentCenter width50 marginSide1'>
                  <img className='' src={imageManualMpurse09} />
                </div>
                <div className='width50 marginSide1 '>
                  パスフレーズ(ニーモニック)が表示されます。<br/>
                  このパスフレーズを順序通りに紙にメモしてください。<br/>
                  <span className='backgroundColorRed'>このパスフレーズを失くしてしまうと、ウォレットを復元することができません。<br/>
                  このパスフレーズを他人に知られてしまうと、ウォレットの中身を奪われてしまいます。<br/></span>
                  パスフレーズは他人に見られないよう、大切に保管してください。<br/>
                  <br/>
                  しっかりメモし、間違いないか確認したら、チェックボックスにチェックを入れて「保存」をクリックしてください。
                </div>
              </div>
              <div className='flexRow justifyContentCenter marginTopBottom1 borderMonacotto padding1 ' >
                <div className='flexRow justifyContentCenter width50 marginSide1'>
                  <img className='' src={imageManualMpurse10} />
                </div>
                <div className='width50 marginSide1 '>
                  初期設定が完了しました。<br/>
                  アカウント名(「アカウント1」)の下に表示されている文字列がこのアカウントのアドレスになります。(クリックするとコピーできます。)<br/>
                  <br/>
                  また、右上のアイコンからアカウントの追加をすることもできます。
                </div>
              </div>
            </div>
            <div className='widthMax marginTopBottom1 marginSide2'>
              ■参考記事
              <div>
                <button className='fontHyperLink borderNone backgroundColorTransparent cursor '
                  onClick={ () => { window.open('https://tadajam.hateblo.jp/entry/2019/05/26/160533'); } }
                >
                  開発者(tadajamさん)のMpurseについての記事
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* SP */}
        <div className='visibleMiddleOrLess' >
          <Header screen='howToUseMpurse' />
          <div className='flexColumn alignItemsCenter'>
            <div className='width93vw marginTopBottom1 '>
              <div className='font2'>
                Mpurseのインストール(スマホ版)
              </div>
              <div className='marginTop1'>
                もなこっとでは、モナコイン、モナカードをMpurseというウォレットで扱います。<br/>
                Mpurseはモナコイン版MetaMaskのようなもので、MetaMaskに慣れているひとであれば使い方はそれほど難しくないでしょう。
              </div>
            </div>
            <div className=''>
              <div className='flexColumn alignItemsCenter width96vw marginTopBottom1 borderMonacotto ' >
                <div className='marginTopBottom1'>
                  <img className='width60vw' src={imageManualMpurseSp01} />
                </div>
                <div className='width93vw  '>
                  Mpurseのダウンロードサイトにアクセスします。<br/>
                  <div>
                    <button className='fontHyperLink borderNone backgroundColorTransparent cursor '
                      onClick={ () => { window.open('https://play.google.com/store/apps/details?id=info.mpchain.mpurse&pli=1'); } }
                    >
                      Mpurse - Andoroidアプリ版
                    </button>
                  </div>
                  <div>
                    <button className='fontHyperLink borderNone backgroundColorTransparent cursor '
                      onClick={ () => { window.open('https://apps.apple.com/us/app/mpurse/id1494156643'); } }
                    >
                      Mpurse - iOSアプリ版
                    </button>
                  </div>
                  <div>
                    <button className='fontHyperLink borderNone backgroundColorTransparent cursor '
                      onClick={ () => { window.open('https://addons.mozilla.org/ja/firefox/addon/mpchain_mpurse/'); } }
                    >
                      Mpurse - Firefox版
                    </button>
                  </div>
                  <br/>
                  <br/>
                  ここでは、iOSアプリ版のインストールを説明します。<br/>
                  アプリをインストールし、起動します。
                </div>
              </div>
              <div className='flexColumn alignItemsCenter width96vw marginTopBottom1 borderMonacotto ' >
                <div className='marginTopBottom1'>
                  <img className='width60vw' src={imageManualMpurseSp02} />
                </div>
                <div className='width93vw'>
                  「カスタムシード」をクリックします。
                </div>
              </div>
              <div className='flexColumn alignItemsCenter width96vw marginTopBottom1 borderMonacotto ' >
                <div className='marginTopBottom1'>
                  <img className='width60vw' src={imageManualMpurseSp03} />
                </div>
                <div className='width93vw'>
                  Mpurseを開くときのパスワードを設定します。
                </div>
              </div>
              <div className='flexColumn alignItemsCenter width96vw marginTopBottom1 borderMonacotto ' >
                <div className='marginTopBottom1'>
                  <img className='width60vw' src={imageManualMpurseSp04} />
                </div>
                <div className='width93vw'>
                  シードフレーズ(ニーモニック)が表示されます。<br/>
                  このシードフレーズを順序通りに紙にメモしてください。<br/>
                  <span className='backgroundColorRed'>このパスフレーズを失くしてしまうと、ウォレットを復元することができません。<br/>
                  このシードフレーズを他人に知られてしまうと、ウォレットの中身を奪われてしまいます。<br/></span>
                  シードフレーズは他人に見られないよう、大切に保管してください。<br/>
                  <br/>
                  しっかりメモし、間違いないか確認したら、チェックボックスにチェックを入れて「OK」をクリックしてください。
                </div>
              </div>
              <div className='flexColumn alignItemsCenter width96vw marginTopBottom1 borderMonacotto ' >
                <div className='marginTopBottom1'>
                  <img className='width60vw' src={imageManualMpurseSp05} />
                </div>
                <div className='width93vw'>
                  初期設定が完了しました。<br/>
                  アカウント名(「アカウント1」)の下に表示されている文字列がこのアカウントのアドレスになります。(クリックするとコピーできます。)<br/>
                  <br/>
                  また、中央上部のアイコンからアカウントの追加をすることもできます。
                </div>
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width96vw marginTopBottom1 '>
              <div>
                ■参考記事
                <div>
                  <button className='fontHyperLink borderNone backgroundColorTransparent cursor textLeft '
                    onClick={ () => { window.open('https://tadajam.hateblo.jp/entry/2019/05/26/160533'); } }
                  >
                    開発者(tadajamさん)のMpurseについての記事
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* popup */}
        <Popup layer={0}/>
        <Popup layer={1}/>
      </div>
    );
  }
  else // english
  {
    return (
      <div>
        {/* PC */}
        <div className='visibleLargeOrMore' >
          <Header screen='howToUseMpurse' />
          <div className='flexColumn alignItemsCenter'>
            <div className='widthMax marginTopBottom1 marginSide2'>
              <div className='font2'>
                Installing Mpurse
              </div>
              <div className='marginTop1'>
                In monacotto, Monacoin and Monacards are handled in a wallet "Mpurse."<br/>
                Mpurse is like a Monacoin version of MetaMask, and if you are familiar with MetaMask, it is not too difficult to use.
              </div>
            </div>
            <div className='marginTopBottom1'>
              <div className='flexRow justifyContentCenter marginTopBottom1 borderMonacotto padding1' >
                <div className='flexRow justifyContentCenter width50 marginSide1'>
                  <img className='width50' src={imageManualMpurse01} />
                </div>
                <div className='width50  '>
                  Go to the Mpurse download site.<br/>
                  <div>
                    <button className='fontHyperLink borderNone backgroundColorTransparent cursor '
                      onClick={ () => { window.open('https://chrome.google.com/webstore/detail/mpurse/ljkohnccmlcpleonoiabgfggnhpkihaa'); } }
                    >
                      Mpurse - Google Chrome version
                    </button>
                  </div>
                  <div>
                    <button className='fontHyperLink borderNone backgroundColorTransparent cursor '
                      onClick={ () => { window.open('https://addons.mozilla.org/ja/firefox/addon/mpchain_mpurse/'); } }
                    >
                      Mpurse - Firefox version
                    </button>
                  </div>
                  <br/>
                  <br/>
                  This section describes the installation of the Chrome version.<br/>
                  Click "Add to Chrome."
                </div>
              </div>
              <div className='flexRow justifyContentCenter marginTopBottom1 borderMonacotto padding1' >
                <div className='flexRow justifyContentCenter width50 marginSide1'>
                  <img className='' src={imageManualMpurse02} />
                </div>
                <div className='width50 marginSide1 '>
                  Click "Add Extension."
                </div>
              </div>
              <div className='flexRow justifyContentCenter marginTopBottom1 borderMonacotto padding1 ' >
                <div className='flexRow justifyContentCenter width50 marginSide1'>
                  <img className='' src={imageManualMpurse03} />
                </div>
                <div className='width50 marginSide1 '>
                  Mpurse has been added to Chrome.
                </div>
              </div>
              <div className='flexRow justifyContentCenter marginTopBottom1 borderMonacotto padding1 ' >
                <div className='flexRow justifyContentCenter width50 marginSide1'>
                  <img className='' src={imageManualMpurse04} />
                </div>
                <div className='width50 marginSide1 '>
                  Click the icon in the upper right corner of the browser that looks like a puzzle piece.
                </div>
              </div>
              <div className='flexRow justifyContentCenter marginTopBottom1 borderMonacotto padding1 ' >
                <div className='flexRow justifyContentCenter width50 marginSide1'>
                  <img className='' src={imageManualMpurse05} />
                </div>
                <div className='width50 marginSide1 '>
                  Click the pin icon to the right of Mpurse.
                </div>
              </div>
              <div className='flexRow justifyContentCenter marginTopBottom1 borderMonacotto padding1 ' >
                <div className='flexRow justifyContentCenter width50 marginSide1'>
                  <img className='' src={imageManualMpurse06} />
                </div>
                <div className='width50 marginSide1 '>
                  An Mpurse icon has been added to the upper right corner of your browser.<br/>
                  Click this icon.
                </div>
              </div>
              <div className='flexRow justifyContentCenter marginTopBottom1 borderMonacotto padding1 ' >
                <div className='flexRow justifyContentCenter width50 marginSide1'>
                  <img className='' src={imageManualMpurse07} />
                </div>
                <div className='width50 marginSide1 '>
                  Click "Agree."
                </div>
              </div>
              <div className='flexRow justifyContentCenter marginTopBottom1 borderMonacotto padding1 ' >
                <div className='flexRow justifyContentCenter width50 marginSide1'>
                  <img className='' src={imageManualMpurse08} />
                </div>
                <div className='width50 marginSide1 '>
                  Set the password for opening Mpurse.
                </div>
              </div>
              <div className='flexRow justifyContentCenter marginTopBottom1 borderMonacotto padding1 ' >
                <div className='flexRow justifyContentCenter width50 marginSide1'>
                  <img className='' src={imageManualMpurse09} />
                </div>
                <div className='width50 marginSide1 '>
                  A passphrase(mnemonic) will appear.<br/>
                  Write this passphrase down on a piece of paper in the correct order.<br/>
                  <span className='backgroundColorRed'>If you lose this passphrase, you will not be able to recover your wallet.<br/>
                  If someone else learns this passphrase, the asset in your wallet will be taken away from you.<br/></span>
                  Please keep your passphrase in a safe place so that others cannot see it.<br/>
                  <br/>
                  After writing it down carefully and making sure it is correct, check the box and click "Save."
                </div>
              </div>
              <div className='flexRow justifyContentCenter marginTopBottom1 borderMonacotto padding1 ' >
                <div className='flexRow justifyContentCenter width50 marginSide1'>
                  <img className='' src={imageManualMpurse10} />
                </div>
                <div className='width50 marginSide1 '>
                  The initial setup is complete.<br/>
                  The string displayed under the account name("Account 1") is the address for this account. (You can copy it by clicking it.)<br/>
                  <br/>
                  You can also add an account from the icon in the upper right corner.
                </div>
              </div>
            </div>
            <div className='widthMax marginTopBottom1 marginSide2'>
              - reference article
              <div>
                <button className='fontHyperLink borderNone backgroundColorTransparent cursor '
                  onClick={ () => { window.open('https://tadajam.hateblo.jp/entry/2019/05/26/160533'); } }
                >
                  Developer's(tadajam's) article about Mpurse (Japanese)
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* SP */}
        <div className='visibleMiddleOrLess' >
          <Header screen='howToUseMpurse' />
          <div className='flexColumn alignItemsCenter'>
            <div className='width93vw marginTopBottom1 '>
              <div className='font2'>
                Installing Mpurse<br/>
                (Smartphone version)
              </div>
              <div className='marginTop1'>
                In monacotto, Monacoin and Monacards are handled in a wallet "Mpurse."<br/>
                Mpurse is like a Monacoin version of MetaMask, and if you are familiar with MetaMask, it is not too difficult to use.
              </div>
            </div>
            <div className=''>
              <div className='flexColumn alignItemsCenter width96vw marginTopBottom1 borderMonacotto ' >
                <div className='marginTopBottom1'>
                  <img className='width60vw' src={imageManualMpurseSp01} />
                </div>
                <div className='width93vw  '>
                  Go to the Mpurse download site.<br/>
                  <div>
                    <button className='fontHyperLink borderNone backgroundColorTransparent cursor '
                      onClick={ () => { window.open('https://play.google.com/store/apps/details?id=info.mpchain.mpurse&pli=1'); } }
                    >
                      Mpurse - Andoroid application version
                    </button>
                  </div>
                  <div>
                    <button className='fontHyperLink borderNone backgroundColorTransparent cursor '
                      onClick={ () => { window.open('https://apps.apple.com/us/app/mpurse/id1494156643'); } }
                    >
                      Mpurse - iOS application version
                    </button>
                  </div>
                  <div>
                    <button className='fontHyperLink borderNone backgroundColorTransparent cursor '
                      onClick={ () => { window.open('https://addons.mozilla.org/ja/firefox/addon/mpchain_mpurse/'); } }
                    >
                      Mpurse - Firefox version
                    </button>
                  </div>
                  <br/>
                  <br/>
                  This section describes the installation of the iOS application version.<br/>
                  Install and launch the application.
                </div>
              </div>
              <div className='flexColumn alignItemsCenter width96vw marginTopBottom1 borderMonacotto ' >
                <div className='marginTopBottom1'>
                  <img className='width60vw' src={imageManualMpurseSp02} />
                </div>
                <div className='width93vw'>
                  Click on "Custom Seed."
                </div>
              </div>
              <div className='flexColumn alignItemsCenter width96vw marginTopBottom1 borderMonacotto ' >
                <div className='marginTopBottom1'>
                  <img className='width60vw' src={imageManualMpurseSp03} />
                </div>
                <div className='width93vw'>
                  Set the password for opening Mpurse.
                </div>
              </div>
              <div className='flexColumn alignItemsCenter width96vw marginTopBottom1 borderMonacotto ' >
                <div className='marginTopBottom1'>
                  <img className='width60vw' src={imageManualMpurseSp04} />
                </div>
                <div className='width93vw'>
                  A seedphrase(mnemonic) will appear.<br/>
                  Write this seedphrase down on a piece of paper in the correct order.<br/>
                  <span className='backgroundColorRed'>If you lose this seedphrase, you will not be able to recover your wallet.<br/>
                  If someone else learns this seedphrase, the asset in your wallet will be taken away from you.<br/></span>
                  Please keep your seedphrase in a safe place so that others cannot see it.<br/>
                  <br/>
                  After writing it down carefully and making sure it is correct, check the box and click "OK."
                </div>
              </div>
              <div className='flexColumn alignItemsCenter width96vw marginTopBottom1 borderMonacotto ' >
                <div className='marginTopBottom1'>
                  <img className='width60vw' src={imageManualMpurseSp05} />
                </div>
                <div className='width93vw'>
                  The initial setup is complete.<br/>
                  The string displayed under the account name("Account 1") is the address for this account. (You can copy it by clicking it.)<br/>
                  <br/>
                  You can also add an account from the icon in the top center.
                </div>
              </div>
            </div>
            <div className='flexColumn alignItemsFlexStart width96vw marginTopBottom1 '>
              - reference article
              <div>
                <button className='fontHyperLink borderNone backgroundColorTransparent cursor textLeft'
                  onClick={ () => { window.open('https://tadajam.hateblo.jp/entry/2019/05/26/160533'); } }
                >
                  Developer's(tadajam's) article about Mpurse (Japanese)
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* popup */}
        <Popup layer={0}/>
        <Popup layer={1}/>
      </div>
    );
  }
}

// TEXT LINE MULTI LAYERS STATE ON PRESS KEY
function TextLine3(props) {
  const [state, dispatch] = useContext(GlobalState);
  const type = props.type !== undefined ? props.type : 'setStateMultiLayers';

  let value = props.keys.reduce( (acc, cur) => { return acc[cur]; }, state );
  if (type === 'setStateMultiLayersFloat') {
    value = value.face;
  }

  let tooltipClass;

  if (props.tooltip === 'left') {
    tooltipClass = 'tooltipLeft breakAll'
  }
  else if (props.tooltip === 'leftLong') {
    tooltipClass = 'tooltipLeft'
  }
  else if (props.tooltip === 'right') {
    tooltipClass = 'tooltipRight breakAll'
  }
  else if (props.tooltip === 'rightLong') {
    tooltipClass = 'tooltipRight'
  }
  else if (props.tooltip === false) {
    tooltipClass = 'invisible'
  }
  else if (props.tooltip !== undefined) {
    tooltipClass = 'tooltipRight'
  }
  else {
    tooltipClass = 'invisible'
  }

  let disabled;
  if (props.disabled) {
    disabled = true;
  }
  else {
    disabled = false;
  }

  return (
    <div>
            <input className='invisibleCheckBox' id={'UVCB_' + props.fieldName + 'Background'} type='checkbox' />
            <div className='box1 widthWithButton1NarrowSp'
              onMouseEnter={ () => {
                if (props.tooltip !== undefined && value !== undefined && value !== null && value !== '') {
                  document.getElementById('UVCB_' + props.fieldName + 'Tooltip').checked=true; 
                }
              }}
              onMouseLeave={ () => {
                if (props.tooltip !== undefined) {
                  document.getElementById('UVCB_' + props.fieldName + 'Tooltip').checked=false; 
                }
              }}
            >
              <input className='textLine1' type="text" value={value} placeholder='' disabled={disabled}
                onFocus={ () => {
                  document.getElementById('UVCB_' + props.fieldName + 'Background').checked=true; 
                  document.getElementById('UVCB_' + props.fieldName + 'Label').checked=true; 

                  if (props.tooltip !== undefined) {
                    document.getElementById('UVCB_' + props.fieldName + 'Tooltip').checked=false; 
                  }
                }}
                onBlur={ () => {
                  document.getElementById('UVCB_' + props.fieldName + 'Background').checked=false; 
                  document.getElementById('UVCB_' + props.fieldName + 'Label').checked=false; 

                  if (type === 'setStateMultiLayersFloat') {
                    dispatch({type: 'adjustFace', keys: props.keys});
                  }
                }}
                onChange={ (e) => {
                  let targetValueAdjusted = e.target.value;

                  if (type === 'setStateMultiLayersFloat') {
                    targetValueAdjusted = adjustFloat(props.adjustType, e.target.value, props.adjustExp);
                  }

                  dispatch({type: type, keys: props.keys, value: e.target.value, adjustType: props.adjustType, adjustExp: props.adjustExp});

                  if (props.extraDispatch !== undefined) {
                    for (const aDispatch of props.extraDispatch) {
                      dispatch({type: aDispatch.type, keys: aDispatch.keys, value: e.target.value});
                    }
                  }

                  if (props.extraFunctionOnChange !== undefined) {
                    for (const aFunction of props.extraFunctionOnChange) {
                      let argumentsRevised = aFunction.arguments;

                      if (aFunction.targetValue !== undefined) {
                        argumentsRevised.splice(aFunction.targetValue === true ? argumentsRevised.length : aFunction.targetValue, 0, targetValueAdjusted);
                      }

                      devLog('argumentsRevised', argumentsRevised);
                      aFunction.function(...argumentsRevised);
                    }
                  }

                  if (props.tooltip !== undefined) {
                    document.getElementById('UVCB_' + props.fieldName + 'Tooltip').checked=false; 
                  }
                }}
                onKeyPress={ (e) => {
                  if (e.which === props.keyNo) {
                    if (props.onKeyPressFunction !== undefined) {
                      props.onKeyPressFunction();
                    }
                  }
                }}
              />
              <input className='invisibleCheckBox' id={'UVCB_' + props.fieldName + 'Label'} type='checkbox' />
              <div className={ value === undefined || value === '' || value === null ? 'label1Unfilled' : 'label1Filled'}>
                {props.face}
              </div>
              <input className='invisibleCheckBox' id={'UVCB_' + props.fieldName + 'Tooltip'} type='checkbox' />
              <div className={tooltipClass} >
                {value}
              </div>
            </div>
    </div>
  );
}

// TEXT AREA MULTI LAYERS STATE
function TextArea2(props) {
  const [state, dispatch] = useContext(GlobalState);
  const value = props.keys.reduce( (acc, cur) => { return acc[cur]; }, state );
  const type = props.type !== undefined ? props.type : 'setStateMultiLayers';

  return (
    <div className={'flexColumn alignItemsCenter ' + props.outerClass}>
            <input className='invisibleCheckBox' id={'UVCB_' + props.fieldName + 'Background'} type='checkbox' />
            <div className={props.boxClass}>
              <textarea className={props.textAreaClass} value={value}
                onFocus={ () => {
                  document.getElementById('UVCB_' + props.fieldName + 'Background').checked=true; 
                  document.getElementById('UVCB_' + props.fieldName + 'Label').checked=true; 
                }}
                onBlur={ () => {
                  document.getElementById('UVCB_' + props.fieldName + 'Background').checked=false; 
                  document.getElementById('UVCB_' + props.fieldName + 'Label').checked=false; 
                }}
                onChange={ (e) => {
                  dispatch({type: type, keys: props.keys, value: e.target.value});
                }}
              />
              <input className='invisibleCheckBox' id={'UVCB_' + props.fieldName + 'Label'} type='checkbox' />
              <div className={ value === '' ? 'label1Unfilled' : 'label1Filled'}>
                {props.face}
              </div>
            </div>
    </div>
  );
}


function handleClickMpurse(state, dispatch, stateKeys, popupLayer) {
  const popupLayerNext = popupLayer !== undefined && popupLayer !== null ? (popupLayer + 1) : 0;

  const callback = (keyPairs) => {
    const keyPair = keyPairs[0];
    const result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      const address= result.address;
      dispatch({ type: 'setStateMultiLayers', keys: stateKeys, value: address });
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return 'rejected';
    }

    keepKeyPairsInMemory(state, dispatch, keyPairs);
  };

  if (state.walletType === 'mpurse') {
    if (window.mpurse) {
      window.mpurse.getAddress()
      .then( (result) => {
        dispatch({ type: 'setStateMultiLayers', keys: stateKeys, value: result });
      })
      .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
      });
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.activateMpurse[state.language] });
    }
  }
  else if (state.walletType === 'nfc') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
    handleClickNfc(state, dispatch, callback);
  }
  else { // qr
    dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
    handleClickScanQr(state, dispatch, callback, { popupLayer: popupLayerNext });
  }
}

// HANDLE CLICK ADDRESS HISTORY
function handleClickAddressHistory(state, dispatch, cookies, stateKeys, popupLayer) {
  const popupLayerNext = popupLayer !== undefined && popupLayer !== null ? (popupLayer + 1) : 0;
  let addressHistory = cookies.addresses;

  if (addressHistory === undefined) {
    addressHistory = [];
  }

  const options = addressHistory.map( record => <AddressHistoryRecord record={record} stateKeys={stateKeys} layer={popupLayerNext} /> );

  const popup = {
    type: 'generalList',
    body: {
      title: <AddressHistoryTitle />,
      options: options,
    },
  };

  dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
}

// HANDLE CLICK VIEW SESSION
function handleClickViewSession(state, dispatch, popupLayer) {
  const popupLayerNext = popupLayer !== undefined && popupLayer !== null ? (popupLayer + 1) : 0;
  let sessionAddresses = Object.keys(state.session);

  // if (addressHistory === undefined) {
  //   addressHistory = [];
  // }

  sessionAddresses = sessionAddresses.filter( address => state.session[address].expirationOfSession >= Date.now() )
  const records = sessionAddresses.map( address => <SessionRecord record={ {address} } /> );

  const popup = {
    type: 'generalList',
    body: {
      title: <SessionTitle />,
      options: records,
    },
  };

  dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });

  if (sessionAddresses.length === 0) {
    dispatch({ type: 'setState', key: 'session', value: {} });
  }
}

// HANDLE CLICK POPUP USER REGISTRATION
function handleClickPopupUserRegistration(state, dispatch, popupLayer) {
  const popupLayerNext = popupLayer !== undefined && popupLayer !== null ? (popupLayer + 1) : 0;

  const popup = {
    type: 'userRegistrationPopup',
  };

  dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
}

// HANDLE CLICK POPUP LOGIN
function handleClickPopupLogin(state, dispatch, popupLayer) {
  const popupLayerNext = popupLayer !== undefined && popupLayer !== null ? (popupLayer + 1) : 0;

  const popup = {
    type: 'loginPopup',
  };

  dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
}

// 「ログイン」ボタン押下 HANDLE CLICK LOGIN
async function handleClickLogin(state, dispatch, cookies, setCookie, keyPairs, popupLayer) {
  let request = {};
  let messages = {};
  let certifications = [];
  const popupLayerNext = (popupLayer !== undefined && popupLayer !== null) ? (popupLayer + 1) : 0;
  const now = Date.now();

  // state固定
  const stateFixed = {
    addressMain: state.login.addressMain,
    signatureVersion: state.config.clientParameters.signatureVersion.login,
    addressCheck: state.addressCheck,
    walletType: state.walletType,
  };

  // 有効なセッションに絞り込む。
  devLog('session', JSON.stringify(state.session));
  devLog('session keys', JSON.stringify(Object.keys(state.session)));

  for (const address of Object.keys(state.session)) {
    // state固定
    stateFixed[address] = {
      // addressCoinType: state.session[address].addressCoinType,
      sessionId: state.session[address].sessionId,
      expirationOfSession: state.session[address].expirationOfSession,
    };

    if (stateFixed[address].expirationOfSession >= now + marginOfSessionTime) {
      certifications.push({
        // addressCoinType: stateFixed[address].addressCoinType,
        address: address,
        sessionId: stateFixed[address].sessionId,
      });
    }
  }

  // アドレスが指定されており、そのアドレスのセッションが存在しない、または、期限が切れていれば、署名する。(キープール or Mpurse)
  // Mpurseの場合、アドレスが指定されていない場合は、アクティブなアドレスで代替するかもしれない。(アドレスチェックモードに依る。)
  // keyPairsが渡された場合(2周目)、keyPairsの先頭の鍵で試みる。アドレスが指定されていない場合は、先頭のアドレスで代替するかもしれない。(アドレスチェックモードに依る。)

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' && keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined) {
    certificationWithKey(state, dispatch, [ keyPairsInPool[stateFixed.addressMain].keyPair ], 'login', stateFixed, certifications, now, null, 'matchedKey');
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    // 鍵ペアが渡され、そのアドレスのセッションが存在しない、または、期限が切れていれば、署名する。
    certificationWithKey(state, dispatch, keyPairs, 'login', stateFixed, certifications, now, null, 'firstKey');
  }
  else if (stateFixed.walletType === 'mpurse') {
    await setSignatureByAddressMain(state, dispatch, stateFixed, certifications, now, null, 'login');
  }

  // 対象がなければ戻る。
  if (
    certifications.length === 0 ||
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    !certifications.map( certification => certification.address ).includes(stateFixed.addressMain)
  ) {
    if (keyPairs === undefined || keyPairs === null) {
      if (stateFixed.walletType === 'nfc') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
        return {
          status: 'rejected',
          message: 'nfcRequired',
        };
      }
      else if (stateFixed.walletType === 'qr') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
        return {
          status: 'rejected',
          message: 'bcRequired',
        };
      }
    }

    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToLogin[state.language] });
    return { status: 'rejected' };
  }

  request.body = JSON.stringify({
    "body": {
      "certifications": certifications,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/login_and_reload';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, 'user', undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    for (const address of Object.keys(response.body)) {
      let balance;
      let assets;
      let assetInfo;
      let result;

      // 署名した場合もろもろ更新
      if (response.body[address]['certificatedBy'] === 'signature') {

        // session更新
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address],
          value: {'sessionId': response.body[address]['sessionId'], 'expirationOfSession': response.body[address]['expirationOfSession']} });
      }
      else if (response.body[address]['certificatedBy'] === 'none') {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address], value: { expirationOfSession: 0 } });
      }

      // カード関連情報取得
      if (response.body[address]['certificatedBy'] === 'signature' || response.body[address]['certificatedBy'] === 'sessionId') {
        // 保有アセット取得
        result = await getBalancesKOM(state, dispatch, address);

        if (result.status === 'fulfilled') {
          balance = result.body;
          assets = Object.keys(balance);
        }
        else {
          continue;
        }

        // アセット情報取得
        result = await getAssetInfo(state, dispatch, assets);

        if (result.status === 'fulfilled') {
          assetInfo = result.body;
        }
        else {
          continue;
        }

        // モナカード情報取得
        const assetCommons = assets.map( asset => assetInfo[asset].asset_longname === null ? asset : assetInfo[asset].asset_longname );
        getMonacard(state, dispatch, assetCommons);
      }
    }
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToLogin[state.language] });
    return { status: 'rejected' };
  }

  if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '') {
    if (response.body[stateFixed.addressMain].certificatedBy === 'sessionId' || response.body[stateFixed.addressMain].certificatedBy === 'signature') {
      // 明示的ログインアドレスでログイン成功

      // 基本情報をconfigureにセット
      dispatch({ type: 'setState', key: 'configure', value: response.body[stateFixed.addressMain].basicInformation });

      // 各種stateにログインアドレスをセット
      if (state.exhibit.addressMain === undefined || state.exhibit.addressMain === null || state.exhibit.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['exhibit', 'addressMain'], value: stateFixed.addressMain });
      }

      if (state.purchase.addressMain === undefined || state.purchase.addressMain === null || state.purchase.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['purchase', 'addressMain'], value: stateFixed.addressMain });
      }

      if (state.permit.addressMain === undefined || state.permit.addressMain === null || state.permit.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['permit', 'addressOwner'], value: stateFixed.addressMain });
      }

      if (state.requestPermission.addressMain === undefined || state.requestPermission.addressMain === null || state.requestPermission.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['requestPermission', 'addressMain'], value: stateFixed.addressMain });
      }

      if (state.getHistory.addressMain === undefined || state.getHistory.addressMain === null || state.getHistory.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['getHistory', 'addressMain'], value: stateFixed.addressMain });
      }

      if (state.getPermissionHistory.addressMain === undefined || state.getPermissionHistory.addressMain === null || state.getPermissionHistory.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'addressMain'], value: stateFixed.addressMain });
      }

      // addressMainが指定されていなかった場合は、dispatchする。
      if (state.login.addressMain === undefined || state.login.addressMain === null || state.login.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['login', 'addressMain'], value: stateFixed.addressMain });
      }

      // cookie情報更新(アドレス情報)
      const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
      const cookiesNew = [
            {
              action: 'login',
              addressType: 'addressMain',
              description: `${userName}`,
              time: now,
              address: stateFixed.addressMain
            },
      ];

      setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
    }
    else {
      // 明示的ログインアドレスでログイン失敗

      if (response.body[stateFixed.addressMain].applicationMessage === 'not accept TAC yet.') {
        // ユーザー登録を促す。
        dispatch({ type: 'setNotification', key: 'notification', value: words.pleaseRegisterAsAUser[state.language] });

        const popup = { type: 'userRegistrationPopup' };
        dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
      }
      else if (response.body[stateFixed.addressMain].applicationMessage === 'you must accept the terms and conditions of the current version.') {
        // 基本情報をconfigureにセット（認証は成功しているので、規約同意しやすいように基本情報は埋めてあげる。）
        dispatch({ type: 'setState', key: 'configure', value: response.body[stateFixed.addressMain].basicInformation });

        if (response.body[stateFixed.addressMain].applicationMessageParameter.versionOfTheTermsAndConditions === state.config.clientParameters.versionOfTheTermsAndConditions) {
          // クライアントには最新バージョンがロードされているので、規約同意を促す。
          dispatch({ type: 'setNotification', key: 'notification', value: words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language] });

          const popup = { type: 'userRegistrationPopup' };
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
        }
        else {
          // クライアントに最新バージョンがロードされていないので、ブラウザリロードと規約同意を促す。
          dispatch({ type: 'setNotification', key: 'notification', value: words.youMustReloadYourBrowserAndAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language] });
        }
      }
      else if (response.body[stateFixed.addressMain].applicationMessage === 'you must accept the privacy policy of the current version.') {
        // 基本情報をconfigureにセット（認証は成功しているので、プライバシーポリシーに同意しやすいように基本情報は埋めてあげる。）
        dispatch({ type: 'setState', key: 'configure', value: response.body[stateFixed.addressMain].basicInformation });

        if (response.body[stateFixed.addressMain].applicationMessageParameter.versionOfPrivacyPolicy === state.config.clientParameters.versionOfPrivacyPolicy) {
          // クライアントには最新バージョンがロードされているので、プライバシーポリシーへの同意を促す。
          dispatch({ type: 'setNotification', key: 'notification', value: words.youMustAcceptThePrivacyPolicyOfTheCurrentVersion[state.language] });

          const popup = { type: 'userRegistrationPopup' };
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
        }
        else {
          // クライアントに最新バージョンがロードされていないので、ブラウザリロードとプライバシーポリシーへの同意を促す。
          dispatch({ type: 'setNotification', key: 'notification', value: words.youMustReloadYourBrowserAndAcceptThePrivacyPolicyOfTheCurrentVersion[state.language] });
        }
      }
      else {
        dispatch({ type: 'setNotification', key: 'notification', value: words.youFailedToLogin[state.language] });
      }
    }
  }
  else {
  }
}

// 「設定」ボタン押下 HANDLE CLICK SETUP
async function handleClickSetup(state, dispatch, cookies, setCookie, keyPairs) {
  let addressMainActual;
  let signature;
  let result;

  // state固定
  const stateFixed = {
    addressMain: state.configure.addressMain,
    addressCardFromDefault: state.configure.addressCardFromDefault,
    addressPayProceedsToDefault: state.configure.addressPayProceedsToDefault,
    addressPayFromDefault: state.configure.addressPayFromDefault,
    addressSendCardToDefault: state.configure.addressSendCardToDefault,
    userName: state.configure.userName,
    homed: state.configure.homed,
    profileText: state.configure.profileText,
    imageMain: state.configure.imagesNew.main,
    acceptedVersionOfTheTermsAndConditions: state.configure.acceptedVersionOfTheTermsAndConditions,
    acceptedVersionOfPrivacyPolicy: state.configure.acceptedVersionOfPrivacyPolicy,
    signatureVersion: state.config.clientParameters.signatureVersion.configure,
    addressCheck: state.addressCheck,
    walletType: state.walletType,
  };

  // if (keyPairs !== undefined && keyPairs !== null) {
  //   keyPair = keyPairs[0];
  //   const result = getAddressFromKey(state, dispatch, keyPair);

  //   if (result.status === 'fulfilled') {
  //     stateFixed.addressMain = result.address;
  //   }
  //   else {
  //     dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
  //     return 'rejected';
  //   }
  // }

  // 必須項目チェック
  // if (stateFixed.addressMain === undefined || stateFixed.addressMain === null || stateFixed.addressMain === '') {
  //   dispatch({ type: 'setNotification', key: 'notification', value: words.mainAddressMustBeFilled[state.language] });
  //   return;
  // }

  if (stateFixed.userName === undefined || stateFixed.userName === null || stateFixed.userName === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.userNameMustBeFilled[state.language] });
    return;
  }

  if (stateFixed.acceptedVersionOfTheTermsAndConditions === undefined ||
      stateFixed.acceptedVersionOfTheTermsAndConditions === null ||
      stateFixed.acceptedVersionOfTheTermsAndConditions === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.acceptTheTermsAndConditions[state.language] });
    return;
  }

  if (stateFixed.acceptedVersionOfPrivacyPolicy === undefined ||
      stateFixed.acceptedVersionOfPrivacyPolicy === null ||
      stateFixed.acceptedVersionOfPrivacyPolicy === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.acceptThePrivacyPolicy[state.language] });
    return;
  }

  const clientTime = Date.now();

  const getMessage = (addressMain) => {
    return `I want to use monacotto. My main address is ${addressMain}. My name is ${stateFixed.userName}. I will send cards from ${stateFixed.addressCardFromDefault} and I want to receive proceeds in ${stateFixed.addressPayProceedsToDefault} as default. I will pay from ${stateFixed.addressPayFromDefault} and I want to receive cards in ${stateFixed.addressSendCardToDefault} as default. I accept the terms and conditions version ${stateFixed.acceptedVersionOfTheTermsAndConditions} and privacy policy version ${stateFixed.acceptedVersionOfPrivacyPolicy}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
  }

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' && keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined) {
    const keyPair = keyPairsInPool[stateFixed.addressMain].keyPair;
    addressMainActual = stateFixed.addressMain;

    const result = signWithKey(state, dispatch, keyPair, getMessage(stateFixed.addressMain));

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    }
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    const keyPair = keyPairs[0];
    let result;

    result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      addressMainActual = result.address;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return 'rejected';
    }

    // addressMainが指定されていれば、アドレスチェックを行う。
    // 指定されていなければ、addressMainActualをaddressMainとして扱う。
    if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '') {
      if (!checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressMainActual, stateFixed.addressMain, stateFixed.addressCheck)) {
        return { status: 'rejected' };
      }
    }
    else {
      stateFixed.addressMain = addressMainActual;
    }

    result = signWithKey(state, dispatch, keyPair, getMessage(stateFixed.addressMain));

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    }
  }
  else if (stateFixed.walletType === 'mpurse') {
    const result = await window.mpurse.getAddress()
    .then( (result) => {
      addressMainActual = result;

      // addressMainが指定されていれば、アドレスチェックを行う。
      // 指定されていなければ、addressMainActualをaddressMainとして扱う。
      if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '') {
        if (!checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressMainActual, stateFixed.addressMain, stateFixed.addressCheck)) {
          return 'rejected';
        }
      }
      else {
        stateFixed.addressMain = addressMainActual;
      }
    })
    .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
        return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    // 署名
    try {
      signature= await window.mpurse.signMessage(getMessage(stateFixed.addressMain)).then( result => result );
    }
    catch (err) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return;
    }
  }
  else if (stateFixed.walletType === 'nfc') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
    return {
      status: 'rejected',
      message: 'nfcRequired',
    };
  }
  else { // qr
    dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
    return {
      status: 'rejected',
      message: 'bcRequired',
    };
  }

  // GO！
  const resultConfigure = await configure(state, stateFixed, dispatch, cookies, setCookie, 'upsert', clientTime, signature, addressMainActual);
}

async function configure(state, stateFixed, dispatch, cookies, setCookie, method, clientTime, signature, addressMainActual) {
  let request = {};
  let messages = {};

  const formData = new FormData();

  const requestJson = JSON.stringify({
    "body": {
      "method": method,
      "addressMain": stateFixed.addressMain,
      "addressMainActual": addressMainActual,
      "addressCardFromDefault": stateFixed.addressCardFromDefault,
      "addressPayProceedsToDefault": stateFixed.addressPayProceedsToDefault,
      "addressPayFromDefault": stateFixed.addressPayFromDefault,
      "addressSendCardToDefault": stateFixed.addressSendCardToDefault,
      "userName": stateFixed.userName,
      "homed": stateFixed.homed,
      "profileText": stateFixed.profileText,
      "acceptedVersionOfTheTermsAndConditions": stateFixed.acceptedVersionOfTheTermsAndConditions,
      "acceptedVersionOfPrivacyPolicy": stateFixed.acceptedVersionOfPrivacyPolicy,
      "clientTime" : clientTime,
      "signature" : signature,
      "signatureVersion" : stateFixed.signatureVersion,
    }
  });

  formData.append('json', requestJson);

  if (stateFixed.imageMain !== undefined && stateFixed.imageMain !== null) {
    formData.append('imageMain', stateFixed.imageMain, stateFixed.imageMain.name);
  }

  request.body = formData;
  request.headers = {};
  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/configure';
  request.method = 'POST';

  messages = {
    "you must accept the terms and conditions of the current version.": words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language],
    "internal server error": words.internalServerError[state.language],
    "bad signature": words.badSignature[state.language],
    "bad signature, can't recover address": words.badSignatureCanNotRecoverAddress[state.language],
    "unknown signatureVersion": words.unknownSignatureVersion[state.language],
    "clientTime must be larger than last time.": words.clientTimeMustBeLargerThanLastTime[state.language],
    "this address is not permitted.": words.thisAddressIsNotPermitted[state.language],
    "this address is forbidden.": words.thisAddressIsForbidden[state.language],
    "out of service": words.outOfService[state.language],
    "you are not delegated.": words.norIsTheSignatureDelegatedFromMainAddress[state.language],
    "addressMainActual must be filled.": words.clearYourBrowserOrAppCache[state.language],
    "success": words.success[state.language],
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, undefined, undefined, 'notification', messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {
    // session情報更新
    dispatch({ type: 'setStateMultiLayers', keys: ['session', stateFixed.addressMain], value: {'sessionId': response.body.sessionId.addressMain, 'expirationOfSession': response.body.expirationOfSession.addressMain} });

    if (addressMainActual !== stateFixed.addressMain) {
      dispatch({ type: 'setStateMultiLayers', keys: ['session', addressMainActual], value: {'sessionId': response.body.sessionId.addressMainActual, 'expirationOfSession': response.body.expirationOfSession.addressMainActual} });
    }

    // addressMainが指定されていなかった場合は、dispatchする。
    if (state.configure.addressMain === undefined || state.configure.addressMain === null || state.configure.addressMain === '') {
      dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'addressMain'], value: stateFixed.addressMain });
    }

    // オーダーNo.通知欄表示
    // dispatch({ type: 'setNotification', key: 'notification', value: words.orderAcceptedOrderNo[state.language] + response.body.orderNo + words.orderAcceptedOrderNo2[state.language] });

    // -- ユーザ情報取得
    const usersGeneral = await getUser(state, dispatch, 'all');
    devLog('usersGeneral', JSON.stringify(usersGeneral));

    let usersGeneralIndex = {};

    for (const user of usersGeneral.body) {
      usersGeneralIndex[user.addressMain] = user;
    }

    dispatch({ type: 'setState', key: 'usersGeneralIndex', value: usersGeneralIndex });

    // cookie情報更新(アドレス情報)
    const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
    const cookiesNew = [
          {
            action: 'setUp',
            addressType: 'addressMain',
            description: `${userName}`,
            time: clientTime,
            address: stateFixed.addressMain
          },
    ];

    setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
  }
  else {
  }

  return response;
}


// 「許可リクエスト」ボタン押下 HANDLE CLICK REQUEST PERMISSION
async function handleClickRequestPermission(state, dispatch, cookies, setCookie, keyPairs) {
  let addressActual;
  let request = {};
  let messages = {};
  let signature;

  // state固定
  const stateFixed = {
    addressMain: state.requestPermission.addressMain,
    addressOwner: state.requestPermission.addressOwner,
    royaltyPercentage: state.requestPermission.royaltyPercentage.value,
    signatureVersion: state.config.clientParameters.signatureVersion.requestPermission,
    addressCheck: state.addressCheck,
    walletType: state.walletType,
  };

  // 必須項目チェック
  // if (stateFixed.addressMain === undefined || stateFixed.addressMain === null || stateFixed.addressMain === '') {
  //   dispatch({ type: 'setNotification', key: 'notification', value: words.mainAddressMustBeFilled[state.language] });
  //   return;
  // }

  if (stateFixed.addressOwner === undefined || stateFixed.addressOwner === null || stateFixed.addressOwner === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.ownerAddressMustBeFilled[state.language] });
    return;
  }

  if (stateFixed.royaltyPercentage === undefined || stateFixed.royaltyPercentage === null || stateFixed.royaltyPercentage === '') {
    stateFixed.royaltyPercentage = 0;
  }

  // 数量チェック
  if (stateFixed.royaltyPercentage > 90) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.royaltyPercentageMustBeEqualOrSmallerThan90[state.language] });
    return;
  }

  if (stateFixed.royaltyPercentage < 0) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.royaltyPercentageMustBeEqualOrLargerThan0[state.language] });
    return;
  }


  // 署名
  const clientTime = Date.now();

  const getMessage = (addressMain) => {
    return `I request the owner of ${stateFixed.addressOwner} to permit me(${addressMain}) to sell tokens owned(issued) by him/her for the ${stateFixed.royaltyPercentage}% royalty. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
  };

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' && keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined) {
    const keyPair = keyPairsInPool[stateFixed.addressMain].keyPair;
    addressActual = stateFixed.addressMain;

    const result = signWithKey(state, dispatch, keyPair, getMessage(stateFixed.addressMain));

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return { status: 'rejected' };
    }
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    const keyPair = keyPairs[0];
    let result;

    result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      addressActual = result.address;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return { status: 'rejected' };
    }

    // addressMainが指定されていれば、アドレスチェックを行う。
    // 指定されていなければ、addressMainActualをaddressMainとして扱う。
    if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '') {
      if (!checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressActual, stateFixed.addressMain, stateFixed.addressCheck)) {
        return { status: 'rejected' };
      }
    }
    else {
      stateFixed.addressMain = addressActual;
    }

    result = signWithKey(state, dispatch, keyPair, getMessage(stateFixed.addressMain));

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return { status: 'rejected' };
    }
  }
  else if (stateFixed.walletType === 'mpurse') {
    const result = await window.mpurse.getAddress()
    .then( (result) => {
      addressActual = result;

      // addressMainが指定されていれば、アドレスチェックを行う。
      // 指定されていなければ、addressMainActualをaddressMainとして扱う。
      if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '') {
        if (!checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressActual, stateFixed.addressMain, stateFixed.addressCheck)) {
          return 'rejected';
        }
      }
      else {
        stateFixed.addressMain = addressActual;
      }
    })
    .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
        return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    // 署名
    try {
      signature= await window.mpurse.signMessage(getMessage(stateFixed.addressMain)).then( result => result );
    }
    catch (err) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return;
    }
  }
  else if (stateFixed.walletType === 'nfc') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
    return {
      status: 'rejected',
      message: 'nfcRequired',
    };
  }
  else { // qr
    dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
    return {
      status: 'rejected',
      message: 'bcRequired',
    };
  }

  // GO！

  request.body = JSON.stringify({
    body: {
      addressMain: stateFixed.addressMain,
      addressOwner: stateFixed.addressOwner,
      addressMainActual: addressActual,
      royaltyPercentage: stateFixed.royaltyPercentage,
      clientTime: clientTime,
      signature: signature,
      signatureVersion: stateFixed.signatureVersion,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/request_permission';
  request.method = 'POST';

  messages = {
    "you must accept the terms and conditions of the current version.": words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language],
    "internal server error": words.internalServerError[state.language],
    "bad signature": words.badSignature[state.language],
    "bad signature, can't recover address": words.badSignatureCanNotRecoverAddress[state.language],
    "unknown signatureVersion": words.unknownSignatureVersion[state.language],
    "clientTime must be larger than last time.": words.clientTimeMustBeLargerThanLastTime[state.language],
    "this address is not permitted.": words.thisAddressIsNotPermitted[state.language],
    "this address is forbidden.": words.thisAddressIsForbidden[state.language],
    "order expired.": words.orderExpired[state.language],
    "out of service": words.outOfService[state.language],
    "user does not exist.": words.addressIsNotBeRegistered[state.language],
    "you are not delegated from main address.": words.norIsTheSignatureDelegatedFromMainAddress[state.language],
    "success": words.success[state.language],
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, undefined, undefined, 'notification', messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {
    // session情報更新
    dispatch({ type: 'setStateMultiLayers', keys: ['session', stateFixed.addressMain], value: {'sessionId': response.body.sessionId.addressMain, 'expirationOfSession': response.body.expirationOfSession.addressMain} });

    if (addressActual !== stateFixed.addressMain) {
      dispatch({ type: 'setStateMultiLayers', keys: ['session', addressActual], value: {'sessionId': response.body.sessionId.addressMainActual, 'expirationOfSession': response.body.expirationOfSession.addressMainActual} });
    }

    // addressMainが指定されていなかった場合は、dispatchする。
    if (state.requestPermission.addressMain === undefined || state.requestPermission.addressMain === null || state.requestPermission.addressMain === '') {
      dispatch({ type: 'setStateMultiLayers', keys: ['requestPermission', 'addressMain'], value: stateFixed.addressMain });
    }

    // オーダーNo.通知欄表示
    // dispatch({ type: 'setNotification', key: 'notification', value: words.orderAcceptedOrderNo[state.language] + response.body.orderNo + words.orderAcceptedOrderNo2[state.language] });

    // cookie情報更新(アドレス情報)
    const ownerName = state.usersGeneralIndex[stateFixed.addressOwner] !== undefined ? state.usersGeneralIndex[stateFixed.addressOwner].userName : stateFixed.addressOwner;
    const cookiesNew = [
          {
            action: 'requestPermissionToExhibit',
            addressType: 'addressMain',
            description: `${ownerName} ${stateFixed.royaltyPercentage}%`,
            time: clientTime,
            address: stateFixed.addressMain
          },
    ];

    if (cookiesNew.every( cookie => cookie.address !== stateFixed.addressOwner )) {
      cookiesNew.push(
          {
            action: 'requestPermissionToExhibit',
            addressType: 'addressOwner',
            description: `${ownerName} ${stateFixed.royaltyPercentage}%`,
            time: clientTime,
            address: stateFixed.addressOwner
          },
      );
    }

    setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
  }
  else {
  }

  return response;
}


// 「許可」ボタン押下 HANDLE CLICK PERMIT
async function handleClickPermit(state, dispatch, cookies, setCookie, keyPairs) {
  let addressActual;
  let request = {};
  let messages = {};
  let signature;

  // state固定
  const stateFixed = {
    action: state.permit.action,
    mode: state.permit.mode,
    addressOwner: state.permit.addressOwner,
    addressPermitTo: state.permit.addressPermitTo,
    addressPayRoyaltyTo: state.permit.addressPayRoyaltyTo,
    // ownerName: state.permit.ownerName,
    royaltyPercentage: state.permit.royaltyPercentage.value,
    // acceptTheTermsAndConditions: state.acceptTheTermsAndConditions,
    signatureVersion: state.config.clientParameters.signatureVersion.permit,
    addressCheck: state.addressCheck,
    walletType: state.walletType,
  };

  // 必須項目チェック
  // if (stateFixed.addressOwner === undefined || stateFixed.addressOwner === null || stateFixed.addressOwner === '') {
  //   dispatch({ type: 'setNotification', key: 'notification', value: words.ownerAddressMustBeFilled[state.language] });
  //   return;
  // }

  if (stateFixed.royaltyPercentage === undefined || stateFixed.royaltyPercentage === null || stateFixed.royaltyPercentage === '') {
    stateFixed.royaltyPercentage = 0;
  }

  if (stateFixed.action !== 'grant' && stateFixed.action !== 'revoke') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.selectGrantOrRevoke[state.language] });
    return;
  }

  if (stateFixed.mode !== 'individual' && stateFixed.mode !== 'inclusive') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.selectIndividualOrInclusive[state.language] });
    return;
  }

  // 数量チェック
  if (stateFixed.royaltyPercentage > 90) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.royaltyPercentageMustBeEqualOrSmallerThan90[state.language] });
    return;
  }

  if (stateFixed.royaltyPercentage < 0) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.royaltyPercentageMustBeEqualOrLargerThan0[state.language] });
    return;
  }

  // 複合チェック
  if (stateFixed.action === 'grant') {
    if (stateFixed.royaltyPercentage !== undefined && stateFixed.royaltyPercentage !== null && stateFixed.royaltyPercentage !== '' && stateFixed.royaltyPercentage !== 0) {
      if (stateFixed.addressPayRoyaltyTo === undefined || stateFixed.addressPayRoyaltyTo === null || stateFixed.addressPayRoyaltyTo === '') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.addressYouReceiveRoyaltyInMustBeFilled[state.language] });
        return;
      }
    }
  }

  if (stateFixed.mode === 'individual') {
    if (stateFixed.addressPermitTo === undefined || stateFixed.addressPermitTo === null || stateFixed.addressPermitTo === '') {
      dispatch({ type: 'setNotification', key: 'notification', value: words.addressYouPermitToExhibitMustBeFilled[state.language] });
      return;
    }
  }
  else { // inclusive
    stateFixed.addressPermitTo = '0';
  }

  // const checkResult = await checkMpurseAddress(state, dispatch, stateFixed, 'addressOwner');

  // if (checkResult.status === 'rejected') {
  //   return { status: 'rejected' };
  // }
  // else {
  //   addressActual = checkResult.addressActual;
  // }

  // 署名

  const clientTime = Date.now();

  const getMessage = (addressOwner) => {
    if (stateFixed.mode === 'individual') {
      if (stateFixed.action === 'grant') {
        if (stateFixed.addressPayRoyaltyTo !== undefined && stateFixed.addressPayRoyaltyTo !== null && stateFixed.addressPayRoyaltyTo !== '') {
          return `I permit the owner of ${stateFixed.addressPermitTo} to sell tokens owned(issued) by ${addressOwner} for the ${stateFixed.royaltyPercentage}% royalty. I want receive royalty in ${stateFixed.addressPayRoyaltyTo}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
        }
        else {
          return `I permit the owner of ${stateFixed.addressPermitTo} to sell tokens owned(issued) by ${addressOwner} for the ${stateFixed.royaltyPercentage}% royalty. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
        }
      }
      else { // revoke
        return `I revoke permission for the owner of ${stateFixed.addressPermitTo} to sell tokens owned(issued) by ${addressOwner}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
      }
    }
    else { // inclusive
      if (stateFixed.action === 'grant') {
        if (stateFixed.addressPayRoyaltyTo !== undefined && stateFixed.addressPayRoyaltyTo !== null && stateFixed.addressPayRoyaltyTo !== '') {
          return `I permit inclusively to sell tokens owned(issued) by ${addressOwner} for the ${stateFixed.royaltyPercentage}% royalty. I want receive royalty in ${stateFixed.addressPayRoyaltyTo}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
        }
        else {
          return `I permit inclusively to sell tokens owned(issued) by ${addressOwner} for the ${stateFixed.royaltyPercentage}% royalty. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
        }
      }
      else { // revoke
        return `I revoke inclusive permission to sell tokens owned(issued) by ${addressOwner}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
      }
    }
  };

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  if (stateFixed.addressOwner !== undefined && stateFixed.addressOwner !== null && stateFixed.addressOwner !== '' && keyPairsInPool[stateFixed.addressOwner]?.keyPair !== undefined) {
    const keyPair = keyPairsInPool[stateFixed.addressOwner].keyPair;
    addressActual = stateFixed.addressOwner;

    const result = signWithKey(state, dispatch, keyPair, getMessage(stateFixed.addressOwner));

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return { status: 'rejected' };
    }
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    const keyPair = keyPairs[0];
    let result;

    result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      addressActual = result.address;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return { status: 'rejected' };
    }

    // addressOwnerが指定されていれば、アドレスチェックを行う。
    // 指定されていなければ、addressMainActualをaddressOwnerとして扱う。
    if (stateFixed.addressOwner !== undefined && stateFixed.addressOwner !== null && stateFixed.addressOwner !== '') {
      if (!checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressActual, stateFixed.addressOwner, stateFixed.addressCheck)) {
        return { status: 'rejected' };
      }
    }
    else {
      stateFixed.addressOwner = addressActual;
    }

    result = signWithKey(state, dispatch, keyPair, getMessage(stateFixed.addressOwner));

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return { status: 'rejected' };
    }
  }
  else if (stateFixed.walletType === 'mpurse') {
    const result = await window.mpurse.getAddress()
    .then( (result) => {
      addressActual = result;

      // addressOwnerが指定されていれば、アドレスチェックを行う。
      // 指定されていなければ、addressMainActualをaddressOwnerとして扱う。
      if (stateFixed.addressOwner !== undefined && stateFixed.addressOwner !== null && stateFixed.addressOwner !== '') {
        if (!checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressActual, stateFixed.addressOwner, stateFixed.addressCheck)) {
          return 'rejected';
        }
      }
      else {
        stateFixed.addressOwner = addressActual;
      }
    })
    .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
        return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    // 署名
    try {
      signature= await window.mpurse.signMessage(getMessage(stateFixed.addressOwner)).then( result => result );
    }
    catch (err) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return;
    }
  }
  else if (stateFixed.walletType === 'nfc') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
    return {
      status: 'rejected',
      message: 'nfcRequired',
    };
  }
  else { // qr
    dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
    return {
      status: 'rejected',
      message: 'bcRequired',
    };
  }

  // GO！

  request.body = JSON.stringify({
    body: {
      method: 'upsert',
      action: stateFixed.action,
      mode: stateFixed.mode,
      addressPermitTo: stateFixed.addressPermitTo,
      addressOwnerActual: addressActual,
      addressOwner: stateFixed.addressOwner,
      addressPayRoyaltyTo: stateFixed.addressPayRoyaltyTo,
      royaltyPercentage: stateFixed.royaltyPercentage,
      clientTime : clientTime,
      signature : signature,
      signatureVersion : stateFixed.signatureVersion,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/permit';
  request.method = 'POST';

  messages = {
    "you must accept the terms and conditions of the current version.": words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language],
    "internal server error": words.internalServerError[state.language],
    "bad signature": words.badSignature[state.language],
    "bad signature, can't recover address": words.badSignatureCanNotRecoverAddress[state.language],
    "unknown signatureVersion": words.unknownSignatureVersion[state.language],
    "clientTime must be larger than last time.": words.clientTimeMustBeLargerThanLastTime[state.language],
    "this address is not permitted.": words.thisAddressIsNotPermitted[state.language],
    "this address is forbidden.": words.thisAddressIsForbidden[state.language],
    "order expired.": words.orderExpired[state.language],
    "out of service": words.outOfService[state.language],
    "user does not exist.": words.addressIsNotBeRegistered[state.language],
    "you are not delegated from owner address.": words.norIsTheSignatureDelegatedFromOwnerAddress[state.language],
    "success": words.success[state.language],
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, undefined, undefined, 'notification', messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {
    // session情報更新
    dispatch({ type: 'setStateMultiLayers', keys: ['session', stateFixed.addressOwner], value: {'sessionId': response.body.sessionId.addressOwner, 'expirationOfSession': response.body.expirationOfSession.addressOwner} });

    if (addressActual !== stateFixed.addressOwner) {
      dispatch({ type: 'setStateMultiLayers', keys: ['session', addressActual], value: {'sessionId': response.body.sessionId.addressOwnerActual, 'expirationOfSession': response.body.expirationOfSession.addressOwnerActual} });
    }

    // addressOwnerが指定されていなかった場合は、dispatchする。
    if (state.permit.addressOwner === undefined || state.permit.addressOwner === null || state.permit.addressOwner === '') {
      dispatch({ type: 'setStateMultiLayers', keys: ['permit', 'addressOwner'], value: stateFixed.addressOwner });
    }

    // オーダーNo.通知欄表示
    // dispatch({ type: 'setNotification', key: 'notification', value: words.orderAcceptedOrderNo[state.language] + response.body.orderNo + words.orderAcceptedOrderNo2[state.language] });

    // cookie情報更新(アドレス情報)
    const action = stateFixed.action === 'grant' ? 'permitToExhibit' : 'revokePermissionToExhibit';
    const exhibitorName = state.usersGeneralIndex[stateFixed.addressPermitTo] !== undefined ? state.usersGeneralIndex[stateFixed.addressPermitTo].userName : stateFixed.addressPermitTo;
    const cookiesNew = [
          {
            action: action,
            addressType: 'addressOwner',
            description: `${exhibitorName} ${stateFixed.royaltyPercentage}%`,
            time: clientTime,
            address: stateFixed.addressOwner
          },
    ];

    if (cookiesNew.every( cookie => cookie.address !== stateFixed.addressPermitTo ) && stateFixed.mode === 'individual') {
      cookiesNew.push(
          {
            action: action,
            addressType: 'addressPermitTo',
            description: `${exhibitorName} ${stateFixed.royaltyPercentage}%`,
            time: clientTime,
            address: stateFixed.addressPermitTo
          },
      );
    }

    if (stateFixed.action === 'grant') {
      if (stateFixed.addressPayRoyaltyTo !== undefined && stateFixed.addressPayRoyaltyTo !== null && stateFixed.addressPayRoyaltyTo !== '') {
        if (cookiesNew.every( cookie => cookie.address !== stateFixed.addressPayRoyaltyTo )) {
          cookiesNew.push(
              {
                action: action,
                addressType: 'addressPayRoyaltyTo',
                description: `${exhibitorName} ${stateFixed.royaltyPercentage}%`,
                time: clientTime,
                address: stateFixed.addressPayRoyaltyTo
              },
          );
        }
      }
    }

    setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressOwner, cookiesNew);
  }
  else {
  }

  return response;
}


// 「委任」ボタン押下 HANDLE CLICK DELEGATE
async function handleClickDelegate(state, dispatch, cookies, setCookie, keyPairs) {
  let addressActual;
  let request = {};
  let messages = {};
  let addressForSignature;
  // let addressForSignatureKey;
  let signature;

  // state固定
  const stateFixed = {
    action: state.delegate.action,
    addressDelegateFrom: state.delegate.addressDelegateFrom,
    addressDelegateTo: state.delegate.addressDelegateTo,
    signatureVersion: state.config.clientParameters.signatureVersion.delegate,
    addressCheck: state.addressCheck,
    walletType: state.walletType,
  };

  // 必須項目チェック
  if (stateFixed.action === 'accept' || stateFixed.action === 'revokeAcceptance') {
    if (stateFixed.addressDelegateFrom === undefined || stateFixed.addressDelegateFrom === null || stateFixed.addressDelegateFrom === '') {
      dispatch({ type: 'setNotification', key: 'notification', value: words.addressDelegateFromMustBeFilled[state.language] });
      return { status: 'rejected' };
    }
  }

  if (stateFixed.action === 'request' || stateFixed.action === 'revokeRequest') {
    if (stateFixed.addressDelegateTo === undefined || stateFixed.addressDelegateTo === null || stateFixed.addressDelegateTo === '') {
      dispatch({ type: 'setNotification', key: 'notification', value: words.addressDelegateToMustBeFilled[state.language] });
      return { status: 'rejected' };
    }
  }

  if (stateFixed.action !== 'request' && stateFixed.action !== 'revokeRequest' && stateFixed.action !== 'accept' && stateFixed.action !== 'revokeAcceptance') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.selectRequestOrRevokeRequestOrAcceptOrRevokeAcceptance[state.language] });
    return { status: 'rejected' };
  }

  // 署名する秘密鍵に紐づくアドレス
  if (stateFixed.action === 'request' || stateFixed.action === 'revokeRequest') {
    addressForSignature = stateFixed.addressDelegateFrom;
    // addressForSignatureKey = 'addressDelegateFrom';
  }
  else { // accept or revokeAcceptance
    addressForSignature = stateFixed.addressDelegateTo;
    // addressForSignatureKey = 'addressDelegateTo';
  }

  // 署名
  const clientTime = Date.now();

  const getMessage = (addressForSignature) => {
    if (stateFixed.action === 'request') {
      return `I delegate the signature by the private key pertaining the address ${addressForSignature} to the owner of address ${stateFixed.addressDelegateTo} in monacotto. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
    }
    else if (stateFixed.action === 'revokeRequest') {
      return `I revoke the delegation of the signature by the private key pertaining the address ${addressForSignature} to the owner of address ${stateFixed.addressDelegateTo} in monacotto. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
    }
    else if (stateFixed.action === 'accept') {
      return `I accept the delegation of the signature by the private key pertaining the address ${stateFixed.addressDelegateFrom} to the owner of address ${addressForSignature} in monacotto. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
    }
    else { // revokeAcceptance
      return `I revoke the acceptance of the delegation of the signature by the private key pertaining the address ${stateFixed.addressDelegateFrom} to the owner of address ${addressForSignature} in monacotto. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
    }
  };

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  if (addressForSignature !== undefined && addressForSignature !== null && addressForSignature !== '' && keyPairsInPool[addressForSignature]?.keyPair !== undefined) {
    const keyPair = keyPairsInPool[addressForSignature].keyPair;
    addressActual = addressForSignature;

    const result = signWithKey(state, dispatch, keyPair, getMessage(addressForSignature));

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return { status: 'rejected' };
    }
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    const keyPair = keyPairs[0];
    let result;

    result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      addressActual = result.address;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return { status: 'rejected' };
    }

    // addressForSignatureいれば、アドレスチェックを行う。
    // 指定されていなければ、addressMainActualをaddressForSignature
    if (addressForSignature !== undefined && addressForSignature !== null && addressForSignature !== '') {
      if (!checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressActual, addressForSignature, stateFixed.addressCheck)) {
        return { status: 'rejected' };
      }
    }
    else {
      addressForSignature = addressActual;

      if (stateFixed.action === 'request' || stateFixed.action === 'revokeRequest') {
        stateFixed.addressDelegateFrom = addressActual;
      }
      else { // accept or revokeAcceptance
        stateFixed.addressDelegateTo = addressActual;
      }
    }

    result = signWithKey(state, dispatch, keyPair, getMessage(addressForSignature));

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return { status: 'rejected' };
    }
  }
  else if (stateFixed.walletType === 'mpurse') {
    const result = await window.mpurse.getAddress()
    .then( (result) => {
      addressActual = result;

      // addressForSignatureいれば、アドレスチェックを行う。
      // 指定されていなければ、addressMainActualをaddressForSignature
      if (addressForSignature !== undefined && addressForSignature !== null && addressForSignature !== '') {
        if (!checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressActual, addressForSignature, stateFixed.addressCheck)) {
          return 'rejected';
        }
      }
      else {
        addressForSignature = addressActual;
      }
    })
    .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
        return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    // 署名
    try {
      signature= await window.mpurse.signMessage(getMessage(addressForSignature)).then( result => result );
    }
    catch (err) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return;
    }
  }
  else if (stateFixed.walletType === 'nfc') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
    return {
      status: 'rejected',
      message: 'nfcRequired',
    };
  }
  else { // qr
    dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
    return {
      status: 'rejected',
      message: 'bcRequired',
    };
  }

  // GO！

  request.body = JSON.stringify({
    body: {
      action: stateFixed.action,
      addressDelegateFrom: stateFixed.addressDelegateFrom,
      addressDelegateTo: stateFixed.addressDelegateTo,
      addressActual: addressActual,
      clientTime: clientTime,
      signature: signature,
      signatureVersion: stateFixed.signatureVersion,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/delegate';
  request.method = 'POST';

  messages = {
    "you must accept the terms and conditions of the current version.": words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language],
    "internal server error": words.internalServerError[state.language],
    "bad signature": words.badSignature[state.language],
    "bad signature, can't recover address": words.badSignatureCanNotRecoverAddress[state.language],
    "unknown signatureVersion": words.unknownSignatureVersion[state.language],
    "clientTime must be larger than last time.": words.clientTimeMustBeLargerThanLastTime[state.language],
    "this address is not permitted.": words.thisAddressIsNotPermitted[state.language],
    "this address is forbidden.": words.thisAddressIsForbidden[state.language],
    "order expired.": words.orderExpired[state.language],
    "out of service": words.outOfService[state.language],
    "user does not exist.": words.addressIsNotBeRegistered[state.language],
    "you are not delegated from delegation source address.": words.norIsTheSignatureDelegatedFromDelegationSourceAddress[state.language],
    "you are not delegated from delegated address.": words.norIsTheSignatureDelegatedFromDelegatedAddress[state.language],
    "success": words.success[state.language],
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, undefined, undefined, 'notification', messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {
    // session情報更新
    dispatch({ type: 'setStateMultiLayers', keys: ['session', addressForSignature], value: {'sessionId': response.body.sessionId.addressForVerify, 'expirationOfSession': response.body.expirationOfSession.addressForVerify} });

    if (addressActual !== addressForSignature) {
      dispatch({ type: 'setStateMultiLayers', keys: ['session', addressActual], value: {'sessionId': response.body.sessionId.addressActual, 'expirationOfSession': response.body.expirationOfSession.addressActual} });
    }

    // addressForSignatureが指定されていなかった場合は、dispatchする。
    if (stateFixed.action === 'request' || stateFixed.action === 'revokeRequest') {
      if (state.delegate.addressDelegateFrom === undefined || state.delegate.addressDelegateFrom === null || state.delegate.addressDelegateFrom === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['delegate', 'addressDelegateFrom'], value: stateFixed.addressDelegateFrom });
      }
    }
    else { // accept or revokeAcceptance
      if (state.delegate.addressDelegateTo === undefined || state.delegate.addressDelegateTo === null || state.delegate.addressDelegateTo === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['delegate', 'addressDelegateTo'], value: stateFixed.addressDelegateTo });
      }
    }

    // オーダーNo.通知欄表示
    // dispatch({ type: 'setNotification', key: 'notification', value: words.orderAcceptedOrderNo[state.language] + response.body.orderNo + words.orderAcceptedOrderNo2[state.language] });

    // cookie情報更新(アドレス情報)
    let action;

    if (stateFixed.action === 'request') {
      action = 'delegate';
    }
    else if (stateFixed.action === 'revokeRequest') {
      action = 'revokeDelegation';
    }
    else if (stateFixed.action === 'accept') {
      action = 'acceptDelegation';
    }
    else { // revokeAcceptance
      action = 'revokeAcceptance';
    }

    const delegatorName = state.usersGeneralIndex[stateFixed.addressDelegateFrom] !== undefined ? state.usersGeneralIndex[stateFixed.addressDelegateFrom].userName : stateFixed.addressDelegateFrom;
    const delegateeName = state.usersGeneralIndex[stateFixed.addressDelegateTo] !== undefined ? state.usersGeneralIndex[stateFixed.addressDelegateTo].userName : stateFixed.addressDelegateTo;

    const cookiesNew = [
          {
            action: action,
            addressType: 'addressDelegateFrom',
            description: `${delegatorName} ${delegateeName}`,
            time: clientTime,
            address: stateFixed.addressDelegateFrom,
          },
    ];

    if (cookiesNew.every( cookie => cookie.address !== stateFixed.addressDelegateTo )) {
      cookiesNew.push(
          {
            action: action,
            addressType: 'addressDelegateTo',
            description: `${delegatorName} ${delegateeName}`,
            time: clientTime,
            address: stateFixed.addressDelegateTo,
          },
      );
    }

    setAddressesInCookie(state, dispatch, cookies, setCookie, addressForSignature, cookiesNew);
  }
  else {
  }

  return response;
}

// CHECK MPURSE ADDRESS
async function checkMpurseAddress(state, dispatch, stateFixed, addressFirst) {
  // Mpurseのアドレスが合ってるかチェック
  const result = await window.mpurse.getAddress()
  .then( (result) => {
    const addressActual = result;

    if (stateFixed.addressCheck === 'strict' || stateFixed.addressCheck === 'addressSecond') {
      if (addressActual !== stateFixed[addressFirst]) {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
        return { status: 'rejected' };
      }
      else {
        return { status: 'fulfilled', addressActual: addressActual };
      }
    }
    else { // off
      return { status: 'fulfilled', addressActual: addressActual };
    }
  })
  .catch( (error) => {
      dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
      return { status: 'rejected' };
  });

  return result;
}


// 「許可履歴」ボタン押下 HANDLE CLICK GET PERMIT HISTORY
async function handleClickGetPermitHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs) {
  let addressActual;
  let request = {};
  let messages = {};
  let certifications = [];
  const now = Date.now();

  // state固定
  const stateFixed = {
    addressMain: state.getPermissionHistory.addressMain,
    lastEvaluatedSortKey: pagingIndex >= 1 ? state.getPermissionHistory.lastEvaluatedKey.permit[pagingIndex - 1].addressPermitTo : null,
    signatureVersion: state.config.clientParameters.signatureVersion.getPermitHistory,
    addressCheck: state.addressCheck,
    walletType: state.walletType,
  };

  // 有効なセッションに絞り込む。

  for (const address of Object.keys(state.session)) {
    // state固定
    stateFixed[address] = {
      // addressCoinType: state.session[address].addressCoinType,
      sessionId: state.session[address].sessionId,
      expirationOfSession: state.session[address].expirationOfSession,
    };

    if (stateFixed[address].expirationOfSession >= now + marginOfSessionTime) {
      certifications.push({
        // addressCoinType: stateFixed[address].addressCoinType,
        address: address,
        sessionId: stateFixed[address].sessionId,
        lastEvaluatedSortKey: address === stateFixed.addressMain ? stateFixed.lastEvaluatedSortKey : null,
      });
    }
  }

  // アドレスが指定されており、そのアドレスのセッションが存在しない、または、期限が切れていれば、署名する。(キープール or Mpurse)
  // Mpurseの場合、アドレスが指定されていない場合は、アクティブなアドレスで代替するかもしれない。(アドレスチェックモードに依る。)
  // keyPairsが渡された場合(2周目)、keyPairsの先頭の鍵で試みる。アドレスが指定されていない場合は、先頭のアドレスで代替するかもしれない。(アドレスチェックモードに依る。)

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  const extendedCertificationProperties = [
  ];

  if (keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined) {
    certificationWithKey(state, dispatch, [ keyPairsInPool[stateFixed.addressMain].keyPair ], 'information', stateFixed, certifications, now, extendedCertificationProperties, 'matchedKey');
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    certificationWithKey(state, dispatch, keyPairs, 'information', stateFixed, certifications, now, extendedCertificationProperties, 'firstKey');
  }
  else if (stateFixed.walletType === 'mpurse') {
    await setSignatureByAddressMain(state, dispatch, stateFixed, certifications, now, extendedCertificationProperties, 'information');
  }

  // 対象がなければ戻る。
  // NFC, BCの場合、キーカードを要求して2周目。

  if (
    certifications.length === 0 ||
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    !certifications.map( certification => certification.address ).includes(stateFixed.addressMain)
  ) {
    if (keyPairs === undefined || keyPairs === null) {
      if (stateFixed.walletType === 'nfc') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
        return {
          status: 'rejected',
          message: 'nfcRequired',
        };
      }
      else if (stateFixed.walletType === 'qr') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
        return {
          status: 'rejected',
          message: 'bcRequired',
        };
      }
    }

    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }

  request.body = JSON.stringify({
    "body": {
      action: 'permit',
      certifications: certifications,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/get_some_history';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, 'permitHistory', undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    for (const address of Object.keys(response.body)) {
      // 署名した場合もろもろ更新
      if (response.body[address]['certificatedBy'] === 'signature') {

        // session更新
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address],
          value: {'sessionId': response.body[address]['sessionId'], 'expirationOfSession': response.body[address]['expirationOfSession']} });

        // dispatch({ type: 'setStateMultiLayers', keys: ['session', address],
        //   value: {'sessionId': response.body[address]['sessionId'], 'expirationOfSession': response.body[address]['expirationOfSession'], 'addressCoinType': response.body[address]['addressCoinType']} });
      }
      else if (response.body[address]['certificatedBy'] === 'none') {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address], value: { expirationOfSession: 0 } });
      }
    }

    // addressMainが検索対象で、検索成功した場合
    if (response.body[stateFixed.addressMain] !== undefined &&
         (response.body[stateFixed.addressMain].certificatedBy === 'signature' || response.body[stateFixed.addressMain].certificatedBy === 'sessionId')) {
      if (response.body[stateFixed.addressMain].lastEvaluatedKey !== undefined) {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'lastEvaluatedKey', 'permit', pagingIndex], value: response.body[stateFixed.addressMain].lastEvaluatedKey });
      }
      else {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'lastEvaluatedKey', 'permit', pagingIndex], value: null });
      }

      // pagingIndexが指定されているということは、前回の検索時からaddressMainが変更されていないということ。(変更するとリセットされてしまう。)
      // addressMainが指定されていないということは、後ページ検索ではない。
      // なぜならば、addressMainが指定されていない状態にするには、前回の検索のあと、addressMainを削除しなければならないから。

      dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'pagingIndex', 'permit'], value: pagingIndex });

      // addressMainが指定されていなかった場合は、dispatchする。
      if (state.getPermissionHistory.addressMain === undefined || state.getPermissionHistory.addressMain === null || state.getPermissionHistory.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'addressMain'], value: stateFixed.addressMain });
      }

      // cookie情報更新(アドレス情報)
      const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
      const cookiesNew = [
            {
              action: 'searchPermitHistory',
              addressType: 'addressMain',
              description: `${userName}`,
              time: now,
              address: stateFixed.addressMain
            },
      ];

      setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
    }

    return { status: 'fulfilled', body: response.body };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }
}

// 「被許可履歴」ボタン押下 HANDLE CLICK GET PERMITTED HISTORY
async function handleClickGetPermittedHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs) {
  let addressActual;
  let request = {};
  let messages = {};
  let certifications = [];
  const now = Date.now();

  // state固定
  const stateFixed = {
    addressMain: state.getPermissionHistory.addressMain,
    lastEvaluatedSortKey: pagingIndex >= 1 ? state.getPermissionHistory.lastEvaluatedKey.permitted[pagingIndex - 1].addressOwner : null,
    signatureVersion: state.config.clientParameters.signatureVersion.getPermittedHistory,
    addressCheck: state.addressCheck,
    walletType: state.walletType,
  };

  // 有効なセッションに絞り込む。

  for (const address of Object.keys(state.session)) {
    // state固定
    stateFixed[address] = {
      // addressCoinType: state.session[address].addressCoinType,
      sessionId: state.session[address].sessionId,
      expirationOfSession: state.session[address].expirationOfSession,
    };

    if (stateFixed[address].expirationOfSession >= now + marginOfSessionTime) {
      certifications.push({
        // addressCoinType: stateFixed[address].addressCoinType,
        address: address,
        sessionId: stateFixed[address].sessionId,
        lastEvaluatedSortKey: address === stateFixed.addressMain ? stateFixed.lastEvaluatedSortKey : null,
      });
    }
  }

  // アドレスが指定されており、そのアドレスのセッションが存在しない、または、期限が切れていれば、署名する。(キープール or Mpurse)
  // Mpurseの場合、アドレスが指定されていない場合は、アクティブなアドレスで代替するかもしれない。(アドレスチェックモードに依る。)
  // keyPairsが渡された場合(2周目)、keyPairsの先頭の鍵で試みる。アドレスが指定されていない場合は、先頭のアドレスで代替するかもしれない。(アドレスチェックモードに依る。)

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  const extendedCertificationProperties = [
  ];

  if (keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined) {
    certificationWithKey(state, dispatch, [ keyPairsInPool[stateFixed.addressMain].keyPair ], 'information', stateFixed, certifications, now, extendedCertificationProperties, 'matchedKey');
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    certificationWithKey(state, dispatch, keyPairs, 'information', stateFixed, certifications, now, extendedCertificationProperties, 'firstKey');
  }
  else if (stateFixed.walletType === 'mpurse') {
    await setSignatureByAddressMain(state, dispatch, stateFixed, certifications, now, extendedCertificationProperties, 'information');
  }

  // 対象がなければ戻る。
  // NFC, BCの場合、キーカードを要求して2周目。

  if (
    certifications.length === 0 ||
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    !certifications.map( certification => certification.address ).includes(stateFixed.addressMain)
  ) {
    if (keyPairs === undefined || keyPairs === null) {
      if (stateFixed.walletType === 'nfc') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
        return {
          status: 'rejected',
          message: 'nfcRequired',
        };
      }
      else if (stateFixed.walletType === 'qr') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
        return {
          status: 'rejected',
          message: 'bcRequired',
        };
      }
    }

    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }

  request.body = JSON.stringify({
    "body": {
      action: 'permitted',
      certifications: certifications,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/get_some_history';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, 'permittedHistory', undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    for (const address of Object.keys(response.body)) {
      // 署名した場合もろもろ更新
      if (response.body[address]['certificatedBy'] === 'signature') {

        // session更新
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address],
          value: {'sessionId': response.body[address]['sessionId'], 'expirationOfSession': response.body[address]['expirationOfSession']} });
      }
      else if (response.body[address]['certificatedBy'] === 'none') {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address], value: { expirationOfSession: 0 } });
      }
    }

    // addressMainが検索対象で、検索成功した場合
    if (response.body[stateFixed.addressMain] !== undefined &&
         (response.body[stateFixed.addressMain].certificatedBy === 'signature' || response.body[stateFixed.addressMain].certificatedBy === 'sessionId')) {
      if (response.body[stateFixed.addressMain].lastEvaluatedKey !== undefined) {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'lastEvaluatedKey', 'permitted', pagingIndex], value: response.body[stateFixed.addressMain].lastEvaluatedKey });
      }
      else {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'lastEvaluatedKey', 'permitted', pagingIndex], value: null });
      }

      // pagingIndexが指定されているということは、前回の検索時からaddressMainが変更されていないということ。(変更するとリセットされてしまう。)
      // addressMainが指定されていないということは、後ページ検索ではない。
      // なぜならば、addressMainが指定されていない状態にするには、前回の検索のあと、addressMainを削除しなければならないから。

      dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'pagingIndex', 'permitted'], value: pagingIndex });

      // addressMainが指定されていなかった場合は、dispatchする。
      if (state.getPermissionHistory.addressMain === undefined || state.getPermissionHistory.addressMain === null || state.getPermissionHistory.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'addressMain'], value: stateFixed.addressMain });
      }

      // cookie情報更新(アドレス情報)
      const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
      const cookiesNew = [
            {
              action: 'searchPermittedHistory',
              addressType: 'addressMain',
              description: `${userName}`,
              time: now,
              address: stateFixed.addressMain
            },
      ];

      setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
    }

    return { status: 'fulfilled', body: response.body };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }
}

// 「包括的被許可履歴」ボタン押下 HANDLE CLICK GET PERMITTED INCLUSIVELY HISTORY
async function handleClickGetPermittedInclusivelyHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs) {
  let addressActual;
  let request = {};
  let messages = {};
  let certifications = [];
  const now = Date.now();

  // state固定
  const stateFixed = {
    addressMain: state.getPermissionHistory.addressMain,
    lastEvaluatedSortKey: pagingIndex >= 1 ? state.getPermissionHistory.lastEvaluatedKey.permittedInclusively[pagingIndex - 1].addressOwner : null,
    signatureVersion: state.config.clientParameters.signatureVersion.getPermittedInclusivelyHistory,
    addressCheck: state.addressCheck,
    walletType: state.walletType,
  };

  // 有効なセッションに絞り込む。

  for (const address of Object.keys(state.session)) {
    // state固定
    stateFixed[address] = {
      // addressCoinType: state.session[address].addressCoinType,
      sessionId: state.session[address].sessionId,
      expirationOfSession: state.session[address].expirationOfSession,
    };

    if (stateFixed[address].expirationOfSession >= now + marginOfSessionTime) {
      certifications.push({
        // addressCoinType: stateFixed[address].addressCoinType,
        address: address,
        sessionId: stateFixed[address].sessionId,
        lastEvaluatedSortKey: address === stateFixed.addressMain ? stateFixed.lastEvaluatedSortKey : null,
      });
    }
  }

  // アドレスが指定されており、そのアドレスのセッションが存在しない、または、期限が切れていれば、署名する。(キープール or Mpurse)
  // Mpurseの場合、アドレスが指定されていない場合は、アクティブなアドレスで代替するかもしれない。(アドレスチェックモードに依る。)
  // keyPairsが渡された場合(2周目)、keyPairsの先頭の鍵で試みる。アドレスが指定されていない場合は、先頭のアドレスで代替するかもしれない。(アドレスチェックモードに依る。)

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  const extendedCertificationProperties = [
  ];

  if (keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined) {
    certificationWithKey(state, dispatch, [ keyPairsInPool[stateFixed.addressMain].keyPair ], 'information', stateFixed, certifications, now, extendedCertificationProperties, 'matchedKey');
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    certificationWithKey(state, dispatch, keyPairs, 'information', stateFixed, certifications, now, extendedCertificationProperties, 'firstKey');
  }
  else if (stateFixed.walletType === 'mpurse') {
    await setSignatureByAddressMain(state, dispatch, stateFixed, certifications, now, extendedCertificationProperties, 'information');
  }

  // 対象がなければ戻る。
  // NFC, BCの場合、キーカードを要求して2周目。

  if (
    certifications.length === 0 ||
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    !certifications.map( certification => certification.address ).includes(stateFixed.addressMain)
  ) {
    if (keyPairs === undefined || keyPairs === null) {
      if (stateFixed.walletType === 'nfc') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
        return {
          status: 'rejected',
          message: 'nfcRequired',
        };
      }
      else if (stateFixed.walletType === 'qr') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
        return {
          status: 'rejected',
          message: 'bcRequired',
        };
      }
    }

    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }

  request.body = JSON.stringify({
    "body": {
      action: 'permittedInclusively',
      certifications: certifications,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/get_some_history';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, 'permittedInclusivelyHistory', undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    for (const address of Object.keys(response.body)) {
      // 署名した場合もろもろ更新
      if (response.body[address]['certificatedBy'] === 'signature') {

        // session更新
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address],
          value: {'sessionId': response.body[address]['sessionId'], 'expirationOfSession': response.body[address]['expirationOfSession']} });
      }
      else if (response.body[address]['certificatedBy'] === 'none') {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address], value: { expirationOfSession: 0 } });
      }
    }

    // addressMainが検索対象で、検索成功した場合
    if (response.body[stateFixed.addressMain] !== undefined &&
         (response.body[stateFixed.addressMain].certificatedBy === 'signature' || response.body[stateFixed.addressMain].certificatedBy === 'sessionId')) {
      if (response.body[stateFixed.addressMain].lastEvaluatedKey !== undefined) {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'lastEvaluatedKey', 'permittedInclusively', pagingIndex], value: response.body[stateFixed.addressMain].lastEvaluatedKey });
      }
      else {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'lastEvaluatedKey', 'permittedInclusively', pagingIndex], value: null });
      }

      // pagingIndexが指定されているということは、前回の検索時からaddressMainが変更されていないということ。(変更するとリセットされてしまう。)
      // addressMainが指定されていないということは、後ページ検索ではない。
      // なぜならば、addressMainが指定されていない状態にするには、前回の検索のあと、addressMainを削除しなければならないから。

      dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'pagingIndex', 'permittedInclusively'], value: pagingIndex });

      // addressMainが指定されていなかった場合は、dispatchする。
      if (state.getPermissionHistory.addressMain === undefined || state.getPermissionHistory.addressMain === null || state.getPermissionHistory.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'addressMain'], value: stateFixed.addressMain });
      }

      // cookie情報更新(アドレス情報)
      const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
      const cookiesNew = [
            {
              action: 'searchPermittedInclusivelyHistory',
              addressType: 'addressMain',
              description: `${userName}`,
              time: now,
              address: stateFixed.addressMain
            },
      ];

      setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
    }

    return { status: 'fulfilled', body: response.body };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }
}

// 「許可リクエスト履歴」ボタン押下 HANDLE CLICK GET REQUEST PERMISSION HISTORY
async function handleClickGetRequestPermissionHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs) {
  let request = {};
  let messages = {};
  let certifications = [];
  const now = Date.now();

  // state固定
  const stateFixed = {
    addressMain: state.getPermissionHistory.addressMain,
    lastEvaluatedSortKey: pagingIndex >= 1 ? state.getPermissionHistory.lastEvaluatedKey.requestPermission[pagingIndex - 1].addressOwner : null,
    signatureVersion: state.config.clientParameters.signatureVersion.getRequestPermissionHistory,
    addressCheck: state.addressCheck,
    walletType: state.walletType,
  };

  // 有効なセッションに絞り込む。

  for (const address of Object.keys(state.session)) {
    // state固定
    stateFixed[address] = {
      // addressCoinType: state.session[address].addressCoinType,
      sessionId: state.session[address].sessionId,
      expirationOfSession: state.session[address].expirationOfSession,
    };

    if (stateFixed[address].expirationOfSession >= now + marginOfSessionTime) {
      certifications.push({
        // addressCoinType: stateFixed[address].addressCoinType,
        address: address,
        sessionId: stateFixed[address].sessionId,
        lastEvaluatedSortKey: address === stateFixed.addressMain ? stateFixed.lastEvaluatedSortKey : null,
      });
    }
  }

  // アドレスが指定されており、そのアドレスのセッションが存在しない、または、期限が切れていれば、署名する。(キープール or Mpurse)
  // Mpurseの場合、アドレスが指定されていない場合は、アクティブなアドレスで代替するかもしれない。(アドレスチェックモードに依る。)
  // keyPairsが渡された場合(2周目)、keyPairsの先頭の鍵で試みる。アドレスが指定されていない場合は、先頭のアドレスで代替するかもしれない。(アドレスチェックモードに依る。)

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  const extendedCertificationProperties = [
  ];

  if (keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined) {
    certificationWithKey(state, dispatch, [ keyPairsInPool[stateFixed.addressMain].keyPair ], 'information', stateFixed, certifications, now, extendedCertificationProperties, 'matchedKey');
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    certificationWithKey(state, dispatch, keyPairs, 'information', stateFixed, certifications, now, extendedCertificationProperties, 'firstKey');
  }
  else if (stateFixed.walletType === 'mpurse') {
    await setSignatureByAddressMain(state, dispatch, stateFixed, certifications, now, extendedCertificationProperties, 'information');
  }

  // 対象がなければ戻る。
  // NFC, BCの場合、キーカードを要求して2周目。

  if (
    certifications.length === 0 ||
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    !certifications.map( certification => certification.address ).includes(stateFixed.addressMain)
  ) {
    if (keyPairs === undefined || keyPairs === null) {
      if (stateFixed.walletType === 'nfc') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
        return {
          status: 'rejected',
          message: 'nfcRequired',
        };
      }
      else if (stateFixed.walletType === 'qr') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
        return {
          status: 'rejected',
          message: 'bcRequired',
        };
      }
    }

    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }

  request.body = JSON.stringify({
    "body": {
      action: 'requestPermission',
      certifications: certifications,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/get_some_history';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, 'requestPermissionHistory', undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    for (const address of Object.keys(response.body)) {
      // 署名した場合もろもろ更新
      if (response.body[address]['certificatedBy'] === 'signature') {

        // session更新
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address],
          value: {'sessionId': response.body[address]['sessionId'], 'expirationOfSession': response.body[address]['expirationOfSession']} });
      }
      else if (response.body[address]['certificatedBy'] === 'none') {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address], value: { expirationOfSession: 0 } });
      }
    }

    // addressMainが検索対象で、検索成功した場合
    if (response.body[stateFixed.addressMain] !== undefined &&
         (response.body[stateFixed.addressMain].certificatedBy === 'signature' || response.body[stateFixed.addressMain].certificatedBy === 'sessionId')) {
      if (response.body[stateFixed.addressMain].lastEvaluatedKey !== undefined) {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'lastEvaluatedKey', 'requestPermission', pagingIndex], value: response.body[stateFixed.addressMain].lastEvaluatedKey });
      }
      else {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'lastEvaluatedKey', 'requestPermission', pagingIndex], value: null });
      }

      // pagingIndexが指定されているということは、前回の検索時からaddressMainが変更されていないということ。(変更するとリセットされてしまう。)
      // addressMainが指定されていないということは、後ページ検索ではない。
      // なぜならば、addressMainが指定されていない状態にするには、前回の検索のあと、addressMainを削除しなければならないから。

      dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'pagingIndex', 'requestPermission'], value: pagingIndex });

      // addressMainが指定されていなかった場合は、dispatchする。
      if (state.getPermissionHistory.addressMain === undefined || state.getPermissionHistory.addressMain === null || state.getPermissionHistory.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'addressMain'], value: stateFixed.addressMain });
      }

      // cookie情報更新(アドレス情報)
      const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
      const cookiesNew = [
            {
              action: 'searchrequestPermissionHistory',
              addressType: 'addressMain',
              description: `${userName}`,
              time: now,
              address: stateFixed.addressMain
            },
      ];

      setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
    }

    return { status: 'fulfilled', body: response.body };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }
}

// 「被許可リクエスト履歴」ボタン押下 HANDLE CLICK GET REQUESTED PERMISSION HISTORY
async function handleClickGetRequestedPermissionHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs) {
  let request = {};
  let messages = {};
  let certifications = [];
  const now = Date.now();

  // state固定
  const stateFixed = {
    addressMain: state.getPermissionHistory.addressMain,
    lastEvaluatedSortKey: pagingIndex >= 1 ? state.getPermissionHistory.lastEvaluatedKey.requestedPermission[pagingIndex - 1].addressMain : null,
    signatureVersion: state.config.clientParameters.signatureVersion.getRequestedPermissionHistory,
    addressCheck: state.addressCheck,
    walletType: state.walletType,
  };

  // 有効なセッションに絞り込む。

  for (const address of Object.keys(state.session)) {
    // state固定
    stateFixed[address] = {
      // addressCoinType: state.session[address].addressCoinType,
      sessionId: state.session[address].sessionId,
      expirationOfSession: state.session[address].expirationOfSession,
    };

    if (stateFixed[address].expirationOfSession >= now + marginOfSessionTime) {
      certifications.push({
        // addressCoinType: stateFixed[address].addressCoinType,
        address: address,
        sessionId: stateFixed[address].sessionId,
        lastEvaluatedSortKey: address === stateFixed.addressMain ? stateFixed.lastEvaluatedSortKey : null,
      });
    }
  }

  // アドレスが指定されており、そのアドレスのセッションが存在しない、または、期限が切れていれば、署名する。(キープール or Mpurse)
  // Mpurseの場合、アドレスが指定されていない場合は、アクティブなアドレスで代替するかもしれない。(アドレスチェックモードに依る。)
  // keyPairsが渡された場合(2周目)、keyPairsの先頭の鍵で試みる。アドレスが指定されていない場合は、先頭のアドレスで代替するかもしれない。(アドレスチェックモードに依る。)

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  const extendedCertificationProperties = [
  ];

  if (keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined) {
    certificationWithKey(state, dispatch, [ keyPairsInPool[stateFixed.addressMain].keyPair ], 'information', stateFixed, certifications, now, extendedCertificationProperties, 'matchedKey');
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    certificationWithKey(state, dispatch, keyPairs, 'information', stateFixed, certifications, now, extendedCertificationProperties, 'firstKey');
  }
  else if (stateFixed.walletType === 'mpurse') {
    await setSignatureByAddressMain(state, dispatch, stateFixed, certifications, now, extendedCertificationProperties, 'information');
  }

  // 対象がなければ戻る。
  // NFC, BCの場合、キーカードを要求して2周目。

  if (
    certifications.length === 0 ||
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    !certifications.map( certification => certification.address ).includes(stateFixed.addressMain)
  ) {
    if (keyPairs === undefined || keyPairs === null) {
      if (stateFixed.walletType === 'nfc') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
        return {
          status: 'rejected',
          message: 'nfcRequired',
        };
      }
      else if (stateFixed.walletType === 'qr') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
        return {
          status: 'rejected',
          message: 'bcRequired',
        };
      }
    }

    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }

  request.body = JSON.stringify({
    "body": {
      action: 'requestedPermission',
      certifications: certifications,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/get_some_history';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, 'requestedPermissionHistory', undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    for (const address of Object.keys(response.body)) {
      // 署名した場合もろもろ更新
      if (response.body[address]['certificatedBy'] === 'signature') {

        // session更新
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address],
          value: {'sessionId': response.body[address]['sessionId'], 'expirationOfSession': response.body[address]['expirationOfSession']} });
      }
      else if (response.body[address]['certificatedBy'] === 'none') {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address], value: { expirationOfSession: 0 } });
      }
    }

    // addressMainが検索対象で、検索成功した場合
    if (response.body[stateFixed.addressMain] !== undefined &&
         (response.body[stateFixed.addressMain].certificatedBy === 'signature' || response.body[stateFixed.addressMain].certificatedBy === 'sessionId')) {
      if (response.body[stateFixed.addressMain].lastEvaluatedKey !== undefined) {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'lastEvaluatedKey', 'requestedPermission', pagingIndex], value: response.body[stateFixed.addressMain].lastEvaluatedKey });
      }
      else {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'lastEvaluatedKey', 'requestedPermission', pagingIndex], value: null });
      }

      // pagingIndexが指定されているということは、前回の検索時からaddressMainが変更されていないということ。(変更するとリセットされてしまう。)
      // addressMainが指定されていないということは、後ページ検索ではない。
      // なぜならば、addressMainが指定されていない状態にするには、前回の検索のあと、addressMainを削除しなければならないから。

      dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'pagingIndex', 'requestedPermission'], value: pagingIndex });

      // addressMainが指定されていなかった場合は、dispatchする。
      if (state.getPermissionHistory.addressMain === undefined || state.getPermissionHistory.addressMain === null || state.getPermissionHistory.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'addressMain'], value: stateFixed.addressMain });
      }

      // cookie情報更新(アドレス情報)
      const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
      const cookiesNew = [
            {
              action: 'searchrequestedPermissionHistory',
              addressType: 'addressMain',
              description: `${userName}`,
              time: now,
              address: stateFixed.addressMain
            },
      ];

      setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
    }

    return { status: 'fulfilled', body: response.body };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }
}

// 「委任した履歴」ボタン押下 HANDLE CLICK GET DELEGATE HISTORY
async function handleClickGetDelegateHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs) {
  let request = {};
  let messages = {};
  let certifications = [];
  const now = Date.now();

  // state固定
  const stateFixed = {
    addressMain: state.getPermissionHistory.addressMain,
    lastEvaluatedSortKey: pagingIndex >= 1 ? state.getPermissionHistory.lastEvaluatedKey.delegate[pagingIndex - 1].addressDelegateTo : null,
    signatureVersion: state.config.clientParameters.signatureVersion.getDelegateHistory,
    addressCheck: state.addressCheck,
    walletType: state.walletType,
  };

  // 有効なセッションに絞り込む。

  for (const address of Object.keys(state.session)) {
    // state固定
    stateFixed[address] = {
      sessionId: state.session[address].sessionId,
      expirationOfSession: state.session[address].expirationOfSession,
    };

    if (stateFixed[address].expirationOfSession >= now + marginOfSessionTime) {
      certifications.push({
        address: address,
        sessionId: stateFixed[address].sessionId,
        lastEvaluatedSortKey: address === stateFixed.addressMain ? stateFixed.lastEvaluatedSortKey : null,
      });
    }
  }

  // アドレスが指定されており、そのアドレスのセッションが存在しない、または、期限が切れていれば、署名する。(キープール or Mpurse)
  // Mpurseの場合、アドレスが指定されていない場合は、アクティブなアドレスで代替するかもしれない。(アドレスチェックモードに依る。)
  // keyPairsが渡された場合(2周目)、keyPairsの先頭の鍵で試みる。アドレスが指定されていない場合は、先頭のアドレスで代替するかもしれない。(アドレスチェックモードに依る。)

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  const extendedCertificationProperties = [
  ];

  if (keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined) {
    certificationWithKey(state, dispatch, [ keyPairsInPool[stateFixed.addressMain].keyPair ], 'information', stateFixed, certifications, now, extendedCertificationProperties, 'matchedKey');
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    certificationWithKey(state, dispatch, keyPairs, 'information', stateFixed, certifications, now, extendedCertificationProperties, 'firstKey');
  }
  else if (stateFixed.walletType === 'mpurse') {
    await setSignatureByAddressMain(state, dispatch, stateFixed, certifications, now, extendedCertificationProperties, 'information');
  }

  // 対象がなければ戻る。
  // NFC, BCの場合、キーカードを要求して2周目。

  if (
    certifications.length === 0 ||
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    !certifications.map( certification => certification.address ).includes(stateFixed.addressMain)
  ) {
    if (keyPairs === undefined || keyPairs === null) {
      if (stateFixed.walletType === 'nfc') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
        return {
          status: 'rejected',
          message: 'nfcRequired',
        };
      }
      else if (stateFixed.walletType === 'qr') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
        return {
          status: 'rejected',
          message: 'bcRequired',
        };
      }
    }

    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }

  request.body = JSON.stringify({
    "body": {
      action: 'delegate',
      certifications: certifications,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/get_some_history';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, 'delegateHistory', undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    for (const address of Object.keys(response.body)) {
      // 署名した場合もろもろ更新
      if (response.body[address]['certificatedBy'] === 'signature') {

        // session更新
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address],
          value: {'sessionId': response.body[address]['sessionId'], 'expirationOfSession': response.body[address]['expirationOfSession']} });
      }
      else if (response.body[address]['certificatedBy'] === 'none') {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address], value: { expirationOfSession: 0 } });
      }
    }

    // addressMainが検索対象で、検索成功した場合
    if (response.body[stateFixed.addressMain] !== undefined &&
         (response.body[stateFixed.addressMain].certificatedBy === 'signature' || response.body[stateFixed.addressMain].certificatedBy === 'sessionId')) {
      if (response.body[stateFixed.addressMain].lastEvaluatedKey !== undefined) {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'lastEvaluatedKey', 'delegate', pagingIndex], value: response.body[stateFixed.addressMain].lastEvaluatedKey });
      }
      else {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'lastEvaluatedKey', 'delegate', pagingIndex], value: null });
      }

      // pagingIndexが指定されているということは、前回の検索時からaddressMainが変更されていないということ。(変更するとリセットされてしまう。)
      // addressMainが指定されていないということは、後ページ検索ではない。
      // なぜならば、addressMainが指定されていない状態にするには、前回の検索のあと、addressMainを削除しなければならないから。

      dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'pagingIndex', 'delegate'], value: pagingIndex });

      // addressMainが指定されていなかった場合は、dispatchする。
      if (state.getPermissionHistory.addressMain === undefined || state.getPermissionHistory.addressMain === null || state.getPermissionHistory.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'addressMain'], value: stateFixed.addressMain });
      }

      // cookie情報更新(アドレス情報)
      const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
      const cookiesNew = [
            {
              action: 'searchDelegateHistory',
              addressType: 'addressMain',
              description: `${userName}`,
              time: now,
              address: stateFixed.addressMain
            },
      ];

      setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
    }

    return { status: 'fulfilled', body: response.body };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }
}

// 「委任された履歴」ボタン押下 HANDLE CLICK GET DELEGATED HISTORY
async function handleClickGetDelegatedHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs) {
  let request = {};
  let messages = {};
  let certifications = [];
  const now = Date.now();

  // state固定
  const stateFixed = {
    addressMain: state.getPermissionHistory.addressMain,
    lastEvaluatedSortKey: pagingIndex >= 1 ? state.getPermissionHistory.lastEvaluatedKey.delegated[pagingIndex - 1].addressDelegateFrom : null,
    signatureVersion: state.config.clientParameters.signatureVersion.getDelegatedHistory,
    addressCheck: state.addressCheck,
    walletType: state.walletType,
  };

  // 有効なセッションに絞り込む。

  for (const address of Object.keys(state.session)) {
    // state固定
    stateFixed[address] = {
      sessionId: state.session[address].sessionId,
      expirationOfSession: state.session[address].expirationOfSession,
    };

    if (stateFixed[address].expirationOfSession >= now + marginOfSessionTime) {
      certifications.push({
        address: address,
        sessionId: stateFixed[address].sessionId,
        lastEvaluatedSortKey: address === stateFixed.addressMain ? stateFixed.lastEvaluatedSortKey : null,
      });
    }
  }

  // アドレスが指定されており、そのアドレスのセッションが存在しない、または、期限が切れていれば、署名する。(キープール or Mpurse)
  // Mpurseの場合、アドレスが指定されていない場合は、アクティブなアドレスで代替するかもしれない。(アドレスチェックモードに依る。)
  // keyPairsが渡された場合(2周目)、keyPairsの先頭の鍵で試みる。アドレスが指定されていない場合は、先頭のアドレスで代替するかもしれない。(アドレスチェックモードに依る。)

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  const extendedCertificationProperties = [
  ];

  if (keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined) {
    certificationWithKey(state, dispatch, [ keyPairsInPool[stateFixed.addressMain].keyPair ], 'information', stateFixed, certifications, now, extendedCertificationProperties, 'matchedKey');
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    certificationWithKey(state, dispatch, keyPairs, 'information', stateFixed, certifications, now, extendedCertificationProperties, 'firstKey');
  }
  else if (stateFixed.walletType === 'mpurse') {
    await setSignatureByAddressMain(state, dispatch, stateFixed, certifications, now, extendedCertificationProperties, 'information');
  }

  // 対象がなければ戻る。
  // NFC, BCの場合、キーカードを要求して2周目。

  if (
    certifications.length === 0 ||
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    !certifications.map( certification => certification.address ).includes(stateFixed.addressMain)
  ) {
    if (keyPairs === undefined || keyPairs === null) {
      if (stateFixed.walletType === 'nfc') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
        return {
          status: 'rejected',
          message: 'nfcRequired',
        };
      }
      else if (stateFixed.walletType === 'qr') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
        return {
          status: 'rejected',
          message: 'bcRequired',
        };
      }
    }

    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }

  request.body = JSON.stringify({
    "body": {
      action: 'delegated',
      certifications: certifications,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/get_some_history';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, 'delegatedHistory', undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    for (const address of Object.keys(response.body)) {
      // 署名した場合もろもろ更新
      if (response.body[address]['certificatedBy'] === 'signature') {

        // session更新
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address],
          value: {'sessionId': response.body[address]['sessionId'], 'expirationOfSession': response.body[address]['expirationOfSession']} });
      }
      else if (response.body[address]['certificatedBy'] === 'none') {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address], value: { expirationOfSession: 0 } });
      }
    }

    // addressMainが検索対象で、検索成功した場合
    if (response.body[stateFixed.addressMain] !== undefined &&
         (response.body[stateFixed.addressMain].certificatedBy === 'signature' || response.body[stateFixed.addressMain].certificatedBy === 'sessionId')) {
      if (response.body[stateFixed.addressMain].lastEvaluatedKey !== undefined) {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'lastEvaluatedKey', 'delegated', pagingIndex], value: response.body[stateFixed.addressMain].lastEvaluatedKey });
      }
      else {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'lastEvaluatedKey', 'delegated', pagingIndex], value: null });
      }

      // pagingIndexが指定されているということは、前回の検索時からaddressMainが変更されていないということ。(変更するとリセットされてしまう。)
      // addressMainが指定されていないということは、後ページ検索ではない。
      // なぜならば、addressMainが指定されていない状態にするには、前回の検索のあと、addressMainを削除しなければならないから。

      dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'pagingIndex', 'delegated'], value: pagingIndex });

      // addressMainが指定されていなかった場合は、dispatchする。
      if (state.getPermissionHistory.addressMain === undefined || state.getPermissionHistory.addressMain === null || state.getPermissionHistory.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPermissionHistory', 'addressMain'], value: stateFixed.addressMain });
      }

      // cookie情報更新(アドレス情報)
      const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
      const cookiesNew = [
            {
              action: 'searchDelegatedHistory',
              addressType: 'addressMain',
              description: `${userName}`,
              time: now,
              address: stateFixed.addressMain
            },
      ];

      setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
    }

    return { status: 'fulfilled', body: response.body };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }
}

// 「売上履歴」ボタン押下 HANDLE CLICK GET SALES HISTORY
async function handleClickGetSalesHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs) {
  let request = {};
  let messages = {};
  let certifications = [];
  const now = Date.now();

  // state固定
  const stateFixed = {
    addressMain: state.getHistory.addressMain,
    lastEvaluatedSortKey: pagingIndex >= 1 ? state.getHistory.lastEvaluatedKey.sales[pagingIndex - 1].receiveMonaConfirmationTime : null,
    lastEvaluatedTablePartitionKey: pagingIndex >= 1 ? state.getHistory.lastEvaluatedKey.sales[pagingIndex - 1].addressMain : null,
    lastEvaluatedTableSortkey: pagingIndex >= 1 ? state.getHistory.lastEvaluatedKey.sales[pagingIndex - 1].purchaseNo : null,
    signatureVersion: state.config.clientParameters.signatureVersion.getPurchaseHistory,
    addressCheck: state.addressCheck,
    walletType: state.walletType,
  };

  // 有効なセッションに絞り込む。

  for (const address of Object.keys(state.session)) {

      // state固定
      stateFixed[address] = {
        sessionId: state.session[address].sessionId,
        expirationOfSession: state.session[address].expirationOfSession,
      };

      if (stateFixed[address].expirationOfSession >= now + marginOfSessionTime) {
        certifications.push({
          address: address,
          sessionId: stateFixed[address].sessionId,
          lastEvaluatedSortKey: address === stateFixed.addressMain ? stateFixed.lastEvaluatedSortKey : null,
          lastEvaluatedTablePartitionKey: address === stateFixed.addressMain ? stateFixed.lastEvaluatedTablePartitionKey : null,
          lastEvaluatedTableSortkey: address === stateFixed.addressMain ? stateFixed.lastEvaluatedTableSortkey : null,
        });
      }
  }

  // アドレスが指定されており、そのアドレスのセッションが存在しない、または、期限が切れていれば、署名する。(キープール or Mpurse)
  // Mpurseの場合、アドレスが指定されていない場合は、アクティブなアドレスで代替するかもしれない。(アドレスチェックモードに依る。)
  // keyPairsが渡された場合(2周目)、keyPairsの先頭の鍵で試みる。アドレスが指定されていない場合は、先頭のアドレスで代替するかもしれない。(アドレスチェックモードに依る。)

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  const extendedCertificationProperties = [
    { key: 'lastEvaluatedTablePartitionKey', value: stateFixed.lastEvaluatedTablePartitionKey },
    { key: 'lastEvaluatedTableSortkey', value: stateFixed.lastEvaluatedTableSortkey },
  ];

  if (keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined) {
    certificationWithKey(state, dispatch, [ keyPairsInPool[stateFixed.addressMain].keyPair ], 'information', stateFixed, certifications, now, extendedCertificationProperties, 'matchedKey');
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    certificationWithKey(state, dispatch, keyPairs, 'information', stateFixed, certifications, now, extendedCertificationProperties, 'firstKey');
  }
  else if (stateFixed.walletType === 'mpurse') {
    await setSignatureByAddressMain(state, dispatch, stateFixed, certifications, now, extendedCertificationProperties, 'information');
  }

  // 対象がなければ戻る。
  // NFC, BCの場合、キーカードを要求して2周目。

  if (
    certifications.length === 0 ||
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    !certifications.map( certification => certification.address ).includes(stateFixed.addressMain)
  ) {
    if (keyPairs === undefined || keyPairs === null) {
      if (stateFixed.walletType === 'nfc') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
        return {
          status: 'rejected',
          message: 'nfcRequired',
        };
      }
      else if (stateFixed.walletType === 'qr') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
        return {
          status: 'rejected',
          message: 'bcRequired',
        };
      }
    }

    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }

  request.body = JSON.stringify({
    body: {
      action: 'sales',
      certifications: certifications,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/get_some_history';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, 'salesHistory', undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    for (const address of Object.keys(response.body)) {
      // 署名した場合もろもろ更新
      if (response.body[address]['certificatedBy'] === 'signature') {

        // session更新
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address],
          value: {'sessionId': response.body[address]['sessionId'], 'expirationOfSession': response.body[address]['expirationOfSession']} });
      }
      else if (response.body[address]['certificatedBy'] === 'none') {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address], value: { expirationOfSession: 0 } });
      }
    }

    // addressMainが検索対象で、検索成功した場合
    if (response.body[stateFixed.addressMain] !== undefined &&
         (response.body[stateFixed.addressMain].certificatedBy === 'signature' || response.body[stateFixed.addressMain].certificatedBy === 'sessionId')) {
      if (response.body[stateFixed.addressMain].lastEvaluatedKey !== undefined) {
        dispatch({ type: 'setStateMultiLayers', keys: ['getHistory', 'lastEvaluatedKey', 'sales', pagingIndex], value: response.body[stateFixed.addressMain].lastEvaluatedKey });
      }
      else {
        dispatch({ type: 'setStateMultiLayers', keys: ['getHistory', 'lastEvaluatedKey', 'sales', pagingIndex], value: null });
      }

      // pagingIndexが指定されているということは、前回の検索時からaddressMainが変更されていないということ。(変更するとリセットされてしまう。)
      // addressMainが指定されていないということは、後ページ検索ではない。
      // なぜならば、addressMainが指定されていない状態にするには、前回の検索のあと、addressMainを削除しなければならないから。

      dispatch({ type: 'setStateMultiLayers', keys: ['getHistory', 'pagingIndex', 'sales'], value: pagingIndex });

      // addressMainが指定されていなかった場合は、dispatchする。
      if (state.getHistory.addressMain === undefined || state.getHistory.addressMain === null || state.getHistory.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['getHistory', 'addressMain'], value: stateFixed.addressMain });
      }

      // cookie情報更新(アドレス情報)
      const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
      const cookiesNew = [
            {
              action: 'searchSalesHistory',
              addressType: 'addressMain',
              description: `${userName}`,
              time: now,
              address: stateFixed.addressMain
            },
      ];

      setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
    }

    return { status: 'fulfilled', body: response.body };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }
}

// 「ロイヤリティ履歴」ボタン押下 HANDLE CLICK GET ROYALTY HISTORY
async function handleClickGetRoyaltyHistory(state, dispatch, cookies, setCookie, pagingIndex, keyPairs) {
  let request = {};
  let messages = {};
  let certifications = [];
  let stateKey;
  let combinedAction;
  const now = Date.now();

  // addressType毎の設定
  if (state.getHistory.addressType === 'addressOwner') {
    stateKey = 'royaltyAddressOwnerHistory';
    combinedAction = 'royaltyAddressOwner';
  }
  else { // addressPayRoyaltyTo
    stateKey = 'royaltyAddressPayRoyaltyToHistory';
    combinedAction = 'royaltyAddressPayRoyaltyTo';
  }

  // state固定
  const stateFixed = {
    addressMain: state.getHistory.addressMain,
    addressType: state.getHistory.addressType,
    lastEvaluatedSortKey: pagingIndex >= 1 ? state.getHistory.lastEvaluatedKey[combinedAction][pagingIndex - 1].receiveMonaConfirmationTime : null,
    lastEvaluatedTablePartitionKey: pagingIndex >= 1 ? state.getHistory.lastEvaluatedKey[combinedAction][pagingIndex - 1].addressMain : null,
    lastEvaluatedTableSortkey: pagingIndex >= 1 ? state.getHistory.lastEvaluatedKey[combinedAction][pagingIndex - 1].purchaseNo : null,
    signatureVersion: state.config.clientParameters.signatureVersion.getRoyaltyHistory,
    addressCheck: state.addressCheck,
    walletType: state.walletType,
  };

  // 有効なセッションに絞り込む。

  for (const address of Object.keys(state.session)) {

      // state固定
      stateFixed[address] = {
        sessionId: state.session[address].sessionId,
        expirationOfSession: state.session[address].expirationOfSession,
      };

      if (stateFixed[address].expirationOfSession >= now + marginOfSessionTime) {
        certifications.push({
          address: address,
          addressType: stateFixed.addressType,
          sessionId: stateFixed[address].sessionId,
          lastEvaluatedSortKey: address === stateFixed.addressMain ? stateFixed.lastEvaluatedSortKey : null,
          lastEvaluatedTablePartitionKey: address === stateFixed.addressMain ? stateFixed.lastEvaluatedTablePartitionKey : null,
          lastEvaluatedTableSortkey: address === stateFixed.addressMain ? stateFixed.lastEvaluatedTableSortkey : null,
        });
      }
  }

  // アドレスが指定されており、そのアドレスのセッションが存在しない、または、期限が切れていれば、署名する。(キープール or Mpurse)
  // Mpurseの場合、アドレスが指定されていない場合は、アクティブなアドレスで代替するかもしれない。(アドレスチェックモードに依る。)
  // keyPairsが渡された場合(2周目)、keyPairsの先頭の鍵で試みる。アドレスが指定されていない場合は、先頭のアドレスで代替するかもしれない。(アドレスチェックモードに依る。)

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  const extendedCertificationProperties = [
    { key: 'addressType', value: stateFixed.addressType },
    { key: 'lastEvaluatedTablePartitionKey', value: stateFixed.lastEvaluatedTablePartitionKey },
    { key: 'lastEvaluatedTableSortkey', value: stateFixed.lastEvaluatedTableSortkey },
  ];

  if (keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined) {
    certificationWithKey(state, dispatch, [ keyPairsInPool[stateFixed.addressMain].keyPair ], 'information', stateFixed, certifications, now, extendedCertificationProperties, 'matchedKey');
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    certificationWithKey(state, dispatch, keyPairs, 'information', stateFixed, certifications, now, extendedCertificationProperties, 'firstKey');
  }
  else if (stateFixed.walletType === 'mpurse') {
    await setSignatureByAddressMain(state, dispatch, stateFixed, certifications, now, extendedCertificationProperties, 'information');
  }

  // 対象がなければ戻る。
  // NFC, BCの場合、キーカードを要求して2周目。

  if (
    certifications.length === 0 ||
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    !certifications.map( certification => certification.address ).includes(stateFixed.addressMain)
  ) {
    if (keyPairs === undefined || keyPairs === null) {
      if (stateFixed.walletType === 'nfc') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
        return {
          status: 'rejected',
          message: 'nfcRequired',
        };
      }
      else if (stateFixed.walletType === 'qr') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
        return {
          status: 'rejected',
          message: 'bcRequired',
        };
      }
    }

    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }

  request.body = JSON.stringify({
    body: {
      action: 'royalty',
      certifications: certifications,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/get_some_history';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, stateKey, undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    for (const address of Object.keys(response.body)) {
      // 署名した場合もろもろ更新
      if (response.body[address]['certificatedBy'] === 'signature') {

        // session更新
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address],
          value: {'sessionId': response.body[address]['sessionId'], 'expirationOfSession': response.body[address]['expirationOfSession']} });
      }
      else if (response.body[address]['certificatedBy'] === 'none') {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address], value: { expirationOfSession: 0 } });
      }
    }

    // addressMainが検索対象で、検索成功した場合
    if (response.body[stateFixed.addressMain] !== undefined &&
         (response.body[stateFixed.addressMain].certificatedBy === 'signature' || response.body[stateFixed.addressMain].certificatedBy === 'sessionId')) {
      if (response.body[stateFixed.addressMain].lastEvaluatedKey !== undefined) {
        dispatch({ type: 'setStateMultiLayers', keys: ['getHistory', 'lastEvaluatedKey', combinedAction, pagingIndex], value: response.body[stateFixed.addressMain].lastEvaluatedKey });
      }
      else {
        dispatch({ type: 'setStateMultiLayers', keys: ['getHistory', 'lastEvaluatedKey', combinedAction, pagingIndex], value: null });
      }

      // pagingIndexが指定されているということは、前回の検索時からaddressMainが変更されていないということ。(変更するとリセットされてしまう。)
      // addressMainが指定されていないということは、後ページ検索ではない。
      // なぜならば、addressMainが指定されていない状態にするには、前回の検索のあと、addressMainを削除しなければならないから。

      dispatch({ type: 'setStateMultiLayers', keys: ['getHistory', 'pagingIndex', combinedAction], value: pagingIndex });

      // addressMainが指定されていなかった場合は、dispatchする。
      if (state.getHistory.addressMain === undefined || state.getHistory.addressMain === null || state.getHistory.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['getHistory', 'addressMain'], value: stateFixed.addressMain });
      }

      // cookie情報更新(アドレス情報)
      const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
      const cookiesNew = [
            {
              action: 'searchRoyaltyHistory',
              addressType: stateFixed.addressType,
              description: `${userName}`,
              time: now,
              address: stateFixed.addressMain
            },
      ];

      setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
    }

    return { status: 'fulfilled', body: response.body };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }
}

// SET SIGNATURE BY MAIN ADDRESS
async function setSignatureByAddressMain(state, dispatch, stateFixed, certifications, clientTime, extendedCertificationProperties, messageType) {
  // アクティブなMONAアドレスが有り、そのアドレスがcertificationsに含まれていなければ、署名する。
  // if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== '' && !certifications.map( certification => certification.address ).includes(stateFixed.addressMain)) {
    // -- Mpurseのアドレス取得
    const result = await window.mpurse.getAddress()
    .then( async (result) => {
      const addressActual = result;
      let address;

      // lastEvaluatedSortKey等があるということは、前回の検索時からaddressMainが変更されていないということ。(変更するとリセットされてしまう。)
      // addressMainが指定されていないということは、後ページ検索ではない。
      // なぜならば、addressMainが指定されていない状態にするには、前回の検索のあと、addressMainを削除しなければならないから。

      if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '') {
        if (stateFixed.addressCheck === 'strict' || stateFixed.addressCheck === 'addressSecond') {
          if (addressActual !== stateFixed.addressMain) {
            dispatch({ type: 'setNotification', key: 'notification', value: words.mainAddressAndTheAddressInWalletAreDifferent[state.language] });
            return 'rejected';
          }
          else {
            // 続行
          }
        }
        else { // off
          // 続行
        }

        address = stateFixed.addressMain;
      }
      else {
        address = addressActual;
        stateFixed.addressMain = addressActual;
      }

      devLog('address', address);
      devLog('certifications', JSON.stringify(certifications));

      if (!certifications.map( certification => certification.address ).includes(address)) {
      devLog('sign');
        let message;

        if (messageType === 'login') {
          message = `I want to log in to monacotto as ${address}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
        }
        else if (messageType === 'information') {
          message = `I want to get the information of ${address}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
        }

        devLog('message', message);

        let signatureMona;

        try {
          signatureMona = await window.mpurse.signMessage(message).then( result => result );

          const certification = {
            // addressCoinType: 'mona',
            address: address,
            addressActual: addressActual,
            clientTime: clientTime,
            signatureVersion: stateFixed.signatureVersion,
            signature: signatureMona,
            lastEvaluatedSortKey: stateFixed.lastEvaluatedSortKey,
          };

          // 拡張プロパティ
          if (extendedCertificationProperties !== undefined && extendedCertificationProperties !== null) {
            for (const property of extendedCertificationProperties) {
              certification[property.key] = property.value;
            }
          }

          devLog('certification addressMain', JSON.stringify(certification));

          certifications.push(certification);
        }
        catch (err) {
          dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
        }
      }
    })
    .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
    });
  // }
}


// 「出品:署名」ボタン押下 -- HANDLE CLICK SIGN EXHIBIT
async function handleClickSignExhibit(state, dispatch, cookies, setCookie, addressType, keyPairs) {
  let addressActual;

  // トークン存在チェック

  // トークン保有量チェック

  let request = {};
  let messages = {};
  let bodyObj = {};

  // state固定
  const stateFixed = {
    exhibitNo: state.exhibit.exhibitNo,
    addressMain: state.exhibit.addressMain,
    // addressMainCoinType: state.exhibit.addressMainCoinType,
    exhibitType: 'singleToken',
    addressCardFrom: state.exhibit.addressCardFrom,
    addressPayProceedsTo: state.exhibit.addressPayProceedsTo,
    monacottoAddressMonaparty: state.exhibit.monacottoAddressMonaparty,
    tokenName: state.exhibit.tokenName,
    amountToSell: state.exhibit.amountToSell,
    priceMona: state.exhibit.priceMona.value,
    signatureVersion: state.config.clientParameters.signatureVersion.exhibit,
    minimumAmountToSell: state.config.clientParameters.amountToSellRange.min,
    maximumAmountToSell: state.config.clientParameters.amountToSellRange.max,
    minimumPriceMona: state.config.clientParameters.priceMonaRange.min,
    maximumPriceMona: state.config.clientParameters.priceMonaRange.max,
    addressCheck: state.addressCheck,
    walletType: state.walletType,
  };

  // 必須項目チェック
  if (stateFixed.addressMain === undefined || stateFixed.addressMain === null || stateFixed.addressMain === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.mainAddressMustBeFilled[state.language] });
    return { status: 'rejected' };
  }

  if (stateFixed.addressCardFrom === undefined || stateFixed.addressCardFrom === null || stateFixed.addressCardFrom === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.addressYouSendCardsFromMustBeFilled[state.language] });
    return { status: 'rejected' };
  }

  if (stateFixed.addressPayProceedsTo === undefined || stateFixed.addressPayProceedsTo === null || stateFixed.addressPayProceedsTo === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.addressYouReceiveProceedsInMustBeFilled[state.language] });
    return { status: 'rejected' };
  }

  if (stateFixed.tokenName === undefined || stateFixed.tokenName === null || stateFixed.tokenName === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.tokenNameMustBeFilled[state.language] });
    return { status: 'rejected' };
  }

  if (stateFixed.amountToSell === undefined || stateFixed.amountToSell === null || stateFixed.amountToSell === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.amountToSellMustBeFilled[state.language] });
    return { status: 'rejected' };
  }

  if (stateFixed.priceMona === undefined || stateFixed.priceMona === null || stateFixed.priceMona === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.unitPriceMustBeFilled[state.language] });
    return { status: 'rejected' };
  }

  // 数量チェック
  if (stateFixed.amountToSell < stateFixed.minimumAmountToSell) {
    dispatch({ type: 'setNotification', key: 'notification',
      value: `${words.amountToSellMustBeEqualOrMoreThanX1[state.language]} ${stateFixed.minimumAmountToSell} ${words.amountToSellMustBeEqualOrMoreThanX2[state.language]}`
    });
    return { status: 'rejected' };
  }

  if (stateFixed.amountToSell > stateFixed.maximumAmountToSell) {
    dispatch({ type: 'setNotification', key: 'notification',
      value: `${words.amountToSellMustBeEqualOrLessThanX1[state.language]} ${stateFixed.maximumAmountToSell} ${words.amountToSellMustBeEqualOrLessThanX2[state.language]}`
    });
    return { status: 'rejected' };
  }

  if (stateFixed.priceMona < stateFixed.minimumPriceMona) {
    dispatch({ type: 'setNotification', key: 'notification',
      value: `${words.unitPriceMustBeEqualOrMoreThanXMona1[state.language]} ${stateFixed.minimumPriceMona} ${words.unitPriceMustBeEqualOrMoreThanXMona2[state.language]}`
    });
    return { status: 'rejected' };
  }

  if (stateFixed.priceMona > stateFixed.maximumPriceMona) {
    dispatch({ type: 'setNotification', key: 'notification',
      value: `${words.unitPriceMustBeEqualOrLessThanXMona1[state.language]} ${stateFixed.maximumPriceMona} ${words.unitPriceMustBeEqualOrLessThanXMona2[state.language]}`
    });
    return { status: 'rejected' };
  }

  // monacottoAddressMonaparty決定
  if (stateFixed.exhibitNo === null || stateFixed.exhibitNo === '') {
    stateFixed.monacottoAddressMonaparty = state.config.monacottoAddress.monacottoAddressMonaparty[0];
    dispatch({ type: 'setStateMultiLayers', keys: ['exhibit', 'monacottoAddressMonaparty'], value: stateFixed.monacottoAddressMonaparty });
  }

  const clientTime = Date.now();
  const message = `I want to sell ${stateFixed.amountToSell} ${stateFixed.tokenName}(s) for ${stateFixed.priceMona} MONA per unit. I want receive proceeds in ${stateFixed.addressPayProceedsTo}. I want to exhibit them as ${stateFixed.addressMain}. I will send tokens from ${stateFixed.addressCardFrom} to ${stateFixed.monacottoAddressMonaparty}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;

  let signature;
  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  if (
    addressType === 'addressMain' && keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined ||
    addressType === 'addressCardFrom' && keyPairsInPool[stateFixed.addressCardFrom]?.keyPair !== undefined
  ) {
    let keyPair;

    if (addressType === 'addressMain') {
      addressActual = stateFixed.addressMain;
      keyPair = keyPairsInPool[stateFixed.addressMain].keyPair;
    }
    else { // addressCardFrom
      addressActual = stateFixed.addressCardFrom;
      keyPair = keyPairsInPool[stateFixed.addressCardFrom].keyPair;
    }

    const result = signWithKey(state, dispatch, keyPair, message);

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    }
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    const keyPair = keyPairs[0];
    let result;

    result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      addressActual = result.address;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return 'rejected';
    }

    if (!checkIfAddressIsMatchedWithTwoAddresses(state, dispatch, addressActual, stateFixed.addressMain, stateFixed.addressCardFrom, addressType, stateFixed.addressCheck)) {
      return { status: 'rejected' };
    }

    // // キーカードのアドレスが合ってるかチェック

    // if (stateFixed.addressCheck === 'strict') {
    //   if (addressActual !== (addressType === 'addressMain' ? stateFixed.addressMain : stateFixed.addressCardFrom)) {
    //     dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
    //     return 'rejected';
    //   }
    // }
    // else if (stateFixed.addressCheck === 'addressSecond') {
    //   if (addressActual !== stateFixed.addressMain && addressActual !== stateFixed.addressCardFrom) {
    //     dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
    //     return 'rejected';
    //   }
    // }
    // else { // off
    // }

    result = signWithKey(state, dispatch, keyPair, message);

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    }
  }
  else if (stateFixed.walletType === 'mpurse') {
    // キープールに鍵が無い。
    // Mpurseのアドレスが合ってるかチェック
    const result = await window.mpurse.getAddress()
    .then( (result) => {
      addressActual = result;

      if (stateFixed.addressCheck === 'strict') {
        if (addressActual !== (addressType === 'addressMain' ? stateFixed.addressMain : stateFixed.addressCardFrom)) {
          dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
          return 'rejected';
        }
        else {
          return 'fulfilled';
        }
      }
      else if (stateFixed.addressCheck === 'addressSecond') {
        if (addressActual !== stateFixed.addressMain && addressActual !== stateFixed.addressCardFrom) {
          dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
          return 'rejected';
        }
        else {
          return 'fulfilled';
        }
      }
      else { // off
        return 'fulfilled';
      }
    })
    .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
        return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    // 署名
    try {
      signature= await window.mpurse.signMessage(message).then( result => result );
    }
    catch (err) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return { status: 'rejected' };
    }
  }
  else if (stateFixed.walletType === 'nfc') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
    return {
      status: 'rejected',
      message: 'nfcRequired',
    };
  }
  else { // qr
    dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
    return {
      status: 'rejected',
      message: 'bcRequired',
    };
  }

  // GO！

  if (stateFixed.exhibitNo === undefined || stateFixed.exhibitNo === null || stateFixed.exhibitNo === '') {
    bodyObj = {
      "body": {
        "method": 'insert',
        "addressType": addressType,
        "addressMain": stateFixed.addressMain,
        "addressMainActual": addressActual,
        // "addressMainCoinType": stateFixed.addressMainCoinType,
        "exhibitType": stateFixed.exhibitType,
        "addressCardFrom": stateFixed.addressCardFrom,
        "addressPayProceedsTo": stateFixed.addressPayProceedsTo,
        "monacottoAddressMonaparty": stateFixed.monacottoAddressMonaparty,
        "tokenName": stateFixed.tokenName,
        "amountToSell": stateFixed.amountToSell,
        "priceMona": stateFixed.priceMona,
      }
    };

    bodyObj.body.clientTimeOfAddressMain = clientTime;
    bodyObj.body.signatureByAddressMain = signature;
    bodyObj.body.signatureVersionOfAddressMain = stateFixed.signatureVersion;

    if (stateFixed.addressMain === stateFixed.addressCardFrom) {
      bodyObj.body.clientTimeOfAddressCardFrom = clientTime;
      bodyObj.body.signatureByAddressCardFrom = signature;
      bodyObj.body.signatureVersionOfAddressCardFrom = stateFixed.signatureVersion;
    }

    request.body = JSON.stringify(bodyObj);
  }
  else {
    bodyObj = {
      "body": {
        "method": 'update',
        "addressMain": stateFixed.addressMain,
        "addressCardFromActual": addressActual,
        // "addressMainCoinType": stateFixed.addressMainCoinType,
        "exhibitNo": stateFixed.exhibitNo,
        "addressType": addressType,
      }
    };

    bodyObj.body.clientTimeOfAddressCardFrom = clientTime;
    bodyObj.body.signatureByAddressCardFrom = signature;
    bodyObj.body.signatureVersionOfAddressCardFrom = stateFixed.signatureVersion;

    request.body = JSON.stringify(bodyObj);
  }

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/exhibit';
  request.method = 'POST';

  messages = {
    "you must accept the terms and conditions of the current version.": words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language],
    "internal server error": words.internalServerError[state.language],
    "bad signature": words.badSignature[state.language],
    "bad signature, can't recover address": words.badSignatureCanNotRecoverAddress[state.language],
    "unknown signatureVersion": words.unknownSignatureVersion[state.language],
    "clientTime must be larger than last time.": words.clientTimeMustBeLargerThanLastTime[state.language],
    "this address is not permitted.": words.thisAddressIsNotPermitted[state.language],
    "this address is forbidden.": words.thisAddressIsForbidden[state.language],
    "order expired.": words.orderExpired[state.language],
    "out of service": words.outOfService[state.language],
    "not permitted by owner.": words.notPermittedByOwner[state.language],
    "user does not exist.": words.pleaseRegisterAsAUserOnTheSetupPage[state.language],
    "you are not delegated from main address.": words.norIsTheSignatureDelegatedFromMainAddress[state.language],
    "you are not delegated from the address you send cards from.": words.norIsTheSignatureDelegatedFromAddressYouSendCardsFrom[state.language],
    "success": words.success[state.language],
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, undefined, undefined, 'notification', messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {
    const exhibit = response.body.exhibit;
    const sessionId = response.body.sessionId;
    const expirationOfSession = response.body.expirationOfSession;

    // exhibit情報更新
    dispatch({ type: 'setState', key: 'exhibit', value: exhibit });

    // session情報更新
    if (addressType === 'addressMain') {
      dispatch({ type: 'setStateMultiLayers', keys: ['session', stateFixed.addressMain], value: {'sessionId': sessionId.addressMain, 'expirationOfSession': expirationOfSession.addressMain} });

      if (addressActual !== stateFixed.addressMain) {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', addressActual], value: {'sessionId': sessionId.addressMainActual, 'expirationOfSession': expirationOfSession.addressMainActual} });
      }

      if (sessionId.addressCardFrom !== undefined) {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', stateFixed.addressCardFrom], value: {'sessionId': sessionId.addressCardFrom, 'expirationOfSession': expirationOfSession.addressCardFrom} });
      }
    }
    else { // addressCardFrom
      dispatch({ type: 'setStateMultiLayers', keys: ['session', stateFixed.addressCardFrom], value: {'sessionId': sessionId.addressCardFrom, 'expirationOfSession': expirationOfSession.addressCardFrom} });

      if (addressActual !== stateFixed.addressCardFrom) {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', addressActual], value: {'sessionId': sessionId.addressCardFromActual, 'expirationOfSession': expirationOfSession.addressCardFromActual} });
      }
    }

    // オーダーNo.通知欄表示(全署名完了していたら)
    if ( exhibit.signatureByAddressMain !== undefined && exhibit.signatureByAddressMain !== null && exhibit.signatureByAddressMain !== '' &&
         exhibit.signatureByAddressCardFrom !== undefined && exhibit.signatureByAddressCardFrom !== null && exhibit.signatureByAddressCardFrom !== '') {
      dispatch({ type: 'setNotification', key: 'notification', value: words.acceptedAsExhibitNoX1[state.language] + exhibit.exhibitNo + words.acceptedAsExhibitNoX2[state.language] });
    }

    // cookie情報更新(アドレス情報)
    const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
    const cookiesNew = [
          {
            action: 'exhibit',
            addressType: 'addressMain',
            description: `${userName} ${stateFixed.tokenName} ${stateFixed.amountToSell}`,
            // addressMainExhibitor: stateFixed.addressMain,
            // tokenName: stateFixed.tokenName,
            // amount: stateFixed.amountToSell,
            time: clientTime,
            address: stateFixed.addressMain
          },
    ];

    if (cookiesNew.every( cookie => cookie.address !== stateFixed.addressCardFrom )) {
      cookiesNew.push(
          {
            action: 'exhibit',
            addressType: 'addressCardFrom',
            description: `${userName} ${stateFixed.tokenName} ${stateFixed.amountToSell}`,
            time: clientTime,
            address: stateFixed.addressCardFrom
          },
      );
    }

    if (cookiesNew.every( cookie => cookie.address !== stateFixed.addressPayProceedsTo )) {
      cookiesNew.push(
          {
            action: 'exhibit',
            addressType: 'addressPayProceedsTo',
            description: `${userName} ${stateFixed.tokenName} ${stateFixed.amountToSell}`,
            time: clientTime,
            address: stateFixed.addressPayProceedsTo
          }
      );
    }

    setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
  }
  else {
  }

  return response;
}

// 「出品:デフォルト」ボタン押下
async function handleClickDefaultExhibit(state, dispatch) {
  if (state.exhibit.addressMain !== undefined && state.exhibit.addressMain !== '') {
    if (state.user[state.exhibit.addressMain] !== undefined) {
      dispatch({ type: 'setStateMultiLayers', keys: ['exhibit', 'addressCardFrom'], value: state.user[state.exhibit.addressMain].basicInformation.addressCardFromDefault });
      dispatch({ type: 'setStateMultiLayers', keys: ['exhibit', 'addressPayProceedsTo'], value: state.user[state.exhibit.addressMain].basicInformation.addressPayProceedsToDefault });
    }
    else {
    }
  }
  else if (state.login.addressMain !== undefined && state.login.addressMain !== '') {
    if (state.user[state.login.addressMain] !== undefined) {
      dispatch({ type: 'setStateMultiLayers', keys: ['exhibit', 'addressMain'], value: state.login.addressMain });
      dispatch({ type: 'setStateMultiLayers', keys: ['exhibit', 'addressCardFrom'], value: state.user[state.login.addressMain].basicInformation.addressCardFromDefault });
      dispatch({ type: 'setStateMultiLayers', keys: ['exhibit', 'addressPayProceedsTo'], value: state.user[state.login.addressMain].basicInformation.addressPayProceedsToDefault });
    }
    else {
    }
  }
  else {
  }

  return;
}

// 「出品:クリア」ボタン押下 HANDLE CLICK CLEAR EXHIBIT
async function handleClickClearExhibit(state, dispatch) {
  dispatch({ type: 'setState', key: 'exhibit',
    value: {
      exhibitNo: '',
      addressMain: '',
      // addressMainCoinType: 'mona',
      addressCardFrom: '',
      addressPayProceedsTo: '',
      tokenName: '',
      amountToSell: '',
      priceMona: { face: '', value: '' },
      signatureByAddressMain: '',
      signatureByAddressCardFrom: '',
      disabled: false,
      status: 'waitingForSignature',
    }
  });

  return;
}

// 「出品:カード送信」ボタン押下 HANDLE CLICK SEND TOKEN
async function handleClickSendToken(state, dispatch, keyPairs) {
  let request = {};
  let response = {};
  let messages = {};
  let txid;

  // state固定
  const stateFixed = {
    addressCardFrom: state.exhibit.addressCardFrom,
    monacottoAddressMonaparty: state.exhibit.monacottoAddressMonaparty,
    tokenName: state.exhibit.tokenName,
    amountToSell: state.exhibit.amountToSell,
    addressMain: state.exhibit.addressMain,
    exhibitNo: state.exhibit.exhibitNo,
    signatureByAddressMain: state.exhibit.signatureByAddressMain,
    walletType: state.walletType,
  };

  const buildAndBroadcast = async (keyPairsInArg) => {
    let actionItemsMultiSources;
    let divisible;
    let quantity;

    if (state.exhibit.divisible !== undefined && state.exhibit.divisible !== null) {
      divisible = state.exhibit.divisible;
    }
    else {
      // アセット情報取得
      const result = await getAssetInfo(state, dispatch, [stateFixed.tokenName], true);

      if (result.status === 'fulfilled') {
        const assetInfoTmp = result.body;
        divisible = assetInfoTmp[stateFixed.tokenName].divisible;
      }
      else {
        dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSend[state.language] });
        return { status: 'rejected' };
      }
    }

    if (divisible === true) {
      // 実は、exhibitでdivisibleはNGにしている...
      quantity = new decimal(stateFixed.amountToSell).times(100000000).toNumber();
    }
    else {
      quantity = stateFixed.amountToSell;
    }

    // まず最初にカートを空にする！

    actionItemsMultiSources = deleteCart(state, dispatch, 'cartMonaparty');

    actionItemsMultiSources = addTokensToTheCartMonaparty(
      state, dispatch, stateFixed.addressCardFrom, stateFixed.monacottoAddressMonaparty, stateFixed.tokenName, quantity, actionItemsMultiSources
    );

    for (const addressFrom of Object.keys(actionItemsMultiSources)) {
      for (const actionItem of actionItemsMultiSources[addressFrom]) {
        actionItem.status = 'unprocessed';
      }
    }

    dispatch({ type: 'setState', key: 'accessing', value: true });

    await buildMonapartyTransactions(state, dispatch, keyPairsInArg, actionItemsMultiSources);

    dispatch({ type: 'setState', key: 'accessing', value: false });

    if (actionItemsMultiSources[stateFixed.addressCardFrom][0].status === 'succeeded') {
    }
    else { // failed
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSend[state.language] });
      return { status: 'rejected' };
    }

    dispatch({ type: 'setState', key: 'accessing', value: true });

    await broadcastMonaTransactions(state, dispatch, actionItemsMultiSources);

    dispatch({ type: 'setState', key: 'accessing', value: false });

    if (actionItemsMultiSources[stateFixed.addressCardFrom][0].status === 'succeeded') {
      const txid = actionItemsMultiSources[stateFixed.addressCardFrom][0].txid;

      dispatch({ type: 'setState', key: 'exhibit',
        value: {
          exhibitNo: '',
          addressMain: state.exhibit.addressMain,
          // addressMainCoinType: 'mona',
          addressCardFrom: state.exhibit.addressCardFrom,
          addressPayProceedsTo: state.exhibit.addressPayProceedsTo,
          tokenName: '',
          amountToSell: '',
          priceMona: { face: '', value: '' },
          signatureByAddressMain: '',
          signatureByAddressCardFrom: '',
          disabled: false,
          status: 'waitingForSignature',
        }
      });

      dispatch({ type: 'setNotification', key: 'notification', value: words.sentSuccessfully[state.language] + ' ' + txid });
    }
    else { // failed
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSend[state.language] });
      return { status: 'rejected' };
    }

    txid = actionItemsMultiSources[stateFixed.addressCardFrom][0].txid;

    // 一応、カートを空にする

    deleteCart(state, dispatch, 'cartMonaparty');
  };


  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  if (keyPairsInPool[stateFixed.addressCardFrom]?.keyPair !== undefined) {
    await buildAndBroadcast(keyPairsInPool);
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    const keyPair = keyPairs[0];
    let addressActual;
    let result;

    result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      addressActual = result.address;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return 'rejected';
    }

    // キーカードのアドレスが合ってるかチェック

    if (addressActual !== stateFixed.addressCardFrom) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
      return 'rejected';
    }

    await buildAndBroadcast({ [addressActual]: { keyPair: keyPair } });
  }
  else if (stateFixed.walletType === 'mpurse') {
    // Mpurseが接続されているかチェック
    if (window.mpurse === undefined) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.activateMpurse[state.language] });
      return { status: 'rejected' };
    }

    // Mpurseのアドレスが合ってるかチェック
    const result = await window.mpurse.getAddress()
    .then( result => {
      if (result !== stateFixed.addressCardFrom) {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
        return 'rejected';
      }
      else {
        return 'fulfilled';
      }
    })
    .catch( error => {
      dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
      return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    if (false) {
      // GO!
      const result = await window.mpurse.sendAsset(stateFixed.monacottoAddressMonaparty, stateFixed.tokenName, stateFixed.amountToSell)
      .then( async (result) => {
        devLog(result);

        dispatch({ type: 'setState', key: 'exhibit',
          value: {
            exhibitNo: '',
            addressMain: state.exhibit.addressMain,
            // addressMainCoinType: 'mona',
            addressCardFrom: state.exhibit.addressCardFrom,
            addressPayProceedsTo: state.exhibit.addressPayProceedsTo,
            tokenName: '',
            amountToSell: '',
            priceMona: { face: '', value: '' },
            signatureByAddressMain: '',
            signatureByAddressCardFrom: '',
            disabled: false,
            status: 'waitingForSignature',
          }
        });

        dispatch({ type: 'setNotification', key: 'notification', value: words.sentSuccessfully[state.language] + ' ' + result });
      })
      .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSend[state.language] });
        return 'rejected';
      });

      if (result === 'rejected') {
        return { status: 'rejected' };
      }
    }
    else {
      await buildAndBroadcast(null);
    }
  }
  else if (stateFixed.walletType === 'nfc') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
    return {
      status: 'rejected',
      message: 'nfcRequired',
    };
  }
  else { // bc
    dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
    return {
      status: 'rejected',
      message: 'bcRequired',
    };
  }

  // トークン送信成功通知
  request.body = JSON.stringify({
    "body": {
      addressMain: stateFixed.addressMain,
      exhibitNo: stateFixed.exhibitNo,
      signatureByAddressMain: stateFixed.signatureByAddressMain,
      sendTokenTx_hash: txid,
    }
  });
  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/notify_sending_token';
  request.method = 'POST';
  messages = {
  };

  response = await syncHttpRequest2(request, undefined, undefined, undefined, messages, dispatch);

  return { status: 'fulfilled' };
}

// 「出品キャンセル：署名」ボタン押下 -- HANDLE CLICK SIGN CANCEL EXHIBIT
async function handleClickSignCancelExhibit(state, dispatch, cookies, setCookie, keyPairs) {
  let addressActual;

  // トークン存在チェック

  // トークン保有量チェック

  let request = {};
  let messages = {};
  let bodyObj = {};

  // state固定
  const stateFixed = {
    exhibitNo: state.cancelExhibit.exhibitNo,
    addressMain: state.cancelExhibit.addressMain,
    tokenName: state.cancelExhibit.tokenName,
    amountToSell: state.cancelExhibit.amountToSell,
    signatureVersion: state.config.clientParameters.signatureVersion.cancelExhibit,
    addressCheck: state.addressCheck,
    walletType: state.walletType,
  };

  // // Mpurseのアドレスが合ってるかチェック
  // const result = await window.mpurse.getAddress()
  // .then( (result) => {
  //   addressActual = result;

  //   if (stateFixed.addressCheck === 'strict' || stateFixed.addressCheck === 'addressSecond') {
  //     if (addressActual !== stateFixed.addressMain) {
  //       dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
  //       return 'rejected';
  //     }
  //     else {
  //       return 'fulfilled';
  //     }
  //   }
  //   else { // off
  //     return 'fulfilled';
  //   }
  // })
  // .catch( (error) => {
  //     dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
  //     return 'rejected';
  // });

  // if (result === 'rejected') {
  //   return { status: 'rejected' };
  // }

  // 署名
  const clientTime = Date.now();
  const message = `I cancel the exhibit; No.${stateFixed.exhibitNo} of ${stateFixed.addressMain}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;

  const keyPairsInPool = getValidKeyPairs(state, dispatch);
  let signature;

  if (keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined) {
    const keyPair = keyPairsInPool[stateFixed.addressMain].keyPair;
    addressActual = stateFixed.addressMain;

    const result = signWithKey(state, dispatch, keyPair, message);

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return { status: 'rejected' };
    }
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    const keyPair = keyPairs[0];
    let result;

    result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      addressActual = result.address;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return { status: 'rejected' };
    }

    // addressMainは必ず指定されている。
    if (!checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressActual, stateFixed.addressMain, stateFixed.addressCheck)) {
      return { status: 'rejected' };
    }

    result = signWithKey(state, dispatch, keyPair, message);

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return { status: 'rejected' };
    }
  }
  else if (stateFixed.walletType === 'mpurse') {
    // キープールに鍵が無い。
    // Mpurseのアドレスが合ってるかチェック
    const result = await window.mpurse.getAddress()
    .then( (result) => {
      addressActual = result;

      // addressMainは必ず指定されている。
      if (!checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressActual, stateFixed.addressMain, stateFixed.addressCheck)) {
        return 'rejected';
      }
    })
    .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
        return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    // 署名
    try {
      signature= await window.mpurse.signMessage(message).then( result => result );
    }
    catch (err) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return { status: 'rejected' };
    }
  }
  else if (stateFixed.walletType === 'nfc') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
    return {
      status: 'rejected',
      message: 'nfcRequired',
    };
  }
  else { // qr
    dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
    return {
      status: 'rejected',
      message: 'bcRequired',
    };
  }

  // GO！

  request.body = JSON.stringify({
    "body": {
      "addressMain": stateFixed.addressMain,
      "addressMainActual": addressActual,
      "exhibitNo": stateFixed.exhibitNo,
      "clientTimeOfAddressMainCancel": clientTime,
      "signatureByAddressMainCancel": signature,
      "signatureVersionOfAddressMainCancel": stateFixed.signatureVersion,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/cancel_exhibit';
  request.method = 'POST';

  messages = {
    "addressMona must be filled.": words.addressMonaMustBeFilled[state.language],
    "you must accept the terms and conditions of the current version.": words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language],
    "internal server error": words.internalServerError[state.language],
    "bad signature": words.badSignature[state.language],
    "bad signature, can't recover address": words.badSignatureCanNotRecoverAddress[state.language],
    "unknown signatureVersion": words.unknownSignatureVersion[state.language],
    "clientTime must be larger than last time.": words.clientTimeMustBeLargerThanLastTime[state.language],
    "this address is not permitted.": words.thisAddressIsNotPermitted[state.language],
    "this address is forbidden.": words.thisAddressIsForbidden[state.language],
    "order expired.": words.orderExpired[state.language],
    "out of service": words.outOfService[state.language],
    "you are not delegated from main address.": words.norIsTheSignatureDelegatedFromMainAddress[state.language],
    "success": words.success[state.language],
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, undefined, undefined, 'notification', messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {
    const sessionId = response.body.sessionId;
    const expirationOfSession = response.body.expirationOfSession;

    // cancel exhibit情報更新
    dispatch({ type: 'setState', key: 'cancelExhibit', value: response.body.cancelExhibit });

    // change exhibit情報更新
    dispatch({ type: 'setStateMultiLayers', keys: ['changeExhibit', 'status'], value: 'cancelled' });
    dispatch({ type: 'setStateMultiLayers', keys: ['changeExhibit', 'disabled'], value: true });

    // session情報更新
    dispatch({ type: 'setStateMultiLayers', keys: ['session', stateFixed.addressMain], value: {'sessionId': sessionId.addressMain, 'expirationOfSession': expirationOfSession.addressMain} });

    if (addressActual !== stateFixed.addressMain) {
      dispatch({ type: 'setStateMultiLayers', keys: ['session', addressActual], value: {'sessionId': sessionId.addressMainActual, 'expirationOfSession': expirationOfSession.addressMainActual} });
    }

    // オーダーNo.通知欄表示
    // dispatch({ type: 'setNotification', key: 'notification', value: words.orderAcceptedOrderNo[state.language] + response.body.orderNo + words.orderAcceptedOrderNo2[state.language] });

    // cookie情報更新(アドレス情報)
    const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
    const cookiesNew = [
          {
            action: 'cancel',
            addressType: 'addressMain',
            description: `${userName} ${stateFixed.tokenName} ${stateFixed.amountToSell}`,
            // addressMainExhibitor: stateFixed.addressMain,
            // tokenName: stateFixed.tokenName,
            // amount: stateFixed.amountToSell,
            time: clientTime,
            address: stateFixed.addressMain
          },
    ];

    setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
  }
  else {
  }

  return response;
}

// 「出品キャンセル:クリア」ボタン押下
async function handleClickClearCancelExhibit(state, dispatch) {
  dispatch({ type: 'setState', key: 'cancelExhibit',
    value: {
      exhibitNo: '',
      addressMain: '',
      amountFree: null,
      signatureByAddressMainCancel: null,
      status: 'waitingForSignature',
    }
  });

  return;
}

// 「出品変更：署名」ボタン押下 -- HANDLE CLICK SIGN CHANGE EXHIBIT
async function handleClickSignChangeExhibit(state, dispatch, cookies, setCookie, keyPairs) {
  let addressActual;

  // トークン存在チェック

  // トークン保有量チェック

  let request = {};
  let messages = {};
  let bodyObj = {};

  // state固定
  const stateFixed = {
    exhibitNo: state.changeExhibit.exhibitNo,
    addressMain: state.changeExhibit.addressMain,
    addressPayProceedsTo: state.changeExhibit.addressPayProceedsTo,
    priceMona: state.changeExhibit.priceMona.value,
    tokenName: state.changeExhibit.tokenName,
    amountToSell: state.changeExhibit.amountToSell,
    signatureVersion: state.config.clientParameters.signatureVersion.changeExhibit,
    minimumPriceMona: state.config.clientParameters.priceMonaRange.min,
    maximumPriceMona: state.config.clientParameters.priceMonaRange.max,
    addressCheck: state.addressCheck,
    walletType: state.walletType,
  };

  // 必須項目チェック
  if (stateFixed.addressPayProceedsTo === undefined || stateFixed.addressPayProceedsTo === null || stateFixed.addressPayProceedsTo === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.addressYouReceiveProceedsInMustBeFilled[state.language] });
    return;
  }

  if (stateFixed.priceMona === undefined || stateFixed.priceMona === null || stateFixed.priceMona === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.unitPriceMustBeFilled[state.language] });
    return;
  }

  // 数量チェック
  if (stateFixed.priceMona < stateFixed.minimumPriceMona) {
    dispatch({ type: 'setNotification', key: 'notification',
      value: `${words.unitPriceMustBeEqualOrMoreThanXMona1[state.language]} ${stateFixed.minimumPriceMona} ${words.unitPriceMustBeEqualOrMoreThanXMona2[state.language]}`
    });
    return;
  }

  if (stateFixed.priceMona > stateFixed.maximumPriceMona) {
    dispatch({ type: 'setNotification', key: 'notification',
      value: `${words.unitPriceMustBeEqualOrLessThanXMona1[state.language]} ${stateFixed.maximumPriceMona} ${words.unitPriceMustBeEqualOrLessThanXMona2[state.language]}`
    });
    return;
  }

  // 署名
  const clientTime = Date.now();
  const message = `I cancel the exhibit; No.${stateFixed.exhibitNo} of ${stateFixed.addressMain}. I will take it over and sell token(s) for ${stateFixed.priceMona} MONA per unit. I want receive proceeds in ${stateFixed.addressPayProceedsTo}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;

  const keyPairsInPool = getValidKeyPairs(state, dispatch);
  let signature;

  if (keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined) {
    const keyPair = keyPairsInPool[stateFixed.addressMain].keyPair;
    addressActual = stateFixed.addressMain;

    const result = signWithKey(state, dispatch, keyPair, message);

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return { status: 'rejected' };
    }
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    const keyPair = keyPairs[0];
    let result;

    result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      addressActual = result.address;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return { status: 'rejected' };
    }

    // addressMainは必ず指定されている。
    if (!checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressActual, stateFixed.addressMain, stateFixed.addressCheck)) {
      return { status: 'rejected' };
    }

    result = signWithKey(state, dispatch, keyPair, message);

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return { status: 'rejected' };
    }
  }
  else if (stateFixed.walletType === 'mpurse') {
    // キープールに鍵が無い。
    // Mpurseのアドレスが合ってるかチェック
    const result = await window.mpurse.getAddress()
    .then( (result) => {
      addressActual = result;

      // addressMainは必ず指定されている。
      if (!checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressActual, stateFixed.addressMain, stateFixed.addressCheck)) {
        return 'rejected';
      }
    })
    .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
        return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    // 署名
    try {
      signature= await window.mpurse.signMessage(message).then( result => result );
    }
    catch (err) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return { status: 'rejected' };
    }
  }
  else if (stateFixed.walletType === 'nfc') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
    return {
      status: 'rejected',
      message: 'nfcRequired',
    };
  }
  else { // qr
    dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
    return {
      status: 'rejected',
      message: 'bcRequired',
    };
  }

  // GO！

  request.body = JSON.stringify({
    "body": {
      "addressMain": stateFixed.addressMain,
      "addressMainActual": addressActual,
      "exhibitNo": stateFixed.exhibitNo,
      "addressPayProceedsTo": stateFixed.addressPayProceedsTo,
      "priceMona": stateFixed.priceMona,
      "clientTimeOfAddressMainChange": clientTime,
      "signatureByAddressMainChange": signature,
      "signatureVersionOfAddressMainChange": stateFixed.signatureVersion,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/change_exhibit';
  request.method = 'POST';

  messages = {
    "addressMona must be filled.": words.addressMonaMustBeFilled[state.language],
    "you must accept the terms and conditions of the current version.": words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language],
    "internal server error": words.internalServerError[state.language],
    "bad signature": words.badSignature[state.language],
    "bad signature, can't recover address": words.badSignatureCanNotRecoverAddress[state.language],
    "unknown signatureVersion": words.unknownSignatureVersion[state.language],
    "clientTime must be larger than last time.": words.clientTimeMustBeLargerThanLastTime[state.language],
    "this address is not permitted.": words.thisAddressIsNotPermitted[state.language],
    "this address is forbidden.": words.thisAddressIsForbidden[state.language],
    "out of service": words.outOfService[state.language],
    "you are not delegated from main address.": words.norIsTheSignatureDelegatedFromMainAddress[state.language],
    "success": words.success[state.language],
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, undefined, undefined, 'notification', messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {
    const sessionId = response.body.sessionId;
    const expirationOfSession = response.body.expirationOfSession;
    const exhibit = response.body.exhibit;

    // change exhibit情報更新
    dispatch({ type: 'setState', key: 'changeExhibit', value: exhibit });

    // cancel exhibit情報更新
    dispatch({ type: 'setStateMultiLayers', keys: ['cancelExhibit', 'status'], value: 'takenOver' });

    // session情報更新
    dispatch({ type: 'setStateMultiLayers', keys: ['session', stateFixed.addressMain], value: {'sessionId': sessionId.addressMain, 'expirationOfSession': expirationOfSession.addressMain} });

    if (addressActual !== stateFixed.addressMain) {
      dispatch({ type: 'setStateMultiLayers', keys: ['session', addressActual], value: {'sessionId': sessionId.addressMainActual, 'expirationOfSession': expirationOfSession.addressMainActual} });
    }

    // オーダーNo.通知欄表示(全署名完了していたら)
    if ( exhibit.signatureByAddressMain !== undefined && exhibit.signatureByAddressMain !== null && exhibit.signatureByAddressMain !== '' &&
         exhibit.signatureByAddressCardFrom !== undefined && exhibit.signatureByAddressCardFrom !== null && exhibit.signatureByAddressCardFrom !== '') {
      dispatch({ type: 'setNotification', key: 'notification', value: words.takenOverToExhibitNoX1[state.language] + exhibit.exhibitNo + words.takenOverToExhibitNoX2[state.language] });
    }

    // cookie情報更新(アドレス情報)
    const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
    const cookiesNew = [
          {
            action: 'cancelAndReexhibit',
            addressType: 'addressMain',
            description: `${userName} ${stateFixed.tokenName} ${stateFixed.amountToSell}`,
            // addressMainExhibitor: stateFixed.addressMain,
            // tokenName: stateFixed.tokenName,
            // amount: stateFixed.amountToSell,
            time: clientTime,
            address: stateFixed.addressMain
          },
    ];

    if (cookiesNew.every( cookie => cookie.address !== stateFixed.addressPayProceedsTo )) {
      cookiesNew.push(
          {
            action: 'cancelAndReexhibit',
            addressType: 'addressPayProceedsTo',
            description: `${userName} ${stateFixed.tokenName} ${stateFixed.amountToSell}`,
            time: clientTime,
            address: stateFixed.addressPayProceedsTo
          },
      );
    }

    setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
  }
  else {
  }

  return response;
}

// 「出品変更:クリア」ボタン押下
async function handleClickClearChangeExhibit(state, dispatch) {
  dispatch({ type: 'setState', key: 'changeExhibit',
    value: {
      exhibitNo: null,
      addressMain: '',
      addressPayProceedsTo: '',
      amountToSell: null,
      priceMona: { face: '', value: '' },
      priceMonaWatanabe: '',
      signatureByAddressMainChange: null,
      status: 'waitingForSignature',
    }
  });

  return;
}

// 「履歴」ボタン押下 HANDLE CLICK GET EXHIBIT HISTORY
async function handleClickGetExhibitHistory(state, dispatch, cookies, setCookie, pagingIndex, activeCertification, consistentRead, keyPairs) {
  let addressActual;
  let request = {};
  let messages = {};
  let certifications = [];
  const now = Date.now();

  devLog('activeCertification', JSON.stringify(activeCertification));

  if (activeCertification === undefined || activeCertification === null) {
    activeCertification = [];
    // activeCertification = {
    //   address: null,
    // };
  }

  if (consistentRead == undefined || consistentRead === null) {
    consistentRead = false;
  }

  // state固定
  const stateFixed = {
    addressMain: state.getHistory.addressMain,
    lastEvaluatedExhibitNo: pagingIndex >= 1 ? state.getHistory.lastEvaluatedKey.exhibit[pagingIndex - 1].exhibitNo : null,
    signatureVersion: state.config.clientParameters.signatureVersion.getExhibitHistory,
    addressCheck: state.addressCheck,
    walletType: state.walletType,
  };

  // 有効なセッションに絞り込む。

  devLog('session', JSON.stringify(state.session));
  for (const address of Object.keys(state.session)) {

    // activeCertificationの場合は別処理
    // if (address !== activeCertification.address) 
    if ( !activeCertification.map( certification => certification.address ).includes(address) ) {

      // -- state固定
      stateFixed[address] = {
        // addressCoinType: state.session[address].addressCoinType,
        sessionId: state.session[address].sessionId,
        expirationOfSession: state.session[address].expirationOfSession,
      };

      // -- 絞り込み
      devLog(stateFixed[address].expirationOfSession, now);
      if (stateFixed[address].expirationOfSession >= now + marginOfSessionTime) {
      devLog('push');
        certifications.push({
          // addressCoinType: stateFixed[address].addressCoinType,
          address: address,
          sessionId: stateFixed[address].sessionId,
          lastEvaluatedExhibitNo: address === stateFixed.addressMain ? stateFixed.lastEvaluatedExhibitNo : null,
          consistentRead: address === stateFixed.addressMain ? consistentRead : false,
        });
      }
    }
  }

  for (const certification of activeCertification) {
    // -- state固定
    stateFixed[certification.address] = {
      sessionId: certification.sessionId,
      expirationOfSession: certification.expirationOfSession,
    };

    // -- 有効だったら(まあほぼ有効なんだろうけど)プッシュ
    if (stateFixed[certification.address].expirationOfSession >= now + marginOfSessionTime) {
      certifications.push({
        address: certification.address,
        sessionId: stateFixed[certification.address].sessionId,
        lastEvaluatedExhibitNo: null, // activeCertificationは常に先頭を検索する。
        consistentRead: true, // activeCertificationは常に強力な整合性のある読み込みをする。
      });
    }
  }

  // アドレスが指定されており、そのアドレスのセッションが存在しない、または、期限が切れていれば、署名する。(キープール or Mpurse)
  // Mpurseの場合、アドレスが指定されていない場合は、アクティブなアドレスで代替するかもしれない。(アドレスチェックモードに依る。)
  // keyPairsが渡された場合(2周目)、keyPairsの先頭の鍵で試みる。アドレスが指定されていない場合は、先頭のアドレスで代替するかもしれない。(アドレスチェックモードに依る。)

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  const extendedCertificationProperties = [
    { key: 'consistentRead', value: consistentRead },
    { key: 'lastEvaluatedExhibitNo', value: stateFixed.lastEvaluatedExhibitNo },
  ];

  if (keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined) {
    certificationWithKey(state, dispatch, [ keyPairsInPool[stateFixed.addressMain].keyPair ], 'information', stateFixed, certifications, now, extendedCertificationProperties, 'matchedKey');
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    certificationWithKey(state, dispatch, keyPairs, 'information', stateFixed, certifications, now, extendedCertificationProperties, 'firstKey');
  }
  else if (stateFixed.walletType === 'mpurse') {
    await setSignatureByAddressMain(state, dispatch, stateFixed, certifications, now, extendedCertificationProperties, 'information');
  }

  // 対象がなければ戻る。
  // NFC, BCの場合、キーカードを要求して2周目。

  if (
    certifications.length === 0 ||
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    !certifications.map( certification => certification.address ).includes(stateFixed.addressMain)
  ) {
    if (keyPairs === undefined || keyPairs === null) {
      if (stateFixed.walletType === 'nfc') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
        return {
          status: 'rejected',
          message: 'nfcRequired',
        };
      }
      else if (stateFixed.walletType === 'qr') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
        return {
          status: 'rejected',
          message: 'bcRequired',
        };
      }
    }

    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return;
  }

  request.body = JSON.stringify({
    "body": {
      "certifications": certifications,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/get_history';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, 'exhibitHistory', undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    for (const address of Object.keys(response.body)) {
      // 署名した場合もろもろ更新
      if (response.body[address]['certificatedBy'] === 'signature') {

        // session更新
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address],
          value: {'sessionId': response.body[address]['sessionId'], 'expirationOfSession': response.body[address]['expirationOfSession']} });
      }
      else if (response.body[address]['certificatedBy'] === 'none') {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address], value: { expirationOfSession: 0 } });
      }
    }

    // addressMainが検索対象で、検索成功した場合
    if (response.body[stateFixed.addressMain] !== undefined &&
         (response.body[stateFixed.addressMain].certificatedBy === 'signature' || response.body[stateFixed.addressMain].certificatedBy === 'sessionId')) {
      if (response.body[stateFixed.addressMain].lastEvaluatedKey !== undefined) {
        dispatch({ type: 'setStateMultiLayers', keys: ['getHistory', 'lastEvaluatedKey', 'exhibit', pagingIndex], value: response.body[stateFixed.addressMain].lastEvaluatedKey });
      }
      else {
        dispatch({ type: 'setStateMultiLayers', keys: ['getHistory', 'lastEvaluatedKey', 'exhibit', pagingIndex], value: null });
      }

      // pagingIndexが指定されているということは、前回の検索時からaddressMainが変更されていないということ。(変更するとリセットされてしまう。)
      // addressMainが指定されていないということは、後ページ検索ではない。
      // なぜならば、addressMainが指定されていない状態にするには、前回の検索のあと、addressMainを削除しなければならないから。

      dispatch({ type: 'setStateMultiLayers', keys: ['getHistory', 'pagingIndex', 'exhibit'], value: pagingIndex });

      // addressMainが指定されていなかった場合は、dispatchする。
      if (state.getHistory.addressMain === undefined || state.getHistory.addressMain === null || state.getHistory.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['getHistory', 'addressMain'], value: stateFixed.addressMain });
      }

      // cookie情報更新(アドレス情報)
      const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
      const cookiesNew = [
            {
              action: 'searchExhibitHistory',
              addressType: 'addressMain',
              description: `${userName}`,
              time: now,
              address: stateFixed.addressMain
            },
      ];

      setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
    }
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return;
  }
}

// 「購入:署名」ボタン押下 HANDLE CLICK SIGN PURCHASE
async function handleClickSignPurchase(state, dispatch, cookies, setCookie, addressType, popupLayer ,keyPairs) {
  let addressActual;
  let request = {};
  let messages = {};
  let stateFixed = {};
  let bodyObj = {};

  // state固定
  if (popupLayer !== undefined && popupLayer !== null) {
    stateFixed = {
      purchaseNo: state.purchase.purchaseNo,
      addressMain: state.purchase.addressMain,
      addressPayFrom: state.purchase.addressPayFrom,
      addressSendCardTo: state.purchase.addressSendCardTo,
      addressMainExhibitor: state.popup[popupLayer].body.addressMainExhibitor,
      exhibitNo: state.popup[popupLayer].body.exhibitNo,
      amountFree: state.popup[popupLayer].body.amountFree,
      amountToBuy: state.purchase.amountToBuy,
      asset: state.popup[popupLayer].body.asset,
      asset_longname: state.popup[popupLayer].body.asset_longname,
      priceMona: state.popup[popupLayer].body.priceMona,
      signatureVersion: state.config.clientParameters.signatureVersion.purchase,
      addressCheck: state.addressCheck,
      walletType: state.walletType,
    };
  }
  else {
    stateFixed = {
      purchaseNo: state.purchase.purchaseNo,
      addressMain: state.purchase.addressMain,
      addressPayFrom: state.purchase.addressPayFrom,
      addressSendCardTo: state.purchase.addressSendCardTo,
      addressMainExhibitor: state.itemDetail.item.addressMain,
      exhibitNo: state.itemDetail.item.exhibitNo,
      amountFree: state.itemDetail.item.amountFree,
      amountToBuy: state.purchase.amountToBuy,
      asset: state.itemDetail.item.asset,
      asset_longname: state.itemDetail.item.asset_longname,
      priceMona: state.itemDetail.item.priceMona,
      signatureVersion: state.config.clientParameters.signatureVersion.purchase,
      addressCheck: state.addressCheck,
      walletType: state.walletType,
    };
  }

  // 必須項目チェック
  if (stateFixed.addressMain === undefined || stateFixed.addressMain === null || stateFixed.addressMain === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.mainAddressMustBeFilled[state.language] });
    return;
  }

  if (stateFixed.addressPayFrom === undefined || stateFixed.addressPayFrom === null || stateFixed.addressPayFrom === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.addressYouPayFromMustBeFilled[state.language] });
    return;
  }

  if (stateFixed.addressSendCardTo === undefined || stateFixed.addressSendCardTo === null || stateFixed.addressSendCardTo === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.addressYouReceiveCardsInMustBeFilled[state.language] });
    return;
  }

  if (stateFixed.amountToBuy === undefined || stateFixed.amountToBuy === null || stateFixed.amountToBuy === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.amountToBuyMustBeFilled[state.language] });
    return;
  }

  // 数量チェック
  if (stateFixed.amountToBuy > stateFixed.amountFree) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.amountToBuyMustBeWithinTheNumberOfStock[state.language] });
    return;
  }

  if (stateFixed.amountToBuy < 1) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.amountToBuyMustBeEqualOrLargerThan1[state.language] });
    return;
  }

  const tokenName = stateFixed.asset_longname === null ? stateFixed.asset : `${stateFixed.asset_longname}(${stateFixed.asset})`;
  const tokenNameEither = stateFixed.asset_longname === null ? stateFixed.asset : stateFixed.asset_longname;

  devLog('popupLayer', popupLayer);
  devLog('stateFixed', JSON.stringify(stateFixed));

  let signature;

  const clientTime = Date.now();
  const message = `I want to buy ${stateFixed.amountToBuy} ${tokenName}(s), No.${stateFixed.exhibitNo} of ${stateFixed.addressMainExhibitor}, for ${stateFixed.priceMona} MONA per unit. I want to purchase them as ${stateFixed.addressMain}. I will send MONA from ${stateFixed.addressPayFrom}. I want receive token(s) in ${stateFixed.addressSendCardTo}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;

  // キープールに鍵があるかチェック

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  if (
    addressType === 'addressMain' && keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined ||
    addressType === 'addressPayFrom' && keyPairsInPool[stateFixed.addressPayFrom]?.keyPair !== undefined
  ) {
    let keyPair;

    if (addressType === 'addressMain') {
      addressActual = stateFixed.addressMain;
      keyPair = keyPairsInPool[stateFixed.addressMain].keyPair;
    }
    else { // addressPayFrom
      addressActual = stateFixed.addressPayFrom;
      keyPair = keyPairsInPool[stateFixed.addressPayFrom].keyPair;
    }

    const result = signWithKey(state, dispatch, keyPair, message);

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    }
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    const keyPair = keyPairs[0];
    let result;

    result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      addressActual = result.address;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return 'rejected';
    }

    // キーカードのアドレスが合ってるかチェック

    if (stateFixed.addressCheck === 'strict') {
      if (addressActual !== (addressType === 'addressMain' ? stateFixed.addressMain : stateFixed.addressPayFrom)) {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
        return 'rejected';
      }
    }
    else if (stateFixed.addressCheck === 'addressSecond') {
      if (addressActual !== stateFixed.addressMain && addressActual !== stateFixed.addressPayFrom) {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
        return 'rejected';
      }
    }
    else { // off
    }

    result = signWithKey(state, dispatch, keyPair, message);

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    }
  }
  else if (stateFixed.walletType === 'mpurse') {
    // キープールに鍵が無い。
    // Mpurseのアドレスが合ってるかチェック
    const result = await window.mpurse.getAddress()
    .then( (result) => {
      addressActual = result;
      devLog('address', stateFixed.addressMain, stateFixed.addressPayFrom, addressActual, stateFixed.addressCheck);

      if (stateFixed.addressCheck === 'strict') {
        if (addressActual !== (addressType === 'addressMain' ? stateFixed.addressMain : stateFixed.addressPayFrom)) {
          dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
          return 'rejected';
        }
        else {
          return 'fulfilled';
        }
      }
      else if (stateFixed.addressCheck === 'addressSecond') {
        if (addressActual !== stateFixed.addressMain && addressActual !== stateFixed.addressPayFrom) {
          dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
          return 'rejected';
        }
        else {
          return 'fulfilled';
        }
      }
      else { // off
        return 'fulfilled';
      }
    })
    .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
        return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    // 署名
    try {
      signature= await window.mpurse.signMessage(message).then( result => result );
    }
    catch (err) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return;
    }
  }
  else if (stateFixed.walletType === 'nfc') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
    return {
      status: 'rejected',
      message: 'nfcRequired',
    };
  }
  else { // bc
    dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
    return {
      status: 'rejected',
      message: 'bcRequired',
    };
  }

  // GO！

  if (stateFixed.purchaseNo === null || stateFixed.purchaseNo === '') {
    bodyObj = {
      "body": {
        "method": 'insert',
        "addressType": addressType,
        "addressMain": stateFixed.addressMain,
        "addressMainActual": addressActual,
        "addressPayFrom": stateFixed.addressPayFrom,
        "addressSendCardTo": stateFixed.addressSendCardTo,
        "addressMainExhibitor": stateFixed.addressMainExhibitor,
        "exhibitNo": stateFixed.exhibitNo,
        "amountToBuy": stateFixed.amountToBuy,
        // "asset": stateFixed.asset,
        // "asset_longname": stateFixed.asset_longname,
        // "tokenName": tokenName,
        // "priceMona": stateFixed.priceMona,
      }
    };

    bodyObj.body.clientTimeOfAddressMain = clientTime;
    bodyObj.body.signatureByAddressMain = signature;
    bodyObj.body.signatureVersionOfAddressMain = stateFixed.signatureVersion;

    if (stateFixed.addressMain === stateFixed.addressPayFrom || addressActual === stateFixed.addressPayFrom) {
      bodyObj.body.clientTimeOfAddressPayFrom = clientTime;
      bodyObj.body.signatureByAddressPayFrom = signature;
      bodyObj.body.signatureVersionOfAddressPayFrom = stateFixed.signatureVersion;
    }

    request.body = JSON.stringify(bodyObj);
  }
  else {
    bodyObj = {
      "body": {
        "method": 'update',
        "addressMain": stateFixed.addressMain,
        "addressPayFromActual": addressActual,
        "purchaseNo": stateFixed.purchaseNo,
        "addressType": addressType,
      }
    };

    bodyObj.body.clientTimeOfAddressPayFrom = clientTime;
    bodyObj.body.signatureByAddressPayFrom = signature;
    bodyObj.body.signatureVersionOfAddressPayFrom = stateFixed.signatureVersion;

    request.body = JSON.stringify(bodyObj);
  }

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/purchase';
  request.method = 'POST';

  messages = {
    "you must accept the terms and conditions of the current version.": words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language],
    "internal server error": words.internalServerError[state.language],
    "bad signature": words.badSignature[state.language],
    "bad signature, can't recover address": words.badSignatureCanNotRecoverAddress[state.language],
    "unknown signatureVersion": words.unknownSignatureVersion[state.language],
    "clientTime must be larger than last time.": words.clientTimeMustBeLargerThanLastTime[state.language],
    "this address is not permitted.": words.thisAddressIsNotPermitted[state.language],
    "this address is forbidden.": words.thisAddressIsForbidden[state.language],
    "order expired.": words.orderExpired[state.language],
    "out of service": words.outOfService[state.language],
    "user does not exist.": words.pleaseRegisterAsAUserOnTheSetupPage[state.language],
    "you are not delegated from main address.": words.norIsTheSignatureDelegatedFromMainAddress[state.language],
    "you are not delegated from the address you pay from.": words.norIsTheSignatureDelegatedFromAddressYouPayFrom[state.language],
    "success": words.success[state.language],
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, undefined, undefined, 'notification', messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {
    const purchase = response.body.purchase;
    const sessionId = response.body.sessionId;
    const expirationOfSession = response.body.expirationOfSession;

    // purchase情報更新
    dispatch({ type: 'setState', key: 'purchase', value: purchase });

    // session情報更新
    if (addressType === 'addressMain') {
      dispatch({ type: 'setStateMultiLayers', keys: ['session', stateFixed.addressMain], value: {'sessionId': sessionId.addressMain, 'expirationOfSession': expirationOfSession.addressMain} });

      if (addressActual !== stateFixed.addressMain) {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', addressActual], value: {'sessionId': sessionId.addressMainActual, 'expirationOfSession': expirationOfSession.addressMainActual} });
      }

      if (sessionId.addressPayFrom !== undefined) {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', stateFixed.addressPayFrom], value: {'sessionId': sessionId.addressPayFrom, 'expirationOfSession': expirationOfSession.addressPayFrom} });
      }
    }
    else { // addressPayFrom
      dispatch({ type: 'setStateMultiLayers', keys: ['session', stateFixed.addressPayFrom], value: {'sessionId': sessionId.addressPayFrom, 'expirationOfSession': expirationOfSession.addressPayFrom} });

      if (addressActual !== stateFixed.addressPayFrom) {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', addressActual], value: {'sessionId': sessionId.addressPayFromActual, 'expirationOfSession': expirationOfSession.addressPayFromActual} });
      }
    }

    // オーダーNo.通知欄表示(全署名完了していたら)
    if ( purchase.signatureByAddressMain !== undefined && purchase.signatureByAddressMain !== null && purchase.signatureByAddressMain !== '' &&
         purchase.signatureByAddressPayFrom !== undefined && purchase.signatureByAddressPayFrom !== null && purchase.signatureByAddressPayFrom !== '') {
      dispatch({ type: 'setNotification', key: 'notification', value: words.acceptedAsPurchaseNoX1[state.language] + purchase.purchaseNo + words.acceptedAsPurchaseNoX2[state.language] });
    }

    // cookie情報更新(アドレス情報)
    /*
    const exhibitorName = state.usersGeneralIndex[stateFixed.addressMainExhibitor] !== undefined ? state.usersGeneralIndex[stateFixed.addressMainExhibitor].userName : stateFixed.addressMainExhibitor;
    const responseGetBalances = await getBalances(state, dispatch, stateFixed.addressMain);
    devLog('get balances; result', JSON.stringify(responseGetBalances));

    if (responseGetBalances.status !== 'rejected' && responseGetBalances.body[state.config.clientParameters.cookie.addresses.restrainingToken] === undefined) {
      try {
        let addressHistory = cookies.addresses;

        if (addressHistory === undefined) {
          addressHistory = [];
        }

        addressHistory = addressHistory.filter( item => ![stateFixed.addressMain, stateFixed.addressPayFrom, stateFixed.addressSendCardTo].includes(item.address) );
        addressHistory.unshift(
          {
            action: 'purchase',
            addressType: 'addressMain',
            description: `${exhibitorName} ${tokenNameEither} ${stateFixed.amountToBuy}`,
            // addressMainExhibitor: stateFixed.addressMainExhibitor,
            // tokenName: tokenNameEither,
            // amount: stateFixed.amountToBuy,
             time: clientTime,
            address: stateFixed.addressMain
          },
          {
            action: 'purchase',
            addressType: 'addressPayFrom',
            description: `${exhibitorName} ${tokenNameEither} ${stateFixed.amountToBuy}`,
            // addressMainExhibitor: stateFixed.addressMainExhibitor,
            // tokenName: tokenNameEither,
            // amount: stateFixed.amountToBuy,
             time: clientTime,
            address: stateFixed.addressPayFrom
          },
          {
            action: 'purchase',
            addressType: 'addressSendCardTo',
            description: `${exhibitorName} ${tokenNameEither} ${stateFixed.amountToBuy}`,
            // addressMainExhibitor: stateFixed.addressMainExhibitor,
            // tokenName: tokenNameEither,
            // amount: stateFixed.amountToBuy,
            time: clientTime,
            address: stateFixed.addressSendCardTo
          }
        );
        addressHistory = addressHistory.slice(0, state.config.clientParameters.cookie.addresses.maxSize);

        setCookie('addresses', addressHistory, { maxAge: state.config.clientParameters.cookie.addresses.maxAge, secure: true, sameSite: 'strict' });
      }
      catch (err) {
        devLog('failed to set a cookie.', JSON.stringify(err));
      }
    }
    */

    const exhibitorName = state.usersGeneralIndex[stateFixed.addressMainExhibitor] !== undefined ? state.usersGeneralIndex[stateFixed.addressMainExhibitor].userName : stateFixed.addressMainExhibitor;
    const cookiesNew = [
          {
            action: 'purchase',
            addressType: 'addressMain',
            description: `${exhibitorName} ${tokenNameEither} ${stateFixed.amountToBuy}`,
            time: clientTime,
            address: stateFixed.addressMain
          },
    ];

    if (cookiesNew.every( cookie => cookie.address !== stateFixed.addressPayFrom )) {
      cookiesNew.push(
          {
            action: 'purchase',
            addressType: 'addressPayFrom',
            description: `${exhibitorName} ${tokenNameEither} ${stateFixed.amountToBuy}`,
            time: clientTime,
            address: stateFixed.addressPayFrom
          },
      );
    }

    if (cookiesNew.every( cookie => cookie.address !== stateFixed.addressSendCardTo )) {
      cookiesNew.push(
          {
            action: 'purchase',
            addressType: 'addressSendCardTo',
            description: `${exhibitorName} ${tokenNameEither} ${stateFixed.amountToBuy}`,
            time: clientTime,
            address: stateFixed.addressSendCardTo
          }
      );
    }

    setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
  }
  else {
  }

  return response;
}

// 「購入:デフォルト」ボタン押下
async function handleClickDefaultPurchase(state, dispatch) {
  if (state.purchase.addressMain !== undefined && state.purchase.addressMain !== '') {
    if (state.user[state.purchase.addressMain] !== undefined && state.user[state.purchase.addressMain].basicInformation !== undefined) {
      dispatch({ type: 'setStateMultiLayers', keys: ['purchase', 'addressPayFrom'], value: state.user[state.purchase.addressMain].basicInformation.addressPayFromDefault });
      dispatch({ type: 'setStateMultiLayers', keys: ['purchase', 'addressSendCardTo'], value: state.user[state.purchase.addressMain].basicInformation.addressSendCardToDefault });
    }
    else {
    }
  }
  else if (state.login.addressMain !== undefined && state.login.addressMain !== '') {
    if (state.user[state.login.addressMain] !== undefined) {
      dispatch({ type: 'setStateMultiLayers', keys: ['purchase', 'addressMain'], value: state.login.addressMain });

      if (state.user[state.login.addressMain].basicInformation !== undefined) {
        dispatch({ type: 'setStateMultiLayers', keys: ['purchase', 'addressPayFrom'], value: state.user[state.login.addressMain].basicInformation.addressPayFromDefault });
        dispatch({ type: 'setStateMultiLayers', keys: ['purchase', 'addressSendCardTo'], value: state.user[state.login.addressMain].basicInformation.addressSendCardToDefault });
      }
    }
    else {
    }
  }
  else {
  }

  return;
}

// 「購入:クリア」ボタン押下 HANDLE CLICK CLEAR PURCHASE
async function handleClickClearPurchase(state, dispatch) {
  dispatch({ type: 'setState', key: 'purchase',
    value: {
      purchaseNo: null,
      addressMain: '',
      addressPayFrom: '',
      addressSendCardTo: '',
      addressMainExhibitor: '',
      exhibitNo: '',
      amountToBuy: 1,
      signatureByAddressMain: '',
      signatureByAddressPayFrom: '',
      addressPayRoyaltyTo: null,
      disabled: false,
      status: 'waitingForSignature',
    }
  });

  return;
}

// 「購入:MONA送信」ボタン押下 HANDLE CLICK SEND MONA
async function handleClickSendMona(state, dispatch, popupLayer, keyPairs) {
  let request = {};
  let response = {};
  let stateFixed = {};
  let messages = {};
  let utxos;
  let utxosSelected;
  let approximatedTransactionBytes;
  let feeRate;
  let transactionFee;
  let change;
  let amountToPay;
  let inputs;
  let outputs;
  let unsignedTransaction;
  let txid;

  // state固定
  if (popupLayer !== undefined && popupLayer !== null) {
    stateFixed = {
      addressPayFrom: state.purchase.addressPayFrom,
      addressPayProceedsTo: state.popup[popupLayer].body.addressPayProceedsTo,
      addressPayRoyaltyTo: state.purchase.addressPayRoyaltyTo,
      monacottoAddressMona: state.config.monacottoAddress.monacottoAddressMona,
      amountToBuy: state.purchase.amountToBuy,
      priceMona: state.popup[popupLayer].body.priceMona,
      priceMonaWatanabe: state.popup[popupLayer].body.priceMonaWatanabe,
      proceedsNetMona: state.popup[popupLayer].body.proceedsNetMona,
      royaltyMona: state.popup[popupLayer].body.royaltyMona,
      feeMona: state.popup[popupLayer].body.feeMona,
      provisionalTransactionFee: state.config.clientParameters.provisionalTransactionFee,
      transactionFeeUpperBound: state.config.clientParameters.transactionFeeUpperBound,
      addressMain: state.purchase.addressMain,
      purchaseNo: state.purchase.purchaseNo,
      signatureByAddressMain: state.purchase.signatureByAddressMain,
      walletType: state.walletType,
    };
  }
  else {
    stateFixed = {
      addressPayFrom: state.purchase.addressPayFrom,
      addressPayProceedsTo: state.itemDetail.item.addressPayProceedsTo,
      addressPayRoyaltyTo: state.purchase.addressPayRoyaltyTo,
      monacottoAddressMona: state.config.monacottoAddress.monacottoAddressMona,
      amountToBuy: state.purchase.amountToBuy,
      priceMona: state.itemDetail.item.priceMona,
      priceMonaWatanabe: state.itemDetail.item.priceMonaWatanabe,
      proceedsNetMona: state.itemDetail.item.proceedsNetMona,
      royaltyMona: state.itemDetail.item.royaltyMona,
      feeMona: state.itemDetail.item.feeMona,
      provisionalTransactionFee: state.config.clientParameters.provisionalTransactionFee,
      transactionFeeUpperBound: state.config.clientParameters.transactionFeeUpperBound,
      addressMain: state.purchase.addressMain,
      purchaseNo: state.purchase.purchaseNo,
      signatureByAddressMain: state.purchase.signatureByAddressMain,
      walletType: state.walletType,
    };
  }

  const buildConfirmAndBroadcast = async (keyPairsInArg) => {
    let actionItemsMultiSources;

    // まず最初にカートを空にする！

    actionItemsMultiSources = deleteCart(state, dispatch, 'cartMona');

    const amountProceedsWatanabe = decimalAdjust( 'floor', new decimal(stateFixed.proceedsNetMona).times(stateFixed.amountToBuy).toNumber(), 0 ); 

    actionItemsMultiSources = addSendsToTheCartMona(
      state, dispatch, stateFixed.addressPayFrom, stateFixed.addressPayProceedsTo, amountProceedsWatanabe, actionItemsMultiSources
    );

    if (stateFixed.royaltyMona > 0) {
      const amountRoyaltyWatanabe = decimalAdjust( 'floor', new decimal(stateFixed.royaltyMona).times(stateFixed.amountToBuy).toNumber(), 0 ); 

      actionItemsMultiSources = addSendsToTheCartMona(
        state, dispatch, stateFixed.addressPayFrom, stateFixed.addressPayRoyaltyTo, amountRoyaltyWatanabe, actionItemsMultiSources
      );
    }

    const amountServiceFeeWatanabe = decimalAdjust( 'floor', new decimal(stateFixed.feeMona).times(stateFixed.amountToBuy).toNumber(), 0 ); 

    actionItemsMultiSources = addSendsToTheCartMona(
      state, dispatch, stateFixed.addressPayFrom, stateFixed.monacottoAddressMona, amountServiceFeeWatanabe, actionItemsMultiSources
    );

    for (const addressFrom of Object.keys(actionItemsMultiSources)) {
      for (const actionItem of actionItemsMultiSources[addressFrom]) {
        actionItem.status = 'unprocessed';
      }
    }

    dispatch({ type: 'setState', key: 'accessing', value: true });

    await buildMonaTransactions(
      state, dispatch, keyPairsInArg, actionItemsMultiSources,
      {
        provisionalTransactionFee: new decimal(stateFixed.provisionalTransactionFee).times(100000000).toNumber(),
        transactionFeeUpperBound: stateFixed.transactionFeeUpperBound,
      }
    );

    dispatch({ type: 'setState', key: 'accessing', value: false });

    if (actionItemsMultiSources[stateFixed.addressPayFrom][0].status === 'succeeded') {
    }
    else { // failed
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSend[state.language] });
      return { status: 'rejected' };
    }

    confirmCommitMonaTransaction(state, dispatch, popupLayer, actionItemsMultiSources, stateFixed);
  };


  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  if (keyPairsInPool[stateFixed.addressPayFrom]?.keyPair !== undefined) {
    buildConfirmAndBroadcast(keyPairsInPool);
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    const keyPair = keyPairs[0];
    let addressActual;
    let result;

    result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      addressActual = result.address;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return 'rejected';
    }

    // キーカードのアドレスが合ってるかチェック

    if (addressActual !== stateFixed.addressPayFrom) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
      return 'rejected';
    }

    buildConfirmAndBroadcast({ [addressActual]: { keyPair: keyPair } });
  }
  else if (stateFixed.walletType === 'mpurse') {
    // Mpurseが接続されているかチェック
    if (window.mpurse === undefined) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.activateMpurse[state.language] });
      return { status: 'rejected' };
    }

    // Mpurseのアドレスが合ってるかチェック
    const result = await window.mpurse.getAddress()
    .then( result => {
      if (result !== stateFixed.addressPayFrom) {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
        return 'rejected';
      }
      else {
        return 'fulfilled';
      }
    })
    .catch( error => {
      dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
      return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    buildConfirmAndBroadcast();
  }
  else if (stateFixed.walletType === 'nfc') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] + 'NFC' });
    return {
      status: 'rejected',
      message: 'nfcRequired',
    };
  }
  else { // bc
    dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
    return {
      status: 'rejected',
      message: 'bcRequired',
    };
  }
}

// 「購入:検索」ボタン押下 HANDLE CLICK GET PURCHASE HISTORY
async function handleClickGetPurchaseHistory(state, dispatch, cookies, setCookie, pagingIndex, activeCertification, consistentRead, keyPairs) {
  let addressActual;
  let request = {};
  let messages = {};
  let certifications = [];
  const now = Date.now();

  if (activeCertification === undefined || activeCertification === null) {
    activeCertification = {
      address: null,
    };
  }

  if (consistentRead === undefined || consistentRead === null) {
    consistentRead = false;
  }

  // state固定
  const stateFixed = {
    addressMain: state.getHistory.addressMain,
    lastEvaluatedPurchaseNo: pagingIndex >= 1 ? state.getHistory.lastEvaluatedKey.purchase[pagingIndex - 1].purchaseNo : null,
    signatureVersion: state.config.clientParameters.signatureVersion.getPurchaseHistory,
    addressCheck: state.addressCheck,
    walletType: state.walletType,
  };

  // 有効なセッションに絞り込む。

  for (const address of Object.keys(state.session)) {

    // activeCertificationの場合は別処理
    if (address !== activeCertification.address) {

      // state固定
      stateFixed[address] = {
        // addressCoinType: state.session[address].addressCoinType,
        sessionId: state.session[address].sessionId,
        expirationOfSession: state.session[address].expirationOfSession,
      };

      if (stateFixed[address].expirationOfSession >= now + marginOfSessionTime) {
        certifications.push({
          // addressCoinType: stateFixed[address].addressCoinType,
          address: address,
          sessionId: stateFixed[address].sessionId,
          lastEvaluatedPurchaseNo: address === stateFixed.addressMain ? stateFixed.lastEvaluatedPurchaseNo : null,
          consistentRead: address === stateFixed.addressMain ? consistentRead : false,
        });
      }
    }
  }

  // activeCertificationの処理

  if (activeCertification.address !== null) {
    // -- state固定
    stateFixed[activeCertification.address] = {
      sessionId: activeCertification.sessionId,
      expirationOfSession: activeCertification.expirationOfSession,
    };

    // -- 有効だったら(まあほぼ有効なんだろうけど)プッシュ
    if (stateFixed[activeCertification.address].expirationOfSession >= now + marginOfSessionTime) {
      certifications.push({
        address: activeCertification.address,
        sessionId: stateFixed[activeCertification.address].sessionId,
        lastEvaluatedExhibitNo: null, // activeCertificationは常に先頭を検索する。
        consistentRead: true, // activeCertificationは常に強力な整合性のある読み込みをする。
      });
    }
  }

  // アドレスが指定されており、そのアドレスのセッションが存在しない、または、期限が切れていれば、署名する。(キープール or Mpurse)
  // Mpurseの場合、アドレスが指定されていない場合は、アクティブなアドレスで代替するかもしれない。(アドレスチェックモードに依る。)
  // keyPairsが渡された場合(2周目)、keyPairsの先頭の鍵で試みる。アドレスが指定されていない場合は、先頭のアドレスで代替するかもしれない。(アドレスチェックモードに依る。)

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  const extendedCertificationProperties = [
    { key: 'consistentRead', value: consistentRead },
    { key: 'lastEvaluatedPurchaseNo', value: stateFixed.lastEvaluatedPurchaseNo },
  ];

  if (keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined) {
    certificationWithKey(state, dispatch, [ keyPairsInPool[stateFixed.addressMain].keyPair ], 'information', stateFixed, certifications, now, extendedCertificationProperties, 'matchedKey');
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    certificationWithKey(state, dispatch, keyPairs, 'information', stateFixed, certifications, now, extendedCertificationProperties, 'firstKey');
  }
  else if (stateFixed.walletType === 'mpurse') {
    await setSignatureByAddressMain(state, dispatch, stateFixed, certifications, now, extendedCertificationProperties, 'information');
  }

  // 対象がなければ戻る。
  // NFC, BCの場合、キーカードを要求して2周目。

  if (
    certifications.length === 0 ||
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    !certifications.map( certification => certification.address ).includes(stateFixed.addressMain)
  ) {
    if (keyPairs === undefined || keyPairs === null) {
      if (stateFixed.walletType === 'nfc') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
        return {
          status: 'rejected',
          message: 'nfcRequired',
        };
      }
      else if (stateFixed.walletType === 'qr') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
        return {
          status: 'rejected',
          message: 'bcRequired',
        };
      }
    }

    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }

  request.body = JSON.stringify({
    "body": {
      "certifications": certifications,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/get_purchase_history';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, 'purchaseHistory', undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    for (const address of Object.keys(response.body)) {
      // 署名した場合もろもろ更新
      if (response.body[address]['certificatedBy'] === 'signature') {

        // session更新
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address],
          value: {'sessionId': response.body[address]['sessionId'], 'expirationOfSession': response.body[address]['expirationOfSession']} });
      }
      else if (response.body[address]['certificatedBy'] === 'none') {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address], value: { expirationOfSession: 0 } });
      }
    }

    // addressMainが検索対象で、検索成功した場合
    if (response.body[stateFixed.addressMain] !== undefined &&
         (response.body[stateFixed.addressMain].certificatedBy === 'signature' || response.body[stateFixed.addressMain].certificatedBy === 'sessionId')) {
      if (response.body[stateFixed.addressMain].lastEvaluatedKey !== undefined) {
        dispatch({ type: 'setStateMultiLayers', keys: ['getHistory', 'lastEvaluatedKey', 'purchase', pagingIndex], value: response.body[stateFixed.addressMain].lastEvaluatedKey });
      }
      else {
        dispatch({ type: 'setStateMultiLayers', keys: ['getHistory', 'lastEvaluatedKey', 'purchase', pagingIndex], value: null });
      }

      // pagingIndexが指定されているということは、前回の検索時からaddressMainが変更されていないということ。(変更するとリセットされてしまう。)
      // addressMainが指定されていないということは、後ページ検索ではない。
      // なぜならば、addressMainが指定されていない状態にするには、前回の検索のあと、addressMainを削除しなければならないから。

      dispatch({ type: 'setStateMultiLayers', keys: ['getHistory', 'pagingIndex', 'purchase'], value: pagingIndex });

      // addressMainが指定されていなかった場合は、dispatchする。
      if (state.getHistory.addressMain === undefined || state.getHistory.addressMain === null || state.getHistory.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['getHistory', 'addressMain'], value: stateFixed.addressMain });
      }

      // cookie情報更新(アドレス情報)
      const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
      const cookiesNew = [
            {
              action: 'searchPurchaseHistory',
              addressType: 'addressMain',
              description: `${userName}`,
              time: now,
              address: stateFixed.addressMain
            },
      ];

      setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
    }

    return { status: 'fulfilled', body: response.body };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }
}

// HANDLE CLICK REPLACE ITEM
async function handleClickReplaceItem(state, dispatch) {
  let request = {};
  let messages = {};
  let certification = {};
  const now = Date.now();

  // state固定
  const stateFixed = {
    addressMain: state.gallery.addressMain,
    roomNo: 1,
    items: state.items[state.gallery.addressMain].itemPlacement[0].items,
    updateTime: state.items[state.gallery.addressMain].itemPlacement[0].updateTime,
    [state.gallery.addressMain]: {
      // addressCoinType: state.session[address].addressCoinType,
      sessionId: state.session[state.gallery.addressMain].sessionId, // ボタン押せたってことは定義されてるってことなんだけど。
      expirationOfSession: state.session[state.gallery.addressMain].expirationOfSession,
    },
    signatureVersion: state.config.clientParameters.signatureVersion.replaceItem,
    addressCheck: state.addressCheck,
  };

  // メインアドレスのセッションが存在しない、または、期限が切れていれば、署名する。

  if ( stateFixed[stateFixed.addressMain] === undefined || stateFixed[stateFixed.addressMain].expirationOfSession < now + marginOfSessionTime ) {

    // -- Mpurseのアドレス取得
    const result = await window.mpurse.getAddress()
    .then( async (result) => {
      const addressActual = result;
      const clientTimeMona = now;

      if (stateFixed.addressCheck === 'strict' || stateFixed.addressCheck === 'addressSecond') {
        if (addressActual !== stateFixed.addressMain) {
          dispatch({ type: 'setNotification', key: 'notification', value: words.mainAddressAndTheAddressInWalletAreDifferent[state.language] });
          return 'rejected';
        }
        else {
          // 続行
        }
      }
      else { // off
        // 続行
      }

      const message = `I want to get the information of ${stateFixed.addressMain}. (epoch time: ${clientTimeMona}, signature version: ${stateFixed.signatureVersion})`;
      devLog('message', message);

      let signatureMona;

      try {
        signatureMona = await window.mpurse.signMessage(message).then( result => result );

        certification = {
          // addressCoinType: 'mona',
          address: stateFixed.addressMain,
          addressActual: addressActual,
          clientTime: clientTimeMona,
          signatureVersion: stateFixed.signatureVersion,
          signature: signatureMona,
        };
      }
      catch (err) {
        dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
        return 'rejected';
      }
    })
    .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
        return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }
  }
  else if ( stateFixed[stateFixed.addressMain] === undefined || stateFixed[stateFixed.addressMain].expirationOfSession >= now + marginOfSessionTime ) { // セッションが有効
    certification = {
      // addressCoinType: stateFixed[address].addressCoinType,
      address: stateFixed.addressMain,
      sessionId: stateFixed[stateFixed.addressMain].sessionId,
    };
  }

  // GO！

  request.body = JSON.stringify({
    body: {
      certification: certification,
      roomNo: stateFixed.roomNo,
      items: stateFixed.items,
      updateTime: stateFixed.updateTime,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/replace_item';
  request.method = 'POST';

  messages = {
    "you must accept the terms and conditions of the current version.": words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language],
    "internal server error": words.internalServerError[state.language],
    "bad signature": words.badSignature[state.language],
    "bad signature, can't recover address": words.badSignatureCanNotRecoverAddress[state.language],
    "unknown signatureVersion": words.unknownSignatureVersion[state.language],
    "clientTime must be larger than last time.": words.clientTimeMustBeLargerThanLastTime[state.language],
    "this address is not permitted.": words.thisAddressIsNotPermitted[state.language],
    "this address is forbidden.": words.thisAddressIsForbidden[state.language],
    "order expired.": words.orderExpired[state.language],
    "out of service": words.outOfService[state.language],
    "success": words.success[state.language],
  };

  const response = await syncHttpRequest2(request, undefined, undefined, 'notification', messages, dispatch);

  for (const address of Object.keys(response.body)) {
    // 署名した場合もろもろ更新
    if (response.body[address]['certificatedBy'] === 'signature') {

      // session更新
      dispatch({ type: 'setStateMultiLayers', keys: ['session', address],
        value: {'sessionId': response.body[address]['sessionId'], 'expirationOfSession': response.body[address]['expirationOfSession']} });
    }
    else if (response.body[address]['certificatedBy'] === 'none') {
      dispatch({ type: 'setStateMultiLayers', keys: ['session', address], value: { expirationOfSession: 0 } });
    }
  }

  if ( response.status === 'fulfilled' ) {
    // itemPlacement情報更新
    dispatch({ type: 'setStateMultiLayersNum', keys: ['items', stateFixed.addressMain, 'itemPlacement', 0, 'updateTime'],
               value: response.body[stateFixed.addressMain].itemPlacement.updateTime });

    // オーダーNo.通知欄表示
    // dispatch({ type: 'setNotification', key: 'notification', value: words.orderAcceptedOrderNo[state.language] + response.body.orderNo + words.orderAcceptedOrderNo2[state.language] });
  }
  else {
    devLog('error', JSON.stringify(response));
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToUpdate[state.language] });
    return { status: 'rejected' };
  }

  /*
  // session情報更新
  if (response.body !== undefined) {
    if (response.body[stateFixed.addressMain]['certificatedBy'] === 'signature') {
      dispatch({ type: 'setStateMultiLayers', keys: ['session', stateFixed.addressMain], value: {
        'sessionId': response.body[stateFixed.addressMain].sessionId,
        'expirationOfSession': response.body[stateFixed.addressMain].expirationOfSession,
      } });
    }
    else if (response.body[stateFixed.addressMain]['certificatedBy'] === 'none') {
      dispatch({ type: 'setStateMultiLayers', keys: ['session', stateFixed.addressMain], value: { expirationOfSession: 0 } });
    }
  }
  */

  return response;
}


async function getConfig(dispatch, keys) {
  let request = {};
  let messages = {};

  request.body = JSON.stringify({
    "body": {
      "keys": keys,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/get_client_parameter';
  request.method = 'POST';

  messages = {
  };

  const response = await syncHttpRequest2(request, 'config', undefined, undefined, messages, dispatch);

  if ( response.status === 'fulfilled' ) {
    return response;
  }
  else {
  }
}

// GET ITEM
async function getItem(state, dispatch, addresses) {
  let request = {};
  let messages = {};

  /*
  // state固定
  const stateFixed = {
    addressMain: state.getItem.addressMain,
  };
  */

  request.body = JSON.stringify({
    "body": {
      "addressesMain": addresses,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/get_item';
  request.method = 'POST';

  messages = {
  };

  const response = await syncHttpRequest2(request, 'items', undefined, undefined, messages, dispatch);

  if ( response.status === 'fulfilled' ) {
    return {
      status: 'fulfilled',
      body: response.body,
    };
  }
  else {
    return {
      status: 'rejected',
    };
  }
}

// GET ITEM NEW
async function getItemNew(state, dispatch, searchType, key) {
  let request = {};
  let messages = {};

  /*
  // state固定
  const stateFixed = {
    addressMain: state.getItem.addressMain,
  };
  */

  request.body = JSON.stringify({
    body: {
      searchType: searchType,
      // searchType: 'new',
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/get_item';
  request.method = 'POST';

  messages = {
  };

  const response = await syncHttpRequest2(request, key, undefined, undefined, messages, dispatch);
  // const response = await syncHttpRequest2(request, 'itemsNew', undefined, undefined, messages, dispatch);

  if ( response.status === 'fulfilled' ) {
    return {
      status: 'fulfilled',
      body: response.body,
    };
  }
  else {
    return {
      status: 'rejected',
    };
  }
}

// GET ASSET INFO BY ITEM
async function getAssetInfoByItems(state, dispatch, items) {

  const assets = Object.keys(items.body).reduce( (acc, cur) => {
    devLog('cur', cur);
    devLog('item', JSON.stringify(items.body[cur].item));
    const assets = items.body[cur].item.map( item => item.asset );
    return acc.concat(assets);
  }, []);

  return await getAssetInfo(state, dispatch, assets);
}

// GET ASSET INFO
async function getAssetInfo(state, dispatch, assets, indexAsset_longname) {
  let assetInfosArray;
  const assetInfos = state.assetInfo;
  const request = {};
  const bodyObj = {
    jsonrpc: "2.0",
    id: 0,
    method: "get_assets_info",
    params: {
      assetsList: assets,
    }
  };

  request.url = 'https://monapa.electrum-mona.org/_api';
  // request.url = 'https://wallet.monaparty.me/_api';
  // request.url = 'https://counterblock.api.monaparty.me/';
  request.method = 'POST';
  // request.body = `{"jsonrpc":"2.0","id":0,"method":"get_assets_info","params":{"assetsList":["${tokenName}"]}}`;
  request.body = JSON.stringify(bodyObj);

  devLog('get asset info; request', JSON.stringify(request));

  const response = await syncHttpRequest(request);
  devLog('get asset info; response', JSON.stringify(response));

  if (response.status === 'rejected') {
    return { status: 'rejected' };
  }
  else if (response.body.error !== undefined) {
    // -- 失敗　エラー返却
    return { status: 'rejected', error: response.body.error };
  }
  else if (response.body.result.length === 0) {
    // -- そんなトークンは存在しない
    assetInfosArray = response.body.result;
  }
  else {
    // -- 成功
    assetInfosArray = response.body.result;
  }

  // state格納
  for (const assetInfo of assetInfosArray) {
    assetInfos[assetInfo.asset] = assetInfo;

    if (indexAsset_longname) {
      assetInfos[assetInfo.asset_longname] = assetInfo;
    }
  }

  dispatch({ type: 'setState', key: 'assetInfo', value: assetInfos });

  if (assetInfosArray.length === 0) {
    return { status: 'notFound', body: {} };
  }
  else {
    return { status: 'fulfilled', body: assetInfos };
  }
}

// GET MONACARD
async function getMonacard(state, dispatch, assets) {
//  const tokenNamesCSV = tokenNames.reduce( (acc, cur) => `${acc},${cur}`, '' );

  const assetCsvsDivided = [];
  let csv = '';

  for (const asset of assets) {
    if ( (new Blob([csv])).size > 1700 ) {
      assetCsvsDivided.push(csv);
      csv = '';
    }

    csv += ',' + asset;
  }

  assetCsvsDivided.push(csv);

  let request = {};
  let response;
  let monacardsArray = [];
  let monacards = state.monacard;

  request.method = 'GET';
  request.body = undefined;

  for (const assetCSV of assetCsvsDivided) {
    request.url = `https://card.mona.jp/api/card_detail?assets=${assetCSV}`
    devLog('get monacard; request', JSON.stringify(request));

    response = await syncHttpRequest(request);
    devLog('get monacard; response', JSON.stringify(response));

    if (response.status === 'rejected') {
      return { status: 'rejected' };
    }
    else if (response.body.error !== undefined) {
      if (response.body.error.message === 'No matched assets.') {
//        return { status: 'notFound', body: [] };
      }
      else {
        return { status: 'rejected', error: response.body.error.message };
      }
    }
    else if (response.body.details === undefined) {
      return { status: 'rejected', error: 'details undefined' };
    }
    else {
      // -- get成功！
//      return { status: 'fulfilled', body: response.body.details };
      monacardsArray = monacardsArray.concat(response.body.details);
    }
  }

  // state格納
  for (const monacard of monacardsArray) {
    monacards[monacard.asset] = monacard;
  }

  dispatch({ type: 'setState', key: 'monacard', value: monacards });

  if (monacardsArray.length === 0) {
    return { status: 'notFound', body: [] };
  }
  else {
    return { status: 'fulfilled', body: monacards };
  }
}

// GET BALANCES
async function getBalances(state, dispatch, address) {
  let balancesArray =[];
  const balances = {};
  const request = {};
  const defaultLimit = state.config.clientParameters.counterparty.getTableDefaultLimit;
  let offset = 0;

  while (true) {
    let balancesArrayPartial;
    const bodyObj = {
      jsonrpc: "2.0",
      id: 0,
      method: "proxy_to_counterpartyd",
      params: {
        method: "get_balances",
        params: {
          filters: [
            {field: "address", op: "==", value: address}
          ],
          offset: offset,
          limit: defaultLimit,
        },
      }
    };

    request.url = 'https://monapa.electrum-mona.org/_api';
    // request.url = 'https://wallet.monaparty.me/_api';
    // request.url = 'https://counterblock.api.monaparty.me/';
    request.method = 'POST';
    request.body = JSON.stringify(bodyObj);

    devLog('get balances; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('get balances; response', JSON.stringify(response));

    if (response.status === 'rejected') {
      return { status: 'rejected' };
    }
    else if (response.body.error !== undefined) {
      // -- 失敗　エラー返却
      return { status: 'rejected', error: response.body.error };
    }
    else if (response.body.result.length === 0) {
      // -- そんなトークンは存在しない
      balancesArrayPartial = response.body.result;
    }
    else {
      // -- 成功
      balancesArrayPartial = response.body.result;
    }

    balancesArray = balancesArray.concat(balancesArrayPartial);

    if (balancesArrayPartial.length < defaultLimit) {
      break;
    }
    else {
      offset += defaultLimit;
    }
  }

  // state格納
  for (const balance of balancesArray) {
    balances[balance.asset] = balance;
  }

  dispatch({ type: 'setState', key: 'balance', value: balances });

  if (balancesArray.length === 0) {
    return { status: 'notFound', body: {} };
  }
  else {
    return { status: 'fulfilled', body: balances };
  }
}

// GET BALANCES KOM
async function getBalancesKOM(state, dispatch, address) {
  let balancesArray =[];
  const balances = {};
  const request = {};
  // const defaultLimit = state.config.clientParameters.counterparty.getTableDefaultLimit;
  const defaultLimit = 500;
  let offset = 0;

  while (true) {
    let balancesArrayPartial;
    const bodyObj = {
      jsonrpc: "2.0",
      id: 0,
      method: "proxy_to_counterpartyd",
      params: {
        method: "get_balances",
        params: {
          filters: [
            {field: "address", op: "==", value: address}
          ],
          offset: offset,
          limit: defaultLimit,
        },
      }
    };

    request.url = 'https://monapa.electrum-mona.org/_api';
    // request.url = 'https://wallet.monaparty.me/_api';
    // request.url = 'https://counterblock.api.monaparty.me/';
    request.method = 'POST';
    request.body = JSON.stringify(bodyObj);

    devLog('get balances; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('get balances; response', JSON.stringify(response));

    if (response.status === 'rejected') {
      return { status: 'rejected' };
    }
    else if (response.body.error !== undefined) {
      // -- 失敗　エラー返却
      return { status: 'rejected', error: response.body.error };
    }
    else if (response.body.result.length === 0) {
      // -- そんなトークンは存在しない
      balancesArrayPartial = response.body.result;
    }
    else {
      // -- 成功
      balancesArrayPartial = response.body.result;
    }

    balancesArray = balancesArray.concat(balancesArrayPartial);

    if (balancesArrayPartial.length < defaultLimit) {
      break;
    }
    else {
      offset += defaultLimit;
    }
  }

  // state格納
  for (const balance of balancesArray) {
    balances[balance.asset] = balance;
  }

  dispatch({ type: 'setStateMultiLayers', keys: ['balanceKOM', address], value: balances });

  if (balancesArray.length === 0) {
    return { status: 'notFound', body: {} };
  }
  else {
    return { status: 'fulfilled', body: balances };
  }
}

// GET REGISTERED CARD
async function getRegisteredCard(state, dispatch) {
  let request = {};
  let messages = {};
  const registeredCardsIndex = {};

  // state固定
  const stateFixed = {
  };

  request.body = JSON.stringify({
    "body": {
    }
  });

  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/get_registered_card';
  request.method = 'POST';

  messages = {
  };

  const response = await syncHttpRequest2(request, undefined, undefined, undefined, messages, dispatch);

  if ( response.status === 'rejected' ) {
    return {
      status: 'rejected',
    };
  }

  for (const registeredCard of response.body) {
    registeredCardsIndex[registeredCard.asset] = registeredCard;
  }

  dispatch({ type: 'setState', key: 'registeredCard', value: registeredCardsIndex });
  devLog('registeredCard', JSON.stringify(registeredCardsIndex));

  return {
    status: 'fulfilled',
    body: registeredCardsIndex,
  };
}

// SET ADDRESSES IN COOKIE
async function setAddressesInCookie(state, dispatch, cookies, setCookie, addressMain, cookiesNew) {
  let response;
  const responseGetBalances = await getBalances(state, dispatch, addressMain);
  devLog('get balances; result', JSON.stringify(responseGetBalances));

  if (responseGetBalances.status !== 'rejected' &&
      (responseGetBalances.body[state.config.clientParameters.cookie.addresses.restrainingToken] === undefined ||
       responseGetBalances.body[state.config.clientParameters.cookie.addresses.restrainingToken].quantity === 0)) {
    try {
      let addressHistory = cookies.addresses;

      if (addressHistory === undefined) {
        addressHistory = [];
      }

      addressHistory = addressHistory.filter( item => !cookiesNew.map( cookie => cookie.address ).includes(item.address) );
      addressHistory.unshift(...cookiesNew);
      addressHistory = addressHistory.slice(0, state.config.clientParameters.cookie.addresses.maxSize);

      setCookie('addresses', addressHistory, { maxAge: state.config.clientParameters.cookie.addresses.maxAge, secure: true, sameSite: 'strict' });

      response = { status: 'fulfilled', body: addressHistory };
    }
    catch (err) {
      devLog('failed to set a cookie.', JSON.stringify(err));
      response = { status: 'rejected', body: err };
    }
  }
  else {
    response = { status: 'rejected', body: 'restrainingToken' };
  }

  return response;
}

// GET USER
async function getUser(state, dispatch, getType) {
  let request = {};
  let messages = {};

  // state固定
  const stateFixed = {
    addressMain: state.getUser.addressMain,
  };

  request.body = JSON.stringify({
    "body": {
      "getType": getType,
      "addressesMain": getType === 'all' ? null : [stateFixed.addressMain],
    }
  });

  request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/get_user';
  request.method = 'POST';

  messages = {
  };

  const response = await syncHttpRequest2(request, 'usersGeneral', undefined, undefined, messages, dispatch);

  if ( response.status === 'fulfilled' ) {
    return {
      status: 'fulfilled',
      body: response.body,
    };
  }
  else {
    return {
      status: 'rejected',
    };
  }
}

// 「クリエイター情報編集」ボタン押下 HANDLE CLICK EDIT CREATOR INFORMATION
async function handleClickEditCreatorInformation(state, dispatch, asset, { keyPairs } = {}) {
  let addressMainActual;
  let signature;
  let request = {};
  let messages = {};
  let result;
  let keyPair;

  // state固定
  const stateFixed = {
    // addressMain: state.cardRegistration.addressMain,
    creater: state.creatorInformation.creater,
    createrText: state.creatorInformation.createrText,
    monacottoAddressMain: state.creatorInformation.monacottoAddressMain,
    createrLink: state.creatorInformation.createrLink,
    // imageMain: state.cardRegistration.imagesNew.main,
    signatureVersion: state.config.clientParameters.signatureVersion.editCreatorInformation,
    // addressCheck: state.addressCheck,
  };

  // 必須項目チェック
  if (asset === undefined || asset === null || asset === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.assetMustBeFilled[state.language] });
    return;
  }

  // // 文字数チェック
  // if (stateFixed.name.length > 25) {
  //   dispatch({ type: 'setNotification', key: 'notification', value: words.nameIsTooLong[state.language] });
  //   return;
  // }

  // if (stateFixed.feature.length > 70) {
  //   dispatch({ type: 'setNotification', key: 'notification', value: words.featureIsTooLong[state.language] });
  //   return;
  // }

  // アドレスは入力させていないので、ノーチェックで署名する。
  const clientTime = Date.now();

  if (keyPairs !== undefined && keyPairs !== null) {
    let result;

    keyPair = keyPairs[0];
    result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      addressMainActual = result.address;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return result;
    }

    // 署名
    const message = `I edit the creator information of ${asset}. My main address is ${addressMainActual}. I am the owner of the card. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
    devLog('message', message);

    result = signWithKey(state, dispatch, keyPair, message);

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    }
  }
  else {
    result = await window.mpurse.getAddress()
    .then( (result) => {
      addressMainActual = result;

      // if (stateFixed.addressCheck === 'strict' || stateFixed.addressCheck === 'addressSecond') {
      //   if (addressMainActual !== stateFixed.addressMain) {
      //     dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
      //     return 'rejected';
      //   }
      //   else {
      //     return 'fulfilled';
      //   }
      // }
      // else { // off
      //   return 'fulfilled';
      // }

      return 'fulfilled';
    })
    .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
        return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    // 署名
    const message = `I edit the creator information of ${asset}. My main address is ${addressMainActual}. I am the owner of the card. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
    devLog('message', message);

    result = await window.mpurse.signMessage(message)
    .then( result => {
      signature = result;
      return 'fulfilled';
    })
    .catch( err => {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }
  }

  const formData = new FormData();

  const requestJson = JSON.stringify({
    body: {
      addressMain: addressMainActual,
      addressMainActual: addressMainActual,
      asset: asset,
      creater: stateFixed.creater,
      createrText: stateFixed.createrText,
      monacottoAddressMain: stateFixed.monacottoAddressMain,
      createrLink: stateFixed.createrLink,
      clientTime : clientTime,
      signature : signature,
      signatureVersion : stateFixed.signatureVersion,
    }
  });

  formData.append('json', requestJson);

  // if (stateFixed.imageMain !== undefined && stateFixed.imageMain !== null) {
  //   formData.append('imageMain', stateFixed.imageMain, stateFixed.imageMain.name);
  // }

  request.body = formData;
  request.headers = {};
  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/edit_creator_information';
  request.method = 'POST';

  messages = {
    "you must accept the terms and conditions of the current version.": words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language],
    "internal server error": words.internalServerError[state.language],
    "bad signature": words.badSignature[state.language],
    "bad signature, can't recover address": words.badSignatureCanNotRecoverAddress[state.language],
    "unknown signatureVersion": words.unknownSignatureVersion[state.language],
    "clientTime must be larger than last time.": words.clientTimeMustBeLargerThanLastTime[state.language],
    "this address is not permitted.": words.thisAddressIsNotPermitted[state.language],
    "this address is forbidden.": words.thisAddressIsForbidden[state.language],
    "out of service": words.outOfService[state.language],
    "signature by asset owner is required.": words.signatureByAssetOwnerIsRequired[state.language],
    // "asset is required; not asset_longname.": words.assetIsRequiredNotAssetLongname[state.language],
    // "you are not delegated.": words.norIsTheSignatureDelegatedFromMainAddress[state.language],
    // "addressMainActual must be filled.": words.clearYourBrowserOrAppCache[state.language],
    "success": words.success[state.language],
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, undefined, undefined, 'notification', messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {
    // session情報更新
    dispatch({ type: 'setStateMultiLayers', keys: ['session', addressMainActual],
      value: {'sessionId': response.body.sessionId.addressMain, 'expirationOfSession': response.body.expirationOfSession.addressMain} });

    // if (addressMainActual !== stateFixed.addressMain) {
    //   dispatch({ type: 'setStateMultiLayers', keys: ['session', addressMainActual],
    //     value: {'sessionId': response.body.sessionId.addressMainActual, 'expirationOfSession': response.body.expirationOfSession.addressMainActual} });
    // }

    // // cookie情報更新(アドレス情報)
    // const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
    // const cookiesNew = [
    //       {
    //         action: 'setUp',
    //         addressType: 'addressMain',
    //         description: `${userName}`,
    //         time: clientTime,
    //         address: stateFixed.addressMain
    //       },
    // ];

    // setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);

    // -- 登録カード情報取得
    getRegisteredCard(state, dispatch);
  }
  else {
  }

  return response;
}

// モナカード情報結局
// GET MONACARD INFO AFTER ALL
function getMonacardInfoAfterAll(state, item, size) {
  let cardName;
  let cardOwnerName;
  let cardDescription;
  let cid;
  let cardImageUrl;
  let cardImageUrlSP;
  let isAMonacard;

  if (Object.keys(state.assetInfo).length >= 1) { // 分割取得した場合、完全には働かない。
    if (state.assetInfo[item.asset]?.description !== undefined && state.assetInfo[item.asset]?.description !== null && state.assetInfo[item.asset]?.description !== '') {
      try {
        const description = JSON.parse(state.assetInfo[item.asset].description);
        cardName = description.monacard.name;
        cardOwnerName = description.monacard.owner;
        cardDescription = description.monacard.desc;
        cid = description.monacard.cid;

        if (size === 'large') {
          cardImageUrl = `${state.config.clientParameters.monacardUrl}${cid}`;
          cardImageUrlSP = `${state.config.clientParameters.monacardUrl}${cid}`;
        }
        else { // small
          cardImageUrl = `${state.config.clientParameters.monacardUrl}${cid}m`;
          cardImageUrlSP = `${state.config.clientParameters.monacardUrl}${cid}t`;
        }

        isAMonacard = true;
      }
      catch (err) {
        if (state.monacard[item.asset] !== undefined) {
          cardName = state.monacard[item.asset].card_name;
          cardOwnerName = state.monacard[item.asset].owner_name;
          cardDescription = state.monacard[item.asset].add_description;
          cardImageUrl = state.monacard[item.asset].imgur_url;
          cardImageUrlSP = state.monacard[item.asset].imgur_url;
          isAMonacard = true;
        }
        else {
          cardName = state.assetInfo[item.asset].asset_longname || item.asset;
          cardOwnerName = null;
          cardDescription = state.assetInfo[item.asset].description;
          cardImageUrl = null;
          cardImageUrlSP = null;

          if (Object.keys(state.monacard).length >= 1) { // 分割取得した場合、完全には働かない。
            isAMonacard = false;
          }
        }
      }
    }
    else if (state.assetInfo[item.asset] !== undefined) {
      if (state.monacard[item.asset] !== undefined) {
        cardName = state.monacard[item.asset].card_name;
        cardOwnerName = state.monacard[item.asset].owner_name;
        cardDescription = state.monacard[item.asset].add_description;
        cardImageUrl = state.monacard[item.asset].imgur_url;
        cardImageUrlSP = state.monacard[item.asset].imgur_url;
        isAMonacard = true;
      }
      else {
        cardName = state.assetInfo[item.asset].asset_longname || item.asset;
        cardOwnerName = null;
        cardDescription = state.assetInfo[item.asset].description;
        cardImageUrl = null;
        cardImageUrlSP = null;

        if (Object.keys(state.monacard).length >= 1) { // 分割取得した場合、完全には働かない。
          isAMonacard = false;
        }
      }
    }
    else { // このケースはありえない想定。
      cardName = null;
      cardOwnerName = null;
      cardDescription = null;
      cardImageUrl = null;
      cardImageUrlSP = null;
      isAMonacard = false;
    }
  }
  else { // アセット情報取得途中
    cardName = null;
    cardOwnerName = null;
    cardDescription = null;
    cardImageUrl = null;
    cardImageUrlSP = null;
  }

  return {
    cardName,
    cardOwnerName,
    cardDescription,
    cid,
    cardImageUrl,
    cardImageUrlSP,
    isAMonacard,
  };
}

// RELOAD 
async function reload(state, dispatch, getType, searchItemType, setItemKey) {
  dispatch({ type: 'setState', key: 'accessing', value: true });

  await Promise.all([
    // アイテム情報取得
    getItemNew(state, dispatch, searchItemType, setItemKey)
    .then( items => {
      devLog('items', JSON.stringify(items));

      if (items.body.item.length > 0) {
        // -- アセット情報取得
        getAssetInfo(state, dispatch, items.body.item.map( item => item.asset ));

        // -- モナカード情報取得
        const assetCommons = items.body.item.map( item => item.asset_longname === null ? item.asset : item.asset_longname );
        getMonacard(state, dispatch, assetCommons);
      }
    }),

    // ユーザ情報取得
    getUser(state, dispatch, getType),
    // devLog('usersGeneral', JSON.stringify(usersGeneral));
  ])

  dispatch({ type: 'setState', key: 'accessing', value: false });
}

// RELOAD GALLERY
async function reloadGallery(state, dispatch) {
  if (state.gallery.addressMain !== undefined && state.gallery.addressMain !== null && state.gallery.addressMain !== '') {
    dispatch({ type: 'setState', key: 'accessing', value: true });

    // アイテム情報取得
    const items = await getItem(state, dispatch, [state.gallery.addressMain])
    .then( items => {
      devLog('items', JSON.stringify(items));

      // アセット情報取得
      getAssetInfoByItems(state, dispatch, items);

      // モナカード情報取得
      const assetCommons = Object.keys(items.body).reduce( (acc, cur) => {
        devLog('cur', cur);
        devLog('item', JSON.stringify(items.body[cur].item));
        const assetCommons = items.body[cur].item.map( item => item.asset_longname === null ? item.asset : item.asset_longname );
        return acc.concat(assetCommons);
      }, []);

      getMonacard(state, dispatch, assetCommons);
    })

    dispatch({ type: 'setState', key: 'accessing', value: false });
  }
}

// BUILD ACTIVECERTIFICATION
function buildActiveCertification(result, x, addressTypes) {
  const { body: { sessionId, expirationOfSession }} = result;
  const activeCertification = [];

  for (const addressType of addressTypes) {
    if (sessionId[addressType] !== undefined) {
      activeCertification.push({ address: result.body[x][addressType], sessionId: sessionId[addressType], expirationOfSession: expirationOfSession[addressType] });
    }
  }

  return activeCertification;
}

function checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressActual, address, addressCheck) {
  if (addressCheck === 'strict' || addressCheck === 'addressSecond') {
    if (addressActual !== address) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
      return false;
    }
    else {
      return true;
    }
  }
  else { // off
    return true;
  }
}

function checkIfAddressIsMatchedWithTwoAddresses(state, dispatch, addressActual, addressMain, address2, addressType, addressCheck) {
  if (addressCheck === 'strict') {
    if (addressActual !== (addressType === 'addressMain' ? addressMain : address2)) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
      return false;
    }
    else {
      return true;
    }
  }
  else if (addressCheck === 'addressSecond') {
    if (addressActual !== addressMain && addressActual !== address2) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
      return false;
    }
    else {
      return true;
    }
  }
  else { // off
    return true;
  }
}

// モナパトークンをカゴに入れる
// ADD TOKENS TO THE CART MONAPARTY
function addTokensToTheCartMonaparty(state, dispatch, addressFrom, addressTo, asset, quantity, actionItemsMultiSourcesInArg) {
  let actionItemsMultiSources;

  if (actionItemsMultiSourcesInArg !== undefined && actionItemsMultiSourcesInArg !== null) {
    actionItemsMultiSources = actionItemsMultiSourcesInArg;
  }
  else {
    actionItemsMultiSources = state.cartMonaparty;
  }

  let done;

  if (actionItemsMultiSources[addressFrom] === undefined) {
    actionItemsMultiSources[addressFrom] = [];
  }

  for (const actionItem of actionItemsMultiSources[addressFrom]) {
    if (actionItem.action === 'send') {
      if (actionItem.recipients[addressTo] === undefined) {
        actionItem.recipients[addressTo] = {};
      }

      if (actionItem.recipients[addressTo][asset] === undefined) {
        actionItem.recipients[addressTo][asset] = {};
      }

      actionItem.recipients[addressTo][asset].quantity = quantity;

      done = true;
      break;
    }
  }

  if (!done) {
    actionItemsMultiSources[addressFrom].push(
      {
        transactionType: 'sendToken',
        action: 'send',
        status: 'unprocessed',
        addressFrom: addressFrom,
        recipients: {
          [addressTo]: {
            [asset]: {
              quantity: quantity,
            },
          },
        },
      },
    );
  }

  dispatch({ type: 'setState', key: 'cartMonaparty', value: actionItemsMultiSources });

  return actionItemsMultiSources;
}

// BUILD MONAPARTY TRANSACTIONS
async function buildMonapartyTransactions(state, dispatch, keyPairs, actionItemsMultiSources) {
  let requestToMonapartyServer = {};
  let bodyObject = {};
  let result = {};
  let response = {};
  let outValueExcludeChange;
  let change;
  let transactionFee;
  let transactionUnsigned;
  let transactionSigned;
  let txid;
  let serverIndex = 0;

  requestToMonapartyServer.method = 'POST';

  devLog('actionItemsMultiSources', JSON.stringify(actionItemsMultiSources));

  SOURCE: for (const source of Object.keys(actionItemsMultiSources)) {
    const keyPair = keyPairs?.[source]?.keyPair;

    ACTION_ITEM: for (const actionItem of actionItemsMultiSources[source]) {
      // actionItem単位でトランザクションを作成し、ブロードキャストする。

      if (actionItem.status === 'unprocessed') {
        if (actionItem.action === 'send') {
          // パラメータ作成

          const destinations = [];
          const assets = [];
          const quantitys = [];

          for (const destination of Object.keys(actionItem.recipients)) {
            for (const asset of Object.keys(actionItem.recipients[destination])) {
              destinations.push(destination);
              assets.push(asset);
              quantitys.push(actionItem.recipients[destination][asset].quantity);
            }
          }

          if (destinations.length < 1 || assets.length < 1 || quantitys.some( quantity => quantity <= 0)) {
            // continue ACTION_ITEM;
            break SOURCE;
          }

          // トランザクション作成

          bodyObject = {
            "jsonrpc":"2.0",
            "id":0,
            "method":"proxy_to_counterpartyd",
            "params": {
              "method":"create_send",
              "params": {
                "source": source,
                "destination": destinations.length >= 2 ? destinations : destinations[0],
                "asset": assets.length >= 2 ? assets : assets[0],
                "quantity": quantitys.length >= 2 ? quantitys : quantitys[0],
                "extended_tx_info": true,
              }
            }
          };

          requestToMonapartyServer.body = JSON.stringify(bodyObject);

          let buildTransactionRetryCount = 0;

          while (response.status !== 'fulfilled') {
            requestToMonapartyServer.url = `https://${state.config.clientParameters.monapaServer.domainAndPath[serverIndex]}`;
            console.log('request create_send', JSON.stringify(requestToMonapartyServer));

            response = await syncHttpRequest(requestToMonapartyServer);
            console.log('response', JSON.stringify(response));

            if (response.status === 'rejected' || response.body.error !== undefined) {
              devLog('create_send to counterpartyd; rejected');
              buildTransactionRetryCount++;

              if (serverIndex >= state.config.clientParameters.monapaServer.domainAndPath.length - 1) {
                serverIndex = 0;
              }
              else {
                serverIndex++;
              }

              await setTimeout(state.config.clientParameters.retry.buildTransaction.retryWaitingTime);
              // keepSqsMessage = true;
              // continue;
            }
            else {
              transactionUnsigned = response.body.result.tx_hex;
              outValueExcludeChange = response.body.result.btc_out;
              change = response.body.result.btc_change;
              transactionFee = response.body.result.btc_fee;
              devLog('transactionUnsigned', transactionUnsigned);
            }

            if (buildTransactionRetryCount > state.config.clientParameters.retry.buildTransaction.retryMax) {
              // -- retry over エラー終了
              const error = response.status === 'rejected' ? response.error : response.body.error;

              devLog(`build transaction retry over.; ${error}`);
              dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToSendToken[state.language]}; failed to build transaction.; ${error}` });

              actionItem.status = 'failed';
              actionItem.statusDetail = 'failedToBuildTransaction';
              actionItem.error = error;
              // continue ACTION_ITEM;
              break SOURCE;
            }
          }

          // トランザクション署名

          if (keyPair !== undefined) {
            result = {};

            result = signTransaction(transactionUnsigned, keyPair);
            devLog('sign transaction; result', JSON.stringify(result));

            if (result.status === 'fulfilled') {
              devLog('succeeded to sign transaction.');

              transactionSigned = result.body;
              actionItem.transactionSigned = transactionSigned;
              actionItem.outValueExcludeChange = outValueExcludeChange;
              actionItem.transactionFee = transactionFee;
              actionItem.status = 'succeeded';
              actionItem.statusDetail = 'signed';
            }
            else {
              devLog(`failed to sign transaction.; ${result.error}`);
              dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToSendToken[state.language]}; failed to sign transaction.; ${result.error}` });

              actionItem.status = 'failed';
              actionItem.statusDetail = 'failedToSignWithKey';
              actionItem.error = result.error;
              // continue ACTION_ITEM;
              break SOURCE;
            }
          }
          else { // Mpurse
            result = {};

            result = await window.mpurse.signRawTransaction(transactionUnsigned)
            .then( async (result) => {
              devLog('succeeded to sign transaction.');
              devLog(result);

              return {
                status: 'fulfilled',
                body: result,
              };
            })
            .catch( (error) => {
              devLog('failed to sign transaction.');
              devLog(error);

              // notification
              dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSend[state.language] });

              return {
                status: 'rejected',
                body: error
              };
            });

            if (result.status === 'fulfilled') {
              devLog('succeeded to sign transaction.');

              transactionSigned = result.body;
              actionItem.transactionSigned = transactionSigned;
              actionItem.outValueExcludeChange = outValueExcludeChange;
              actionItem.transactionFee = transactionFee;
              actionItem.status = 'succeeded';
              actionItem.statusDetail = 'signed';
            }
            else {
              devLog(`failed to sign transaction with Mpurse.; ${result.error}`);
              dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToSendToken[state.language]}; failed to sign transaction with Mpurse.; ${result.error}` });

              actionItem.status = 'failed';
              actionItem.statusDetail = 'failedToSignWithMpurse';
              actionItem.error = result.error;
              // continue ACTION_ITEM;
              break SOURCE;
            }
          }
        }
        else { // sendしかない
        }

        break SOURCE;
      }
    }
  }

  return actionItemsMultiSources;
}

// BROADCAST MONA TRANSACTIONS
async function broadcastMonaTransactions(state, dispatch, actionItemsMultiSources) {

  // ブロードキャスト

  for (const addressFrom of Object.keys(actionItemsMultiSources)) {
    ACTION_ITEM: for (const actionItem of actionItemsMultiSources[addressFrom]) {
      if (actionItem.statusDetail === 'signed') {
        const result = await broadcastTransaction(state, actionItem.transactionSigned);

        if (result.status === 'fulfilled') {
          actionItem.txid = result.body;
          actionItem.status = 'succeeded';
          actionItem.statusDetail = 'broadcasted';

          // notification
          dispatch({ type: 'setNotification', key: 'notification', value: words.sentSuccessfully[state.language] + ' ' + actionItem.txid });
        }
        else {
          devLog('failed to broadcast transaction.');
          dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] });

          actionItem.status = 'failed';
          actionItem.statusDetail = 'failedToBroadcastTransaction';
          continue ACTION_ITEM;
        }
      }
    }
  }

  return actionItemsMultiSources;
}

// MONAをカゴに入れる
// ADD SENDS TO THE CART MONA
function addSendsToTheCartMona(state, dispatch, addressFrom, addressTo, quantity, actionItemsMultiSourcesInArg) {
  let actionItemsMultiSources;

  if (actionItemsMultiSourcesInArg !== undefined && actionItemsMultiSourcesInArg !== null) {
    actionItemsMultiSources = actionItemsMultiSourcesInArg;
  }
  else {
    actionItemsMultiSources = state.cartMona;
  }

  let done;

  if (actionItemsMultiSources[addressFrom] === undefined) {
    actionItemsMultiSources[addressFrom] = [];
  }

  for (const actionItem of actionItemsMultiSources[addressFrom]) {
    if (actionItem.action === 'send') {
      actionItem.recipients.push(
        {
          addressTo: addressTo,
          amountWatanabe: quantity,
        },
      );

      done = true;
      break;
    }
  }

  if (!done) {
    actionItemsMultiSources[addressFrom].push(
      {
        transactionType: 'sendMona',
        action: 'send',
        status: 'unprocessed',
        addressFrom: addressFrom,
        recipients: [
          {
            addressTo: addressTo,
            amountWatanabe: quantity,
          },
        ],
      },
    );
  }

  dispatch({ type: 'setState', key: 'cartMona', value: actionItemsMultiSources });

  return actionItemsMultiSources;
}

// CONFIRM COMMIT MONA TRANSACTION
function confirmCommitMonaTransaction(state, dispatch, popupLayer, actionItemsMultiSourcesSigned, stateFixed) {
  const popupLayerNext = popupLayer !== undefined && popupLayer !== null ? (popupLayer + 1) : 0;
  devLog('popupLayer popupLayerNext', popupLayer, popupLayerNext);

  const transactionFee = actionItemsMultiSourcesSigned[stateFixed.addressPayFrom][0].transactionFee;

  const buttonFaceOk =
  <div>
    OK
  </div>;

  const buttonFaceCancel =
  <div>
    CANCEL
  </div>;

  const callbackOk = async () => {
    dispatch({ type: 'setState', key: 'accessing', value: true });

    await broadcastMonaTransactions(state, dispatch, actionItemsMultiSourcesSigned);
    devLog('resultOfBroadcast', JSON.stringify(actionItemsMultiSourcesSigned));

    // 一応、カートを空にする

    deleteCart(state, dispatch, 'cartMona');

    dispatch({ type: 'setState', key: 'accessing', value: false });

    if (actionItemsMultiSourcesSigned[stateFixed.addressPayFrom][0].status === 'succeeded') {
    }
    else { // failed
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSend[state.language] });
      return { status: 'rejected' };
    }

    const txid = actionItemsMultiSourcesSigned[stateFixed.addressPayFrom][0].txid;

    // purchase状態更新(部分クリア)
    dispatch({ type: 'setState', key: 'purchase',
      value: {
        purchaseNo: null,
        addressMain: state.purchase.addressMain,
        addressPayFrom: state.purchase.addressPayFrom,
        addressSendCardTo: state.purchase.addressSendCardTo,
        addressMainExhibitor: '',
        exhibitNo: '',
        amountToBuy: state.purchase.amountToBuy,
        signatureByAddressMain: '',
        signatureByAddressPayFrom: '',
        addressPayRoyaltyTo: null,
        disabled: false,
        status: 'waitingForSignature',
      }
    });

    // notification
    dispatch({ type: 'setNotification', key: 'notification', value: words.sentSuccessfully[state.language] + ' ' + txid });

    // MONA送信成功通知
    const request = {};
    request.body = JSON.stringify({
      "body": {
        addressMain: stateFixed.addressMain,
        purchaseNo: stateFixed.purchaseNo,
        signatureByAddressMain: stateFixed.signatureByAddressMain,
        sendMonaTxid: txid,
      }
    });
    request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/notify_sending_mona';
    request.method = 'POST';

    const messages = {
    };

    const response = await syncHttpRequest2(request, undefined, undefined, undefined, messages, dispatch);

    // ポップアップを消す

    for (let i = 0; i <= popupLayerNext; i++) {
      dispatch({ type: 'setStateMultiLayers', keys: ['popup', i], value: { type: null, body: null } });
    }
  }

  const callbackCancel = async () => {
    // 一応、カートを空にする

    deleteCart(state, dispatch, 'cartMona');

    // ポップアップを消す

    for (let i = 0; i <= popupLayerNext; i++) {
      dispatch({ type: 'setStateMultiLayers', keys: ['popup', i], value: { type: null, body: null } });
    }
  }

  // ポップアップで送金内容確認してもらったらGO!
  const popup = {
    type: 'sendMonaConfirmation',
    body: {
      addressPayProceedsTo: stateFixed.addressPayProceedsTo,
      addressPayRoyaltyTo: stateFixed.addressPayRoyaltyTo,
      monacottoAddressMona: stateFixed.monacottoAddressMona,
      proceedsNetMona: stateFixed.proceedsNetMona * stateFixed.amountToBuy,
      royaltyMona: stateFixed.royaltyMona * stateFixed.amountToBuy,
      feeMona: stateFixed.feeMona * stateFixed.amountToBuy,
      transactionFee: transactionFee,
      buttonFaceOk,
      buttonFaceCancel,
      callbackOk,
      callbackCancel,
    },
  };

  if (wallet === 'MonaPallet') {
    popup.extendedClassesBackGround = 'alignItemsFlexStart';
  }
  else { // Mpurse
  }

  dispatch({ type: 'setStateMultiLayers', keys: ['popup', (popupLayer !== undefined && popupLayer !== null) ? (popupLayer + 1) : 0], value: popup });
}

// UPDATE CART
function updateCart(state, dispatch, actionItemsMultiSources, cart) {

  // カート更新

  const actionItemsMultiSourcesNew = {};

  for (const addressFrom of Object.keys(actionItemsMultiSources)) {
    const actionItemsNew = actionItemsMultiSources[addressFrom].filter( actionItem => actionItem.status === 'failed' );

    if (actionItemsNew.length >= 1) {
      actionItemsMultiSourcesNew[addressFrom] = actionItemsNew;
    }
  }

  dispatch({ type: 'setState', key: cart, value: actionItemsMultiSourcesNew });

  return actionItemsMultiSourcesNew;
}

// DELETE CART
function deleteCart(state, dispatch, cart) {
  dispatch({ type: 'setState', key: cart, value: {} });
  return {};
}

// BUILD MONA TRANSACTIONS
async function buildMonaTransactions(state, dispatch, keyPairs, actionItemsMultiSources, { provisionalTransactionFee, transactionFeeUpperBound }) {
  let result;
  let utxos;
  let feeRate;
  let transactionFee;
  let change;
  let virtualSize;
  let transactionUnsignedPreBuild;
  let transactionSignedPreBuild;
  let transactionUnsigned;
  let transactionSigned;

  // const keyPairDummy = bitcoin.ECPair.fromWIF(state.config.clientParameters.wifDummy, networkMona);

  devLog('provisionalTransactionFee', provisionalTransactionFee);

  SOURCE: for (const addressFrom of Object.keys(actionItemsMultiSources)) {
    const keyPair = keyPairs?.[addressFrom]?.keyPair;

    ACTION_ITEM: for (const [index, actionItem] of actionItemsMultiSources[addressFrom].entries()) {
      // actionItem単位でトランザクションを作成し、ブロードキャストする。

      if (actionItem.status === 'unprocessed') {
        if (actionItem.action === 'send' && actionItem.recipients.length >= 1) {
          const amountWatanabeTotal = actionItem.recipients.reduce((acc, cur) => acc + cur.amountWatanabe, 0);

          // UTXO取得
          result = await getUtxo(state, addressFrom);

          if (result.status === 'fulfilled' && result.body.error === undefined) {
            utxos = result.body;
          }
          else {
            const error = result.status !== 'fulfilled' ? result.error : result.body.error;

            devLog(`failed to get UTXO.; ${error}`);
            dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToSendMona[state.language]}; failed to get UTXO.; ${result.error}` });

            actionItem.status = 'failed';
            actionItem.statusDetail = 'failedToGetUtxos';
            actionItem.error = error;
            // continue ACTION_ITEM;
            break SOURCE;
          }

          // UTXO選定
          utxos.sort( (a, b) => a.amount - b.amount );
          const utxosSelected = selectUtxoWatanabe(utxos, amountWatanabeTotal + provisionalTransactionFee); // boundaryは概算。provisionalTransactionFeeには少し余裕をもたせるべし。
          devLog('utxosSelected', JSON.stringify(utxosSelected));

          if (utxosSelected === null) {
            devLog('short of MONA');
            dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '; short of MONA' });

            actionItem.status = 'failed';
            actionItem.statusDetail = 'shortOfMona';
            // continue ACTION_ITEM;
            break SOURCE;
          }

          // UTX raw transaction取得
          result = await getRawTransaction(state, utxosSelected);

          if (result.status === 'rejected') {
            devLog(`failed to get unspent raw transactions.; ${result.error}`);
            dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToSendMona[state.language]} ; failed to get unspent raw transactions.; ${result.error}` });

            actionItem.status = 'failed';
            actionItem.statusDetail = 'failedToGetUnspentRawTransactions';
            actionItem.error = result.error;
            // continue ACTION_ITEM;
            break SOURCE;
          }

          // fee rate取得
          result = await getFeeRate(state);

          if (result.status === 'fulfilled' && result.body.error === undefined) {
            feeRate = result.body;
          }
          else {
            const error = result.status !== 'fulfilled' ? result.error : result.body.error;

            devLog(`failed to get fee rate.; ${error}`);
            dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToSendMona[state.language]}; failed to get fee rate.; ${error}` });

            actionItem.status = 'failed';
            actionItem.statusDetail = 'failedToGetFeeRate';
            actionItem.error = error;
            // continue ACTION_ITEM;
            break SOURCE;
          }

          // input, output作成

          const changeOfZeroFee = utxosSelected.reduce((acc, cur) => acc + cur.satoshis, 0) - amountWatanabeTotal;

          const inputs = utxosSelected.map( utxo => {
            return {
              txid: utxo.txid,
              vout: utxo.vout,
              rawTransaction: utxo.rawTransaction,
            };
          });

          devLog('inputs', JSON.stringify(inputs));

          const outputs = actionItem.recipients.map( recipient => {
            return {
              address: recipient.addressTo,
              amount: recipient.amountWatanabe,
            };
          });

          const outputsPreBuild = [ ...outputs ];

          outputsPreBuild.push(
            {
              address: addressFrom,
              amount: changeOfZeroFee,
            }
          );

          devLog('outputsPreBuild', JSON.stringify(outputsPreBuild));


          // 仮トランザクション作成(トランザクションサイズ取得のため)

          result = buildUnsignedTransactionSendMona(inputs, outputsPreBuild);

          if (result.status === 'fulfilled') {
            transactionUnsignedPreBuild = result.body;
          }
          else {
            devLog(`failed to pre-build transaction.; ${result.error}`);
            dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToSendMona[state.language]}; failed to pre-build transaction.; ${result.error}` });

            actionItem.status = 'failed';
            actionItem.statusDetail = 'failedToPreBuildTransaction';
            actionItem.error = result.error;
            // continue ACTION_ITEM;
            break SOURCE;
          }

          if (keyPair !== undefined) {
            // 仮トランザクション署名(トランザクションサイズ取得のため)

            result = signTransaction(transactionUnsignedPreBuild, keyPair);

            if (result.status === 'fulfilled') {
              transactionSignedPreBuild = result.body;
            }
            else {
              devLog(`failed to pre-sign transaction.; ${result.error}`);
              dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToSendMona[state.language]}; failed to pre-sign transaction.; ${result.error}` });

              actionItem.status = 'failed';
              actionItem.statusDetail = 'failedToPreSign';
              actionItem.error = result.error;
              // continue ACTION_ITEM;
              break SOURCE;
            }

            // トランザクションサイズ取得

            virtualSize = bitcoin.Transaction.fromHex(transactionSignedPreBuild).virtualSize();
            console.log('virtualSize', virtualSize);
          }
          else {
            // トランザクションサイズ取得

            virtualSize = bitcoin.Transaction.fromHex(transactionUnsignedPreBuild).virtualSize();
            console.log('approximated virtualSize', virtualSize);
          }

          // 本トランザクション作成

          // お釣り計算

          result = calculateChangeWatanabe(utxosSelected, amountWatanabeTotal, feeRate, virtualSize, transactionFeeUpperBound);

          if (result.status === 'fulfilled') {
            change = result.body.change;
            transactionFee = result.body.transactionFee;
          }
          else {
            devLog(`failed to calculate change.; ${result.error}`);
            dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToSendMona[state.language]}; failed to calculate change.; ${result.error}` });

            actionItem.status = 'failed';
            actionItem.statusDetail = 'failedToCalculateChange';
            actionItem.error = result.error;
            // continue ACTION_ITEM;
            break SOURCE;
          }

          // トランザクション作成

          outputs.push(
            {
              address: addressFrom,
              amount: change,
            }
          );

          devLog('outputs', JSON.stringify(outputs));

          result = buildUnsignedTransactionSendMona(inputs, outputs);

          if (result.status === 'fulfilled') {
            transactionUnsigned = result.body;
          }
          else {
            devLog(`failed to build transaction.; ${result.error}`);
            dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToSendMona[state.language]}; failed to build transaction.; ${result.error}` });

            actionItem.status = 'failed';
            actionItem.statusDetail = 'failedToBuildTransaction';
            actionItem.error = result.error;
            // continue ACTION_ITEM;
            break SOURCE;
          }

          // トランザクション署名

          if (keyPair !== undefined) {
            result = signTransaction(transactionUnsigned, keyPair);

            if (result.status === 'fulfilled') {
              transactionSigned = result.body;
              actionItem.transactionSigned = transactionSigned;
              actionItem.transactionFee = transactionFee;
              actionItem.status = 'succeeded';
              actionItem.statusDetail = 'signed';
            }
            else {
              devLog(`failed to sign transaction.; ${result.error}`);
              dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToSendMona[state.language]}; failed to sign transaction.; ${result.error}` });

              actionItem.status = 'failed';
              actionItem.statusDetail = 'failedToSignWithKey';
              actionItem.error = result.error;
              // continue ACTION_ITEM;
              break SOURCE;
            }
          }
          else { // Mpurse
            result = await window.mpurse.signRawTransaction(transactionUnsigned)
            .then( async (result) => {
              devLog('succeeded to sign transaction.');
              devLog(result);

              return {
                status: 'fulfilled',
                body: result,
              };
            })
            .catch( (error) => {
              devLog('failed to sign transaction with Mpurse.');
              devLog(error);

              // notification
              dispatch({ type: 'setNotification', key: 'notification', value: `${words.failedToSend[state.language]}; failed to sign transaction with Mpurse.; ${error}`  });

              return {
                status: 'rejected',
                body: error
              };
            });

            if (result.status === 'fulfilled') {
              transactionSigned = result.body;
              actionItem.transactionSigned = transactionSigned;
              actionItem.transactionFee = transactionFee;
              actionItem.status = 'succeeded';
              actionItem.statusDetail = 'signed';
            }
            else {
              actionItem.status = 'failed';
              actionItem.statusDetail = 'failedToSignWithMpurse';
              actionItem.error = result.error;
              // continue ACTION_ITEM;
              break SOURCE;
            }
          }
        }
        else { // sendしかない
        }

        break SOURCE;
      }
    }
  }

  return actionItemsMultiSources;
}

// GET UTXO
async function getUtxo(state, address) {
  let serverIndex = 0;

  let request = {};
  request.method = 'GET';
  request.headers = {
    'Accept': 'application/json',
  };

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.blockbook.domain[serverIndex]}${state.config.clientParameters.blockbook.utxoPath}${address}`;
    devLog('get UTXO; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('get UTXO; response', JSON.stringify(response));

    if ( response.status === 'fulfilled' ) {
      const utxos = response.body;
      devLog('utxos', JSON.stringify(utxos));
      return {
        status: 'fulfilled',
        body: utxos,
      };
    }
    else {
      devLog('get UTXO; rejected');

      if (serverIndex >= state.config.clientParameters.blockbook.domain.length - 1) {
        return {
          status: 'rejected',
          error: response.body + request.url,
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
  }
}

// モナコインUTXO選定
// -- utxosは昇順ソート
function selectUtxoMona(utxos, amount) {
  devLog('selectUtxoMona', JSON.stringify(utxos), amount);
  const minimumGrater= minimumGraterUtxo(utxos, amount);
  devLog('minimumGrater', JSON.stringify(minimumGrater));

  if (minimumGrater !== null) {
    return [minimumGrater];
  }
  else {
    if (utxos.length <= 1) {
      return null;
    }

    const maximum = utxos.pop();
    devLog('maximum', JSON.stringify(maximum));
    const supplement = selectUtxoMona(utxos, amount - maximum.amount);

    if (supplement !== null) {
      supplement.unshift(maximum);
      return supplement;
    }
    else {
      return null;
    }
  }
}

function minimumGraterUtxo(utxos, bound) {
  devLog('minimumGraterUtxo', JSON.stringify(utxos), bound);
  let result = null;

  for(const utxo of utxos) {
   if (utxo.amount >= bound) {
     result = utxo;
     break;
   } 
  }

  return result;
}

// SELECT UTXO WATANABE
// モナコインUTXO選定
// -- utxosは昇順ソート
function selectUtxoWatanabe(utxos, amount) {
  devLog('selectUtxoWatanabe', JSON.stringify(utxos), amount);
  const minimumGrater= minimumGraterUtxoWatanabe(utxos, amount);
  devLog('minimumGrater', JSON.stringify(minimumGrater));

  if (minimumGrater !== null) {
    return [minimumGrater];
  }
  else {
    if (utxos.length <= 1) {
      return null;
    }

    const maximum = utxos.pop();
    devLog('maximum', JSON.stringify(maximum));
    const supplement = selectUtxoWatanabe(utxos, amount - maximum.satoshis);

    if (supplement !== null) {
      supplement.unshift(maximum);
      return supplement;
    }
    else {
      return null;
    }
  }
}

function minimumGraterUtxoWatanabe(utxos, bound) {
  devLog('minimumGraterUtxo', JSON.stringify(utxos), bound);
  let result = null;

  for(const utxo of utxos) {
   if (utxo.satoshis >= bound) {
     result = utxo;
     break;
   } 
  }

  return result;
}

// GET TRANSACTION
async function getTransaction(state, txid) {
  let transaction;
  let serverIndex = 0;

  // transaction取得
  let request = {};
  request.method = 'GET';
  request.headers = {
    'Accept': 'application/json',
  };

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.blockbook.domain[serverIndex]}${state.config.clientParameters.blockbook.transactionDetailPath}${txid}`;
    devLog('get transaction; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('get transaction; response', JSON.stringify(response));

    if ( response.status === 'fulfilled' ) {
      transaction = response.body;
      devLog('transaction', JSON.stringify(transaction));

      return {
        status: 'fulfilled',
        body: transaction,
      };
    }
    else {
      devLog('get transaction; rejected');

      if (serverIndex >= state.config.clientParameters.blockbook.domain.length - 1) {
        return {
          status: 'rejected',
          error: response.error,
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
  }
}

// GET RAW TRANSACTION
async function getRawTransaction(state, utxosSelected) {
  let serverIndex = 0;

  let request = {};
  request.method = 'POST';

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.monapaServer.domainAndPath[serverIndex]}`;

    for (const utxo of utxosSelected) {
      request.body = `{"jsonrpc":"2.0","id":0,"method":"proxy_to_counterpartyd","params":{"method":"getrawtransaction","params":{"tx_hash":"${utxo.txid}"}}}`;
      devLog('get UTX raw transaction; request', JSON.stringify(request));

      const response = await syncHttpRequest(request);
      devLog('get UTX raw transaction; response', JSON.stringify(response));

      if ( response.status === 'rejected' || response.body.error !== undefined ) {
        devLog('get UTXO; rejected');
        const error = response.status === 'rejected' ? response.error : response.body.error;

        if (serverIndex >= state.config.clientParameters.monapaServer.domainAndPath.length - 1) {
          // -- 失敗 エラー返却
          return {
            status: 'rejected',
            error: error
          };
        }
        else {
          serverIndex++;
          continue SERVER;
        }
      }

      utxo.rawTransaction = response.body.result;
    }

    break SERVER;
  }

  return {
    status: 'fulfilled',
    body: utxosSelected,
  };
}

// GET ADDRESS INFO
async function getAddressInfo(state, address) {
  let addressInfo;
  let serverIndex = 0;

  let request = {};
  request.method = 'GET';
  request.headers = {
    'Accept': 'application/json',
  };

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.blockbook.domain[serverIndex]}${state.config.clientParameters.blockbook.addressPath}${address}?details=basic`;
    devLog('get address info; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('get address info; response', JSON.stringify(response));

    if ( response.status === 'fulfilled' ) {
      addressInfo = response.body;
      devLog('address info', JSON.stringify(addressInfo));

      return {
        status: 'fulfilled',
        body: addressInfo,
      };
    }
    else {
      devLog('get address info; rejected', response.error);

      if (serverIndex >= state.config.clientParameters.blockbook.domain.length - 1) {
        return {
          status: 'rejected',
          error: response.error,
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
  }
}

// GET FEE RATE
async function getFeeRate(state) {
  let feeRate;
  let serverIndex = 0;

  // fee rate取得
  let request = {};
  request.method = 'GET';
  request.headers = {
    'Accept': 'application/json',
  };
  // delete request.body;

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.blockbook.domain[serverIndex]}${state.config.clientParameters.blockbook.feePath}${state.config.clientParameters.blockbook.feeBlocks}`;
    devLog('get fee rate; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('get fee rate; response', JSON.stringify(response));

    if ( response.status === 'fulfilled' ) {
      if (response.body.result === '0') {
        feeRate = 0.002;
      }
      else {
        feeRate = toFloat(response.body.result);
      }

      devLog('feeRate', feeRate);

      return {
        status: 'fulfilled',
        body: feeRate,
      };
    }
    else {
      devLog('get fee rate; rejected');

      if (serverIndex >= state.config.clientParameters.blockbook.domain.length - 1) {
        return {
          status: 'rejected',
          error: response.error,
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
  }
}

// CALCULATE CHANGE WATANABE
function calculateChangeWatanabe(utxosSelected, baseAmountWatanabe, feeRate, virtualSize, transactionFeeUpperBound) {
  // 概算バイト数計算

  const feeRateDecimal = new decimal(feeRate); // MONA/KB = 10^8watanabe/10^3byte = 10^5watanabe/byte
  const transactionFee = decimalAdjust( 'floor',  feeRateDecimal.times(100000).times(virtualSize).toNumber(), 0 ); 
  const amountToPay = baseAmountWatanabe + transactionFee;
  const change = utxosSelected.reduce( (acc, cur) => acc + cur.satoshis, 0 ) - amountToPay;
  devLog(`transactionFee ${transactionFee}, amountToPay ${amountToPay}, change ${change}`);

  // transactionFee歯止め

  if (transactionFee > transactionFeeUpperBound) {
    devLog('transactionFee is too high.');
    return {
      status: 'rejected',
      error: 'transactionFeeIsTooHigh',
    };
  }

  return {
    status: 'fulfilled',
    body: {
      change,
      transactionFee,
    },
  };
}

// BUILD UNSIGNED TRANSACTION SEND MONA
function buildUnsignedTransactionSendMona(inputs, outputs) {
  try {
    console.log('A');

    const txb = new bitcoin.TransactionBuilder(networkMona);

    console.log('B');

    for (const input of inputs) {
      const inputTransaction = bitcoin.Transaction.fromHex(input.rawTransaction);
      console.log(JSON.stringify(inputTransaction.outs[input.vout].script));
      input.scriptPubkey = inputTransaction.outs[input.vout].script;
      console.log(input.scriptPubkey);
      txb.addInput(input.txid, input.vout, null, input.scriptPubkey);
    }

    console.log('C');

    for (const output of outputs) {
      txb.addOutput(output.address, output.amount);
    }

    console.log('D');

    const unsignedTx = txb.buildIncomplete();

    console.log('E');
    console.log(inputs[0].scriptPubkey);

    for (let i = 0; i <= unsignedTx.ins.length - 1; i++) {
      console.log(i);
      unsignedTx.ins[i].script = inputs[i].scriptPubkey;
    }

    console.log('F');

    const unsignedTxHex = unsignedTx.toHex();

    return {
      status: 'fulfilled',
      body: unsignedTxHex,
    };
  }
  catch (error) {
    return {
      status: 'rejected',
      body: error,
    };
  }
}

// SIGN TRANSACTION
function signTransaction(txHex, keyPair, wif) {
  try {
    if (keyPair === undefined || keyPair === null) {
      keyPair = bitcoin.ECPair.fromWIF(wif, networkMona);
    }

    const tx = bitcoin.Transaction.fromHex(txHex);   // The unsigned second part of the 2 part P2SH transactions
    const txb = bitcoin.TransactionBuilder.fromTransaction(tx, networkMona);

    for (let i = 0; i < tx.ins.length; i++) {
      txb.sign(i, keyPair);
    }

    const signedTxHex = txb.build().toHex();

    devLog('inner result', signedTxHex);

    return {
      status: 'fulfilled',
      body: signedTxHex, // The resulting signed transaction in raw hex, ready to be broadcasted
    };
  }
  catch (error) {
    return {
      status: 'rejected',
      body: error,
    };
  }
}

// GET OUTPUT VALUE TOTAL
function getOutputValueTotal(txHex) {
  try {
    const tx = bitcoin.Transaction.fromHex(txHex);
    const outputValueTotal = tx.outs.reduce( (acc, cur) => acc + cur.value, 0 );

    return {
      status: 'fulfilled',
      body: outputValueTotal,
    };
  }
  catch (error) {
    return {
      status: 'rejected',
      body: error,
    };
  }
}

// BROADCAST TRANSACTION
async function broadcastTransaction(state, signedTxHex) {
  let serverIndex = 0;

  let request = {};
  request.method = 'POST';
  request.headers = {
    'Accept': 'application/json',
  };
  request.body = signedTxHex;

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.blockbook.domain[serverIndex]}${state.config.clientParameters.blockbook.sendTransactionPath}`;
    devLog('broadcast transaction; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('broadcast transaction; response', JSON.stringify(response));

    if ( response.status === 'fulfilled' ) {
      const txid = response.body.result;
      devLog('txid', txid);

      return {
        status: 'fulfilled',
        body: txid,
      };
    }
    else {
      devLog('broadcast transaction; rejected');

      if (serverIndex >= state.config.clientParameters.blockbook.domain.length - 1) {
        return {
          status: 'rejected',
          error: response.error,
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
  }
}


// NFCボタン押下 HANDLE CLICK NFC
async function handleClickNfc(state, dispatch, callback, { args = ['keyPairs'], recordNos = [1] } = {}) {
  if ('NDEFReader' in window) {
    const ndef = new window.NDEFReader();
    await ndef.scan();

    ndef.onreading = async event => {
      console.log("NDEF tag detected");

      const keyPairs = [];
      const mnemonics = [];

      const argumentsAll = { keyPairs, mnemonics };
      const argumentsToPass = args.map( arg => argumentsAll[arg] );

      for (const [index, record] of event.message.records.entries()) {
        if (!recordNos.includes(index)) {
          continue;
        }

        try {
          const textDecoder = new TextDecoder(record.encoding);
          const mnemonicEncrypted = textDecoder.decode(record.data);
          const pass = state.config.clientParameters.specialMessage;
          // const pass = undefined;
          const salt = ['00', '00', '00', '00', '00', '00', '00', '72'];
          const iv = ['00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '01', 'bf', '52'];
          const mnemonic = await decrypt(state, dispatch, mnemonicEncrypted, pass, salt, iv);
          // const mnemonic = textDecoder.decode(record.data);
          // const mnemonic = undefined;
          mnemonics.push(mnemonic);
          const keyPair = getKeyPairsFromMnemonic(mnemonic)[0];
          // const seed = bip39.mnemonicToSeedSync(mnemonic);
          // const root = bip32.fromSeed(seed)
          // const path = "m/44'/22'/0'/0/0";
          // const leaf = root.derivePath(path);
          // const keyPair = ECPair.fromPrivateKey(leaf.privateKey, { network: networkMona });
          keyPairs.push(keyPair);
          // dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'address'], value: getAddressFromKey(state, dispatch, keyPair).address }); // 暫定
          // dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'wif'], value: JSON.stringify(keyPair) }); // 暫定
          // dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: typeof mnemonic }); // 暫定
          // const payment = bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey, network: networkMona });
          // const message = 'hoge';
          // let randomBytes = new Uint8Array(32);
          // window.crypto.getRandomValues(randomBytes);
          // const signature = bitcoinMessage.sign(message, keyPair.privateKey, keyPair.compressed, networkMona.messagePrefix, { extraEntropy: Buffer.from(randomBytes) });
          // console.log(`Record type: ${record.recordType}`);
          // console.log(`MIME type: ${record.mediaType}`);
          // console.log(`Data: ${textDecoder.decode(record.data)}`);
          // dispatch({ type: 'setStateMultiLayers', keys: ['login', 'addressMain'], value: signature.toString('base64')}); 
          // dispatch({ type: 'setStateMultiLayers', keys: ['login', 'addressMain'], value: payment.address}); 
          // dispatch({ type: 'setStateMultiLayers', keys: ['cardRegistration', 'feature'], value: keyPair.toWIF()}); 

          // const encryptedMessage = 'kR10Y9tegf2qDkjOFAxK7/YuXX4J4vgHiI3uxaQJQPDVnfc0pR+j2XrK3cn8cJRMwEl9B6DT2ZDcKr2SUxehhe6OcAO57nYLLSOZe+JVP0p4uoMUctfZeoU40Bo2lVjZHnhKd9+zEeDGuqbMXz6ToccqQAa2426B44W5RGRa+9we/Zts5adaalZZu1vnpdn74w3UjBy+ggEwsuu03mjQqLjl1mBB97PaOOdG+XBiczc=';
          // dispatch({ type: 'setStateMultiLayers', keys: ['cardRegistration', 'feature'], value: mnemonic.toString() }); 
          // const encryptedU8A = Uint8Array.from(window.atob(mnemonicEncrypted), byte => byte.charCodeAt(0));
          // dispatch({ type: 'setStateMultiLayers', keys: ['cardRegistration', 'name'], value: mnemonicEncrypted }); 
        }
        catch (error) {
          dispatch({ type: 'setNotification', key: 'notification', value: words.cannotReadDataFromTheKeyCard[state.language] });
        }
      }

      // 先に後処理やっちゃう。
      ndef.onreading = null;
      ndef.onreadingerror = null;

      if (argumentsToPass.some( arg => arg.length === 0 )) {
        dispatch({ type: 'setNotification', key: 'notification', value: words.cannotReadDataFromTheKeyCard[state.language] });
        throw new Error('noArgumentToPass');
      }

      callback(...argumentsToPass);
    };

    ndef.onreadingerror = () => {
      dispatch({ type: 'setNotification', key: 'notification', value: words.cannotReadDataFromTheKeyCard[state.language] });

      ndef.onreading = null;
      ndef.onreadingerror = null;
    };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.yourDeviceDoesNotSupportKeyCards[state.language] });
  }
}

// NFC読み込み READ NFC
async function readNfc(state, dispatch, encryptedMnemonic) {
  if (!('NDEFReader' in window)) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.yourDeviceDoesNotSupportKeyCards[state.language] });
    return;
  }

  const ndef = new window.NDEFReader();

  const result = await ndef.write({
    records: [
      {
        recordType: "url",
        data: "https://knightsofmonadom.monatoka.com/?wallettype=nfc"
      },
      {
        recordType: "text",
        lang: "en",
        encoding: "utf-8",
        data: encryptedMnemonic,
        // data: state.displaySecret.mnemonicToWif,
      },
    ]
  })
  .then( (result) => {
    dispatch({ type: 'setNotification', key: 'notification', value: 'NDEF messages written!' });
    return;
  })
  .catch( (error) => {
    dispatch({ type: 'setNotification', key: 'notification', value: 'Failed to write NDEF messages.' });
    throw new Error(error);
  });

  return result;
}

// NFC書き込み WRITE MNEMONIC ON NFC
async function writeMnemonicOnNfc(state, dispatch, encryptedMnemonic) {
  if (!('NDEFReader' in window)) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.yourDeviceDoesNotSupportKeyCards[state.language] });
    return;
  }

  const ndef = new window.NDEFReader();

  const result = await ndef.write({
    records: [
      {
        recordType: "url",
        data: "https://knightsofmonadom.monatoka.com/?wallettype=nfc"
      },
      {
        recordType: "text",
        lang: "en",
        encoding: "utf-8",
        data: encryptedMnemonic,
        // data: state.displaySecret.mnemonicToWif,
      },
    ]
  })
  .then( (result) => {
    dispatch({ type: 'setNotification', key: 'notification', value: 'NDEF messages written!' });
    return;
  })
  .catch( (error) => {
    dispatch({ type: 'setNotification', key: 'notification', value: 'Failed to write NDEF messages.' });
    throw new Error(error + encryptedMnemonic);
  });

  return result;
}

// NFC書き込んだニーモニックチェック CHECK MNEMONIC ON NFC
function checkMnemonicOnNfc(state, dispatch, mnemonicToBeWritten, mnemonicsWritten) {
  if (mnemonicToBeWritten === mnemonicsWritten[0]) {
    localStorage.setItem('swapStatus', 'checkedMnemonic');
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'check mnemonic OK.' }); // 暫定
  }
  else {
    localStorage.setItem('swapStatus', 'sweptMona');
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'check mnemonic NG.' }); // 暫定
  }
}

// mnemonicから秘密鍵取得 GET KEYPAIRS FROM MNEMONIC
function getKeyPairsFromMnemonic(mnemonic) {
  let keyPairs = [];

  try {
    const seed = bip39.mnemonicToSeedSync(mnemonic);
    const root = bip32.fromSeed(seed)
    const path = "m/44'/22'/0'/0/0";
    const leaf = root.derivePath(path);
    const keyPair = ECPair.fromPrivateKey(leaf.privateKey, { network: networkMona });
    keyPairs.push(keyPair);
  }
  catch (error) {
    devLog('getKeyPairsFromMnemonic ; error', error);
    throw new Error('cannotGetKeyPairsFromMnemonic');
  }

  return keyPairs;
}

// 鍵渡してcertificationつくる CERTIFICATION WITH KEY
function certificationWithKey(state, dispatch, keyPairs, messageType, stateFixed, certifications, clientTime, extendedCertificationProperties, signingMode) {
  let addressIsSpecified;

  // lastEvaluatedSortKey等があるということは、前回の検索時からaddressMainが変更されていないということ。(変更するとリセットされてしまう。)
  // addressMainが指定されていないということは、後ページ検索ではない。
  // なぜならば、addressMainが指定されていない状態にするには、前回の検索のあと、addressMainを削除しなければならないから。
    
  if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '') {
    addressIsSpecified = true;
  }
  else {
    addressIsSpecified = false;
  }

  if (signingMode === 'firstKey') {
    keyPairs = [ keyPairs[0] ];
  }
  else { // matchedKey
  }

  for (const [index, keyPair] of keyPairs.entries()) {
    // アドレス導出
    let payment;
    let address;
    let addressActual;

    try {
      payment = bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey, network: networkMona });
      addressActual = payment.address;
    }
    catch (err) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      continue;
    }

    if (signingMode === 'matchedKey') {
      if (addressActual !== stateFixed.addressMain) {
        continue;
      }
      else {
        address = stateFixed.addressMain;
      }
    }
    else { // firstKey
      if (addressIsSpecified) {
        if (checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressActual, stateFixed.addressMain, stateFixed.addressCheck)) {
          address = stateFixed.addressMain;
        }
        else {
          continue;
        }
      }
      else {
        address = addressActual;
        stateFixed.addressMain = addressActual;
      }
    }

    // 渡された鍵ペアに対応するアドレスがcertificationsに含まれていなければ、署名する。
    if (address !== undefined && address !== null && address !== '' && !certifications.map( certification => certification.address ).includes(address)) {
      let message;

      if (messageType === 'login') {
      //   if (stateFixed.addressMain !== undefined || stateFixed.addressMain !== null || stateFixed.addressMain !== '') {
      //     message = `I want to log in to monacotto as ${stateFixed.addressMain}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
      //   }
      //   else {
        message = `I want to log in to monacotto as ${address}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
      //   }
      }
      else if (messageType === 'information') {
        message = `I want to get the information of ${address}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
      }

      devLog('message', message);

      let signatureMona;

      const result = signWithKey(state, dispatch, keyPair, message);

      if (result.status === 'fulfilled') {
        signatureMona = result.signature;
      }
      else {
        dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
        continue;
      }

      const certification = {
        // addressCoinType: 'mona',
        address: address,
        addressActual: addressActual,
        clientTime: clientTime,
        signatureVersion: stateFixed.signatureVersion,
        signature: signatureMona,
        lastEvaluatedSortKey: stateFixed.lastEvaluatedSortKey,
      };

      // 拡張プロパティ
      if (extendedCertificationProperties !== undefined && extendedCertificationProperties !== null) {
        for (const property of extendedCertificationProperties) {
          certification[property.key] = property.value;
        }
      }

      devLog('certification given key', JSON.stringify(certification));

      certifications.push(certification);
    }
  }
}

// アドレス抽出 GET ADDRESS FROM KEY
function getAddressFromKey (state, dispatch, keyPair) {
  // アドレス導出
  let payment;
  let address;

  try {
    payment = bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey, network: networkMona });
    address = payment.address;

    return {
      status: 'fulfilled',
      address: address,
    };
  }
  catch (err) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });

    return {
      status: 'rejected',
    };
  }
}

// 鍵渡して署名 SIGN WITH KEY
function signWithKey(state, dispatch, keyPair, message) {
  let signatureMona;

  try {
    let randomBytes = new Uint8Array(32);
    window.crypto.getRandomValues(randomBytes);
    signatureMona = bitcoinMessage.sign(message, keyPair.privateKey, keyPair.compressed, networkMona.messagePrefix, { extraEntropy: Buffer.from(randomBytes) }).toString('base64');

    return {
      status: 'fulfilled',
      signature: signatureMona,
    };
  }
  catch (err) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] + 'SWK' });

    return {
      status: 'rejected'
    };
  }

}

// DECRYPT
async function decrypt(state, dispatch, encryptedData, password, salt, iv) {
  try {
    // 暗号化データをUint8Arrayに変換
    const encryptedU8A = Uint8Array.from(window.atob(encryptedData), byte => byte.charCodeAt(0));

    // パスワードをUint8Arrayに変換
    const passwordU8A = new TextEncoder().encode(password);

    // ソルトをUint8Arrayに変換
    const saltHexArray = salt;
    const saltNumberArray = saltHexArray.map( hex => Number('0x' + hex) );
    const saltU8A = Uint8Array.from(saltNumberArray);

    // if (salt !== undefined && salt !== null) {
    //   const saltBstr = window.atob(salt); // ソルトをBASE64からバイナリバイト列へデコード
    //   const saltU8A = Uint8Array.from(saltBstr, byte => byte.charCodeAt(0));
    //   deriveKeyAlgorithm.salt = saltU8A;
    // }

    // IVをUint8Arrayに変換
    const ivHexArray = iv;
    const ivNumberArray = ivHexArray.map( hex => Number('0x' + hex) );
    const ivU8A = Uint8Array.from(ivNumberArray);

    // if (iv !== undefined && iv !== null) {
    //   ivU8A = Uint8Array.from(window.atob(iv), byte => byte.charCodeAt(0));
    // }

    // PBKDF2を使用して鍵を導出
    const keyMaterial = await window.crypto.subtle.importKey(
        "raw",
        passwordU8A,
        { name: "PBKDF2" },
        false,
        ["deriveKey"]
    );

    const deriveKeyAlgorithm = {
        name: "PBKDF2",
        salt: saltU8A,
        iterations: 514,
        hash: "SHA-256"
    };

    const key = await window.crypto.subtle.deriveKey(
        deriveKeyAlgorithm,
        keyMaterial,
        { name: "AES-CBC", length: 256 },
        false,
        ["decrypt"]
    );

    // データを復号
    const decryptedABuff = await window.crypto.subtle.decrypt(
        { name: "AES-CBC", iv: ivU8A },
        key,
        encryptedU8A
    );

    // 復号されたデータを文字列に変換
    const decrypted = new TextDecoder().decode(decryptedABuff);

    // トリム
    const decryptedTrimmed = decrypted.trim();

    return decryptedTrimmed;
    // return new Uint8Array(decryptedABuff);
  } catch (e) {
    console.error("Decryption failed", e);
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToDecrypt[state.language] });
    throw e;
  }
}

// ENCRYPT
async function encrypt(state, dispatch, plaintext, password, salt, iv) {
  try {
    // 平文データをUint8Arrayに変換
    // const plaintextU8A = Uint8Array.from(window.atob(plaintext), byte => byte.charCodeAt(0));
    const plaintextU8A = new TextEncoder().encode(plaintext);

    // パスワードをUint8Arrayに変換
    const passwordU8A = new TextEncoder().encode(password);

    // ソルトをUint8Arrayに変換
    const saltHexArray = salt;
    const saltNumberArray = saltHexArray.map( hex => Number('0x' + hex) );
    const saltU8A = Uint8Array.from(saltNumberArray);

    // if (salt !== undefined && salt !== null) {
    //   const saltBstr = window.atob(salt); // ソルトをBASE64からバイナリバイト列へデコード
    //   const saltU8A = Uint8Array.from(saltBstr, byte => byte.charCodeAt(0));
    //   deriveKeyAlgorithm.salt = saltU8A;
    // }

    // IVをUint8Arrayに変換
    const ivHexArray = iv;
    const ivNumberArray = ivHexArray.map( hex => Number('0x' + hex) );
    const ivU8A = Uint8Array.from(ivNumberArray);

    // if (iv !== undefined && iv !== null) {
    //   ivU8A = Uint8Array.from(window.atob(iv), byte => byte.charCodeAt(0));
    // }

    // PBKDF2を使用して鍵を導出
    const keyMaterial = await window.crypto.subtle.importKey(
        "raw",
        passwordU8A,
        { name: "PBKDF2" },
        false,
        ["deriveKey"]
    );

    const deriveKeyAlgorithm = {
        name: "PBKDF2",
        salt: saltU8A,
        iterations: 514,
        hash: "SHA-256"
    };

    const key = await window.crypto.subtle.deriveKey(
        deriveKeyAlgorithm,
        keyMaterial,
        { name: "AES-CBC", length: 256 },
        false,
        ["encrypt"]
    );

    // データを暗号化
    const encryptedABuff = await window.crypto.subtle.encrypt(
        { name: "AES-CBC", iv: ivU8A },
        key,
        plaintextU8A
    );

    // 暗号化されたデータを文字列に変換
    // const encrypted = new TextDecoder().decode(encryptedABuff);
    const encrypted = arrayBufferToBase64(encryptedABuff);

    // トリム
    const encryptedTrimmed = encrypted.trim();

    dispatch({ type: 'setNotification', key: 'notification', value: 'encryptedTrimmed ' + encryptedTrimmed });
    return encryptedTrimmed;
    // return new Uint8Array(decryptedABuff);
  } catch (e) {
    console.error("Encryption failed", e);
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToEncrypt[state.language] });
    // throw e;
  }
}

// QRボタン押下 HANDLE CLICK SCAN QR
function handleClickScanQr(state, dispatch, callback, { args = ['keyPairs'], recordNos = [0], callbackType = 'decryptMnemonic', popupLayer = 0 } = {}) {
  devLog('handleClickScanQr');
  const popup = {
    type: 'generalItems',
    body: <QrCodeScanner popupLayer={popupLayer} callback={callback} callbackType={callbackType} options={ {args, recordNos} } />,
    // body: <div>hoge</div>,
  };

  dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: popup });
}

// DO SOMETHING WITH ENCRYPTED MNEMONICS
async function doSomethingWithEncryptedMnemonics(state, dispatch, mnemonicsEncrypted, callback, { args = ['keyPairs'], recordNos = [0] } = {}) {
  const keyPairs = [];
  const mnemonics = [];

  const argumentsAll = { keyPairs, mnemonics };
  const argumentsToPass = args.map( arg => argumentsAll[arg] );

  for (const recordNo of recordNos) {
    try {
      const pass = state.config.clientParameters.specialMessage;
      const salt = ['00', '00', '00', '00', '00', '00', '00', '72'];
      const iv = ['00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '01', 'bf', '52'];
      const mnemonic = await decrypt(state, dispatch, mnemonicsEncrypted[recordNo], pass, salt, iv);
      mnemonics.push(mnemonic);
      const keyPair = getKeyPairsFromMnemonic(mnemonic)[0];
      keyPairs.push(keyPair);
    }
    catch (error) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.cannotReadDataFromTheKeyCard[state.language] });
    }
  }

  if (argumentsToPass.some( arg => arg.length === 0 )) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.cannotReadDataFromTheKeyCard[state.language] });
    throw new Error('noArgumentToPass');
  }

  callback(...argumentsToPass);
}

// ArrayBufferをBase64文字列に変換する関数
function arrayBufferToBase64(buffer) {
    // ArrayBufferからバイナリ文字列を生成
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    // window.btoa関数でバイナリ文字列をBase64にエンコード
    return window.btoa(binary);
}

// KEEP KEY PAIRS IN MEMORY
function keepKeyPairsInMemory(state, dispatch, keyPairs) {
  const now = new Date();

  for (const keyPair of keyPairs) {
    // アドレス導出
    let payment;
    let address;

    try {
      payment = bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey, network: networkMona });
      address = payment.address;
    }
    catch (err) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      continue;
    }

    const keyPairData = {
      keyPair: keyPair,
      expiration: now.getTime() + state.config.clientParameters.periodToKeepKeyPairs,
    };

    const keyPairsState = state.keyPairs;
    keyPairsState[address] = keyPairData;

    // dispatch({ type: 'setNotification', key: 'notification', value: 'keep' + address });
    // dispatch({ type: 'setStateMultiLayers', keys: ['keyPairs', address], value: keyPairData });
    dispatch({ type: 'setState', key: 'keyPairs', value: keyPairsState });
  }
}

// GET VALID KEY PAIRS
function getValidKeyPairs(state, dispatch) {
  const keyPairs = state.keyPairs;
  const validKeyPairs = {};
  const now = new Date();

  for (const address of Object.keys(keyPairs)) {
    if (keyPairs[address].expiration > now.getTime()) {
      validKeyPairs[address] = keyPairs[address];
    }
  }

  dispatch({ type: 'setState', key: 'keyPairs', value: validKeyPairs });

  return validKeyPairs;
}

async function hasCamera() {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.some(device => device.kind === 'videoinput');
  } catch (error) {
    devLog("An error occurred:", error);
    return false;
  }
}

// アドレスセクション＆ユーザー名セクション
function getAddressAndUserNameSections(state, dispatch) {
  // アドレスセクション・スイッチアイコン

  let addressSection;
  let switchIcon;

  if (state.configure.displayAddressSection) {
    addressSection = <AddressSection keys={['configure', 'addressMain']} />;
    switchIcon = iconCollapse;
  }
  else {
    switchIcon = iconExpand;
  }

  // ユーザー名セクション

  const userNameSection =
  <div className='flexRow justifyContentFlexStart alignItemsCenter '>
    <TextLine3 fieldName='userName' keys={['configure', 'userName']} face={words.userName[state.language]} tooltip='right' />
    <button className={'button1'} tabindex='0'
      onClick={ () => {
        dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'displayAddressSection'], value: state.configure.displayAddressSection ? false : true });
      }}
    >
      <img className='size2x2' src={switchIcon} alt='' />
    </button>
  </div>

  return { addressSection, userNameSection };
}


// HTTPリクエスト（同期バージョン2）
async function syncHttpRequest2(request, setResultKey, sortFunction, setNotificationKey, messages, dispatch) {
  if (request.headers === undefined) {
    request.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };
  }

  if (request.method === undefined) {
    request.method = 'POST';
  }

  /*
  const method = 'POST';
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };
  */

  const result = await fetch(request.url, { method: request.method, headers: request.headers, body: request.body })
  .then( response => response.json() )
  .then( async json => {
           const applicationMessage = json.applicationMessage;

           devLog(applicationMessage, messages[applicationMessage], 'setNotificationKey', setNotificationKey);

           if (setNotificationKey !== undefined) {
             devLog('inner', setNotificationKey);
             dispatch({ type: 'setNotification', key: setNotificationKey,
                        value: (messages[applicationMessage] ? messages[applicationMessage] : applicationMessage) });  
           }

           if (json.applicationStatus.substring(0,1) === '2') {
             let body = json.body;

             if (setResultKey !== undefined) {
               devLog(setResultKey);
               devLog(JSON.stringify(body));

               if (sortFunction !== undefined) {
                 body.sort(sortFunction);
               }

               dispatch({ type: 'setState', key: setResultKey, value: body });
             }

             return { status: 'fulfilled', body: body };
           }
           else {
             return { status: 'rejected', body: json.body };
           }
         }
  )
  .catch( error => {
           return { status: 'rejected', error: error };
          }
  );

  return result;
}

// HTTPリクエスト（同期バージョン）
async function syncHttpRequest(request) {
  if (request.headers === undefined) {
    request.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };
  }

  return await fetch(request.url, { method: request.method, headers: request.headers, body: request.body })
  .then( response => response.json() )
  .then( json => {
    devLog('syncHttpRequest fulfilled', JSON.stringify(json));
    return { status: 'fulfilled', body: json };
  })
  .catch( error => {
    devLog('syncHttpRequest rejected', JSON.stringify(error));
    return { status: 'rejected', body: error };
  });
}

function toFloat(string) {
  if (Number.isFinite(string) || string === Infinity) {
    return string;
  }

  const numericCharacter = string.replace(/[^\d.]/, '');
  let float = parseFloat(numericCharacter);

  if (Number.isNaN(float)) {
    float = ''
  }

  return float;
}

// LOCAL TIME
function localTime(epoch, format) {
  const epochNum = parseInt(epoch, 10);
  let time;

  if (Number.isNaN(epochNum) || epochNum === '' || epochNum === undefined) {
    return '';
  }

  time = new Date(epochNum);

  if (format === 'yearToMinute') {
    return `${time.getFullYear()}/${(time.getMonth() + 1).toString().padStart(2, '0')}/${time.getDate().toString().padStart(2, '0')} ${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}`;
  }
  else if (format === 'digit14Num') {
    return parseInt(`${time.getFullYear()}${(time.getMonth() + 1).toString().padStart(2, '0')}${time.getDate().toString().padStart(2, '0')}${time.getHours().toString().padStart(2, '0')}${time.getMinutes().toString().padStart(2, '0')}${time.getSeconds().toString().padStart(2, '0')}`, 10);
  }
  else {
    return `${time.getFullYear()}/${(time.getMonth() + 1).toString().padStart(2, '0')}/${time.getDate().toString().padStart(2, '0')} ${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}:${time.getSeconds().toString().padStart(2, '0')}.${time.getMilliseconds()}`;
  }
}

// MINUTES AND SECONDS
function minutesAndSeconds(milliseconds) {
  if (milliseconds <= 0) {
    return '00:00';
  }

  const seconds = Math.floor(milliseconds / 1000); 
  return Math.floor(seconds / 60).toString().padStart(2, '0') + ':' + (seconds % 60).toString().padStart(2, '0');
}

// DECIMAL ADJUST
function decimalAdjust(type, value, exp) {
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  // If value is Infinity...
  if (value === Infinity) {
    return Infinity;
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

// ADJUST MULTI LAYERS FLOAT
function adjustFloat(type, value, exp) {
  let number = parseFloat(value);

  if (Number.isNaN(number)) {
    number = ''
  }
  else if (type !== undefined) {
    number = decimalAdjust(type, number, exp);
  }

  return number;
}

// URL PARAMS STRINGIFY
function urlParamsStringify(urlParams) {
  return urlParams.reduce( (acc, cur) => {
    if (cur !== null) {
      return acc + (acc === '' ? '?' : '&') + cur;
    }
    else {
      return acc;
    }
  }, '' );
}

// DEVLOG
function devLog(...words) {
  if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
    console.log(words.join(' '));
  }
  return 1;
}

// REDUCER
function reducer(state, action) {
  let newState = { ...state };
  let numericCharacter;
  let number;
  let amountMona;
  let amountJpyc;
  let isChanged;
  let items;
  let item;
  let newIndex;

  switch (action.type) {
    case 'setState':
      newState[action.key] = action.value;
      return newState;

    case 'setStateMultiLayers':
      action.keys.concat([action.value]).reduce( (acc, cur, idx, src) => {
        if (idx === src.length - 1) {
          return acc;
        }
        if (idx === src.length - 2) {
          return acc[cur] = src[idx + 1];
        }
        else {
          return acc[cur];
        }
      }, newState );
      return newState;

    case 'setStateNum':
      number = parseInt(action.value, 10);

      if (Number.isNaN(number)) {
        newState[action.key] = ''
      }
      else {
        newState[action.key] = number;
      }

      return newState;

    case 'setStateMultiLayersNum':
      number = parseInt(action.value, 10);

      if (Number.isNaN(number)) {
        number = ''
      }

      action.keys.reduce( (acc, cur, idx, src) => {
        if (idx === src.length - 1) {
          return acc[cur] = number;
        }
        else {
          return acc[cur];
        }
      }, newState );

      return newState;

    case 'setStateFloat':
      number = parseFloat(action.value);

      if (Number.isNaN(number)) {
        newState[action.key] = ''
      }
      else {
        newState[action.key] = number;
      }

      return newState;

    // setStateMultiLayersFloat
    case 'setStateMultiLayersFloat':
      numericCharacter = action.value.replace(/[^\d.]/, '');
      number = parseFloat(numericCharacter);

      if (Number.isNaN(number)) {
        number = ''
      }
      else if (action.adjustType !== undefined) {
        number = decimalAdjust(action.adjustType, number, action.adjustExp);
      }

      action.keys.reduce( (acc, cur, idx, src) => {
        if (idx === src.length - 1) {
          acc[cur].face = numericCharacter;
          acc[cur].value = number;
          return acc[cur];
        }
        else {
          return acc[cur];
        }
      }, newState );

      return newState;

    // adjustFace
    case 'adjustFace':
      action.keys.reduce( (acc, cur, idx, src) => {
        if (idx === src.length - 1) {
          acc[cur].face = acc[cur].value;
          return acc[cur];
        }
        else {
          return acc[cur];
        }
      }, newState );

      return newState;

    // pushOut
    case 'pushOut':
      const target = action.targetKeys.reduce( (acc, cur) => {
          return acc[cur];
      }, newState );

      action.keys.reduce( (acc, cur, idx, src) => {
        const pushedOut = target[cur];
        target[cur] = acc;
        return  pushedOut;
      }, action.value );

      return newState;

    case 'setStateIfChange':
      if (newState[action.key] != action.value) {
        newState[action.key] = action.value;
        return newState;
      }
      else {
        return state;
     }

    case 'setStateMultiLayersIfChange':
      isChanged = action.keys.concat([action.value]).reduce( (acc, cur, idx, src) => {
        if (idx === src.length - 1) {
          return acc;
        }
        if (idx === src.length - 2) {
          if (acc[cur] !== src[idx + 1]) {
            acc[cur] = src[idx + 1];
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return acc[cur];
        }
      }, newState );

      if (isChanged) {
        return newState;
      }
      else {
        return state;
      }

    // setNotification
    case 'setNotification':
      newState[action.key].body.push(action.value);

      if (newState[action.key].body.length > notificationMaxLength) {
        newState[action.key].body.shift();
      }

      newState[action.key].index = newState[action.key].body.length -1;

      newState[action.key].inAnimation = true;

      return newState;

    case 'stopNotificationAnimation':
      newState[action.key].inAnimation = false;
      return newState;

    // itemReplace
    case 'itemReplace':
      items = newState.items[newState.gallery.addressMain].itemPlacement[0].items;
      item = items[action.index];

      if (action.direction === 'left') {
        newIndex = action.index - 1 >= 0 ? action.index -1 : items.length - 1;
      }
      else { // right
        newIndex = action.index + 1 <= items.length - 1 ? action.index + 1 : 0;
      }

      items.splice(action.index, 1);
      items.splice(newIndex, 0, item);

      return newState;

    // default
    default:
      return state;
  }
}

export default App;
